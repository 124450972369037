import React, { useEffect, useRef, useState } from 'react';
import CurrencyInput from 'react-currency-input-field';
import { Link, useHistory } from 'react-router-dom';
import Select from 'react-select';
import Swal from 'sweetalert2';

import BreadCrumb from '../../../components/BreadCrumb';
import DefaultButton from '../../../components/DefaultButton';
import { DefaultCreationFormButtonGroup, DefaultCreationFormGroup } from '../../../components/DefaultCreationForm';
import DefaultInput from '../../../components/DefaultInput';
import { DefaultPageTitle } from '../../../components/DefaultPageTitle';
import Content from '../../../models/content';
import { CouponTargetTypes, CouponTypes, ICreateCouponDTO } from '../../../models/coupon';
import { getAllContents } from '../../../services/contents';
import { createCoupon } from '../../../services/coupons';
import { oneMinToMidNight } from '../../../utils/date';
import { CreateCouponForm, CreateCouponPageContainer, SelectContainer, CouponTypeContainer } from './styles';

interface ISelectOption {
  label: string;
  value: string;
}

export const CreateCouponPage = () => {
  const history = useHistory();

  const [contentsOptions, setContentsOptions] = useState<ISelectOption[]>([]);
  const selectContentsInputRef = useRef<any>(null);

  const [couponType, setCouponType] = useState<CouponTypes>(CouponTypes.AMOUNT);
  const [couponTypeAmount, setCouponTypeAmount] = useState(0);
  const [couponTypePercentage, setCouponTypePercentage] = useState(5);
  const [couponUsageType, setCouponUsageType] = useState<'quantity' | 'time'>('quantity');
  const couponCodeInputRef = useRef<HTMLInputElement>(null);
  const couponQuantityInputRef = useRef<HTMLInputElement>(null);
  const couponStartAtInputRef = useRef<HTMLInputElement>(null);
  const couponFinishAtInputRef = useRef<HTMLInputElement>(null);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const selectedContent = selectContentsInputRef?.current?.state?.value ?? [];
    const value = couponType === CouponTypes.AMOUNT ? couponTypeAmount : couponTypePercentage;

    const data: ICreateCouponDTO = {
      coupon_type: couponType as CouponTypes,
      code: couponCodeInputRef.current?.value || undefined,
      [couponType]: value,
      quantity: couponQuantityInputRef.current?.valueAsNumber || undefined,
      start_at: couponStartAtInputRef.current?.valueAsDate || undefined,
      finish_at: oneMinToMidNight(couponFinishAtInputRef.current?.valueAsDate) || undefined,
      applications: selectedContent.value
        ? [
            {
              coupon_target_type: CouponTargetTypes.CONTENT,
              target_id: selectedContent.value,
            },
          ]
        : [],
    };

    try {
      if (data.code && !/^\w{8,}$/i.test(data.code)) {
        throw new Error(
          'Código de cupom deve conter no mínimo 8 caráteres composto apenas por letras, números, e o símbolo de (_)!'
        );
      }
      if (couponType === CouponTypes.AMOUNT && couponTypeAmount <= 0) {
        throw new Error('O valor do cupom deve ser maior que zero!');
      }

      if (couponType === CouponTypes.PERCENTAGE && (couponTypePercentage < 5 || couponTypePercentage > 100)) {
        throw new Error('A porcentagem deve estar entre 5% à 100%');
      }

      if (data.quantity && data.quantity <= 0) {
        throw new Error('O número de utilizações deve ser maior que zero!');
      }

      if (data.start_at && data.finish_at && data.start_at >= data.finish_at) {
        throw new Error('A data inicial deve ser menor que a final!');
      }

      await createCoupon(data);

      Swal.fire({
        title: 'Cupom criado com sucesso.',
        icon: 'success',
      });
      history.replace('/coupons');
    } catch (e: any) {
      Swal.fire({
        title: 'Erro!',
        text: e.response?.data.message || e.message,
        icon: 'error',
      });
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const response = await getAllContents({
          flag: ['course', 'retreat'],
          type: ['on-demand'],
        });
        setContentsOptions(response.data?.map((course: Content) => ({ label: course.name, value: course.content_id })));
      } catch {
        Swal.fire({
          title: 'Erro!',
          text: 'Ocorreu um erro ao recuperar os cursos!',
          icon: 'error',
        });
      }
    })();
  }, []);

  return (
    <CreateCouponPageContainer>
      <BreadCrumb
        crumbs={[<Link to="/dashboard">Dashboard</Link>, <Link to="/coupons">Cupons</Link>, <span>Criar cupom</span>]}
      />

      <DefaultPageTitle>Criar cupom</DefaultPageTitle>
      <section>
        <CreateCouponForm onSubmit={handleSubmit}>
          <DefaultCreationFormGroup>
            <label htmlFor="code">
              Código <small className="explain"> | deixe em branco para gerar códigos automaticamente</small>
            </label>
            <DefaultInput
              style={{ textTransform: 'uppercase' }}
              ref={couponCodeInputRef}
              id="code"
              name="code"
              maxLength={250}
            />
          </DefaultCreationFormGroup>

          <CouponTypeContainer>
            <label className="required">
              <input
                required
                type="radio"
                name="coupon_type"
                defaultChecked={true}
                value="amount"
                onClick={() => setCouponType(CouponTypes.AMOUNT)}
              />
              <span>Valor</span>
            </label>
            <label className="required">
              <input
                required
                type="radio"
                name="coupon_type"
                value="percentage"
                onClick={() => setCouponType(CouponTypes.PERCENTAGE)}
              />
              <span>Porcentagem</span>
            </label>
          </CouponTypeContainer>

          <DefaultCreationFormGroup>
            <CurrencyInput
              className={`currency-input show-${couponType === CouponTypes.AMOUNT}`}
              id="amountDiscount"
              name="amountDiscount"
              placeholder="Digite um valor"
              prefix="R$ "
              defaultValue={couponTypeAmount}
              decimalScale={2}
              decimalSeparator=","
              groupSeparator="."
              intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
              onValueChange={(value) => {
                setCouponTypeAmount(Number(value?.replace(',', '.')));
              }}
            />
            <CurrencyInput
              className={`currency-input show-${couponType !== CouponTypes.AMOUNT}`}
              id="percentageDiscount"
              name="percentageDiscount"
              suffix=" %"
              allowDecimals={false}
              placeholder="Digite o valor em porcentagem"
              defaultValue={couponTypePercentage}
              onValueChange={(value) => {
                setCouponTypePercentage(Number(value?.replace(',', '.')));
              }}
              decimalSeparator=","
              groupSeparator="."
              maxLength={3}
              max={100}
            />
          </DefaultCreationFormGroup>

          <CouponTypeContainer>
            <label className="required">
              <input
                required
                type="radio"
                name="coupon_usage_type"
                defaultChecked={true}
                value="quantity"
                onClick={() => setCouponUsageType('quantity')}
              />
              <span>Número de utilizações</span>
            </label>
            <label className="required">
              <input
                required
                type="radio"
                name="coupon_usage_type"
                value="time"
                onClick={() => setCouponUsageType('time')}
              />
              <span>Tempo de utilização</span>
            </label>
          </CouponTypeContainer>

          <DefaultCreationFormGroup className={`show-${couponUsageType === 'quantity'}`}>
            <DefaultInput
              required={couponUsageType === 'quantity'}
              ref={couponQuantityInputRef}
              type="number"
              min={1}
              id="quantity"
              name="quantity"
            />
          </DefaultCreationFormGroup>

          <div id="date-limites" className={`show-${couponUsageType !== 'quantity'}`}>
            <DefaultCreationFormGroup>
              <label className="required" htmlFor="start_at">
                Inicia em
              </label>
              <DefaultInput
                required={couponUsageType !== 'quantity'}
                ref={couponStartAtInputRef}
                id="start_at"
                name="start_at"
                type="date"
              />
            </DefaultCreationFormGroup>

            <DefaultCreationFormGroup>
              <label className="required" htmlFor="finish_at">
                Termina em
              </label>
              <DefaultInput
                required={couponUsageType !== 'quantity'}
                ref={couponFinishAtInputRef}
                id="finish_at"
                name="finish_at"
                type="date"
              />
            </DefaultCreationFormGroup>
          </div>

          <SelectContainer>
            <label htmlFor="contents">
              Curso <small className="explain"> | Deixar em branco o cupom é aplicado a todos os cursos</small>
            </label>
            <Select
              id="contents"
              ref={selectContentsInputRef}
              options={contentsOptions}
              placeholder="Selecione um curso"
              isClearable
              noOptionsMessage={() => 'Sem cursos disponíveis'}
            />
          </SelectContainer>

          <DefaultCreationFormButtonGroup>
            <DefaultButton
              type="button"
              className="danger"
              onClick={() => {
                history.replace('/coupons');
              }}
            >
              Cancelar
            </DefaultButton>
            <DefaultButton type="submit" className="success">
              Salvar
            </DefaultButton>
          </DefaultCreationFormButtonGroup>
        </CreateCouponForm>
      </section>
    </CreateCouponPageContainer>
  );
};
