import httpClient from '../http-client';
import Content from '../models/content';

export enum Relations {
  parents = 'parents',
  children = 'children',
  content_user = 'content_user',
  content_users = 'content_users',
  authors = 'authors',
  tags = 'tags',
  categories = 'categories',
  extra_materials = 'extra_materials',
  reviews = 'reviews',
  progress = 'progress',
  exams = 'exams',
}

interface GetContentsQuery {
  name?: string;
  flag?: string[];
  is_active?: boolean;
  is_highlight?: boolean;
  is_recommended?: boolean;
  description?: string;
  deadline?: string;
  type?: string[];
  content_ids?: string[];
  author_ids?: string[];
  tag_ids?: string[];
  category_ids?: string[];
  book_mark?: boolean;
  is_finished?: boolean;
  in_progress?: boolean;
  limit?: number;
  offset?: number;
  client_relations?: 'live';
  relations?: Relations[];
  order_by?: 'name' | 'flag' | 'is_active' | 'is_highlight' | 'is_recommended' | 'type' | 'duration' | 'most_viewed';
  sort_by?: string;
  unrelated?: boolean;
  info_key?: string;
  info_value?: boolean;
  exclude_content_ids?: string[];
}

interface GetContentsResponse {
  data: Content[];
  count: number;
}

const getContents = async (query: GetContentsQuery = {}) => {
  return httpClient.get<GetContentsResponse>('/contents', { params: query }).then((res) => res.data);
};

const uploadSubtitles = async (mediaId: string, formData: FormData) => {
  await httpClient.post(`webhooks/caption/${mediaId}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

const deleteContent = async (contentId: string) => {
  return httpClient.delete(`contents/${contentId}`);
};

const getAllContents = async (query: GetContentsQuery = {}) => {
  return httpClient.get('/contents', { params: query }).then((res) => res.data);
};

const activateOrInactivateContent = async (contentId: string, activate: boolean) => {
  return httpClient.patch(`contents/${contentId}/active`, { is_active: activate });
};

const getContent = async (contentId: string) => {
  const response = await httpClient.get<{ associated_plans: string[]; data: Content }>(`contents/${contentId}`, {
    params: {
      relations: ['extra_materials', 'children', 'authors', 'tags', 'categories'],
      client_relations: ['live'],
      admin: true
    },
  });

  const course = {
    ...response.data.data,
    associated_plans: response.data.associated_plans,
  };

  if (course && course.children) {
    course.children = course.children.sort((a: any, b: any) => (a.position > b.position ? 1 : -1));
  }

  return course;
};

const updateContent = async (contentId: string, data: any) => {
  return httpClient.put<Content>(`contents/${contentId}`, data).then((res) => res.data);
};

const createContent = async (content: Content) => {
  return httpClient.post<Content>('contents', content).then((res) => res.data);
};

const updateSchedule = async (contentId: string, scheduled: boolean) => {
  return httpClient.put<Content>(`contents/${contentId}`, { info: { scheduled: scheduled } });
};

const updateNextRelease = async (contentId: string, nextRelease: boolean) => {
  return httpClient.put<Content>(`contents/${contentId}`, { info: { next_release: nextRelease } });
};

export {
  uploadSubtitles,
  getContents,
  deleteContent,
  getAllContents,
  activateOrInactivateContent,
  getContent,
  updateContent,
  updateSchedule,
  updateNextRelease,
  createContent,
};
