import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { BiTrash } from 'react-icons/bi';
import { AiOutlineLink } from 'react-icons/ai';
import { Link, useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';

import BreadCrumb from '../../components/BreadCrumb';
import { CreateButtonDefaultContainer } from '../../components/CreateButtonDefaultContainer';
import { DefaultPageTitle } from '../../components/DefaultPageTitle';
import DefaultTable from '../../components/DefaultTable';
import Content from '../../models/content';
import { deleteContent, getAllContents, Relations } from '../../services/contents';
import Pagination from '../../components/Pagination';
import FilterForm from '../../components/DefaultFilterTable';
import calculatePagination from '../../utils/calculatePagination';

import { showModal } from '../../helpers/modal';
import LinkCourseModal from './LinkCourseModal';

import { ContentsContainer, StyledButton } from './style';

const SavedClasses: React.FC = () => {
  const history = useHistory();

  const [allClasses, setAllClasses] = useState([]);
  const [classes, setClasses] = useState<Content[]>([]);
  const [paginationState, setPaginationState] = useState({ page: 1, length: 0, showPage: true });

  const getContents = useCallback(
    async (pageParam?: number) => {
      const { initial, final } = calculatePagination(pageParam || paginationState.page);
      const response = await getAllContents({
        flag: ['class'],
        type: ['on-demand', 'curso'],
        unrelated: true,
        relations: [Relations.parents],
      });

      setClasses(response?.data?.slice(initial, final) || []);
      setAllClasses(response?.data || []);
      setPaginationState((state) => ({
        ...state,
        length: Math.ceil(response.data.length / 15),
        showPage: true,
      }));
    },
    [paginationState.page]
  );

  const removeContent = useCallback(
    async (contentId: string, pageParam: number) => {
      Swal.fire({
        title: '<strong>Confirmação</strong>',
        html: `Tem certeza que deseja remover este conteúdo?`,
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        focusConfirm: false,
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await deleteContent(contentId);

            Swal.fire({
              icon: 'success',
              title: 'Sucesso!',
              text: 'Aula excluída com sucesso!',
            }).then((result) => {
              if (result.isConfirmed) {
                history.goBack();
              }
            });
            await getContents(pageParam);
          } catch (error: any) {
            Swal.fire({
              icon: 'error',
              title: 'Erro',
              text: `Erro ao excluir aula. ${
                error.response && error.response.status === 423
                  ? `Esta aula já está associada a um curso!`
                  : error?.response?.data?.message || error.message || 'Ocorreu um erro inesperado'
              }`,
            });
          }
        }
      });
    },
    [getContents, history]
  );

  const selectCourseToLink = useCallback(
    (lessonId) => {
      showModal('Selecionar curso', <LinkCourseModal lessonId={lessonId} reloadContents={getContents} />);
    },
    [getContents]
  );

  const contentsToBeShown = useMemo(() => {
    return classes.map((content) => ({
      title: content.name,
      actions: (
        <>
          <StyledButton
            onClick={() => selectCourseToLink(content.content_id)}
            className="small info"
            title="Relacionar aula"
          >
            Relacionar
            <AiOutlineLink />
          </StyledButton>
          <StyledButton
            onClick={() => {
              removeContent(content.content_id, paginationState.page);
            }}
            className="small danger"
            title="Excluir aula"
          >
            Deletar
            <BiTrash />
          </StyledButton>
        </>
      ),
    }));
  }, [classes, removeContent, paginationState.page, selectCourseToLink]);

  useEffect(() => {
    getContents();
  }, [getContents]);

  return (
    <ContentsContainer>
      <BreadCrumb crumbs={[<Link to="/dashboard">Dashboard</Link>, <span>Cursos</span>]} />

      <DefaultPageTitle>Aulas salvas</DefaultPageTitle>

      <CreateButtonDefaultContainer>
        <div className="right">
          <FilterForm
            data={allClasses}
            onFilter={(filtered) => {
              setClasses(filtered);
              setPaginationState((state) => ({ ...state, showPage: false }));
            }}
            propName="name"
            clearFilter={() => {
              setPaginationState((state) => ({ ...state, page: 1 }));
              getContents(1);
            }}
          />
        </div>
      </CreateButtonDefaultContainer>

      <DefaultTable
        headersConfig={[
          {
            headerLabel: <span>Título</span>,
            propName: 'title',
          },
          {
            headerLabel: <span>Ações</span>,
            propName: 'actions',
          },
        ]}
        items={contentsToBeShown}
        emptyListMessage="Não foram encontradas aulas nao relacionadas a cursos!"
      />

      {paginationState.showPage ? (
        <Pagination
          totalPages={paginationState.length}
          setPage={(page) => setPaginationState((state) => ({ ...state, page }))}
        />
      ) : null}
    </ContentsContainer>
  );
};

export default SavedClasses;
