import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { AiOutlineDown, AiOutlineEdit, AiOutlineUp } from 'react-icons/ai';
import Swal from 'sweetalert2';
import { EditorState, convertToRaw } from 'draft-js';
import DefaultButton from '../../components/DefaultButton';
import DefaultCreationForm, {
  DefaultCreationFormButtonGroup,
  DefaultCreationFormGroup,
} from '../../components/DefaultCreationForm';
import DefaultInput from '../../components/DefaultInput';
import { ReactComponent as DetachButton } from '../../assets/detach.svg';
import { hideModal, showModal } from '../../helpers/modal';
import checkEmptyString from '../../helpers/check-empty-string';
import Content from '../../models/content';
import { DescriptionTextarea } from '../CreateAndEditCourse/Editor';
import {
  getContent as getModuleService,
  updateContent as updateCourseService,
  createContent as createModuleService,
} from '../../services/contents';
import CreateAndEditLesson from '../CreateAndEditLesson';

import { SortLessonsContainer, LessonSelectionContainer, CreateAndEditModuleContainer } from './style';
import draftToHtml from 'draftjs-to-html';

interface CreateAndEditModuleProps {
  moduleId?: string;
  onCreateOrUpdate?: (module: Content) => void;
  modalId?: string;
  plansAssociated: string[];
  courseId: string;
}

const CreateAndEditModule: React.FC<CreateAndEditModuleProps> = ({
  moduleId,
  onCreateOrUpdate,
  modalId,
  plansAssociated,
  courseId,
}) => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [selectedLessons, setSelectedLessons] = useState<Content[]>([]);
  const [lessonsToRemove, setLessonsToRemove] = useState<Content[]>([]);

  const [idOfAddingOrEditingLesson, setIdOfAddingOrEditingLesson] = useState('');
  const [descriptionState, setDescriptionState] = useState(EditorState.createEmpty());
  const addLesson = () => {
    if (idOfAddingOrEditingLesson) {
      hideModal(idOfAddingOrEditingLesson);
    }

    const localIdOfAddingOrEditingLesson = showModal(
      'Adicionar Aula',
      <CreateAndEditLesson courseId={courseId} plansAssociated={plansAssociated} onCreateOrUpdate={includeLesson} />
    );
    setIdOfAddingOrEditingLesson(localIdOfAddingOrEditingLesson);
  };

  const editLesson = (lessonId: string) => {
    const localEditingSeasonModalId = showModal(
      'Editar Aula',
      <CreateAndEditLesson
        courseId={courseId}
        plansAssociated={plansAssociated}
        lessonId={lessonId}
        onCreateOrUpdate={includeLesson}
      />
    );
    setIdOfAddingOrEditingLesson(localEditingSeasonModalId);
  };

  const includeLesson = (lesson: Content) => {
    const localSelectedLessons = selectedLessons || [];
    if (!localSelectedLessons.some((selectedLesson) => lesson.content_id === selectedLesson.content_id)) {
      setSelectedLessons([...localSelectedLessons, lesson]);
    } else {
      const foundLesson = localSelectedLessons.find(
        (selectedLesson) => selectedLesson.content_id === lesson.content_id
      );
      if (foundLesson) {
        const indexOfLesson = localSelectedLessons.indexOf(foundLesson);
        if (indexOfLesson > -1) {
          localSelectedLessons.splice(indexOfLesson, 1, lesson);
          setSelectedLessons([...localSelectedLessons]);
        }
      }
    }
  };

  const createModule = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      if (checkEmptyString(name)) {
        throw new Error('Informe um título válido para o módulo.');
      }

      if (selectedLessons.length === 0) {
        throw new Error('Informe ao menos uma aula para este módulo.');
      }

      const createdModule = await createModuleService({
        name,
        description: draftToHtml(convertToRaw(descriptionState.getCurrentContent())),
        flag: 'module',
        type: 'curso',
        children: selectedLessons.map((lesson, index) => ({
          child_id: lesson.content_id,
          position: index + 1,
        })) as unknown as Content[],
      } as Content);

      Swal.fire({
        title: 'Sucesso!',
        text: 'Módulo criado com sucesso!',
        icon: 'success',
      });

      if (onCreateOrUpdate) {
        onCreateOrUpdate(createdModule);
      }

      hideModal(modalId);
    } catch (error: any) {
      Swal.fire({
        title: 'Erro',
        text: `Houve um erro ao criar o módulo.${
          error?.response?.data?.message || error.message || 'Ocorreu um erro inesperado'
        }`,
        icon: 'error',
      });
    }
  };

  const updateCourse = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      if (checkEmptyString(name)) {
        throw new Error('Informe um nome válido para a temporada.');
      }

      const updateModule = await updateCourseService(moduleId!, {
        name,
        description: draftToHtml(convertToRaw(descriptionState.getCurrentContent())),
        children: selectedLessons.map((lesson, index) => ({
          child_id: lesson.content_id,
          position: index + 1,
        })) as unknown as Content[],
        info: {
          is_free: true,
        },
      });

      Swal.fire({
        title: 'Sucesso!',
        text: 'Módulo editado com sucesso!',
        icon: 'success',
      });

      if (onCreateOrUpdate) {
        onCreateOrUpdate(updateModule);
      }

      hideModal(modalId);
    } catch (error: any) {
      Swal.fire({
        title: 'Erro',
        text: `Houve um erro ao editar o módulo.${
          error?.response?.data?.message || error.message || 'Ocorreu um erro inesperado'
        }`,
        icon: 'error',
      });
    }
  };

  const reorderSelectedLessons = (lesson: Content, up: boolean) => {
    if (selectedLessons) {
      const localSelectedLessons = [...selectedLessons];
      const indexOfLesson = localSelectedLessons.indexOf(lesson);
      if (indexOfLesson > -1 && indexOfLesson < localSelectedLessons.length) {
        localSelectedLessons.splice(indexOfLesson, 1);
        if (up) {
          localSelectedLessons.splice(indexOfLesson - 1, 0, lesson);
        } else {
          localSelectedLessons.splice(indexOfLesson + 1, 0, lesson);
        }
      }

      setSelectedLessons(localSelectedLessons);
    }
  };

  const removeSelectedLesson = async (lessonToBeRemoved: Content) => {
    Swal.fire({
      icon: 'question',
      text: 'Tem certeza que deseja desvincular esse módulo do curso?',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
    }).then(async (result) => {
      if (result.isConfirmed) {
        const lessons = [...selectedLessons];
        const filteredList = lessons.filter((lesson) => lesson.content_id !== lessonToBeRemoved.content_id);
        const reorderedList = filteredList.map((lesson, index) => ({
          ...lesson,
          position: index + 1,
        }));
        setSelectedLessons(reorderedList);

        setLessonsToRemove([...lessonsToRemove, lessonToBeRemoved]);
      }
    });
  };

  const getModule = useCallback(async () => {
    if (moduleId) {
      const module = await getModuleService(moduleId);
      if (module && Object.keys(module).length) {
        setName(module.name);
        setDescription(module.description || '');
        setSelectedLessons(module.children || []);
      }
    }
  }, [moduleId]);

  useEffect(() => {
    getModule();
  }, [getModule]);

  const isEditing = useMemo(() => {
    if (moduleId) {
      return true;
    }

    return false;
  }, [moduleId]);

  return (
    <CreateAndEditModuleContainer>
      <DefaultCreationForm>
        <DefaultCreationFormGroup>
          <label className="required" htmlFor="title">
            Título
          </label>
          <DefaultInput value={name} onChange={(e) => setName(e.target.value)} id="title" required maxLength={250} />
        </DefaultCreationFormGroup>

        <DefaultCreationFormGroup>
          <label htmlFor="description">Descrição</label>
          <DescriptionTextarea
            description={description}
            editorState={descriptionState}
            setEditorState={setDescriptionState}
          />
        </DefaultCreationFormGroup>

        <DefaultCreationFormGroup>
          <label htmlFor="reference">Aulas</label>

          <LessonSelectionContainer>
            {selectedLessons.length ? (
              <SortLessonsContainer>
                {selectedLessons.map((lesson, index) => (
                  <div key={lesson.content_id} className="selected-lessons">
                    <div className="buttons">
                      {index > 0 ? (
                        <>
                          {index + 1}
                          <DefaultButton
                            type="button"
                            title="Subir Aula"
                            className="small white up"
                            onClick={() => reorderSelectedLessons(lesson, true)}
                          >
                            <AiOutlineUp />
                          </DefaultButton>
                        </>
                      ) : null}
                      {index < selectedLessons.length - 1 ? (
                        <>
                          {index === 0 ? index + 1 : null}
                          <DefaultButton
                            type="button"
                            title="Descer Aula"
                            className="small white down"
                            onClick={() => reorderSelectedLessons(lesson, false)}
                          >
                            <AiOutlineDown />
                          </DefaultButton>
                        </>
                      ) : null}
                    </div>
                    <div className="lesson-title">
                      {index + 1} - {lesson.name}
                    </div>
                    <div className="buttons">
                      <DefaultButton
                        className="small"
                        type="button"
                        onClick={() => editLesson(lesson.content_id!)}
                        title="Editar Aula"
                      >
                        <AiOutlineEdit size={16} />
                      </DefaultButton>
                      <DetachButton onClick={() => removeSelectedLesson(lesson)} />
                    </div>
                  </div>
                ))}
              </SortLessonsContainer>
            ) : (
              <></>
            )}
            <DefaultButton type="button" onClick={addLesson}>
              Adicionar Aula
            </DefaultButton>
          </LessonSelectionContainer>
        </DefaultCreationFormGroup>

        <DefaultCreationFormButtonGroup>
          <DefaultButton
            type="button"
            className="danger"
            onClick={() =>
              Swal.fire({
                icon: 'question',
                text: 'Tem certeza que deseja cancelar? Todas as alterações não salvas serão perdidas!',
                showCancelButton: true,
                cancelButtonText: 'Cancelar',
              }).then((result) => {
                if (result.isConfirmed) {
                  hideModal(modalId);
                }
              })
            }
          >
            Cancelar
          </DefaultButton>
          <DefaultButton onClick={(e) => (isEditing ? updateCourse(e) : createModule(e))} className="success">
            Salvar
          </DefaultButton>
        </DefaultCreationFormButtonGroup>
      </DefaultCreationForm>
    </CreateAndEditModuleContainer>
  );
};

export default CreateAndEditModule;
