//@ts-nocheck
import React, { useReducer, useEffect, useCallback } from 'react';

import { Link, useHistory, useParams } from 'react-router-dom';
import Select from 'react-select';
import Swal from 'sweetalert2';

import BreadCrumb from '../../components/BreadCrumb';
import { DefaultPageTitle } from '../../components/DefaultPageTitle';
import DefaultCreationForm, {
  DefaultCreationFormButtonGroup,
  DefaultCreationFormGroup,
} from '../../components/DefaultCreationForm';
import DefaultInput from '../../components/DefaultInput';
import DefaultButton from '../../components/DefaultButton';

import { notificationFormReducer, ActionTypes } from './reducer';

import { listPlans } from '../../services/plans';
import { getContents } from '../../services/contents';

import { Container, TextArea, SelectContainer } from './styles';
import { createNotification, getNotificationById, updateNotification } from '../../services/notification';
import checkEmptyString from '../../helpers/check-empty-string';

interface CreateAndEditNotificationParams {
  notificationId?: string;
}

export const CreateAndEditNotification: React.FC = () => {
  const history = useHistory();
  const { notificationId } = useParams<CreateAndEditNotificationParams>();

  const [state, dispatch] = useReducer(notificationFormReducer, {
    title: '',
    description: '',
    plans: [],
    selectedPlan: [],
    courses: [],
    selectedCourse: [],
  });

  const getPlans = useCallback(async () => {
    const recoveredPlans = await listPlans();
    const options = recoveredPlans.map((plan) => ({
      label: plan.title,
      value: plan.plan_id,
    }));
    dispatch({ type: ActionTypes.GET_ALL_PLANS, payload: options });
  }, []);

  const getCourses = useCallback(async () => {
    const response = await getContents({ flag: ['course', 'retreat', 'on-demand'], is_active: true });

    const options = response.data.map((course: any) => ({
      label: course.name,
      value: course.content_id,
    }));
    dispatch({ type: ActionTypes.GET_ALL_COURSES, payload: options });
  }, []);

  const getExistNotificationById = useCallback(
    async (notificationId: string) => {
      const response = await getNotificationById(notificationId);
      if (!response) return;

      const formattedPlans = response.plans.map((plan) => {
        return state.plans.find((statePlan) => statePlan.value === plan);
      });

      const formattedCourses = response.contents.map((course) => {
        return state.courses.find((stateCourse) => stateCourse.value === course);
      });

      dispatch({ type: ActionTypes.UPDATE_TITLE, payload: response.title });
      dispatch({ type: ActionTypes.UPDATE_DESCRIPTION, payload: response.description });
      dispatch({ type: ActionTypes.UPDATE_PLAN, payload: formattedPlans });
      dispatch({ type: ActionTypes.UPDATE_COURSE, payload: formattedCourses });
    },
    [state.plans, state.courses]
  );

  const goBack = () => {
    history.push('/notifications');
  };

  const onCreateNotification = async () => {
    try {
      if (checkEmptyString(state.title)) {
        throw new Error('Informe um nome válido para a notificação.');
      }

      if (checkEmptyString(state.description)) {
        throw new Error('Informe uma descrição válida para a notificação.');
      }

      const selectedPlanValues = state.selectedPlan.map((plan) => plan.value);
      const selectedContentValues = state.selectedCourse.map((course) => course.value);
      await createNotification({
        title: state.title,
        description: state.description,
        plans: selectedPlanValues,
        contents: selectedContentValues,
      });
      goBack();
      Swal.fire({
        title: 'Sucesso!',
        text: 'Notificação criada com sucesso!',
        icon: 'success',
      });
    } catch (error: any) {
      Swal.fire({
        title: 'Erro',
        text: `Houve um erro ao criar a notificação. ${
          error?.response?.data?.message || error.message || 'Ocorreu um erro inesperado'
        }`,
        icon: 'error',
      });
    }
  };

  const onUpdateNotification = async () => {
    try {
      const selectedPlanValues = state.selectedPlan.map((plan) => plan.value);
      const selectedContentValues = state.selectedCourse.map((course) => course.value);

      await updateNotification(notificationId, {
        title: state.title,
        description: state.description,
        plans: selectedPlanValues,
        contents: selectedContentValues,
      });
      goBack();
      Swal.fire({
        title: 'Sucesso!',
        text: 'Notificação atualizada com sucesso!',
        icon: 'success',
      });
    } catch (error: any) {
      Swal.fire({
        title: 'Erro',
        text: `Houve um erro ao atualizar a notificação. ${
          error?.response?.data?.message || error.message || 'Ocorreu um erro inesperado'
        }`,
        icon: 'error',
      });
    }
  };

  useEffect(() => {
    getPlans();
  }, []);

  useEffect(() => {
    getCourses();
  }, []);

  useEffect(() => {
    if (notificationId) {
      getExistNotificationById(notificationId);
    }
  }, [notificationId, getExistNotificationById]);

  return (
    <Container>
      <BreadCrumb
        crumbs={[
          <Link to="/dashboard">Dashboard</Link>,
          <Link to="/notifications">Notificações</Link>,
          <span>{!!notificationId ? 'Editar' : 'Criar'} Notificação</span>,
        ]}
      />
      <DefaultPageTitle>{!!notificationId ? 'Editar' : 'Criar'} Notificação</DefaultPageTitle>

      <DefaultCreationForm>
        <DefaultCreationFormGroup>
          <label className="required" htmlFor="title">
            Título
          </label>
          <DefaultInput
            id="title"
            placeholder="Digite o título da notificação"
            value={state.title}
            type="text"
            onChange={(e) => dispatch({ type: ActionTypes.UPDATE_TITLE, payload: e.target.value })}
            required
          />
        </DefaultCreationFormGroup>

        <DefaultCreationFormGroup>
          <label className="required" htmlFor="description">
            Descrição
          </label>
          <TextArea
            id="description"
            placeholder="Digite a descrição da notificação"
            value={state.description}
            onChange={(e) => dispatch({ type: ActionTypes.UPDATE_DESCRIPTION, payload: e.target.value })}
            required
            maxLength={300}
          />
          <p>{state.description.length}/300</p>
        </DefaultCreationFormGroup>

        <SelectContainer>
          <label htmlFor="plans">Planos</label>
          <Select
            id="plans"
            options={state.plans}
            value={state.selectedPlan}
            onChange={(option) => dispatch({ type: ActionTypes.UPDATE_PLAN, payload: option })}
            placeholder="Selecione um plano para ser notificado"
            noOptionsMessage={() => 'Sem planos disponíveis'}
            isMulti
          />
        </SelectContainer>

        <SelectContainer>
          <label htmlFor="plans">Cursos</label>
          <Select
            id="plans"
            options={state.courses}
            value={state.selectedCourse}
            onChange={(option) => dispatch({ type: ActionTypes.UPDATE_COURSE, payload: option })}
            placeholder="Selecione um curso para ser notificado"
            noOptionsMessage={() => 'Sem cursos disponíveis'}
            isMulti
          />
        </SelectContainer>
      </DefaultCreationForm>

      <DefaultCreationFormButtonGroup>
        <DefaultButton type="button" className="danger" onClick={goBack}>
          Cancelar
        </DefaultButton>
        <DefaultButton onClick={notificationId ? onUpdateNotification : onCreateNotification} className="success">
          Salvar
        </DefaultButton>
      </DefaultCreationFormButtonGroup>
    </Container>
  );
};
