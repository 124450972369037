import styled from 'styled-components';

import DefaultButton from '../../components/DefaultButton';

const AuthorsContainer = styled.div``;

const StyledButton = styled(DefaultButton)`
  margin-left: 0.5rem;
  padding: 0.4rem !important;
  width: 80px;

  transition: filter 0.2s;

  &:hover {
    filter: brightness(0.9);
  }

  @media (max-width: 768px) {
    margin-top: 1rem;
  }
`;

export { AuthorsContainer, StyledButton };
