import styled from 'styled-components';
import DefaultButton from '../../components/DefaultButton';

export const CouponPageContainer = styled.div`
    width: 100%;
    height: auto;
`;

export const StyledButton = styled(DefaultButton)`
  padding: 0.4rem !important;
  margin: 0.2rem;
  transition: filter 0.2s;

  &:hover {
    filter: brightness(0.9);
  }

  @media (max-width: 768px) {
    margin-top: 1rem;
  }
`;
