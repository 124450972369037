import Content from './content';

export enum ExamQuestionType {
  MULTIPLE_CHOICE = 'multiple_choice',
  SHORT_ANSWER = 'short_answer',
}

export interface ExamQuestionAlternative {
  exam_question_id?: string;
  description?: string;
  position: number;
  is_correct?: boolean;
  info?: Record<string, unknown>;
  updated_at?: string;
  exam_question_alternative_id?: string;
  deleted_at?: string;
  created_at?: string;
}

export interface ExamQuestion {
  id?: string;
  exam_question_id: string;
  exam_id: string;
  description: string;
  position: number;
  type: ExamQuestionType;
  info: Record<string, unknown>;
  exam_question_alternatives: ExamQuestionAlternative[];
}

export interface Exam {
  exam_id: string;
  title: string;
  description: string | null;
  is_active: boolean;
  block_certificate: boolean;
  points: number | null;
  average_in_percent: number | null;
  attempts: number | null;
  question_limit_by_attempt: number | null;
  info: Record<string, unknown>;
  created_at: Date;
  exam_questions: ExamQuestion[];
  contents: Content[];
  block_certificade: boolean
}
