import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Swal from 'sweetalert2';

import DefaultButton from '../../components/DefaultButton';
import DefaultCreationForm, {
  DefaultCreationFormButtonGroup,
  DefaultCreationFormGroup,
} from '../../components/DefaultCreationForm';
import DefaultInput from '../../components/DefaultInput';
import SelectSambaVideosContent from '../../components/SelectSambaVideosContent';
import { hideModal, showModal } from '../../helpers/modal';
import MediaFromResponse from '../../models/from-api-response/media';
import { EditorState, convertToRaw } from 'draft-js';
import {
  createContent as createContentService,
  getContent as getContentService,
  updateContent as updateContentService,
} from '../../services/contents';
import { getMedia } from '../../services/medias';
import CutImage from '../../components/CutImage';
import checkEmptyString from '../../helpers/check-empty-string';
import SelectSubtitles from '../../components/SelectSubtitles';
import Content from '../../models/content';
import { uploadFile } from '../../services/files';
import { createExtraMaterial, CreateExtraMaterialProps } from '../../services/extraMaterial';
import CreateLiveForLesson from './CreateLiveForLesson';
import { deleteLive as deleteLiveService } from '../../services/lives';
import Live from '../../models/lives';
import { BiEdit } from 'react-icons/bi';
import { AiOutlineClose, AiOutlineFolderAdd } from 'react-icons/ai';

import {
  ContentThumbnail,
  ContentUploadOrSelectContainer,
  CreateAndEditContentContainer,
  ThumbnailUploadContainer,
  ButtonGroupInline,
  ExtraMaterialsContainer,
  LiveInformationContainer,
  StyledButton,
  SwitchersContainer,
  Switch,
} from './style';
import { DescriptionTextarea } from '../CreateAndEditCourse/Editor';
import draftToHtml from 'draftjs-to-html';

interface ExtraMaterialsUploaded {
  title: string;
  reference: string;
  extra_material_id?: string;
}

interface CreateAndEditLessonProps {
  lessonId?: string;
  onCreateOrUpdate?: (lesson: Content) => void;
  modalId?: string;
  courseId: string;
  plansAssociated: string[];
}

const CreateAndEditLesson: React.FC<CreateAndEditLessonProps> = ({
  lessonId: contentId,
  onCreateOrUpdate,
  modalId,
  plansAssociated,
  courseId,
}) => {
  const [liveId, setLiveId] = useState('');
  const [live, setLive] = useState<Live | null>(null);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [reference, setReference] = useState('');
  const [duration, setDuration] = useState(0);
  const [points, setPoints] = useState<number | undefined>(undefined);
  const [isLastLesson, setIsLastLesson] = useState(false);

  const [extraMaterials, setExtraMaterials] = useState<File[]>([]);
  const [extraMaterialsUploaded, setExtraMaterialsUploaded] = useState<ExtraMaterialsUploaded[]>([]);
  const [descriptionState, setDescriptionState] = useState(EditorState.createEmpty());
  const [hasSubtitle, setHasSubtitle] = useState(false);

  const getContent = useCallback(async () => {
    if (contentId) {
      const content = await getContentService(contentId);
      if (content && Object.keys(content).length) {
        const currentMedia = content.reference
          ? await getMedia(content.reference.split('/')[content.reference.split('/').length - 1])
          : undefined;
        setTitle(content.name);
        setDescription(content.description);
        if (content.reference) {
          setReference(content.reference);
        }
        setHasSubtitle((currentMedia?.captions || []).length > 0);
        setDuration(content.duration);
        setExtraMaterialsUploaded(content.extra_materials || []);
        setPoints(content?.info?.gamification_points as number);
        setIsLastLesson(content?.info?.last_lesson as boolean);

        if (content.live) {
          setLiveId(content.live_id);
          setLive(content.live || null);
        }
      }
    }
  }, [contentId]);

  const onSelectContent = (sambaVideosContent: MediaFromResponse) => {
    if (sambaVideosContent) {
      if (sambaVideosContent.files && sambaVideosContent.files.length) {
        const firstFile = sambaVideosContent.files[0];

        if (firstFile) {
          const playerKey = process.env.REACT_APP_PLAYER_KEY;
          const referenceUrl = `${process.env.REACT_APP_PLAYER_INITIAL_URL}/${playerKey}/${sambaVideosContent.id}`;

          setReference(referenceUrl || '');
          setDuration(firstFile.fileInfo.duration / 1000);
        }
      }
      setHasSubtitle((sambaVideosContent.captions || []).length > 0);
    }
  };

  const validate = () => {
    if (checkEmptyString(title)) {
      throw new Error('Informe um título válido para a aula.');
    }
  };

  const createContent = async (event: React.FormEvent) => {
    event.preventDefault();

    if (isLastLesson) {
      const result = await Swal.fire({
        icon: 'question',
        title: 'Confirmação de última aula',
        text: 'Ao selecionar esta aula como sendo a última do curso, tenha certeza que não possua nenhuma outra selecionada.',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
      });

      if (!result.isConfirmed) {
        setIsLastLesson(false);
        return;
      }
    }

    try {
      validate();

      const uploadedMaterials: CreateExtraMaterialProps[] = [];
      if (extraMaterials.length) {
        for (let material of extraMaterials) {
          const formData = new FormData();
          formData.append('file', material);

          await uploadFile(formData).then(({ reference, name }) => uploadedMaterials.push({ reference, title: name }));
        }
      }

      setExtraMaterialsUploaded(uploadedMaterials);

      const extraMaterialIds: { extra_material_id: string }[] = [];
      if (uploadedMaterials.length) {
        await Promise.all(
          uploadedMaterials.map(async (material) => {
            const response = await createExtraMaterial(material);
            extraMaterialIds.push({ extra_material_id: response.extra_material_id });
          })
        );
      }

      const contentCreate = {
        name: title,
        description: draftToHtml(convertToRaw(descriptionState.getCurrentContent())),
        duration: duration,
        type: 'curso',
        flag: 'class',
        extra_materials: extraMaterialIds,
        info: {
          gamification_points: points,
          last_lesson: isLastLesson,
        },
      } as unknown as Content;

      if (liveId) contentCreate.live_id = liveId;
      if (reference) contentCreate.reference = reference;

      const createdLesson = await createContentService(contentCreate);

      Swal.fire({
        title: 'Sucesso!',
        text: 'Aula criada com sucesso!',
        icon: 'success',
      });

      if (onCreateOrUpdate) {
        onCreateOrUpdate(createdLesson);
      }

      hideModal(modalId);
    } catch (error: any) {
      Swal.fire({
        title: 'Erro',
        text: `Houve um erro ao criar a aula. ${
          error?.response?.data?.message || error.message || 'Ocorreu um erro inesperado'
        }`,
        icon: 'error',
      });
    }
  };

  const updateContent = async (event: React.FormEvent) => {
    event.preventDefault();

    if (isLastLesson) {
      const result = await Swal.fire({
        icon: 'question',
        title: 'Confirmação de última aula',
        text: 'Ao selecionar esta aula como sendo a última do curso, tenha certeza que não possua nenhuma outra selecionada.',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
      });

      if (!result.isConfirmed) {
        setIsLastLesson(false);
        return;
      }
    }

    try {
      validate();

      const uploadedMaterials: CreateExtraMaterialProps[] = [];
      if (extraMaterials.length) {
        for (let material of extraMaterials) {
          const formData = new FormData();
          formData.append('file', material);

          await uploadFile(formData).then(({ reference, name }) => uploadedMaterials.push({ reference, title: name }));
        }
      }

      setExtraMaterialsUploaded((state) => [...state, ...uploadedMaterials]);

      const extraMaterialIds: { extra_material_id: string }[] = extraMaterialsUploaded.map((material) => ({
        extra_material_id: material.extra_material_id!,
      }));
      if (uploadedMaterials.length) {
        await Promise.all(
          uploadedMaterials.map(async (material) => {
            const response = await createExtraMaterial(material);
            extraMaterialIds.push({ extra_material_id: response.extra_material_id });
          })
        );
      }

      const data = {
        name: title,
        description: draftToHtml(convertToRaw(descriptionState.getCurrentContent())),
        duration: duration,
        info: {
          gamification_points: points,
          last_lesson: isLastLesson,
        },
        extra_materials: extraMaterialIds,
      } as unknown as Content;

      if (reference) data.reference = reference;

      const updatedContent = await updateContentService(contentId!, data);

      Swal.fire({
        title: 'Sucesso!',
        text: 'Aula editada com sucesso!',
        icon: 'success',
      });

      if (onCreateOrUpdate) {
        onCreateOrUpdate(updatedContent);
      }

      hideModal(modalId);
    } catch (error: any) {
      Swal.fire({
        title: 'Erro',
        text: `Houve um erro ao editar a aula. ${
          error?.response?.data?.message || error.message || 'Ocorreu um erro inesperado'
        }`,
        icon: 'error',
      });
    }
  };

  const selectSubtitles = () => {
    showModal('Selecionar Legenda', <SelectSubtitles reference={reference} />);
  };

  const selectContent = () => {
    showModal('Selecionar Conteúdo', <SelectSambaVideosContent onSelectContent={onSelectContent} />);
  };

  const isEditing = useMemo(() => {
    if (contentId) {
      return true;
    }

    return false;
  }, [contentId]);

  const addExtraMaterial = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();

    const files: File[] = [];

    if (event.target.files?.length) {
      for (let index = 0; index < event.target.files.length; index++) {
        files.push(event.target.files[index]);
      }

      setExtraMaterials((state) => [...state, ...files]);
    }
  };

  const removeExtraMaterial = (extraMaterialToBeRemoved: ExtraMaterialsUploaded) => {
    // find end position of guid to remove from string to compare names in filter
    const charAt = extraMaterialToBeRemoved.title.search(/-/) + 1;

    setExtraMaterials((state) =>
      state.filter((file) => file.name !== extraMaterialToBeRemoved.title.substring(charAt))
    );
    setExtraMaterialsUploaded((state) => state.filter((file) => file.title !== extraMaterialToBeRemoved.title));
  };

  const addLive = () => {
    showModal(
      'Live',
      <CreateLiveForLesson courseId={courseId} plansAssociated={plansAssociated} onCreate={handleCreateLive} />
    );
  };

  const editLive = () => {
    showModal(
      'Adicionar Live',
      <CreateLiveForLesson
        courseId={courseId}
        plansAssociated={plansAssociated}
        onCreate={handleCreateLive}
        liveId={live?.live_id}
      />
    );
  };

  const handleCreateLive = (liveId: string, live: Live) => {
    setLiveId(liveId);
    setLive(live);
  };

  const deleteLive = async () => {
    Swal.fire({
      title: '<strong>Confirmação</strong>',
      html: `Tem certeza que deseja remover esta live?`,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      focusConfirm: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await deleteLiveService(live?.live_id!);
          setLiveId('');
          setLive(null);

          Swal.fire({
            icon: 'success',
            title: 'Sucesso!',
            text: 'Live excluída com sucesso!',
          });

          getContent();
        } catch {
          Swal.fire({
            icon: 'success',
            title: 'Sucesso!',
            text: 'Live excluída com sucesso!',
          });
        }
      }
    });
  };

  const formatDate = (date: string) => {
    return date.split('T')[0].split('-').reverse().toString().replaceAll(',', '/');
  };

  useEffect(() => {
    getContent();
  }, [getContent]);

  return (
    <CreateAndEditContentContainer>
      <SwitchersContainer>
        <DefaultCreationFormGroup>
          <label>Última aula</label>
          <Switch>
            <input type="checkbox" checked={isLastLesson} onChange={() => setIsLastLesson((state) => !state)} />
            <span className="slider round"></span>
          </Switch>
        </DefaultCreationFormGroup>
      </SwitchersContainer>

      <DefaultCreationForm>
        <DefaultCreationFormGroup>
          <label className="required" htmlFor="title">
            Título
          </label>
          <DefaultInput value={title} onChange={(e) => setTitle(e.target.value)} id="title" required maxLength={250} />
        </DefaultCreationFormGroup>

        <DefaultCreationFormGroup>
          <label htmlFor="points">Pontos da gamificação</label>
          <DefaultInput
            placeholder="Escolha uma pontuação que essa aula irá conceder ao usuário"
            value={points}
            type="number"
            onChange={(e) => setPoints(Number(e.target.value))}
            id="points"
            required
          />
        </DefaultCreationFormGroup>

        <DefaultCreationFormGroup>
          <label htmlFor="description">Descrição</label>
          <DescriptionTextarea
            description={description}
            editorState={descriptionState}
            setEditorState={setDescriptionState}
          />
        </DefaultCreationFormGroup>

        <DefaultCreationFormGroup>
          <label htmlFor="reference">Aula</label>

          <ContentUploadOrSelectContainer>
            <ButtonGroupInline>
              <DefaultButton type="button" onClick={selectContent}>
                Selecionar Aula
              </DefaultButton>

              {reference && !hasSubtitle && (
                <DefaultButton type="button" onClick={selectSubtitles}>
                  Selecionar Legenda
                </DefaultButton>
              )}

              {!reference && !live?.live_id ? (
                <DefaultButton className="info" type="button" onClick={addLive}>
                  Criar Live
                </DefaultButton>
              ) : null}
            </ButtonGroupInline>

            {live ? (
              <LiveInformationContainer>
                <span>
                  <b>{live.name}</b>
                </span>
                <span>
                  Início: <b>{formatDate(live.start)}</b>
                </span>
                <span>
                  Fim: <b>{formatDate(live.stop)}</b>
                </span>

                <div>
                  <StyledButton
                    title="Editar Live"
                    className="small info"
                    type="button"
                    onClick={editLive}
                    style={{ margin: '0 2px' }}
                  >
                    Editar
                    <BiEdit />
                  </StyledButton>

                  <StyledButton
                    title="Editar Live"
                    type="button"
                    className="small danger"
                    onClick={deleteLive}
                    style={{ margin: '0 2px' }}
                  >
                    Deletar
                    <AiOutlineClose />
                  </StyledButton>

                  <StyledButton
                    title="Adicionar Gravação"
                    type="button"
                    className="small success"
                    onClick={selectContent}
                    style={{ margin: '0 2px' }}
                  >
                    {reference ? 'Trocar Gravação' : 'Adicionar Gravação'}
                    <AiOutlineFolderAdd />
                  </StyledButton>
                </div>
              </LiveInformationContainer>
            ) : null}

            {reference ? (
              <iframe title="referenced-video" allowFullScreen src={reference} frameBorder={0}></iframe>
            ) : null}
          </ContentUploadOrSelectContainer>
        </DefaultCreationFormGroup>

        <DefaultCreationFormGroup>
          <label htmlFor="files">Material extra</label>
          <DefaultInput type="file" multiple onChange={(event) => addExtraMaterial(event)} id="files" required />
        </DefaultCreationFormGroup>

        {extraMaterialsUploaded.length
          ? extraMaterialsUploaded.map((extraMaterial) => (
              <ExtraMaterialsContainer key={extraMaterial.title}>
                <h5>{extraMaterial.title}</h5>
                <div>
                  {extraMaterial.reference && (
                    <DefaultButton onClick={() => window.open(extraMaterial.reference)} type="button">
                      Download
                    </DefaultButton>
                  )}

                  <DefaultButton onClick={() => removeExtraMaterial(extraMaterial)} type="button">
                    Remover
                  </DefaultButton>
                </div>
              </ExtraMaterialsContainer>
            ))
          : null}

        <DefaultCreationFormButtonGroup>
          <DefaultButton
            type="button"
            className="danger"
            onClick={() =>
              Swal.fire({
                icon: 'question',
                text: 'Tem certeza que deseja cancelar? Todas as alterações não salvas serão perdidas.',
                showCancelButton: true,
                cancelButtonText: 'Cancelar',
              }).then((result) => {
                if (result.isConfirmed) {
                  hideModal(modalId);
                }
              })
            }
          >
            Cancelar
          </DefaultButton>
          <DefaultButton onClick={(e) => (isEditing ? updateContent(e) : createContent(e))} className="success">
            Salvar
          </DefaultButton>
        </DefaultCreationFormButtonGroup>
      </DefaultCreationForm>
    </CreateAndEditContentContainer>
  );
};

export default CreateAndEditLesson;
