import styled from 'styled-components';

import ReactSelect from 'react-select';
import FadeIn from 'react-fade-in';

export const CreateAndEditContentContainer = styled.div``;

export const ContentUploadOrSelectContainer = styled.div`
  border-radius: 5px;
  border: solid 1px var(--default-dark-gray);
  padding: 10px 15px;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  iframe {
    margin-top: 10px;
    width: 600px;
    height: 340px;
  }
`;

export const ThumbnailUploadContainer = styled.div`
  border-radius: 5px;
  border: solid 1px var(--default-dark-gray);
  padding: 10px 15px;
  width: 100%;

  img {
    margin-top: 10px;
  }

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const ContentThumbnail = styled.img`
  max-width: 600px;
`;

export const Switch = styled.label`
  position: relative;
  display: inline-block;
  min-width: 60px !important;
  height: 34px;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: '';
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: var(--primary-color);
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #2196f3;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
`;

export const ButtonGroupInline = styled.div`
  display: flex;
  align-items: center;

  button + button {
    margin-left: 10px;
  }
`;

export const ExtraMaterialList = styled.div`
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const ExtraMaterial = styled.div`
  width: 100%;
  border-radius: 4px;
  border: solid 1px var(--default-dark-gray);
  padding: 5px;
  display: flex;
  align-items: center;

  h5 {
    flex: 1 1 100%;
  }

  button,
  a {
    outline: none;
    cursor: pointer;
    padding: 3px 6px;
    font-size: var(--font-small);
    font-weight: bold;
    border-radius: 5px;
    box-shadow: 0px 1px 3px rgb(0 0 0 / 8%), 0px 4px 6px rgb(50 50 93 / 10%);
    background: white;
    color: #444;
    border: solid 1px var(--default-dark-gray);
    margin-left: 10px;
  }

  button svg {
    color: var(--danger-color);
  }

  a svg {
    color: var(--primary-color);
  }
`;

export const ExtraMaterialsInput = styled.input`
  padding: 10px;
  border: 1px solid var(--default-dark-gray);
  width: 100%;
  border-radius: 5px;
`;

export const SwitchWrapper = styled.div`
  margin-bottom: 20px;
`;

export const Select = styled(ReactSelect)`
  width: 100%;
`;

export const Fade = styled(FadeIn)`
  width: 100%;
`;
