import httpClient from '../http-client';
import { AscendedMaster } from '../models/ascendedMaster';
import { uploadFile } from './files';

interface GetAllAscendedMasters {
  name?: string;
  description?: string;
  limit?: number;
  offset?: number;
  order_by?: 'name';
  sort_by?: string;
  relations?: 'contents';
}

const getAllAscendedMasters = async (query?: GetAllAscendedMasters) => {
  return httpClient.get<AscendedMaster[]>('ascended-masters', {params: query}).then((res) => res.data)
}

const createAscendedMaster = async (newAscendedMasters: Partial<Omit<AscendedMaster, 'ascended_master_id'>>) => {
  return httpClient.post<AscendedMaster>('ascended-masters', newAscendedMasters).then((res) => res.data)
}

const updateAscendedMaster = async (ascendedMasterId: string, newAscendedMasters: Partial<Omit<AscendedMaster, 'ascended_master_id'>>) => {
  await httpClient.put(`ascended-masters/${ascendedMasterId}`, newAscendedMasters)
}

const changeAscendedMasterThumbnail = async (ascendedMasterId: string, formData: FormData) => {
  const uploadedFile = await uploadFile(formData);

  await httpClient.patch(`ascended-masters/${ascendedMasterId}/images`, {
    images: {
     url: uploadedFile.reference,
    }
  });
};

const getAscendedMaster = async (ascendedMasterId: string) => {
  return httpClient.get<AscendedMaster>(`ascended-masters/${ascendedMasterId}`).then((res) => res.data)
}

const deleteAscendedMaster = async (ascendedMasterId: string) => {
  return httpClient.delete(`ascended-masters/${ascendedMasterId}`)
}

export { getAllAscendedMasters, createAscendedMaster, updateAscendedMaster, changeAscendedMasterThumbnail, getAscendedMaster, deleteAscendedMaster}