import { debounce } from 'lodash';
import styled from 'styled-components';
import { FC, useEffect, useRef } from 'react';

export interface ISearchProps {
  handleSearch: (params: { search?: string, reset?: boolean }) => Promise<void>;
  handleClear: () => any;
  placeholder?: string;
}

const Input = styled.input`
  border-radius: 5px;
  border: solid 1px var(--default-dark-gray);
  outline: none;
  color: var(--default-gray);
  padding: 8px;
  min-width: 20%;
`;

export const SearchBox: FC<ISearchProps> = ({ handleSearch, placeholder, handleClear }) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const searchDebounced = useRef(
    debounce(() => {
      if (inputRef?.current?.value) {
        handleSearch({ search: inputRef?.current?.value, reset: true });
      } else {
        handleClear();
      }
    }, 1100)
  ).current;

  useEffect(() => {
    inputRef?.current?.addEventListener('keyup', (e) => {
      searchDebounced();
    });
    const inputRefOld = inputRef?.current;

    return () => {
      searchDebounced.cancel();
      inputRefOld?.removeEventListener('keyup', () => {});
    };
  }, [searchDebounced]);

  return <Input ref={inputRef} type="search" placeholder={placeholder || 'Pesquisar'} />;
};
