import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import download from 'downloadjs';
import { format, differenceInDays } from 'date-fns';

import BreadCrumb from '../../../components/BreadCrumb';
import { CreateButtonDefaultContainer } from '../../../components/CreateButtonDefaultContainer';
import DefaultButton from '../../../components/DefaultButton';
import DefaultCreationForm, { DefaultCreationFormGroup } from '../../../components/DefaultCreationForm';
import DefaultInput from '../../../components/DefaultInput';
import { DefaultPageTitle } from '../../../components/DefaultPageTitle';
import { generateReportEngagement } from '../../../services/reports';

import { Container } from './styles';

const Engagement: React.FC = () => {
  const [startCourseStart, setStartCourseStart] = useState('');
  const [startCourseFinish, setStartCourseFinish] = useState('');

  const handleGenerateReport = async (type: 'pdf' | 'csv' | 'xls') => {
    try {
      if (!startCourseStart || !startCourseFinish) {
        throw new Error('Preencha corretamente a data de início e término');
      }

      const [startAt, finishAt] = [new Date(startCourseStart), new Date(startCourseFinish)];

      if (startAt > finishAt) {
        throw new Error('data de início deve ser menor que a data de término');
      }

      if (differenceInDays(finishAt, startAt) > 90) {
        throw new Error('Intervalo entre a data de início e a data de término não deve ser maior que 90 dias');
      }

      const response = await generateReportEngagement({
        type,
        startAt,
        finishAt,
      });

      download(
        response.data,
        `relatório_engajamento_${format(new Date(), 'dd/MM/yyyy')}.${type === 'xls' ? 'xlsx' : 'pdf'}`,
        type === 'xls' ? 'xlsx' : 'pdf'
      );
    } catch (error: any) {
      Swal.fire({
        icon: 'error',
        title: 'Ocorreu um erro ao gerar o relatório',
        text:
          error?.response?.data?.message?.replace('start_at_gt', 'inicial').replace('start_at_lt', 'final') ||
          error.message ||
          'Ocorreu um erro inesperado',
      });
    }
  };

  return (
    <Container>
      <BreadCrumb
        crumbs={[<Link to="/dashboard">Dashboard</Link>, <span>Relatórios</span>, <span>Engajamento</span>]}
      />

      <DefaultPageTitle>Relatório de Engajamento</DefaultPageTitle>

      <CreateButtonDefaultContainer>
        <DefaultButton onClick={() => handleGenerateReport('pdf')}>Gerar relatório PDF</DefaultButton>
        <DefaultButton style={{ marginLeft: 10 }} onClick={() => handleGenerateReport('xls')}>
          Gerar relatório XLSX
        </DefaultButton>
      </CreateButtonDefaultContainer>

      <DefaultCreationForm>
        <DefaultCreationFormGroup>
          <label className="required" htmlFor="startAt">
            Inicio em
          </label>
          <DefaultInput
            id="startAt"
            type="date"
            required
            value={startCourseStart}
            onChange={(e) => setStartCourseStart(e.target.value)}
          />
        </DefaultCreationFormGroup>

        <DefaultCreationFormGroup>
          <label className="required" htmlFor="finishAt">
            até
          </label>
          <DefaultInput
            id="finishAt"
            type="date"
            required
            value={startCourseFinish}
            onChange={(e) => setStartCourseFinish(e.target.value)}
          />
        </DefaultCreationFormGroup>
      </DefaultCreationForm>
    </Container>
  );
};

export default Engagement;
