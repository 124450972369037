import httpClient from '../http-client';
import { Notification } from '../models/notification';

type NotificationParams = {
  title: string;
  description: string;
  plans: string[];
  contents: string[];
};

const getAllNotifications = async () => {
  return httpClient.get<Notification[]>('/notifications').then((res) => res.data);
};

const getNotificationById = async (notificationId: string) => {
  return httpClient.get<Notification>(`/notifications/${notificationId}`).then((res) => res.data);
};

const createNotification = async ({ title, description, plans, contents }: NotificationParams) => {
  return httpClient.post('/notifications', { title, description, plans, contents }).then((res) => res.data);
};

const deleteNotification = async (notificationId: string) => {
  return httpClient.delete(`/notifications/${notificationId}`).then((res) => res.data);
};

const updateNotification = async (
  notificationId: string,
  { title, description, plans, contents }: NotificationParams
) => {
  return httpClient
    .put(`/notifications/${notificationId}`, { title, description, plans, contents })
    .then((res) => res.data);
};

export { getAllNotifications, getNotificationById, createNotification, deleteNotification, updateNotification };
