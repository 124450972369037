import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import Select, { OptionsType } from 'react-select';

import BreadCrumb from '../../components/BreadCrumb';
import DefaultButton from '../../components/DefaultButton';
import DefaultCreationForm, {
  DefaultCreationFormButtonGroup,
  DefaultCreationFormGroup,
} from '../../components/DefaultCreationForm';
import DefaultInput from '../../components/DefaultInput';
import { DefaultPageTitle } from '../../components/DefaultPageTitle';
import { DefaultTextArea } from '../../components/DefaultTextArea';
import { getExam as getExamService, updateQuestion as updateQuestionService } from '../../services/exams';
import checkEmptyString from '../../helpers/check-empty-string';
import { AiOutlineDown, AiOutlineUp } from 'react-icons/ai';
import { BiTrash, BiEdit } from 'react-icons/bi';
import { Exam, ExamQuestion } from '../../models/exam';
import CreateAndEditQuestion from '../CreateAndEditQuestion';
import { ModalWithContext } from '../../components/ModalWithContext';

import { QuestionsList, CreateAndEditExamContainer, SelectContainer, SwitchersContainer, Switch } from './style';
import { useExam } from '../../hooks/ExamHook';
import { getAllContents, Relations } from '../../services/contents';
import Content from '../../models/content';

interface CreateAndEditExamParams {
  examId: string;
  contentId: string;
}

interface SelectProps {
  label: string;
  value: string;
}

const CreateAndEditExam: React.FC = () => {
  const { examId, contentId } = useParams<CreateAndEditExamParams>();
  const history = useHistory();
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [examRequiredForCertificate, setExamRequiredForCertificate] = useState(false);
  const [average, setAverage] = useState(0);
  const [openQuestionModal, setOpenQuestionModal] = useState(false);
  const [coursesOptions, setCoursesOptions] = useState<OptionsType<SelectProps>>([]);
  const [course, setCourse] = useState({ value: '', label: '' });
  const [questionIdToEdit, setQuestionIdToEdit] = useState('');
  const [localId, setQuestionLocalIdToEdit] = useState('');

  const { questions, addQuestions, createExam, updateExam, removeQuestion, addExam, clearQuestions, exam } = useExam();

  const toggleQuestionModal = () => {
    setOpenQuestionModal((state) => !state);
  };

  const goToExams = () => {
    history.push('/exams');
  };

  const getExam = useCallback(async () => {
    const localExam = await getExamService(examId);
    if (localExam && Object.keys(localExam).length) {
      setTitle(localExam.title);
      setDescription(localExam.description!);
      setAverage(localExam.average_in_percent ?? 0);
      addQuestions(
        localExam.exam_questions.sort((questionA, questionB) => (questionA.position > questionB.position ? 1 : -1))
      );
      setExamRequiredForCertificate(localExam.block_certificate);

      const courseRelated = {
        label: localExam.contents[0]?.name || '',
        value: localExam.contents[0]?.content_id || '',
      };

      setCourse(courseRelated);
      addExam(localExam);
    }
  }, [examId, addQuestions, addExam]);

  const handleCreateExam = async (event: React.FormEvent) => {
    if (event && event.preventDefault) {
      event.preventDefault();
    }

    try {
      if (checkEmptyString(title)) {
        throw new Error('Informe um título valido para o questionário!');
      }

      if (checkEmptyString(description)) {
        throw new Error('Informe uma descrição valida para o questionário!');
      }

      if (!course.value) {
        throw new Error('Informe um curso para o questionário!');
      }

      if (!average || average <= 0) {
        throw new Error('Informe o valor da media');
      }

      if (!questions || questions.length < 1) {
        throw new Error('Informe ao menos uma questão!');
      }

      await createExam(
        {
          title,
          description,
          average_in_percent: average,
          block_certificate: examRequiredForCertificate
        } as Exam,
        contentId || course.value
      );

      Swal.fire({
        title: 'Sucesso',
        text: 'Questionário cadastrado com sucesso!',
        icon: 'success',
      });

      goToExams();
    } catch (error: any) {
      Swal.fire({
        title: 'Erro',
        text: `Erro cadastrar o questionário.${
          error?.response?.data?.message || error.message || 'Ocorreu um erro inesperado'
        }`,
        icon: 'error',
      });
    }
  };

  const updateQuestionsOrder = async () => {
    await Promise.all(
      questions.map(async (question, index) => {
        question.exam_question_id && updateQuestionService(question.exam_question_id, { position: index + 1 });
      })
    );
  };

  const handleUpdateExam = async (event: React.FormEvent) => {
    if (event && event.preventDefault) {
      event.preventDefault();
    }

    try {
      let allowContinue = true;

      if (checkEmptyString(title)) {
        throw new Error('Informe um título valido para o questionário!');
      }

      if (!course?.value || course?.value !== exam.contents[0]?.content_id) {
        await Swal.fire({
          title: 'Atenção',
          text: 'Deseja mesmo remover este questionário do curso atual?',
          showCancelButton: true,
          cancelButtonText: 'Cancelar',
        }).then((result) => {
          if (!result.isConfirmed) {
            allowContinue = false;
          }
        });
      }

      if (!allowContinue) {
        return;
      }

      if (!average || average <= 0) {
        throw new Error('Informe o valor da media');
      }

      if (!questions || questions.length < 1) {
        throw new Error('Informe ao menos uma questão!');
      }

      await updateExam({
        exam_id: examId,
        title,
        description,
        block_certificate: examRequiredForCertificate,
        average_in_percent: average,
        contents: course?.value
          ? [
              {
                content_id: course.value,
              },
            ]
          : [],
      } as Exam);

      await updateQuestionsOrder();

      Swal.fire({
        title: 'Sucesso',
        text: 'Questionário editado com sucesso!',
        icon: 'success',
      });

      goToExams();
    } catch (error: any) {
      Swal.fire({
        title: 'Erro',
        text: `Erro ao editar questionário. ${
          error?.response?.data?.message || error.message || 'Ocorreu um erro inesperado'
        }`,
        icon: 'error',
      });
    }
  };

  const handleRemoveQuestion = (question: ExamQuestion) => {
    const newQuestions = questions
      .filter((q) => q.id !== question.id)
      .map((q, index) => ({ ...q, position: index }));

    if (question.exam_question_id) removeQuestion(question);

    addQuestions(newQuestions);
  };

  const changeQuestionPosition = (question: ExamQuestion, up: boolean) => {
    const indexOfQuestion = questions.indexOf(question);

    const questionsAux = [...questions];

    if (up) {
      // nextQuestion = the minimum index number  of array, is more close to up (first element)
      const nextQuestion = questions[indexOfQuestion - 1];

      questionsAux[indexOfQuestion] = { ...nextQuestion, position: nextQuestion.position! + 1 };
      questionsAux[indexOfQuestion - 1] = { ...question, position: question.position! - 1 };
    } else {
      const previousQuestion = questionsAux[indexOfQuestion + 1];

      questionsAux[indexOfQuestion] = { ...previousQuestion, position: previousQuestion.position! - 1 };
      questionsAux[indexOfQuestion + 1] = { ...question, position: question.position! + 1 };
    }

    addQuestions(questionsAux);
  };

  const handleEditQuestion = ({ examQuestionId, localId }: { examQuestionId: string; localId: string }) => {
    setQuestionIdToEdit(examQuestionId);
    setQuestionLocalIdToEdit(localId);
    setOpenQuestionModal(true);
  };

  const isEditing = useMemo(() => {
    return !!examId;
  }, [examId]);

  useEffect(() => {
    if (examId) {
      getExam();
    }
  }, [examId, getExam]);

  useEffect(() => {
    (async () => {
      try {
        const response = await getAllContents({
          flag: ['course', 'retreat'],
          type: ['curso', 'retiro'],
          relations: [Relations.exams],
        });
        setCoursesOptions(
          response.data
            .filter((content: Content) => !content.exams?.length)
            .map((course: Content) => ({ label: course.name, value: course.content_id }))
        );
      } catch {
        Swal.fire({
          title: 'Erro!',
          text: 'Ocorreu um erro ao recuperar os cursos!',
          icon: 'error',
        });
      }
    })();
  }, []);

  useEffect(() => {
    return () => clearQuestions();
  }, [clearQuestions]);

  return (
    <CreateAndEditExamContainer>
      <BreadCrumb
        crumbs={[
          <Link to="/dashboard">Dashboard</Link>,
          <Link to="/exams">Questionários</Link>,
          <span>{isEditing ? 'Editar' : 'Criar'} Questionário</span>,
        ]}
      />

      <DefaultPageTitle>{isEditing ? 'Editar' : 'Criar'} Questionário</DefaultPageTitle>

      <SelectContainer>
        <label className={isEditing ? '' : 'required'} htmlFor="courses">
          Curso
        </label>
        <Select
          id="courses"
          options={coursesOptions}
          value={course}
          onChange={(option) => setCourse(option!)}
          placeholder="Selecione um curso"
          noOptionsMessage={() => 'Sem cursos disponíveis'}
          isClearable
        />
      </SelectContainer>

      <SwitchersContainer>
        <DefaultCreationFormGroup>
          <label>Obrigatório para certificado</label>
          <Switch>
            <input
              type="checkbox"
              checked={examRequiredForCertificate}
              onChange={(e) => setExamRequiredForCertificate(!examRequiredForCertificate)}
            />
            <span className="slider round"></span>
          </Switch>
        </DefaultCreationFormGroup>
      </SwitchersContainer>

      <DefaultCreationForm>
        <DefaultCreationFormGroup>
          <label className="required" htmlFor="title">
            Título
          </label>
          <DefaultInput id="title" type="text" required value={title} onChange={(e) => setTitle(e.target.value)} />
        </DefaultCreationFormGroup>

        <DefaultCreationFormGroup>
          <label htmlFor="instructions" className="required">
            Descrição
          </label>
          <DefaultTextArea
            id="description"
            required
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </DefaultCreationFormGroup>

        <DefaultCreationFormGroup>
          <label className="required" htmlFor="average">
            Media (<span>{average}%</span>)
          </label>
          <DefaultInput
            required
            id="average"
            type="range"
            min={0}
            max={100}
            step={1}
            value={average}
            onChange={(e) => setAverage(+e.target.value)}
          />
        </DefaultCreationFormGroup>

        <DefaultCreationFormGroup>
          <label className="required" htmlFor="name">
            Questões
          </label>
          <div
            style={{
              border: 'solid 1px var(--default-dark-gray)',
              borderRadius: '5px',
              width: '100%',
              padding: '10px',
            }}
          >
            <div className="center">
              <DefaultButton onClick={toggleQuestionModal} type="button">
                Criar Questão
              </DefaultButton>
            </div>

            {questions && questions.length ? (
              <QuestionsList>
                {questions.map((question, index) => (
                  <div
                    key={index}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      position: 'relative',
                    }}
                  >
                    <p>{question.description}</p>

                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-around',
                        minWidth: 100,
                        position: 'absolute',
                        top: '3px',
                        right: '3px',
                      }}
                    >
                      {questions[0] !== question ? (
                        <>
                          <DefaultButton
                            type="button"
                            onClick={() => changeQuestionPosition(question, true)}
                            className="small white up"
                          >
                            <AiOutlineUp />
                          </DefaultButton>
                        </>
                      ) : null}
                      {questions[questions.length - 1] !== question ? (
                        <>
                          <DefaultButton
                            type="button"
                            onClick={() => changeQuestionPosition(question, false)}
                            className="small white down"
                          >
                            <AiOutlineDown />
                          </DefaultButton>
                        </>
                      ) : null}
                      <DefaultButton
                        type="button"
                        onClick={() =>
                          handleEditQuestion({
                            examQuestionId: question.exam_question_id,
                            localId: question.id ?? question.exam_question_id,
                          })
                        }
                        className="small info"
                      >
                        <BiEdit />
                      </DefaultButton>
                      <DefaultButton
                        type="button"
                        onClick={() => handleRemoveQuestion(question)}
                        className="small danger"
                      >
                        <BiTrash />
                      </DefaultButton>
                    </div>
                  </div>
                ))}
              </QuestionsList>
            ) : null}
          </div>
        </DefaultCreationFormGroup>

        {openQuestionModal ? (
          <ModalWithContext
            title="Questão"
            onClose={() => {
              setQuestionLocalIdToEdit('');
              setQuestionIdToEdit('');
              setOpenQuestionModal(false);
            }}
          >
            <CreateAndEditQuestion
              localId={localId}
              questionId={questionIdToEdit}
              onClose={(reload?: boolean) => {
                reload && getExam();
                setQuestionLocalIdToEdit('');
                setQuestionIdToEdit('');
                setOpenQuestionModal(false);
              }}
            />
          </ModalWithContext>
        ) : null}

        <DefaultCreationFormButtonGroup>
          <DefaultButton type="button" className="danger" onClick={goToExams}>
            Cancelar
          </DefaultButton>
          <DefaultButton onClick={(e) => (isEditing ? handleUpdateExam(e) : handleCreateExam(e))} className="success">
            Salvar
          </DefaultButton>
        </DefaultCreationFormButtonGroup>
      </DefaultCreationForm>
    </CreateAndEditExamContainer>
  );
};

export default CreateAndEditExam;
