import httpClient from '../http-client';

export interface Level {
  level_id: string;
  name: string;
  range: { start: number; end: number | null };
}

const listLevels = async () => {
  return httpClient.get<Level[]>('/levels').then((res) => res.data);
};

const createLevel = async (data: Omit<Level, 'level_id'>) => {
  return httpClient.post<Level[]>('/levels', data).then((res) => res.data);
};

const updateLevel = async (levelId: string, data: Omit<Level, 'level_id'>) => {
  return httpClient.put<Level[]>(`/levels/${levelId}`, data).then((res) => res.data);
};

const getLevelById = async (levelId: string) => {
  return httpClient.get<Level>(`/levels/${levelId}`).then((res) => res.data);
};

export { listLevels, createLevel, updateLevel, getLevelById };
