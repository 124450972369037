import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { GiClick } from 'react-icons/gi';

import DefaultTable from '../../../components/DefaultTable';
import Pagination from '../../../components/Pagination';
import Content from '../../../models/from-api-response/content';
import calculatePagination from '../../../utils/calculatePagination';
import { StyledButton } from '../style';
import { getContent } from '../../../services/contents';

import { Container } from './styles';
import { showModal } from '../../../helpers/modal';
import LinkLessonInOrder from '../LinkLessonInOrder';

interface Props {
  courseId: string;
  lessonId: string;
  reloadContents: (agr?: number) => void;
}

const LinkModuleModal: React.FC<Props> = ({ courseId, lessonId, reloadContents }) => {
  const [course, setCourse] = useState<Content | null>(null);
  const [modules, setModules] = useState<Content[]>([]);
  const [paginationState, setPaginationState] = useState({ page: 1, length: 0, showPage: true });

  const getCourse = useCallback(async () => {
    const course = await getContent(courseId);
    setCourse(course);
  }, [courseId]);

  const pagination = useCallback(
    async (pageParam?: number) => {
      if (!course || !course.children) return;
      const { initial, final } = calculatePagination(pageParam || paginationState.page, 5);

      setModules(course.children.slice(initial, final));
      setPaginationState((state) => ({ ...state, showPage: true }));
    },
    [paginationState.page, course]
  );

  const handleSelectModule = useCallback(
    async (module) => {
      showModal(
        'Ordene as aulas',
        <LinkLessonInOrder courseId={courseId} module={module} lessonId={lessonId} reloadContents={reloadContents} />
      );
    },
    [lessonId, reloadContents, courseId]
  );

  useEffect(() => {
    getCourse();
  }, [getCourse]);

  useEffect(() => {
    pagination();
  }, [pagination]);

  const contentsToBeShown = useMemo(() => {
    return modules.map((module) => ({
      course: module.name,
      actions: (
        <StyledButton onClick={() => handleSelectModule(module)} className="small info" title="Editar">
          Selecionar
          <GiClick />
        </StyledButton>
      ),
    }));
  }, [modules, handleSelectModule]);

  return (
    <Container>
      <DefaultTable
        headersConfig={[
          {
            headerLabel: <>Módulo</>,
            propName: 'course',
          },
          {
            headerLabel: <>Ações</>,
            propName: 'actions',
            noWrap: true,
          },
        ]}
        items={contentsToBeShown}
        emptyListMessage="Não foram encontrados módulos para esse curso"
      />

      {paginationState.showPage ? (
        <Pagination
          totalPages={paginationState.length}
          setPage={(page) => setPaginationState((state) => ({ ...state, page }))}
        />
      ) : null}
    </Container>
  );
};

export default LinkModuleModal;
