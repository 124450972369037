import React, { useState, useEffect } from 'react';
import { DefaultFilterTableWrapper, Filter } from './style';
import { AiOutlineCloseCircle } from 'react-icons/ai';

interface IFilter {
  data: Array<any>;
  propName: string;
  onFilter: (filteredData: Array<any>) => void;
  clearFilter: () => void;
}

const DefaultFilterTable: React.FC<IFilter> = ({ data, onFilter, propName, clearFilter }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState(data);

  const filteredResults = () => {
    if (!searchTerm) {
      onFilter(data);
      clearFilter();
    }
    onFilter(searchResults);
  };

  const handlChange = (event: any) => {
    setSearchTerm(event.target.value);
  };

  const handleClearFilter = () => {
    clearFilter();
    setSearchTerm('');
  };

  useEffect(() => {
    const results: any = data.filter(
      (element) =>
        element[propName] &&
        element[propName]
          .toLowerCase()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .includes(
            searchTerm
              .toLowerCase()
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')
          )
    );
    setSearchResults(results);
  }, [data, propName, searchTerm]);

  return (
    <DefaultFilterTableWrapper>
      <h2>Busca: </h2>
      <div>
        <Filter value={searchTerm} onChange={handlChange} onKeyUp={filteredResults} />

        {searchTerm && searchTerm.length ? <AiOutlineCloseCircle onClick={handleClearFilter} /> : null}
      </div>
    </DefaultFilterTableWrapper>
  );
};

export default DefaultFilterTable;
