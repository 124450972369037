import httpClient from '../http-client';

export interface CreateExtraMaterialProps {
  title: string;
  reference: string;
  description?: string;
  images?: Record<string, unknown>;
  info?: Record<string, unknown>;
}

const createExtraMaterial = async (material: CreateExtraMaterialProps) => {
  return httpClient.post('/extra-materials', material).then((res) => res.data);
};

const deleteExtraMaterial = async (extraMaterialId: string) => {
  return httpClient.delete(`/extra-materials/${extraMaterialId}`).then((res) => res.data);
};

export { createExtraMaterial, deleteExtraMaterial };
