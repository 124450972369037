import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Terms from '../../pages/Terms';
import Tags from '../../pages/Tags';
import CreateAndEditTag from '../../pages/CreateAndEditTag';
import Dashboard from '../../pages/Dashboard';
import Categories from '../../pages/Categories';
import CreateAndEditCategory from '../../pages/CreateAndEditCategory';
import AboutIma from '../../pages/AboutIma';
import AboutHalu from '../../pages/AboutHalu';
import LandingPage from '../../pages/LandingPage';
import Users from '../../pages/Users';
import Lives from '../../pages/Lives';
import CreateAndEditLive from '../../pages/CreateAndEditLive';
import Courses from '../../pages/Courses';
import SavedClasses from '../../pages/SavedClasses';
import CreateAndEditCourse from '../../pages/CreateAndEditCourse';
import OnDemand from '../../pages/OnDemand';
import Retreats from '../../pages/Retreats';
import CreateAndEditRetreat from '../../pages/CreateAndEditRetreat';
import AscendedMasters from '../../pages/AscendedMasters';
import CreateAndEditAscendedMaster from '../../pages/CreateAndEditAscendedMasters';
import Exams from '../../pages/Exams';
import CreateAndEditExam from '../../pages/CreateAndEditExam';
import Scheduled from '../../pages/Scheduled';
import NextRelease from '../../pages/NextRelease';
import Plans from '../../pages/Plans';
import CreatePlan from '../../pages/CreatePlan';
import EditPlan from '../../pages/EditPlan';
import Gamification from '../../pages/Gamification';
import CreateLevel from '../../pages/CreateLevel';
import EditLevel from '../../pages/EditLevel';
import Engagement from '../../pages/Reports/Engagement';
import ContentFinished from '../../pages/Reports/ContentFinished';
import Authors from '../../pages/Authors';
import CreateAndEditAuthor from '../../pages/CreateAndEditAuthor';
import ContentPrerequisite from '../../pages/ContentPrerequisite';
import { ListFaqs } from '../../pages/Faq/List';
import { CreateFaq } from '../../pages/Faq/Create';
import { UpdateFaq } from '../../pages/Faq/Update';
import Coupons from '../../pages/Coupons';
import { CreateCouponPage } from '../../pages/Coupons/Create';
import { EditCouponPage } from '../../pages/Coupons/Edit';
import { Notifications } from '../../pages/Notifications';
import { CreateAndEditNotification } from '../../pages/CreateAndEditNotification';

const Routes: React.FC = () => {
  return (
    <Switch>
      <Route exact path="/terms-of-use" component={Terms} />
      <Route exact path="/tags" component={Tags} />
      <Route exact path="/create-tag" component={CreateAndEditTag} />
      <Route exact path="/edit-tag/:tagId" component={CreateAndEditTag} />
      <Route exact path="/dashboard" component={Dashboard} />
      <Route exact path="/categories" component={Categories} />
      <Route exact path="/create-category" component={CreateAndEditCategory} />
      <Route exact path="/edit-category/:categoryId" component={CreateAndEditCategory} />
      <Route exact path="/ascended-masters" component={AscendedMasters} />
      <Route exact path="/create-ascended-master" component={CreateAndEditAscendedMaster} />
      <Route exact path="/edit-ascended-master/:ascendedMasterId" component={CreateAndEditAscendedMaster} />
      <Route exact path="/cms/about-ima" component={AboutIma} />
      <Route exact path="/cms/about-halu" component={AboutHalu} />
      <Route exact path="/cms/landing-page" component={LandingPage} />
      <Route exact path="/cms/scheduled" component={Scheduled} />
      <Route exact path="/cms/next-release" component={NextRelease} />
      <Route exact path="/users" component={Users} />
      <Route exact path="/lives" component={Lives} />
      <Route exact path="/create-live" component={CreateAndEditLive} />
      <Route exact path="/edit-live/:liveId" component={CreateAndEditLive} />
      <Route exact path="/courses" component={Courses} />
      <Route exact path="/create-course" component={CreateAndEditCourse} />
      <Route exact path="/edit-course/:courseId" component={CreateAndEditCourse} />
      <Route exact path="/courses/on-demand" component={OnDemand} />
      <Route exact path="/retreats" component={Retreats} />
      <Route exact path="/edit-retreat/:retreatId" component={CreateAndEditRetreat} />
      <Route exact path="/create-retreat" component={CreateAndEditRetreat} />
      <Route exact path="/saved-classes" component={SavedClasses} />
      <Route exact path="/exams" component={Exams} />
      <Route exact path="/create-exam" component={CreateAndEditExam} />
      <Route exact path="/edit-exam/:examId" component={CreateAndEditExam} />
      <Route exact path="/plans" component={Plans} />
      <Route exact path="/create-plan/:planPeriod" component={CreatePlan} />
      <Route exact path="/edit-plan/:planId" component={EditPlan} />
      <Route exact path="/levels" component={Gamification} />
      <Route exact path="/create-level" component={CreateLevel} />
      <Route exact path="/edit-level/:levelId" component={EditLevel} />
      <Route exact path="/reports/engagement" component={Engagement} />
      <Route exact path="/reports/content-finished" component={ContentFinished} />
      <Route exact path="/authors" component={Authors} />
      <Route exact path="/create-author" component={CreateAndEditAuthor} />
      <Route exact path="/edit-author/:author_id" component={CreateAndEditAuthor} />
      <Route exact path="/content-prerequisites" component={ContentPrerequisite} />
      <Route exact path="/faqs" component={ListFaqs} />
      <Route exact path="/faqs/create" component={CreateFaq} />
      <Route exact path="/faqs/:faq_id/edit" component={UpdateFaq} />
      <Route exact path="/coupons" component={Coupons} />
      <Route exact path="/coupons/create" component={CreateCouponPage} />
      <Route exact path="/coupons/:coupon_id/edit" component={EditCouponPage} />
      <Route exact path="/notifications" component={Notifications} />
      <Route exact path="/create-notification" component={CreateAndEditNotification} />
      <Route exact path="/edit-notification/:notificationId" component={CreateAndEditNotification} />
    </Switch>
  );
};

export default Routes;
