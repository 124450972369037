import styled from 'styled-components';
import DefaultButton from '../../components/DefaultButton';

const Container = styled.div``;

const StyledButton = styled(DefaultButton)`
  padding: 0.4rem !important;
  margin: 0.2rem;
  transition: filter 0.2s;
  width: fit-content;
  font-weight: 600;

  &:hover {
    filter: brightness(0.9);
  }
`;

const ActionsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export { Container, StyledButton, ActionsContainer };
