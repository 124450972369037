import { PaymentType } from '../../../../models/from-api-response/user';

function convertPaymentType(type: PaymentType) {
  switch (type) {
    case PaymentType.ALLOW_BY_ADMIN:
      return 'AUTORIZADO PELO ADMINISTRADOR';

    case PaymentType.BANK_SLIP:
      return 'BOLETO';

    case PaymentType.CREDIT_CARD:
      return 'CARTÃO DE CRÉDITO';

    case PaymentType.PIX:
      return 'PIX';

    case PaymentType.OFFSITE:
      return 'PAYPAL';

    default:
      return 'NÃO RECONHECIDO';
  }
}

export default convertPaymentType;
