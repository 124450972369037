import { Plan } from '../services/plans';

type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;

function formatPlanToSendUpdate(plan: Partial<Plan>): PartialBy<Plan, 'plan_id'> {
  delete plan.plan_id;
  delete plan.vindi_product_id;
  delete plan.vindi_plan_id;
  delete plan.created_at;
  delete plan.updated_at;
  delete plan.deleted_at;

  return plan as PartialBy<Plan, 'plan_id'>;
}

export { formatPlanToSendUpdate };
