import httpClient from '../http-client';
import { Author } from '../models/author';

interface GetAllAuthors {
  name: string;
  description: string;
  is_active: boolean;
  limit: number;
  offset: number;
  order_by: 'name' | 'is_active';
  sort_by: string;
  relations: 'contents';
}

const getAuthor = async (authorId: string) => {
  return httpClient.get<Author>(`authors/${authorId}`).then((res) => res.data);
};

const getAllAuthors = async (query?: GetAllAuthors) => {
  return httpClient
    .get<Author[]>('authors', {
      params: query,
    })
    .then((res) => res.data);
};

const deleteAuthor = async (authorId: string) => {
  return httpClient.delete<Author>(`authors/${authorId}`).then((res) => res.data);
};

const createAuthor = async (name: string) => {
  return httpClient.post<Author>('/authors', { name }).then((res) => res.data);
};

const updateAuthor = async (authorId: string, name: string) => {
  return httpClient.put<Author>(`/authors/${authorId}`, { name }).then((res) => res.data);
};

export { getAllAuthors, getAuthor, deleteAuthor, createAuthor, updateAuthor };
