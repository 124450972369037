import httpClient from '../http-client';
import { ICreateFaqDto, IFaqDto, IListFaqDto, IUpdateFaqDto, IUpdateFaqPositionDto } from '../models/faq';
import { IPaginatedResult, ORDER_BY } from '../models/pagination';

export const listFaqs = async (params: IListFaqDto = {}): Promise<IPaginatedResult<IFaqDto>> => {
  const res = await httpClient.get('/faqs', { params: { sort_by: 'position', order_by: ORDER_BY.ASC, ...params } });

  return res.data;
};

export const createFaq = async (faq: ICreateFaqDto): Promise<IFaqDto> => {
  const { data } = await httpClient.post('/faqs', faq);

  return data;
};

export const updateFaq = async (faq_id: string, updateData: IUpdateFaqDto): Promise<IFaqDto> => {
  const { data } = await httpClient.put(`/faqs/${faq_id}`, updateData);

  return data;
};

export const deleteFaq = async (faq_id: string) => {
  await httpClient.delete(`/faqs/${faq_id}`);
};

export const getFaq = async (faq_id: string) => {
  const { data } = await httpClient.get(`/faqs/${faq_id}`);

  return data;
};

export const updateFaqsPositions = async (faqs: IUpdateFaqPositionDto[]) => {
  await httpClient.patch(`/faqs/positions`, { faqs });
};
