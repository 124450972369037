import React, { useCallback, useState, useMemo, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { AiOutlineCheck, AiOutlineClose } from 'react-icons/ai';
import { RiAdminFill } from 'react-icons/ri';
import { SiGoogleclassroom } from 'react-icons/si';
import { RiExchangeDollarFill } from 'react-icons/ri';
import { GiPoliceBadge } from 'react-icons/gi';
import Swal from 'sweetalert2';

import BreadCrumb from '../../components/BreadCrumb';
import { DefaultPageTitle } from '../../components/DefaultPageTitle';
import DefaultTable from '../../components/DefaultTable';
import {
  getUsers as getUsersService,
  updateUserToAdmin as updateUserToAdminService,
  activateOrInactivateUser as activateOrInactivateUserService,
  updateUserToModerator as updateUserToModeratorService,
} from '../../services/users';
import { getContents } from '../../services/contents';
import { updatePoints } from '../../services/users';
import UserFromResponse from '../../models/from-api-response/user';
import Pagination from '../../components/Pagination';
import CoursesModal from './CoursesModal';
import { Overlay } from './CoursesModal/styles';
import Content from '../../models/from-api-response/content';
import { Plan, listPlans } from '../../services/plans';

import { ActionsContainer, Container, StyledButton } from './styles';
import { DefaultCreationFormGroup } from '../../components/DefaultCreationForm';
import DefaultInput from '../../components/DefaultInput';
import UsersFilterTable from './UsersFilterTable';
import PlansModal from './PlansModal ';

const Users: React.FC = () => {
  const [plans, setPlans] = useState<Plan[]>([]);
  const [users, setUsers] = useState<UserFromResponse[]>([]);
  const [paginationState, setPaginationState] = useState({ page: 1, length: 0, showPage: true });
  const [courses, setCourses] = useState<Content[]>([]);
  const [selectedUserId, setSelectedUserId] = useState('');
  const [openModalCourses, setOpenModalCourses] = useState(false);
  const [openModalPlans, setOpenModalPlans] = useState(false);

  const getUsers = useCallback(async () => {
    const { users: data } = await getUsersService({
      limit: 15,
      offset: paginationState.page > 1 ? (paginationState.page - 1) * 15 : undefined,
    });

    setUsers(data.users);
    setPaginationState((state) => ({ ...state, length: Math.ceil(data.total / 15), showPage: true }));
  }, [paginationState.page]);

  const updateUserToAdmin = useCallback(
    async (userId: string, isAdmin: boolean) => {
      Swal.fire({
        icon: 'question',
        text: `Tem certeza que deseja ${isAdmin ? 'remover admin deste' : 'tornar admin'} usuário?`,
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await updateUserToAdminService(userId);
            await getUsers();
          } catch {
            Swal.fire({
              icon: 'error',
              text: 'Houve um erro ao modificar o status do usuário.',
            });
          }
        }
      });
    },
    [getUsers]
  );

  const updateUserToModerator = useCallback(
    async (userId: string, isModerator: boolean) => {
      Swal.fire({
        icon: 'question',
        text: `Tem certeza que deseja ${isModerator ? 'remover moderador deste' : 'tornar moderator'} usuário?`,
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await updateUserToModeratorService(userId);
            await getUsers();
          } catch {
            Swal.fire({
              icon: 'error',
              text: 'Houve um erro ao modificar o status do usuário.',
            });
          }
        }
      });
    },
    [getUsers]
  );

  const activateOrInactivateUser = useCallback(
    async (userId: string, is_active: boolean) => {
      Swal.fire({
        title: 'Confirmação',
        text: `Tem certeza que deseja ${!is_active ? 'ativar' : 'inativar'} este usuário?`,
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        focusConfirm: false,
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await activateOrInactivateUserService(userId);
            await getUsers();
          } catch (error: any) {
            Swal.fire({
              title: 'Erro',
              text: `Erro ao ${is_active ? 'ativar' : 'inativar'} este usuário. ${
                error.response &&
                error.response.status === 400 &&
                error.response.data.message.includes('It is already enrolled in a content.')
                  ? 'Esta categoria já está associado a um conteúdo!'
                  : error.response
                  ? `Status ${error.response.status}`
                  : null
              }`,
            });
          }
        }
      });
    },
    [getUsers]
  );

  const handleOpenCourses = (userId: string) => {
    setSelectedUserId(userId);
    setOpenModalCourses(true);
  };

  const closeModal = useCallback(() => {
    setSelectedUserId('');
    setOpenModalCourses(false);
    setOpenModalPlans(false);
  }, []);

  const updatePointsValue = useCallback(
    (index: number, value: number) => {
      let data = [...users];
      data[index].points = Number(value);
      setUsers(data);
    },
    [users]
  );

  const updatePoint = useCallback(
    async (userId: string, points: number) => {
      if (!points) {
        return false;
      }

      await updatePoints(userId, points);
      getUsers();
    },
    [getUsers]
  );

  const handleOpenPlans = useCallback((userId: string) => {
    setSelectedUserId(userId);
    setOpenModalPlans(true);
  }, []);

  const usersToBeShown = useMemo(() => {
    return users.map((user, index) => {
      return {
        name: user.name,
        email: user.email,
        status: user.status === 'active' ? 'Ativo' : user.status === 'canceled' ? 'Inativo' : 'Em débito',
        level: user?.level?.name || 'Sem nível',
        points: (
          <>
            <DefaultCreationFormGroup>
              <DefaultInput
                placeholder="Pontos"
                value={user?.points}
                style={{ width: '80px' }}
                type="number"
                onChange={(event) => updatePointsValue(index, Number(event.target.value))}
                onBlur={(e) => updatePoint(user.user_id, Number(e.target.value))}
                id="points"
                required
              />
            </DefaultCreationFormGroup>
          </>
        ),
        actions: (
          <ActionsContainer>
            <StyledButton
              onClick={() => updateUserToAdmin(user.user_id, user.is_admin)}
              className="small info"
              title={`${user.is_admin ? 'Remover' : 'Tornar'} Admin`}
              isAdmin={true}
            >
              <RiAdminFill />
            </StyledButton>

            {/* <StyledButton
              onClick={() => updateUserToModerator(user.user_id, user.roles.includes('Moderator'))}
              className="small info"
              title={`${user.roles.includes('Moderator') ? 'Remover' : 'Tornar'} Moderador`}
              isAdmin={true}
            >
              {`${user.roles.includes('Moderator') ? 'Remover' : 'Tornar'} Moderador`}
              <GiPoliceBadge />
            </StyledButton> */}

            <StyledButton
              onClick={() => activateOrInactivateUser(user.user_id, user.is_active)}
              className="small warning"
              title={(user.is_active ? 'Inativar' : 'Ativar') + ' usuário'}
            >
              {user.is_active ? (
                <>
                  <AiOutlineClose />
                </>
              ) : (
                <>
                  <AiOutlineCheck />
                </>
              )}
            </StyledButton>

            <StyledButton onClick={() => handleOpenCourses(user.user_id)} className="small success" title="Cursos">
              <SiGoogleclassroom />
            </StyledButton>

            <StyledButton onClick={() => handleOpenPlans(user.user_id)} className="small success" title="Planos">
              <RiExchangeDollarFill />
            </StyledButton>
          </ActionsContainer>
        ),
      };
    });
  }, [
    users,
    updatePointsValue,
    updatePoint,
    updateUserToAdmin,
    activateOrInactivateUser,
    handleOpenPlans,
    updateUserToModerator,
  ]);

  useEffect(() => {
    getUsers();
  }, [getUsers]);

  useEffect(() => {
    (async () => {
      try {
        const response = await getContents({ flag: ['course', 'retreat', 'on-demand'], is_active: true });
        if (response.data) return setCourses(response.data);
      } catch {
        Swal.fire({
          title: 'Erro!',
          text: 'Houve um problema ao recuperar os cursos dos usuários',
          timer: 4000,
        });
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        await listPlans().then((response) => setPlans(response.filter((plan) => plan.is_active)));
      } catch {
        Swal.fire({
          title: 'Erro!',
          text: 'Houve um problema ao recuperar os planos dos usuários',
          timer: 4000,
        });
      }
    })();
  }, []);

  return (
    <Container>
      <BreadCrumb crumbs={[<Link to="/dashboard">Dashboard</Link>, <span>Usuários</span>]} />

      <DefaultPageTitle>Usuários</DefaultPageTitle>

      <UsersFilterTable
        data={users}
        onFilter={(filtered) => {
          setUsers(filtered);
          setPaginationState((state) => ({ ...state, showPage: false }));
        }}
        clearFilter={() => {
          setPaginationState((state) => ({ ...state, page: 1 }));
          getUsers();
        }}
      />

      <DefaultTable
        headersConfig={[
          {
            headerLabel: <span>Nome</span>,
            propName: 'name',
          },
          {
            headerLabel: <span>Nível</span>,
            propName: 'level',
          },
          {
            headerLabel: <span>Pontos</span>,
            propName: 'points',
          },
          {
            headerLabel: <span>Email</span>,
            propName: 'email',
          },
          {
            headerLabel: <span>Ações</span>,
            propName: 'actions',
            noWrap: true,
          },
        ]}
        items={usersToBeShown}
        emptyListMessage={'Não foram encontrados usuários'}
      />

      {paginationState.showPage ? (
        <Pagination
          totalPages={paginationState.length}
          setPage={(page) => setPaginationState((state) => ({ ...state, page }))}
        />
      ) : null}

      {openModalCourses && (
        <>
          <CoursesModal courses={courses} userId={selectedUserId} onCloseModal={closeModal} />
          <Overlay onClick={closeModal} />
        </>
      )}

      {openModalPlans && (
        <>
          <PlansModal plans={plans} userId={selectedUserId} onCloseModal={closeModal} />
          <Overlay onClick={closeModal} />
        </>
      )}
    </Container>
  );
};

export default Users;
