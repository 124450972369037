import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { BiCopy, BiTrash, BiStop, BiEdit, BiPlay } from 'react-icons/bi';
import Swal from 'sweetalert2';

import BreadCrumb from '../../components/BreadCrumb';
import { DefaultPageTitle } from '../../components/DefaultPageTitle';
import DefaultTable from '../../components/DefaultTable';
import { getAllLives, deleteLive, changeLiveStatus } from '../../services/lives';
import Live from '../../models/lives';

import { Container, StyledButton } from './styles';

enum LiveStatusEnum {
  CREATING = 'CREATING',
  STARTING = 'STARTING',
  ACTIVE = 'ACTIVE',
  STOPPING = 'STOPPING',
  STOPPED = 'STOPPED',
  SCHEDULED = 'SCHEDULED',
  FINISHED = 'FINISHED',
  ERROR = 'ERROR',
  TO_STOP = 'TO_STOP',
}

const Lives: React.FC = () => {
  const [lives, setLives] = useState<Live[]>([]);

  const history = useHistory();

  const getLives = useCallback(async () => {
    const response = await getAllLives();
    if (response) {
      setLives(response);
    }
  }, []);

  const createLive = async () => {
    history.push('create-live');
  };

  const editLive = useCallback(
    (liveId: string) => {
      history.push(`edit-live/${liveId}`);
    },
    [history]
  );

  const removeLive = useCallback(
    async (liveId: string) => {
      Swal.fire({
        title: '<strong>Confirmação</strong>',
        html: 'Tem certeza que deseja remover esta Live?',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        focusConfirm: false,
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await deleteLive(liveId);

            Swal.fire({
              icon: 'success',
              title: 'Sucesso!',
              text: 'Live excluída com sucesso!',
            });

            await getLives();
          } catch {
            Swal.fire({
              icon: 'error',
              title: 'Erro ao excluir Live',
              text: 'Esta transmissão não pode ser excluída porque não foi interrompida.',
            });
          }
        }
      });
    },
    [getLives]
  );

  const copyRMTP = useCallback(
    async (liveId: string) => {
      const actualLive = lives.find((live) => live.live_id === liveId);
      if (!actualLive) {
        Swal.fire({ text: 'Erro ao tentar copiar RMTP', icon: 'error' });
        return;
      }
      const rtmp = actualLive.rtmp;

      const actualDocument = document.getElementById('root');

      const rtmpInput = document.createElement('input');
      rtmpInput.value = rtmp || '';
      actualDocument?.appendChild(rtmpInput);
      rtmpInput.select();
      rtmpInput.setSelectionRange(0, 99999);
      navigator.clipboard.writeText(rtmpInput.value);
      rtmpInput.remove();
      Swal.fire({ text: `RTMP "${rtmp}" copiado!`, icon: 'info' });
    },
    [lives]
  );

  const stopLive = useCallback(
    async (liveId: string) => {
      Swal.fire({
        icon: 'question',
        text: 'Tem certeza que deseja parar esta live?',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await changeLiveStatus(liveId, 'STOPPED');
            Swal.fire({
              icon: 'success',
              text: 'Live parada com sucesso!',
            });
            await getLives();
          } catch {
            Swal.fire({
              icon: 'error',
              text: 'Houve um erro ao parar a live.',
            });
          }
        }
      });
    },
    [getLives]
  );

  const startLive = useCallback(
    async (liveId: string) => {
      Swal.fire({
        icon: 'question',
        text: 'Tem certeza que iniciar esta live?',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await changeLiveStatus(liveId, 'ACTIVE');
            Swal.fire({
              icon: 'success',
              text: 'Live iniciada com sucesso!',
            });
            await getLives();
          } catch {
            Swal.fire({
              icon: 'error',
              text: 'Houve um erro ao iniciar a live.',
            });
          }
        }
      });
    },
    [getLives]
  );

  useEffect(() => {
    getLives();
  }, [getLives]);

  const livesToBeShown = useMemo(() => {
    const liveStatus = {
      [LiveStatusEnum.CREATING]: 'A live está sendo criada',
      [LiveStatusEnum.STARTING]: 'A live está sendo criada',
      [LiveStatusEnum.ACTIVE]: 'A live está ativa',
      [LiveStatusEnum.STOPPING]: 'A live está sendo encerrada',
      [LiveStatusEnum.STOPPED]: 'A live está encerrada',
      [LiveStatusEnum.SCHEDULED]: 'A live está agendada',
      [LiveStatusEnum.FINISHED]: 'A live está encerrada',
      [LiveStatusEnum.ERROR]: 'Erro ao criar a live',
      [LiveStatusEnum.TO_STOP]: 'Live irá encerrar em instantes',
    };

    return lives.map((live) => ({
      id: live.live_id,
      name: live.name,
      description: live.description,
      start: live.start,
      stop: live.stop,
      status:
        live.status === 'ACTIVE' && liveStatus[live.task_status as LiveStatusEnum]
          ? liveStatus[live.task_status as LiveStatusEnum]
          : liveStatus[live.status as LiveStatusEnum],
      relatedToCourse: live?.content ? live?.content?.name : '',
      actions: (
        <>
          <StyledButton
            title="Editar"
            className="small info"
            onClick={() => {
              editLive(live.live_id);
            }}
          >
            <BiEdit />
          </StyledButton>

          <StyledButton onClick={() => copyRMTP(live.live_id)} className="small white" title="Copiar RMTP">
            <BiCopy />
          </StyledButton>

          <StyledButton
            title="Iniciar"
            className="small success"
            style={{ color: 'white' }}
            onClick={() => startLive(live.live_id)}
          >
            <BiPlay />
          </StyledButton>

          <StyledButton
            title="Parar"
            className="small warning"
            style={{ color: 'white' }}
            onClick={() => stopLive(live.live_id)}
          >
            <BiStop />
          </StyledButton>

          <StyledButton onClick={() => removeLive(live.live_id)} className="small danger" title="Excluir">
            <BiTrash />
          </StyledButton>
        </>
      ),
    }));
  }, [lives, copyRMTP, stopLive, editLive, removeLive, startLive]);

  return (
    <Container>
      <BreadCrumb crumbs={[<Link to="/dashboard">Dashboard</Link>, <span>Lives</span>]} />

      <DefaultPageTitle>Lives</DefaultPageTitle>

      <DefaultTable
        headersConfig={[
          {
            headerLabel: <span>Nome</span>,
            propName: 'name',
          },
          {
            headerLabel: <span>Descrição</span>,
            propName: 'description',
          },
          {
            headerLabel: <span>Curso</span>,
            propName: 'relatedToCourse',
          },
          {
            headerLabel: <span>Início</span>,
            propName: 'start',
          },
          {
            headerLabel: <span>Fim</span>,
            propName: 'stop',
          },
          {
            headerLabel: <span>Status</span>,
            propName: 'status',
          },
          {
            headerLabel: <span>Ações</span>,
            propName: 'actions',
          },
        ]}
        items={livesToBeShown}
        emptyListMessage="Não foi encontrada nenhuma live"
      />
    </Container>
  );
};

export default Lives;
