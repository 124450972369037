import React, { useEffect, useState } from 'react';
import { IoMdFilm } from 'react-icons/io';
import { MdGroupAdd, MdPeople, MdStars } from 'react-icons/md';
import { RiVipDiamondFill } from 'react-icons/ri';
import getActualUser from '../../helpers/get-actual-user';
import { DashboardFromResponse, UsersByPlans } from '../../models/from-api-response/dashboard';
import User from '../../models/user';
import { getDashboard, getUsersByPlans } from '../../services/dashboard';
import {
  Container,
  DashboardCard,
  DashboardCardList,
  DashboardCardsContainer,
  DashboardContainer,
  IconContainer,
  UsersPlan,
} from './style';

const Profile: React.FC = () => {
  const [user, setUser] = useState({} as User);
  const [dashboardData, setDashboardData] = useState<DashboardFromResponse>({} as DashboardFromResponse);
  const [usersByPlans, setUsersByPlans] = useState<UsersByPlans>({} as UsersByPlans);

  useEffect(() => {
    (async () => {
      setUser(await getActualUser());
      setDashboardData(await getDashboard());
      setUsersByPlans(await getUsersByPlans());
    })();
  }, []);

  return (
    <Container>
      <h2>
        Bem-vindo ao painel administrativo{user.name ? ',' : '!'} {user.name ? `${user.name}!` : null}
      </h2>

      <DashboardContainer>
        <DashboardCardList>
          <span>Usuários por planos</span>

          {usersByPlans?.plans?.map((plan) => (
            <UsersPlan key={plan.plan_id}>
              <span>{plan.name}</span>
              <strong>{plan.users}</strong>
            </UsersPlan>
          ))}

          <IconContainer>
            <RiVipDiamondFill />
          </IconContainer>
        </DashboardCardList>

        <DashboardCardsContainer>
          <DashboardCard>
            <span>Total de usuários</span>
            <strong>{dashboardData.total_users}</strong>

            <IconContainer>
              <MdPeople />
            </IconContainer>
          </DashboardCard>

          <DashboardCard>
            <span>Usuários com assinatura</span>
            <strong>{dashboardData.subscriber_users}</strong>

            <IconContainer>
              <MdStars />
            </IconContainer>
          </DashboardCard>

          <DashboardCard>
            <span>Conteúdos ativos</span>
            <strong>{dashboardData.active_contents}</strong>

            <IconContainer>
              <IoMdFilm />
            </IconContainer>
          </DashboardCard>

          <DashboardCard>
            <span>Novos usuários nos últimos 30 dias</span>
            <strong>{dashboardData.users_last_month}</strong>

            <IconContainer>
              <MdGroupAdd />
            </IconContainer>
          </DashboardCard>

          <DashboardCard>
            <span>Usuários ativos nos últimos 30 dias</span>
            <strong>{dashboardData.last30DaysLogin}</strong>

            <IconContainer>
              <MdPeople />
            </IconContainer>
          </DashboardCard>

          <DashboardCard>
            <span>Usuários ativos nos últimos 90 dias</span>
            <strong>{dashboardData.last90DaysLogin}</strong>

            <IconContainer>
              <MdPeople />
            </IconContainer>
          </DashboardCard>
        </DashboardCardsContainer>
      </DashboardContainer>
      <DashboardCardList style={{ flex: 1 }}>
        <span>Cursos On Demand X Usuários</span>

        {dashboardData?.coursesOnDemand?.map((course, index) => (
          <UsersPlan key={index}>
            <span>{course.name}</span>
            <strong>{course.subscribers}</strong>
          </UsersPlan>
        ))}

        <IconContainer>
          <RiVipDiamondFill />
        </IconContainer>
      </DashboardCardList>
    </Container>
  );
};

export default Profile;
