import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { BiEdit, BiTrash } from 'react-icons/bi';
import { Link, useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import { format } from 'date-fns';

import BreadCrumb from '../../components/BreadCrumb';
import { CreateButtonDefaultContainer } from '../../components/CreateButtonDefaultContainer';
import DefaultButton from '../../components/DefaultButton';
import { DefaultPageTitle } from '../../components/DefaultPageTitle';
import DefaultTable from '../../components/DefaultTable';
import Pagination from '../../components/Pagination';
import FilterForm from '../../components/DefaultFilterTable';
import calculatePagination from '../../utils/calculatePagination';
import { ContentsContainer, StyledButton } from './style';
import { deleteExam, getAllExams } from '../../services/exams';
import { Exam } from '../../models/exam';

const Exams: React.FC = () => {
  const [allExams, setAllExams] = useState<Exam[]>([]);
  const [exams, setExams] = useState<Exam[]>([]);
  const [paginationState, setPaginationState] = useState({ page: 1, length: 0, showPage: true });

  const history = useHistory();

  const getContents = useCallback(
    async (pageParam?: number) => {
      const { initial, final } = calculatePagination(pageParam || paginationState.page);
      const localExams = await getAllExams();

      setExams(localExams.slice(initial, final));
      setAllExams(localExams);
      setPaginationState((state) => ({
        ...state,
        length: Math.ceil(localExams.length / 15),
        showPage: true,
      }));
    },
    [paginationState.page]
  );

  const editExam = useCallback(
    (examId: string) => {
      history.push(`edit-exam/${examId}`);
    },
    [history]
  );

  const removeExam = useCallback(
    async (contentId: string, pageParam: number) => {
      Swal.fire({
        title: '<strong>Confirmação</strong>',
        html: `Tem certeza que deseja remover este questionário?`,
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        focusConfirm: false,
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await deleteExam(contentId);

            Swal.fire({
              icon: 'success',
              title: 'Sucesso!',
              text: 'Questionário excluído com sucesso!',
            });

            await getContents(pageParam);
          } catch (error: any) {
            Swal.fire({
              icon: 'error',
              title: 'Erro',
              text: `Erro ao excluir questionário. ${
                error.response && error.response.status === 409
                  ? 'Este questionário já está associado a um curso!'
                  : error?.response?.data?.message || error.message || 'Ocorreu um erro inesperado'
              }`,
            });
          }
        }
      });
    },
    [getContents]
  );

  const createExam = async () => {
    history.push('create-exam');
  };

  const contentsToBeShown = useMemo(() => {
    return exams.map((exam) => ({
      title: exam.title,
      createdAt: format(new Date(exam.created_at), 'dd/MM/yyyy'),
      description: exam.description,
      actions: (
        <>
          <StyledButton onClick={() => editExam(exam.exam_id)} className="small info" title="Editar questionário">
            Editar
            <BiEdit />
          </StyledButton>
          <StyledButton
            onClick={() => {
              removeExam(exam.exam_id, paginationState.page);
            }}
            className="small danger"
            title="Excluir conteúdo"
          >
            Deletar
            <BiTrash />
          </StyledButton>
        </>
      ),
    }));
  }, [exams, editExam, removeExam, paginationState.page]);

  useEffect(() => {
    getContents();
  }, [getContents]);

  return (
    <ContentsContainer>
      <BreadCrumb crumbs={[<Link to="/dashboard">Dashboard</Link>, <span>Questionários</span>]} />

      <DefaultPageTitle>Questionários</DefaultPageTitle>

      <CreateButtonDefaultContainer>
        <DefaultButton onClick={createExam}>Criar Questionário</DefaultButton>
        <div className="right">
          <FilterForm
            data={allExams}
            onFilter={(filtered) => {
              setExams(filtered);
              setPaginationState((state) => ({ ...state, showPage: false }));
            }}
            propName="title"
            clearFilter={() => {
              setPaginationState((state) => ({ ...state, page: 1 }));
              getContents(1);
            }}
          />
        </div>
      </CreateButtonDefaultContainer>

      <DefaultTable
        headersConfig={[
          {
            headerLabel: <span>Título</span>,
            propName: 'title',
          },
          {
            headerLabel: <span>Descrição</span>,
            propName: 'description',
          },
          {
            headerLabel: <span>Criado em</span>,
            propName: 'createdAt',
          },
          {
            headerLabel: <span>Ações</span>,
            propName: 'actions',
          },
        ]}
        items={contentsToBeShown}
        emptyListMessage="Não foram encontrados questionários cadastrados!"
      />

      {paginationState.showPage ? (
        <Pagination
          totalPages={paginationState.length}
          setPage={(page) => setPaginationState((state) => ({ ...state, page }))}
        />
      ) : null}
    </ContentsContainer>
  );
};

export default Exams;
