//@ts-nocheck
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';

import BreadCrumb from '../../components/BreadCrumb';
import { DefaultPageTitle } from '../../components/DefaultPageTitle';
import { CreateButtonDefaultContainer } from '../../components/CreateButtonDefaultContainer';
import DefaultButton from '../../components/DefaultButton';
import FilterForm from '../../components/DefaultFilterTable';

import DefaultTable from '../../components/DefaultTable';
import Pagination from '../../components/Pagination';
import calculatePagination from '../../utils/calculatePagination';
import { deleteNotification, getAllNotifications } from '../../services/notification';
import { Notification } from '../../models/notification';
import { listPlans } from '../../services/plans';
import { getContents } from '../../services/contents';

import { Container, StyledButton } from './styles';
import { BiEdit, BiTrash } from 'react-icons/bi';

export const Notifications: React.FC = () => {
  const history = useHistory();

  const [allNotifications, setAllNotifications] = useState([]);
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [paginationState, setPaginationState] = useState({ page: 1, length: 0, showPage: true });

  const goToCreateNotificationPage = () => {
    history.push('create-notification');
  };

  const goToEditNotification = (notificationId: string) => {
    history.push(`edit-notification/${notificationId}`);
  };

  const getNotifications = useCallback(
    async (pageParam?: number) => {
      const { initial, final } = calculatePagination(pageParam || paginationState.page);
      const localNotifications = await getAllNotifications();
      const plans = await listPlans();
      const contents = await getContents({ flag: ['course', 'retreat', 'on-demand'], is_active: true });

      const formattedLocalNotifications: Notification[] = localNotifications.map((item: Notification) => {
        const plansWithTitles = item.plans.map((planId) => {
          const foundPlan = plans.find((plan) => plan.plan_id === planId);
          return foundPlan ? foundPlan.title : null;
        });
        const coureseWithTitles = item.contents.map((courseId) => {
          const foundCourse = contents.data.find((course) => course.content_id === courseId);
          return foundCourse ? foundCourse.name : null;
        });

        return {
          ...item,
          plans: plansWithTitles[0] + (plansWithTitles.length > 1 ? ` (+${plansWithTitles.length - 1})` : ''),
          contents: coureseWithTitles[0] + (coureseWithTitles.length > 1 ? ` (+${coureseWithTitles.length - 1})` : ''),
        };
      });

      if (localNotifications.length > 0) {
        setNotifications(formattedLocalNotifications.slice(initial, final));
        setAllNotifications(localNotifications);
        setPaginationState((state) => ({
          ...state,
          length: Math.ceil(localNotifications.length / 15),
          showPage: true,
        }));
      }
    },
    [paginationState.page]
  );

  const deleteNotificationSelected = useCallback(async (notificationId: string) => {
    try {
      const confirmationResponse = await Swal.fire({
        title: '<strong>Confirmação</strong>',
        html: `Tem certeza que deseja remover essa notificação?`,
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        focusConfirm: false,
      });

      if (confirmationResponse.isConfirmed) {
        setNotifications((prevNotifications) =>
          prevNotifications.filter((notification) => notification.notification_id !== notificationId)
        );

        await deleteNotification(notificationId);
        Swal.fire({
          icon: 'success',
          title: 'Sucesso!',
          text: `Notificação excluída com sucesso!`,
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        text: `Erro ao excluir a notificação.`,
      });
    }
  }, []);

  const contentsToBeShow = useMemo(() => {
    return notifications.map((notification) => ({
      ...notification,
      actions: (
        <>
          <StyledButton
            onClick={() => goToEditNotification(notification.notification_id)}
            className="small info"
            title={`Editar notificação`}
          >
            Editar
            <BiEdit />
          </StyledButton>
          <StyledButton
            onClick={() => {
              deleteNotificationSelected(notification.notification_id);
            }}
            className="small danger"
            title="Excluir notificação"
          >
            Deletar
            <BiTrash />
          </StyledButton>
        </>
      ),
    }));
  }, [notifications, deleteNotificationSelected]);

  useEffect(() => {
    getNotifications();
  }, [getNotifications]);

  return (
    <Container>
      <BreadCrumb crumbs={[<Link to="/dashboard">Dashboard</Link>, <span>Notificações</span>]} />

      <DefaultPageTitle>Notificações</DefaultPageTitle>

      <CreateButtonDefaultContainer>
        <DefaultButton onClick={goToCreateNotificationPage}>Criar notificação</DefaultButton>
        <div className="right">
          <FilterForm
            data={allNotifications}
            onFilter={(filtered) => {
              setNotifications(filtered);
              setPaginationState((state) => ({ ...state, showPage: false }));
            }}
            propName="title"
            clearFilter={() => {
              setPaginationState((state) => ({ ...state, page: 1 }));
              getNotifications(1);
            }}
          />
        </div>
      </CreateButtonDefaultContainer>

      <DefaultTable
        headersConfig={[
          {
            headerLabel: <span>Título</span>,
            propName: 'title',
          },
          {
            headerLabel: <span>Descrição</span>,
            propName: 'description',
          },
          {
            headerLabel: <span>Planos</span>,
            propName: 'plans',
          },
          {
            headerLabel: <span>Cursos</span>,
            propName: 'contents',
          },
          {
            headerLabel: <span>Ações</span>,
            propName: 'actions',
          },
        ]}
        items={contentsToBeShow}
        emptyListMessage="Nenhuma notificação encontrada"
      />

      {paginationState.showPage ? (
        <Pagination
          totalPages={paginationState.length}
          setPage={(page) => setPaginationState((state) => ({ ...state, page }))}
        />
      ) : null}
    </Container>
  );
};
