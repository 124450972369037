import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { BiEdit } from 'react-icons/bi';
import Swal from 'sweetalert2';

import BreadCrumb from '../../components/BreadCrumb';
import { DefaultPageTitle } from '../../components/DefaultPageTitle';
import DefaultTable from '../../components/DefaultTable';
import Pagination from '../../components/Pagination';
import calculatePagination from '../../utils/calculatePagination';
import { CreateButtonDefaultContainer } from '../../components/CreateButtonDefaultContainer';
import DefaultButton from '../../components/DefaultButton';
import FilterForm from '../../components/DefaultFilterTable';
import { Author } from '../../models/author';
import { deleteAuthor, getAllAuthors } from '../../services/authors';

import { AuthorsContainer, StyledButton } from './styles';

const Authors: React.FC = () => {
  const history = useHistory();

  const [allAuthors, setAllAuthors] = useState<Author[]>([]);
  const [authors, setAuthors] = useState<Author[]>([]);
  const [paginationState, setPaginationState] = useState({ page: 1, length: 0, showPage: true });

  const getAuthors = useCallback(
    async (pageParam?: number) => {
      try {
        const { initial, final } = calculatePagination(pageParam || paginationState.page);

        const localAuthors = await getAllAuthors();

        if (localAuthors && localAuthors.length) {
          setPaginationState((state) => ({ ...state, length: Math.ceil(localAuthors.length / 15) }));
          setPaginationState((state) => ({ ...state, showPage: true }));

          setAuthors(localAuthors.slice(initial, final));
          setAllAuthors(localAuthors);
          return;
        }

        setAuthors([]);
        setAllAuthors([]);
      } catch {
        Swal.fire({
          title: 'Erro',
          text: 'Erro ao recuperar autores.',
        });
      }
    },
    [paginationState.page]
  );

  const editAuthor = useCallback(
    (author_id: string) => {
      history.push(`edit-author/${author_id}`);
    },
    [history]
  );

  const removeAuthor = useCallback(
    async (authorId: any, pageParam: number) => {
      Swal.fire({
        title: '<strong>Confirmação</strong>',
        html: 'Tem certeza que deseja remover esse autor?',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        focusConfirm: false,
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await deleteAuthor(authorId);

            Swal.fire({
              icon: 'success',
              title: 'Sucesso!',
              text: 'Autor excluído com sucesso!',
            });

            await getAuthors(pageParam);
          } catch (error: any) {
            if (error.response.status === 409) {
              Swal.fire({
                icon: 'error',
                title: 'Erro',
                text: error?.response?.data?.message || error.message || 'Ocorreu um erro inesperado',
              });
              return;
            }

            Swal.fire({
              icon: 'error',
              title: 'Erro',
              text: 'Ocorreu um erro desconhecido, tente novamente.',
            });
          }
        }
      });
    },
    [getAuthors]
  );

  const contentsToBeShown = useMemo(() => {
    return authors.map((author) => ({
      id: author.author_id,
      name: author.name,
      actions: (
        <>
          <StyledButton onClick={() => editAuthor(author.author_id)} className="small info" title="Editar autor">
            Editar
            <BiEdit />
          </StyledButton>
          <StyledButton
            onClick={() => removeAuthor(author.author_id, paginationState.page)}
            className="small danger"
            title="Deletar autor"
          >
            Deletar
            <BiEdit />
          </StyledButton>
        </>
      ),
    }));
  }, [authors, editAuthor, removeAuthor, paginationState.page]);

  const createAuthor = async () => {
    history.push('create-author');
  };

  useEffect(() => {
    getAuthors();
  }, [getAuthors]);

  return (
    <AuthorsContainer>
      <BreadCrumb crumbs={[<Link to="/dashboard">Dashboard</Link>, <span>Autores</span>]} />

      <DefaultPageTitle>Autores</DefaultPageTitle>

      <CreateButtonDefaultContainer>
        <DefaultButton onClick={createAuthor}>Criar Autor</DefaultButton>

        <div className="right">
          <FilterForm
            data={allAuthors}
            onFilter={(filtered: Author[]) => {
              setAuthors(filtered);
              setPaginationState((state) => ({ ...state, showPage: false }));
            }}
            propName="name"
            clearFilter={() => {
              setPaginationState((state) => ({ ...state, page: 1 }));
              getAuthors(1);
            }}
          />
        </div>
      </CreateButtonDefaultContainer>

      <DefaultTable
        headersConfig={[
          {
            headerLabel: <span>Nome</span>,
            propName: 'name',
          },
          {
            headerLabel: <span>Ações</span>,
            propName: 'actions',
          },
        ]}
        items={contentsToBeShown}
        emptyListMessage={'Não foram encontrados autores cadastrados!'}
      />

      {paginationState.showPage ? (
        <Pagination
          totalPages={paginationState.length}
          setPage={(value) => setPaginationState((state) => ({ ...state, page: value }))}
        />
      ) : null}
    </AuthorsContainer>
  );
};

export default Authors;
