import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';

import BreadCrumb from '../../components/BreadCrumb';
import DefaultButton from '../../components/DefaultButton';
import DefaultCreationForm, {
  DefaultCreationFormButtonGroup,
  DefaultCreationFormGroup,
} from '../../components/DefaultCreationForm';
import DefaultInput from '../../components/DefaultInput';
import { DefaultPageTitle } from '../../components/DefaultPageTitle';
import { DefaultTextArea } from '../../components/DefaultTextArea';
import checkEmptyString from '../../helpers/check-empty-string';
import {
  createCategory as createCategoryService,
  getCategory,
  updateCategory as updateCategoryService,
} from '../../services/categories';

import { CreateAndEditCategoryContainer } from './style';

interface CreateAndEditCategoryProps {
  categoryId: string;
}

const CreateAndEditCategory: React.FC = () => {
  const { categoryId } = useParams<CreateAndEditCategoryProps>();

  const history = useHistory();

  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');

  const createCategory = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      if (checkEmptyString(title)) {
        throw new Error('Informe um título válido para a categoria.');
      }

      await createCategoryService({
        title: title,
        description: description.trim().length ? description : undefined,
      });

      Swal.fire({
        title: 'Sucesso!',
        text: 'Categoria criado com sucesso!',
        icon: 'success',
      });

      goToCategories();
    } catch (error: any) {
      Swal.fire({
        title: 'Erro',
        text: `Houve um erro ao criar o categoria. ${
          error?.response?.data?.message || error.message || 'Ocorreu um erro inesperado'
        }`,
        icon: 'error',
      });
    }
  };

  const updateCategory = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      if (checkEmptyString(title)) {
        throw new Error('Informe um título válido para a categoria.');
      }
      await updateCategoryService(categoryId, {
        title: title,
        description: description || '',
      });

      Swal.fire({
        title: 'Sucesso!',
        text: 'Conteúdo editado com sucesso!',
        icon: 'success',
      });

      goToCategories();
    } catch (error: any) {
      Swal.fire({
        title: 'Erro',
        text: `Houve um erro ao editar o conteúdo. ${
          error?.response?.data?.message || error.message || 'Ocorreu um erro inesperado'
        }`,
        icon: 'error',
      });
    }
  };

  const goToCategories = () => {
    history.push('/categories');
  };

  const getContent = useCallback(async () => {
    if (categoryId) {
      const content = await getCategory(categoryId);
      if (content && Object.keys(content).length) {
        setTitle(content.title);
        setDescription(content.description);
      }
    }
  }, [categoryId]);

  const updateDescription = (event: any) => {
    const { target } = event;
    setDescription(target.value);
  };

  useEffect(() => {
    getContent();
  }, [getContent]);

  const isEditing = useMemo(() => {
    if (categoryId) {
      return true;
    }

    return false;
  }, [categoryId]);

  return (
    <CreateAndEditCategoryContainer>
      <BreadCrumb
        crumbs={[
          <Link to="/profile">Dashboard</Link>,
          <Link to="/categories">Categorias</Link>,
          <span>{isEditing ? 'Editar' : 'Criar'} Categorias</span>,
        ]}
      />

      <DefaultPageTitle>{isEditing ? 'Editar' : 'Criar'} Categoria</DefaultPageTitle>

      <DefaultCreationForm>
        <DefaultCreationFormGroup>
          <label className="required" htmlFor="title">
            Título
          </label>
          <DefaultInput value={title} onChange={(e) => setTitle(e.target.value)} id="title" required maxLength={250} />
        </DefaultCreationFormGroup>

        <DefaultCreationFormGroup>
          <label htmlFor="description">Descrição</label>
          <DefaultTextArea value={description || ''} onChange={(e) => updateDescription(e)} id="description" />
        </DefaultCreationFormGroup>

        <DefaultCreationFormButtonGroup>
          <DefaultButton type="button" className="danger" onClick={goToCategories}>
            Cancelar
          </DefaultButton>
          <DefaultButton onClick={(e) => (isEditing ? updateCategory(e) : createCategory(e))} className="success">
            Salvar
          </DefaultButton>
        </DefaultCreationFormButtonGroup>
      </DefaultCreationForm>
    </CreateAndEditCategoryContainer>
  );
};

export default CreateAndEditCategory;
