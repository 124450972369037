import styled from 'styled-components';
import DefaultButton from '../../components/DefaultButton';

const Container = styled.div``;

const StyledButton = styled(DefaultButton)`
  padding: 0.4rem !important;
  margin: 0.2rem;
  transition: filter 0.2s;
  width: 120px;

  &:hover {
    filter: brightness(0.9);
  }

  @media (max-width: 768px) {
    margin-top: 1rem;
  }
`;

export { Container, StyledButton };
