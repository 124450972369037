import httpClient from '../http-client';
import { Tag } from '../models/tag';

interface GetAllTags {
  title?: string;
  description?: string;
  is_active?: boolean;
  limit?: number;
  offset?: number;
  order_by?: 'title' | 'is_active';
  sort_by?: string;
  relations?: 'contents';
}

const getAllTags = async (query?: GetAllTags) => {
  return httpClient.get<Tag[]>('tags', { params: query }).then((res) => res.data);
};

const createTag = async (newTag: Partial<Omit<Tag, 'tag_id' | 'is_active'>>) => {
  return httpClient.post<Tag>('tags', newTag).then((res) => res.data);
};

const updateTag = async (tagId: string, newTag: Partial<Omit<Tag, 'tag_id' | 'is_active'>>) => {
  await httpClient.put(`tags/${tagId}`, newTag);
};

const getTag = async (tagId: string) => {
  return httpClient.get<Tag>(`tags/${tagId}`).then((res) => res.data);
};

const deleteTag = async (tagId: string) => {
  return httpClient.delete(`tags/${tagId}`);
};

const activateOrInactivateTag = async (tagId: any, activate: boolean) => {
  return httpClient.patch(`tags/${tagId}/active`, { is_active: activate });
};

export { getAllTags, createTag, updateTag, getTag, deleteTag, activateOrInactivateTag };
