import httpClient from '../http-client';
import { Exam, ExamQuestion } from '../models/exam';

interface CreateExam extends Omit<Exam, 'contents'> {
  contents: { content_id: string }[];
}

const getAllExams = async () => {
  return httpClient
    .get<Exam[]>('exams', {
      params: {},
    })
    .then((res) => res.data);
};

const deleteExam = async (examId: string) => {
  return httpClient.delete(`exams/${examId}`).then((res) => res.data);
};

const getExam = async (examId: string) => {
  return httpClient
    .get<Exam>(`/exams/${examId}`, {
      params: {
        relations: ['exam_questions', 'contents'],
      },
    })
    .then((res) => res.data);
};

const createExam = async (exam: Partial<CreateExam>) => {
  return httpClient.post<Exam>('/exams', exam).then((res) => res.data);
};

const updateExam = async (examId: string, exam: Partial<Exam>) => {
  await httpClient.put(`/exams/${examId}`, exam);
};

const getQuestion = async (questionId: string) => {
  return httpClient
    .get<ExamQuestion>(`/exam-questions/${questionId}`, {
      params: {
        relations: ['exam_question_alternatives'],
      },
    })
    .then((res) => res.data);
};

const updateQuestion = async (questionId: string, data: Partial<Omit<ExamQuestion, 'info' | 'exam_question_id'>>) => {
  await httpClient.put<ExamQuestion>(`/exam-questions/${questionId}`, data);
};

const createQuestion = async (data: Omit<ExamQuestion, 'info' | 'exam_question_id'>) => {
  return httpClient.post<ExamQuestion>('/exam-questions', data).then((res) => res.data);
};

const deleteQuestion = async (questionId: string) => {
  await httpClient.delete<ExamQuestion>(`/exam-questions/${questionId}`);
};

const generateExamReport = async (examId: string, startDate?: string, endDate?: string) => {
  const params: { answered_at_gt?: string; answered_at_lt?: string } = {};

  if (startDate) params['answered_at_gt'] = startDate;
  if (endDate) params['answered_at_lt'] = startDate;

  return httpClient.get(`/exam-users/${examId}/answer-report`, { params }).then((res) => res.data);
};

export {
  getAllExams,
  deleteExam,
  getExam,
  createExam,
  updateExam,
  getQuestion,
  createQuestion,
  updateQuestion,
  deleteQuestion,
  generateExamReport,
};
