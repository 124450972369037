import React, { useEffect, useState } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import CurrencyInput from 'react-currency-input-field';
import { AiOutlineArrowDown, AiOutlineArrowUp, AiOutlineCheck, AiOutlineClose } from 'react-icons/ai';

import BreadCrumb from '../../components/BreadCrumb';
import DefaultCreationForm, {
  DefaultCreationFormButtonGroup,
  DefaultCreationFormGroup,
} from '../../components/DefaultCreationForm';
import DefaultInput from '../../components/DefaultInput';
import { DefaultPageTitle } from '../../components/DefaultPageTitle';
import checkEmptyString from '../../helpers/check-empty-string';

import { BenefitsList, Container, InputBenefitContainer, StyledButton, Switch } from './styles';
import { BiTrash } from 'react-icons/bi';
import Swal from 'sweetalert2';
import { getPlan, Plan, updatePlan } from '../../services/plans';
import { formatPlanToSendUpdate } from '../../utils/formatPlanToSendUpdate';

interface EditPlanParams {
  planId: string;
}

interface Benefit {
  label: string;
  active: boolean;
}

const EditPlan: React.FC = () => {
  const [plan, setPlan] = useState<Plan>({
    plan_id: '',
    title: '',
    discount: 0,
    full_price: 0,
    price: 0,
    period_in_days: 30,
    is_active: true,
    benefits: [],
    info: {
      isRecommended: false,
      max_installments: undefined,
    },
  } as Plan);

  const [benefit, setBenefit] = useState('');
  const [benefitActive, setBenefitActive] = useState<boolean | null>(null);
  const [isFreePlan, setIsFreePlan] = useState(false);
  const [period, setPeriod] = useState<string>('');

  const { planId } = useParams<EditPlanParams>();
  const history = useHistory();

  const handleAddBenefit = () => {
    setPlan((state) => ({ ...state, benefits: [...state.benefits, { label: benefit, active: !!benefitActive }] }));
    setBenefitActive(null);
    setBenefit('');
  };

  const handleExcludeBenefit = (label: string) => {
    setPlan((state) => ({ ...state, benefits: state.benefits.filter((benefit) => benefit.label !== label) }));
  };

  const handleUpdatePlan = async () => {
    const planAux = plan;

    try {
      if (checkEmptyString(planAux.title)) {
        throw new Error('Informe um título');
      }

      if (!planAux.price && plan.info.type !== 0) {
        throw new Error('Informe um valor para o plano');
      }

      if (planAux.benefits.length === 0) {
        throw new Error('Informe ao menos 1 benefício');
      }

      if (!plan.info.max_installments && plan.info.type !== 0) {
        throw new Error('Informe um número máximo de parcelas');
      }

      if (planAux?.full_price && planAux.price >= planAux.full_price) {
        throw new Error('Valor atual do plano não pode ser maior que valor anterior');
      }

      const type = (() => {
        if (planAux.price === 0) {
          return 0;
        }
      })();

      Object.assign(planAux.info, { type });

      const planUpdated = formatPlanToSendUpdate(planAux);

      await updatePlan(planId, planUpdated);

      Swal.fire({
        icon: 'success',
        title: 'Plano editado com sucesso!',
      });
      history.goBack();
    } catch (error: any) {
      Swal.fire({
        icon: 'error',
        title: 'Erro ao enviar os dados!',
        text: error?.response?.data?.message || error.message || 'Ocorreu um erro inesperado',
      });
    }
  };

  const handleCancelUpdate = () => {
    Swal.fire({
      icon: 'question',
      title: 'Deseja realmente cancelar?',
      text: 'As alterações não serão salvas!',
    }).then((result) => {
      if (!result) return;

      history.goBack();
    });
  };

  const handleMoveItem = (item: Benefit, position: number, move: 'up' | 'down') => {
    const benefitsAux = plan.benefits;

    benefitsAux.splice(position, 1);

    move === 'up' ? benefitsAux.splice(position - 1, 0, item) : benefitsAux.splice(position + 1, 0, item);

    setPlan((state) => ({ ...state, benefits: benefitsAux }));
  };

  const planPeriodToDays: { [key: number]: string } = {
    30: 'mensal',
    90: 'trimestral',
    180: 'semestral',
    365: 'anual',
  };

  useEffect(() => {
    const fetchPlan = async () => {
      const response = await getPlan(planId);
      setPlan(response);
      const typeOfPeriod = planPeriodToDays[response.period_in_days];
      setPeriod(typeOfPeriod);
      setIsFreePlan(response.info.type === 0);
    };

    fetchPlan();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [planId]);

  const MAX_INSTALLMENTS_PLAN_MES = 1;
  const MAX_INSTALLMENTS_PLAN_TRIM = 3;
  const MAX_INSTALLMENTS_PLAN_SEM = 6;
  const MAX_INSTALLMENTS_PLAN_ANUAL = 12;

  function getMaxInstallments(value: number) {
    try {
      if (value <= 0) {
        throw new Error('A quantidade mínima possível é a vista de 1x');
      }

      if (period === 'mensal' && value > MAX_INSTALLMENTS_PLAN_MES) {
        throw new Error(`Quantidade de parcelas acima do permitido. Máximo ${MAX_INSTALLMENTS_PLAN_MES}x.`);
      }
      if (period === 'trimestral' && value > MAX_INSTALLMENTS_PLAN_TRIM) {
        throw new Error(`Quantidade de parcelas acima do permitido. Máximo ${MAX_INSTALLMENTS_PLAN_TRIM}x.`);
      }
      if (period === 'semestral' && value > MAX_INSTALLMENTS_PLAN_SEM) {
        throw new Error(`Quantidade de parcelas acima do permitido. Máximo ${MAX_INSTALLMENTS_PLAN_SEM}x.`);
      }
      if (period === 'anual' && value > MAX_INSTALLMENTS_PLAN_ANUAL) {
        throw new Error(`Quantidade de parcelas acima do permitido. Máximo ${MAX_INSTALLMENTS_PLAN_ANUAL}x.`);
      }

      setPlan((state) => ({
        ...state,
        info: { ...state.info, max_installments: Number(value) },
      }));
    } catch (error: any) {
      setPlan((state) => ({
        ...state,
      }));
      Swal.fire({
        icon: 'error',
        title: 'Erro ao enviar os dados!',
        text: error?.response?.data?.message || error.message || 'Ocorreu um erro inesperado',
      });
    }
  }

  return (
    <Container>
      <BreadCrumb
        crumbs={[<Link to="/dashboard">Dashboard</Link>, <Link to="/plans">Planos</Link>, <span>Editar plano</span>]}
      />

      <DefaultPageTitle>Editar plano</DefaultPageTitle>

      <DefaultCreationForm>
        <DefaultCreationFormGroup>
          <label>Recomendado</label>
          <Switch>
            <input
              type="checkbox"
              checked={!!plan?.info?.isRecommended}
              onChange={() =>
                setPlan((state) => ({
                  ...state,
                  info: { ...state.info, isRecommended: !state?.info?.isRecommended },
                }))
              }
            />
            <span className="slider round"></span>
          </Switch>
        </DefaultCreationFormGroup>

        <DefaultCreationFormGroup>
          <label className="required" htmlFor="title">
            Título
          </label>
          <DefaultInput
            id="title"
            placeholder="Digite o título deste plano"
            value={plan.title}
            onChange={(e) => setPlan((state) => ({ ...state, title: e.target.value }))}
            required
          />
        </DefaultCreationFormGroup>

        {isFreePlan ? null : (
          <DefaultCreationFormGroup>
            <label className="required" htmlFor="maxInstallments">
              Parcelamento máximo
            </label>
            <DefaultInput
              id="maxInstallments"
              placeholder="Digite a quantidade máxima de parcelamento deste plano"
              value={plan.info.max_installments}
              type="number"
              onChange={(e) => getMaxInstallments(Number(e.target.value))}
              required
            />
          </DefaultCreationFormGroup>
        )}

        {isFreePlan ? null : (
          <>
            <DefaultCreationFormGroup>
              <label htmlFor="price">Valor anterior do plano</label>
              <CurrencyInput
                className="currency-input"
                id="price"
                name="price"
                placeholder="Valor anterior do plano"
                value={plan.full_price}
                prefix="R$"
                onValueChange={(value) =>
                  setPlan((state) => ({ ...state, full_price: parseFloat(value?.replace(',', '.')!) }))
                }
              />
            </DefaultCreationFormGroup>

            <DefaultCreationFormGroup>
              <label className="required" htmlFor="percentageDiscount">
                Valor atual do plano
              </label>
              <CurrencyInput
                className="currency-input"
                id="price"
                name="price"
                prefix="R$"
                placeholder="Valor atual do plano"
                value={plan.price}
                onValueChange={(value) =>
                  setPlan((state) => ({ ...state, price: parseFloat(value?.replace(',', '.')!) }))
                }
              />
            </DefaultCreationFormGroup>

            <DefaultCreationFormGroup>
              <label htmlFor="percentageDiscount">Porcentagem de desconto em conteúdos</label>
              <CurrencyInput
                className="currency-input"
                id="percentageDiscount"
                name="percentageDiscount"
                suffix="%"
                placeholder="Digite o valor em porcentagem"
                value={plan.discount}
                allowDecimals={false}
                onValueChange={(value) => setPlan((state) => ({ ...state, discount: Number(value)! }))}
                maxLength={2}
              />
            </DefaultCreationFormGroup>
          </>
        )}

        <DefaultCreationFormGroup>
          <label className="required" htmlFor="benefit">
            Benefícios
          </label>

          <InputBenefitContainer>
            <DefaultInput
              value={benefit}
              onChange={(e) => setBenefit(e.target.value)}
              id="benefit"
              placeholder="Escreva a frase resumo de um benefício"
            />

            <StyledButton
              disabled={benefitActive === true}
              type="button"
              className="success"
              onClick={() => setBenefitActive(true)}
            >
              Incluso
            </StyledButton>

            <StyledButton
              disabled={benefitActive === false}
              type="button"
              className="danger"
              onClick={() => setBenefitActive(false)}
            >
              Excluso
            </StyledButton>

            <StyledButton type="button" onClick={handleAddBenefit}>
              Adicionar
            </StyledButton>
          </InputBenefitContainer>
        </DefaultCreationFormGroup>

        <DefaultCreationFormGroup>
          <label>Benefícios adicionados</label>
          <BenefitsList>
            {plan.benefits.length === 0 && <h4>Sem benefícios ainda</h4>}
            {plan.benefits.map((item: Benefit, index) => (
              <li>
                {item.active ? <AiOutlineCheck color="green" /> : <AiOutlineClose color="red" />}
                <p style={{ textDecoration: item.active ? '' : 'line-through' }}>{item.label}</p>

                {index > 0 && (
                  <StyledButton
                    type="button"
                    className="arrows small info"
                    onClick={() => handleMoveItem(item, index, 'up')}
                  >
                    <AiOutlineArrowUp />
                  </StyledButton>
                )}

                {index < plan.benefits.length - 1 && (
                  <StyledButton
                    type="button"
                    className="arrows small info"
                    onClick={() => handleMoveItem(item, index, 'down')}
                  >
                    <AiOutlineArrowDown />
                  </StyledButton>
                )}

                <StyledButton type="button" className="small danger" onClick={() => handleExcludeBenefit(item.label)}>
                  Excluir
                  <BiTrash />
                </StyledButton>
              </li>
            ))}
          </BenefitsList>
        </DefaultCreationFormGroup>

        <DefaultCreationFormButtonGroup>
          <StyledButton type="button" className="success" onClick={handleUpdatePlan}>
            SALVAR
          </StyledButton>

          <StyledButton type="button" className="danger" onClick={handleCancelUpdate}>
            CANCELAR
          </StyledButton>
        </DefaultCreationFormButtonGroup>
      </DefaultCreationForm>
    </Container>
  );
};

export default EditPlan;
