import httpClient from '../http-client';

export interface InfoProps {
  mediaUrl: string;
  bannerUrl: string;
}

const getPageContent = async () => {
  return httpClient.get('/home-info', { params: { type: 'landingPageContent' } }).then((res) => res.data);
};

const updateLandingPage = async (id: string, info: InfoProps, text: string) => {
  return httpClient
    .put(`home-info/${id}`, {
      text,
      info,
    })
    .then((res) => res.data);
};

export { getPageContent, updateLandingPage };
