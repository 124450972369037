import styled from 'styled-components';
import DefaultButton from '../../../../components/DefaultButton';

interface CountProps {
  count: number;
}

export const Container = styled.section`
  margin: 32px 0;

  h2 {
    margin-bottom: 16px;
    font-size: 1.2rem;
  }

  article {
    display: flex;
    margin-bottom: 16px;
    align-items: flex-end;
  }
`;

export const Count = styled.span<CountProps>`
  color: ${({ count }) => {
    switch (count) {
      case 0:
        return '#E52629';

      case 1 || 2:
        return '#FCC201';

      case 3:
        return '#ACC32B';

      default:
        return '#31348B';
    }
  }};

  margin: 0px auto 0 16px;
`;

export const ActionsContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const StyledButton = styled(DefaultButton)`
  padding: 0.4rem !important;
  margin: 0.2rem;
  transition: filter 0.2s;
  width: 120px;

  &:hover {
    filter: brightness(0.9);
  }

  @media (max-width: 768px) {
    margin-top: 1rem;
  }
`;
