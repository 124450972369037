import React, { useState } from 'react';
import InputRange from 'react-input-range';
import { Link, useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import 'react-input-range/lib/css/index.css';

import BreadCrumb from '../../components/BreadCrumb';
import DefaultCreationForm, { DefaultCreationFormGroup } from '../../components/DefaultCreationForm';
import DefaultInput from '../../components/DefaultInput';
import { DefaultPageTitle } from '../../components/DefaultPageTitle';
import checkEmptyString from '../../helpers/check-empty-string';
import { createLevel } from '../../services/level';

import { Container, StyledButton, CustomCreationFormGroup, Switch } from './styles';

const CreateLevel: React.FC = () => {
  const [name, setName] = useState('');
  const [minPoints, setMinPoints] = useState(0);
  const [maxPoints, setMaxPoints] = useState(0);
  const [isLastLevel, setIsLastLevel] = useState(false);

  const history = useHistory();

  const handleSaveLevel = async () => {
    try {
      if (checkEmptyString(name)) {
        throw new Error('Digite um nome para este nível');
      }

      if ((!isLastLevel && maxPoints === 0) || (!isLastLevel && maxPoints < minPoints)) {
        throw new Error('Dê um valor válido de pontos máximos');
      }

      await createLevel({
        name,
        range: {
          start: minPoints,
          end: isLastLevel ? null : maxPoints,
        },
      });

      Swal.fire({
        title: 'Sucesso!',
        text: 'Nível criado com sucesso',
        icon: 'success',
      });

      history.goBack();
    } catch (error: any) {
      Swal.fire({
        title: 'Erro!',
        text: `Houve um problema ao salvar este nível! ${
          error?.response?.data?.message || error.message || 'Ocorreu um erro inesperado'
        }`,
        icon: 'error',
      });
    }
  };

  return (
    <Container>
      <BreadCrumb
        crumbs={[
          <Link to="/dashboard">Dashboard</Link>,
          <Link to="/levels">Gameficação</Link>,
          <span>Criar nível</span>,
        ]}
      />

      <DefaultPageTitle>Criar nível</DefaultPageTitle>

      <DefaultCreationForm>
        <DefaultCreationFormGroup>
          <label>Último nível</label>
          <Switch>
            <input type="checkbox" checked={isLastLevel} onChange={(e) => setIsLastLevel(e.target.checked)} />
            <span className="slider round"></span>
          </Switch>
        </DefaultCreationFormGroup>

        <DefaultCreationFormGroup>
          <label className="required" htmlFor="name">
            Nome
          </label>
          <DefaultInput
            id="name"
            placeholder="Digite o nome deste nível"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
            maxLength={25}
          />
        </DefaultCreationFormGroup>

        <CustomCreationFormGroup>
          <label className="required" htmlFor="minPoints">
            Pontuação mínima
          </label>
          <InputRange
            maxValue={101}
            minValue={0}
            value={minPoints}
            onChange={(value) => setMinPoints(value as number)}
          />

          <span>Valor selecionado: {minPoints}</span>
        </CustomCreationFormGroup>

        {isLastLevel ? null : (
          <CustomCreationFormGroup>
            <label className="required" htmlFor="minPoints">
              Pontuação máxima
            </label>

            <InputRange
              maxValue={101}
              minValue={minPoints + 1}
              value={maxPoints}
              onChange={(value) => setMaxPoints(value as number)}
            />

            <span>Valor selecionado: {maxPoints}</span>
          </CustomCreationFormGroup>
        )}

        <CustomCreationFormGroup>
          <StyledButton type="button" onClick={handleSaveLevel} style={{ marginTop: 100 }}>
            SALVAR
          </StyledButton>
        </CustomCreationFormGroup>
      </DefaultCreationForm>
    </Container>
  );
};

export default CreateLevel;
