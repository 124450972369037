import httpClient from '../http-client';
import { DashboardFromResponse, UsersByPlans } from '../models/from-api-response/dashboard';

const getDashboard = async (): Promise<DashboardFromResponse> => {
  const { data } = await httpClient.get<DashboardFromResponse>('/dashboard');
  return data;
};

const getUsersByPlans = async (): Promise<UsersByPlans> => {
  const { data } = await httpClient.get<UsersByPlans>('/dashboard/users-by-plans');
  return data;
};

export { getDashboard, getUsersByPlans };
