import httpClient from '../http-client';

const getPageContent = async () => {
  return httpClient.get('/home-info', { params: { type: 'terms' } }).then((res) => res.data[0]);
};

const createTermsAndPrivacyPolicy = async (termsOfUse: string, privacyPolicy: string) => {
  return httpClient
    .post('home-info', {
      text: 'Termos de Uso e Política de Privacidade',
      type: 'terms',
      info: {
        termsOfUse,
        privacyPolicy,
      },
    })
    .then((res) => res.data);
};

const updateTermsAndPrivacyPolicy = async (id: string, termsOfUse: string, privacyPolicy: string) => {
  return httpClient
    .put(`home-info/${id}`, {
      info: {
        termsOfUse,
        privacyPolicy,
      },
    })
    .then((res) => res.data);
};

export { getPageContent, updateTermsAndPrivacyPolicy, createTermsAndPrivacyPolicy };
