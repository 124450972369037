import styled, { css } from 'styled-components';

export interface TagProps {
  state: 'active' | 'inactive' | 'overdue' | 'valid';
}

export const Container = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 1200px;
  max-width: 100%;
  min-height: 500px;
  max-height: 100%;
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.3);
  padding: 24px;
`;

export const SelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  margin-top: 24px;

  #finishDate {
    padding: 8px;
    font-size: 1rem;
    border: 1px solid hsl(0, 0%, 80%);
    border-radius: 4px;
    width: 100%;
  }

  > div,
  input,
  button,
  h1 {
    margin-bottom: 8px;
  }

  h1 {
    font-size: 1em;
    margin-bottom: 8px;
  }
`;

export const Overlay = styled.div`
  z-index: 99;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.4;
`;

export const Tag = styled.div<TagProps>`
  border-radius: 4px;
  font-weight: bold;
  padding: 4px 0;
  margin: 4px 0;

  ${({ state }) => {
    const colors = {
      active: 'rgba(90, 300, 43, 0.4)',
      overdue: 'rgba(269, 38, 41, 0.4)',
      inactive: 'rgba(155, 153, 175, 0.31)',
      valid: 'rgba(49, 52, 139, 0.6)',
    };

    const fontColors = {
      active: 'rgba(0, 0, 0, 0.7)',
      overdue: '#fff',
      inactive: 'rgba(0, 0, 0, 0.7)',
      valid: '#fff',
    };

    return css`
      border: 1px solid ${colors[state]};
      background-color: ${colors[state]};
      color: ${fontColors[state]};
    `;
  }}
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ActionButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
