import React, { useRef, useState, useEffect } from 'react';

const ModalContext = React.createContext<HTMLDivElement | null>(null);

const ModalProvider: React.FC = ({ children }) => {
  const modalRef = useRef<HTMLDivElement>(null);
  const [context, setContext] = useState<HTMLDivElement | null>(null);

  useEffect(() => {
    setContext(modalRef.current);
  }, []);

  return (
    <>
      <ModalContext.Provider value={context}>{children}</ModalContext.Provider>
      <div ref={modalRef} />
    </>
  );
};

export { ModalProvider, ModalContext };
