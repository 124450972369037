import React, { useCallback, useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import { AiOutlineDown, AiOutlineUp } from 'react-icons/ai';

import Content from '../../../models/content';
import { getContent, updateContent } from '../../../services/contents';

import { Container, LessonSelectionContainer, SortLessonsContainer } from './styles';
import { hideModal } from '../../../helpers/modal';
import DefaultButton from '../../../components/DefaultButton';

interface Props {
  module: Content;
  lessonId: string;
  reloadContents: (agr?: number) => void;
  courseId: string
}

const LinkLessonInOrder: React.FC<Props> = ({ module, courseId, lessonId, reloadContents }: Props) => {
  const [lessonsOrdered, setLessonsOrdered] = useState<Content[]>([]);

  const reorderSelectedLessons = useCallback(
    (lesson: Content, up: boolean) => {
      const orderLessons = [...lessonsOrdered];

      const indexOfLesson = orderLessons.indexOf(lesson);
      if (indexOfLesson > -1 && indexOfLesson < orderLessons.length) {
        orderLessons.splice(indexOfLesson, 1);
        if (up) {
          orderLessons.splice(indexOfLesson - 1, 0, lesson);
        } else {
          orderLessons.splice(indexOfLesson + 1, 0, lesson);
        }
      }

      setLessonsOrdered(orderLessons);
    },
    [lessonsOrdered]
  );

  const handleSave = useCallback(async () => {
    const formattedLessons = lessonsOrdered.map((lesson, index) => ({
      child_id: lesson.content_id,
      position: index + 1,
    }));

    try {
      await updateContent(module.content_id, {
        children: formattedLessons,
      });

      await updateContent(courseId, {});

      Swal.fire({
        title: 'Sucesso!',
        text: 'Aula adicionada ao módulo selecionado, para mais detalhes acesse a edição do curso.',
        icon: 'success',
      });

      hideModal(undefined, true);
      reloadContents();
    } catch {
      Swal.fire({
        title: 'Erro',
        text: 'Ocorreu um erro ao vincular a aula neste módulo! Tente novamente.',
        icon: 'error',
      });
    }
  }, [lessonsOrdered, module.content_id, reloadContents]);

  useEffect(() => {
    (async () => {
      const lesson = await getContent(lessonId);

      const lessonsUpdated = module.children;
      lessonsUpdated.push(lesson);

      setLessonsOrdered(lessonsUpdated);
    })();
  }, [lessonId, module]);

  return (
    <Container>
      <LessonSelectionContainer>
        <SortLessonsContainer>
          {lessonsOrdered.map((lesson, index) => (
            <div
              key={lesson.content_id}
              className={`selected-lessons ${lesson.content_id === lessonId && 'new-lesson'}`}
            >
              <div className="buttons">
                {index > 0 ? (
                  <>
                    {index + 1}
                    <DefaultButton
                      type="button"
                      title="Subir Aula"
                      className="small white up"
                      onClick={() => reorderSelectedLessons(lesson, true)}
                    >
                      <AiOutlineUp />
                    </DefaultButton>
                  </>
                ) : null}
                {index < lessonsOrdered.length - 1 ? (
                  <>
                    {index === 0 ? index + 1 : null}
                    <DefaultButton
                      type="button"
                      title="Descer Aula"
                      className="small white down"
                      onClick={() => reorderSelectedLessons(lesson, false)}
                    >
                      <AiOutlineDown />
                    </DefaultButton>
                  </>
                ) : null}
              </div>
              <div className="lesson-title">
                {index + 1} - {lesson.name}
              </div>
            </div>
          ))}
        </SortLessonsContainer>
      </LessonSelectionContainer>

      <DefaultButton type="button" title="Salvar" className="success" onClick={handleSave}>
        Salvar
      </DefaultButton>
    </Container>
  );
};

export default LinkLessonInOrder;
