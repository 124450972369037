import httpClient from '../http-client';
import UserFromResponse, { IUser } from '../models/from-api-response/user';

interface GetUsersResponse {
  users: {
    users: UserFromResponse[];
    total: number;
  };
}

interface ParamsRequestUsers {
  limit?: number;
  offset?: number | undefined;
  search?: string;
}

export default interface GetUserSubscriptionResponse {
  id: number;
  status: string;
  start_at: string;
  end_at?: string;
  next_billing_at?: string;
  overdue_since?: string;
  code?: string;
  plan: {
    id: number;
    name: string;
    code: string;
  };
  current_period?: {
    id: number;
    billing_at: string;
    cycle: number;
    start_at: string;
    end_at: string;
    duration: number;
  };
}

const getUsers = async (params: ParamsRequestUsers): Promise<GetUsersResponse> => {
  return httpClient.get<GetUsersResponse>('/users', { params }).then((res) => res.data);
};

const getUserById = async (user_id: string): Promise<IUser> => {
  return httpClient.get<IUser>(`/users/${user_id}`).then((res) => res.data);
};

const getActualUser = async () => {
  return httpClient.get('users/me').then((res) => res.data);
};

const updateUserToAdmin = async (userId: string) => {
  return httpClient.patch(`users/${userId}/admin`).then((res) => res.data);
};

const updateUserToModerator = async (userId: string) => {
  return httpClient.patch(`users/${userId}/moderator`).then((res) => res.data);
};

const activateOrInactivateUser = async (userId: string) => {
  return httpClient.patch(`/users/${userId}/active-deactive`).then((res) => res.data);
};

const updatePoints = async (userId: string, points: number) => {
  return httpClient.patch(`/users/${userId}/points`, { points }).then((res) => res.data);
};

const getUserCourses = async (userId: string) => {
  return httpClient.get(`/users/${userId}/contents`).then((res) => res.data);
};

const getUserPlanSubscription = async (subscriptionId: string): Promise<GetUserSubscriptionResponse> => {
  return httpClient
    .get<GetUserSubscriptionResponse>(`/payments/${subscriptionId}/subscription`)
    .then((res) => res.data);
};

export {
  getActualUser,
  getUsers,
  updateUserToAdmin,
  activateOrInactivateUser,
  getUserCourses,
  updatePoints,
  getUserById,
  getUserPlanSubscription,
  updateUserToModerator,
};
