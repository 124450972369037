import React, { useEffect, useMemo, useState } from 'react';
import { IoAdd } from 'react-icons/io5';
import Swal from 'sweetalert2';
import { v4 as uuid } from 'uuid';

import DefaultButton from '../../components/DefaultButton';
import DefaultCreationForm, {
  DefaultCreationFormButtonGroup,
  DefaultCreationFormGroup,
} from '../../components/DefaultCreationForm';
import { DefaultPageTitle } from '../../components/DefaultPageTitle';
import { DefaultTextArea } from '../../components/DefaultTextArea';
import { updateQuestion as updateQuestionService, getQuestion as getQuestionService } from '../../services/exams';
import checkEmptyString from '../../helpers/check-empty-string';
import { AiOutlineDown, AiOutlineUp } from 'react-icons/ai';
import { BiTrash } from 'react-icons/bi';
import { ExamQuestion, ExamQuestionAlternative, ExamQuestionType } from '../../models/exam';

import { AlternativesList, CreateAndEditQuestionContainer } from './style';
import { useExam } from '../../hooks/ExamHook';

interface Props {
  questionId?: string;
  localId?: string;
  examId?: string;
  onClose: (reload?: boolean) => void;
}

const CreateAndEditQuestion: React.FC<Props> = ({ questionId, localId, examId, onClose }) => {
  const [description, setDescription] = useState('');
  const [alternatives, setAlternatives] = useState([] as ExamQuestionAlternative[]);
  const [actualAlternativeDescription, setActualAlternativeDescription] = useState('');

  const { addQuestion, getLocalQuestion, updateLocalQuestion } = useExam();

  const addAlternative = () => {
    if (!checkEmptyString(actualAlternativeDescription)) {
      const newAlternative: Partial<ExamQuestionAlternative> = {
        description: actualAlternativeDescription,
      };

      setAlternatives(() => {
        const position = alternatives?.length === 0 ? 1 : alternatives.length + 1;
        return [...alternatives, { ...newAlternative, position }];
      });
      setActualAlternativeDescription('');
    } else {
      Swal.fire({
        title: 'Erro',
        text: 'Informe uma descrição valida para a alternativa!',
        icon: 'error',
      });
    }
  };

  const setCorrectAnswer = (alternative: ExamQuestionAlternative) => {
    for (let alternative of alternatives) {
      if (alternative.is_correct !== undefined) {
        alternative.is_correct = false;
      }
    }

    alternative.is_correct = true;

    setAlternatives([...alternatives]);
  };

  const createQuestion = async (event: React.FormEvent) => {
    if (event && event.preventDefault) {
      event.preventDefault();
    }

    try {
      if (checkEmptyString(description)) {
        throw new Error('Informe uma descrição para essa questão');
      }

      if (!alternatives || alternatives.length < 2) {
        throw new Error('Informe ao menos duas alternativas!');
      }

      if (!alternatives.find((alt) => alt.is_correct)) {
        throw new Error('Informe a alternativa correta!');
      }

      const question = {
        id: uuid(),
        description,
        type: ExamQuestionType.MULTIPLE_CHOICE,
        exam_question_alternatives: alternatives,
      } as ExamQuestion;

      addQuestion(question);

      Swal.fire({
        title: 'Sucesso',
        text: 'Questão salva com sucesso!',
        icon: 'success',
      });
      onClose();
    } catch (error: any) {
      Swal.fire({
        title: 'Erro',
        text: `Erro ao salvar questão.${
          error?.response?.data?.message || error.message || 'Ocorreu um erro inesperado'
        }`,
        icon: 'error',
      });
    }
  };

  const updateQuestion = async (event: React.FormEvent) => {
    if (event && event.preventDefault) {
      event.preventDefault();
    }

    try {
      if (checkEmptyString(description)) {
        throw new Error('Informe uma descrição para essa questão');
      }
      
      if (!alternatives || alternatives.length < 2) {
        throw new Error('Informe ao menos duas alternativas!');
      }

      if (!alternatives.find((alt) => alt.is_correct)) {
        throw new Error('Informe a alternativa correta!');
      }

      const alternativesToUpdate = alternatives.map((alt) => {
        delete alt.exam_question_id;
        delete alt.exam_question_alternative_id;
        delete alt.updated_at;
        delete alt.deleted_at;
        delete alt.created_at;

        return alt;
      });

      if (questionId) {
        await updateQuestionService(questionId, {
          description,
          exam_id: examId!,
          type: ExamQuestionType.MULTIPLE_CHOICE,
          exam_question_alternatives: alternativesToUpdate,
        });

        onClose(true);

        return;
      }

      updateLocalQuestion(localId!, {
        description,
        exam_id: examId!,
        type: ExamQuestionType.MULTIPLE_CHOICE,
        exam_question_alternatives: alternativesToUpdate,
      });

      Swal.fire({
        title: 'Sucesso',
        text: 'Questão editada com sucesso!',
        icon: 'success',
      });

      Swal.fire({
        title: 'Sucesso',
        text: 'Questão editada com sucesso!',
        icon: 'success',
      });
      onClose();
    } catch (error: any) {
      Swal.fire({
        title: 'Erro',
        text: `Erro ao editar Questão.${
          error?.response?.data?.message || error.message || 'Ocorreu um erro inesperado'
        }`,
        icon: 'error',
      });
    }
  };

  const removeAlternative = (alternative: ExamQuestionAlternative) => {
    setAlternatives([
      ...alternatives
        .filter((alt) => alt.description !== alternative.description)
        .map((alt, index) => ({ ...alt, position: index + 1 })),
    ]);
  };

  const changeAlternativePosition = (alternative: ExamQuestionAlternative, up: boolean) => {
    const indexOfAlternative = alternatives.indexOf(alternative);

    const alternativesAux = [...alternatives];

    if (up) {
      // nextAlternative = the minimum index number  of array, is more close to up (first element)
      const nextAlternative = alternatives[indexOfAlternative - 1];

      alternativesAux[indexOfAlternative] = { ...nextAlternative, position: nextAlternative.position! + 1 };
      alternativesAux[indexOfAlternative - 1] = { ...alternative, position: alternative.position! - 1 };
    } else {
      const previousAlternative = alternativesAux[indexOfAlternative + 1];

      alternativesAux[indexOfAlternative] = { ...previousAlternative, position: previousAlternative.position! - 1 };
      alternativesAux[indexOfAlternative + 1] = { ...alternative, position: alternative.position! + 1 };
    }

    setAlternatives(alternativesAux);
  };

  const isEditing = useMemo(() => {
    return !!questionId || !!localId;
  }, [questionId, localId]);

  useEffect(() => {
    const getQuestion = async () => {
      if (localId) {
        const question = getLocalQuestion(localId);
        setDescription(question?.description);
        setAlternatives(
          question?.exam_question_alternatives?.sort((altA, altB) => (altA?.position > altB?.position ? 1 : -1)) || []
        );

        return;
      }
      if (questionId) {
        const question = await getQuestionService(questionId);
        setDescription(question.description);
        setAlternatives(
          question.exam_question_alternatives.sort((altA, altB) => (altA?.position > altB?.position ? 1 : -1)) || []
        );
      }
    };

    getQuestion();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionId, localId]);

  return (
    <CreateAndEditQuestionContainer>
      <DefaultPageTitle>{isEditing ? 'Editar' : 'Criar'} Questão</DefaultPageTitle>

      <DefaultCreationForm>
        <DefaultCreationFormGroup>
          <label className="required" htmlFor="description">
            Descrição
          </label>
          <DefaultTextArea
            id="description"
            required
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </DefaultCreationFormGroup>

        <DefaultCreationFormGroup>
          <label className="required" htmlFor="name">
            Alternativas
          </label>
          <div
            style={{
              border: 'solid 1px var(--default-dark-gray)',
              borderRadius: '5px',
              width: '100%',
              padding: '10px',
            }}
          >
            <DefaultCreationFormGroup>
              <label htmlFor="alternative">Descrição da alternativa</label>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <DefaultTextArea
                  style={{
                    marginRight: '10px',
                  }}
                  id="alternative"
                  value={actualAlternativeDescription}
                  onChange={(e) => setActualAlternativeDescription(e.target.value)}
                />
                <DefaultButton type="button" onClick={addAlternative} className="small">
                  <IoAdd size={26} />
                </DefaultButton>
              </div>
            </DefaultCreationFormGroup>

            {alternatives && alternatives.length ? (
              <AlternativesList>
                {alternatives.map((alt, index) => (
                  <div
                    key={index}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      position: 'relative',
                    }}
                  >
                    <input
                      type="radio"
                      name="is_correct"
                      style={{ marginRight: '10px' }}
                      checked={alt.is_correct}
                      onChange={() => setCorrectAnswer(alt)}
                    />
                    <p>{alt.description}</p>

                    <div
                      style={{
                        position: 'absolute',
                        top: '3px',
                        right: '3px',
                      }}
                    >
                      {index !== 0 ? (
                        <>
                          <DefaultButton
                            type="button"
                            onClick={() => changeAlternativePosition(alt, true)}
                            className="small white up"
                          >
                            <AiOutlineUp />
                          </DefaultButton>
                        </>
                      ) : null}
                      {index + 1 !== alternatives.length ? (
                        <>
                          <DefaultButton
                            type="button"
                            onClick={() => changeAlternativePosition(alt, false)}
                            className="small white down"
                          >
                            <AiOutlineDown />
                          </DefaultButton>
                        </>
                      ) : null}
                      <DefaultButton type="button" onClick={() => removeAlternative(alt)} className="small danger">
                        <BiTrash />
                      </DefaultButton>
                    </div>
                  </div>
                ))}
              </AlternativesList>
            ) : null}
          </div>
        </DefaultCreationFormGroup>

        <DefaultCreationFormButtonGroup>
          <DefaultButton type="button" className="danger" onClick={() => onClose()}>
            Cancelar
          </DefaultButton>
          <DefaultButton onClick={(e) => (isEditing ? updateQuestion(e) : createQuestion(e))} className="success">
            Salvar
          </DefaultButton>
        </DefaultCreationFormButtonGroup>
      </DefaultCreationForm>
    </CreateAndEditQuestionContainer>
  );
};

export default CreateAndEditQuestion;
