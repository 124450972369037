import React, { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';

import BreadCrumb from '../../components/BreadCrumb';
import CutImage from '../../components/CutImage';
import DefaultButton from '../../components/DefaultButton';
import DefaultCreationForm, {
  DefaultCreationFormButtonGroup,
  DefaultCreationFormGroup,
} from '../../components/DefaultCreationForm';
import DefaultInput from '../../components/DefaultInput';
import { DefaultPageTitle } from '../../components/DefaultPageTitle';
import { hideModal, showModal } from '../../helpers/modal';
import { DefaultTextArea } from '../../components/DefaultTextArea';
import checkEmptyString from '../../helpers/check-empty-string';
import { uploadFile } from '../../services/files';
import {
  createAscendedMaster as createAscendedMasterService,
  updateAscendedMaster as updateAscendedMasterService,
  getAscendedMaster as getAscendedMasterService,
  changeAscendedMasterThumbnail,
} from '../../services/ascendedMasters';

import { CreateAndEditCategoryContainer, ThumbnailUploadContainer, ContentThumbnail } from './styles';

interface CreateAndEditAscendedMasterProps {
  ascendedMasterId: string;
}

const CreateAndEditAscendedMaster: React.FC = () => {
  const history = useHistory();

  const { ascendedMasterId } = useParams<CreateAndEditAscendedMasterProps>();
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [thumbnail, setThumbnail] = useState('');
  const [thumbnailWebFileToUpload, setThumbnailWebFileToUpload] = useState<File>();

  const selectThumbnail = () => {
    showModal('Selecionar Imagem de Capa web', <CutImage aspectX={37} aspectY={20} onCutImage={onCutImage} />);
  };

  const onCutImage = (file: File) => {
    if (file) {
      setThumbnailWebFileToUpload(file);

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => setThumbnail(`${reader.result}`);

      hideModal();
    }
  };

  const createAscendedMaster = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      if (checkEmptyString(name)) {
        throw new Error('O nome não pode estar em branco.');
      }

      if (checkEmptyString(description)) {
        throw new Error('A descrição não pode estar em branco.');
      }

      let localThumbnail = '';

      if (!thumbnailWebFileToUpload) {
        throw new Error('Selecione uma imagem capa web');
      }

      const formDataThumbnail = new FormData();
      formDataThumbnail.append('file', thumbnailWebFileToUpload);
      const { reference: referenceThumbnail } = await uploadFile(formDataThumbnail);
      localThumbnail = referenceThumbnail;

      await createAscendedMasterService({
        name,
        description: description.trim().length ? description : undefined,
        images: {
          url: localThumbnail,
        },
      });

      Swal.fire({
        title: 'Sucesso!',
        text: 'Mestre Ascencionados criado com sucesso!',
        icon: 'success',
      });
      goToAscendedMasters();
    } catch (error: any) {
      Swal.fire({
        title: 'Erro',
        text: `Houve um erro ao criar o Mestre Ascencionado. ${
          error?.response?.data?.message || error.message || 'Ocorreu um erro inesperado'
        }`,
        icon: 'error',
      });
    }
  };

  const goToAscendedMasters = () => {
    history.push('/ascended-masters');
  };

  const updateDescription = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { target } = event;
    setDescription(target.value);
  };

  const isEditing = (() => {
    if (ascendedMasterId) {
      return true;
    }

    return false;
  })();

  const updateAscendedMaster = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      if (checkEmptyString(name)) {
        throw new Error('Informe um nome válido para o mestre ascencionado');
      }

      if (checkEmptyString(description)) {
        throw new Error('A descrição não pode estar em branco.');
      }

      let localThumbnail = thumbnail;

      if (!localThumbnail) {
        throw new Error('Selecione uma imagem para destaque');
      }

      const formDataThumbnail = new FormData();

      if (thumbnailWebFileToUpload) {
        formDataThumbnail.append('file', thumbnailWebFileToUpload);
      }

      changeAscendedMasterThumbnail(ascendedMasterId, formDataThumbnail);

      await updateAscendedMasterService(ascendedMasterId, {
        name,
        description,
      });

      goToAscendedMasters();
      Swal.fire({
        title: 'Sucesso!',
        text: 'Mestre Ascencionado editado com sucesso!',
        icon: 'success',
      });
    } catch (error: any) {
      Swal.fire({
        title: 'Erro',
        text: `Houve um erro ao editar o Mestre Ascencionado. ${
          error?.response?.data?.message || error.message || 'Ocorreu um erro inesperado'
        }`,
        icon: 'error',
      });
    }
  };

  useEffect(() => {
    (async () => {
      try {
        if (ascendedMasterId) {
          const content = await getAscendedMasterService(ascendedMasterId);
          if (content && Object.keys(content).length) {
            setName(content.name);
            setDescription(content.description || '');
            setThumbnail((content.images.thumbnail_url || content.images.url) as string);
          }
        }
      } catch (error: any) {
        Swal.fire({
          title: 'Erro',
          text: `Houve um erro ao criar o Mestre Ascencionado. ${
            error?.response?.data?.message || error.message || 'Ocorreu um erro inesperado'
          }`,
          icon: 'error',
        });
      }
    })();
  }, [ascendedMasterId]);

  return (
    <CreateAndEditCategoryContainer>
      <BreadCrumb
        crumbs={[
          <Link to="/dashboard">Dashboard</Link>,
          <Link to="/ascended-masters">Mestres Ascencionados</Link>,
          <span>{isEditing ? 'Editar' : 'Criar'} Mestres Ascencionados</span>,
        ]}
      />

      <DefaultPageTitle>{isEditing ? 'Editar' : 'Criar'} Mestres Ascencionados</DefaultPageTitle>

      <DefaultCreationForm>
        <DefaultCreationFormGroup>
          <label className="required" htmlFor="name">
            Nome
          </label>
          <DefaultInput value={name} onChange={(e) => setName(e.target.value)} id="name" required maxLength={250} />
        </DefaultCreationFormGroup>

        <DefaultCreationFormGroup>
          <label htmlFor="description" className="required">
            Descrição
          </label>
          <DefaultTextArea value={description || ''} onChange={updateDescription} id="description" />
        </DefaultCreationFormGroup>

        <DefaultCreationFormGroup>
          <label className="required" htmlFor="reference">
            Capa web
          </label>

          <ThumbnailUploadContainer>
            <DefaultButton type="button" onClick={selectThumbnail}>
              Selecionar Imagem de Capa
            </DefaultButton>

            {thumbnail && <ContentThumbnail src={thumbnail} />}
          </ThumbnailUploadContainer>
        </DefaultCreationFormGroup>

        <DefaultCreationFormButtonGroup>
          <DefaultButton type="button" className="danger" onClick={goToAscendedMasters}>
            Cancelar
          </DefaultButton>
          <DefaultButton
            onClick={(e) => (isEditing ? updateAscendedMaster(e) : createAscendedMaster(e))}
            className="success"
          >
            Salvar
          </DefaultButton>
        </DefaultCreationFormButtonGroup>
      </DefaultCreationForm>
    </CreateAndEditCategoryContainer>
  );
};

export default CreateAndEditAscendedMaster;
