import { EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import React, { useState, useEffect, useCallback } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import Select, { OptionsType } from 'react-select';
import Swal from 'sweetalert2';
import { AiOutlineDown, AiOutlineEdit, AiOutlineUp } from 'react-icons/ai';
import CurrencyInput from 'react-currency-input-field';

import BreadCrumb from '../../components/BreadCrumb';
import CutImage from '../../components/CutImage';
import DefaultButton from '../../components/DefaultButton';
import DefaultCreationForm, {
  DefaultCreationFormButtonGroup,
  DefaultCreationFormGroup,
} from '../../components/DefaultCreationForm';
import DefaultInput from '../../components/DefaultInput';
import { DefaultPageTitle } from '../../components/DefaultPageTitle';
import { hideModal, showModal } from '../../helpers/modal';
import { DescriptionTextarea } from './Editor';
import { ReactComponent as DetachButton } from '../../assets/detach.svg';
import Content from '../../models/content';
import {
  updateContent as updateRetreatService,
  deleteContent,
  createContent as createRetreatService,
  getContent as getContentService,
} from '../../services/contents';
import { getAllAuthors } from '../../services/authors';
import { getAllTags } from '../../services/tags';
import { getAllCategories } from '../../services/categories';
import CreateAndEditModule from '../CreateAndEditModule';
import checkEmptyString from '../../helpers/check-empty-string';
import { uploadFile } from '../../services/files';
import { Category } from '../../models/category';
import { Author } from '../../models/author';
import { Tag } from '../../models/tag';

import {
  Container,
  Switch,
  ThumbnailUploadContainer,
  CourseThumbnail,
  SelectContainer,
  LessonSelectionContainer,
  SortLessonsContainer,
  SwitchersContainer,
  ContentUploadOrSelectContainer,
  ButtonGroupInline,
} from './styles';
import ReactDatePicker from 'react-datepicker';
import SelectSambaVideosContent from '../../components/SelectSambaVideosContent';
import MediaFromResponse from '../../models/from-api-response/media';
import SelectSubtitles from '../../components/SelectSubtitles';
import { getMedia } from '../../services/medias';
import { listPlans } from '../../services/plans';

interface SelectProps {
  label: string;
  value: string;
}

interface CreateAndEditRetreatParams {
  retreatId: string;
}

const CreateAndEditRetreat: React.FC = () => {
  const { retreatId: courseId } = useParams<CreateAndEditRetreatParams>();

  const [content, setContent] = useState<Content>({} as Content);
  const [descriptionState, setDescriptionState] = useState(EditorState.createEmpty());
  const [descriptionText, setDescriptionText] = useState('');
  const [thumbnail, setThumbnail] = useState('');
  const [thumbnailWebFileToUpload, setThumbnailWebFileToUpload] = useState<File>();
  const [bannerFileToUpload, setBannerFileToUpload] = useState<File>();
  const [banner, setBanner] = useState('');
  const [isHighlighted, setIsHighlighted] = useState(false);
  const [lockModules, setLockModules] = useState(false);
  const [isRecommended, setIsRecommended] = useState(false);
  const [name, setName] = useState('');
  const [price, setPrice] = useState('');
  const [points, setPoints] = useState<number | undefined>(undefined);
  const [publishDate, setPublishDate] = useState(new Date());
  const [maxInstallments, setMaxInstallments] = useState<number | undefined>(undefined);

  const [tag, setTag] = useState({ value: '', label: '' });
  const [author, setAuthor] = useState({ value: '', label: '' });
  const [category, setCategory] = useState({ value: '', label: '' });
  const [plans, setPlans] = useState<OptionsType<SelectProps>>([]);

  const [tagOptions, setTagOptions] = useState<OptionsType<SelectProps>>([]);
  const [authorOptions, setAuthorOptions] = useState<OptionsType<SelectProps>>([]);
  const [categoriesOptions, setCategoriesOptions] = useState<OptionsType<SelectProps>>([]);
  const [plansOptions, setPlansOptions] = useState<OptionsType<SelectProps>>([]);

  const [selectedModules, setSelectedModules] = useState<Content[]>([]);
  const [modulesToRemove, setModulesToRemove] = useState<Content[]>([]);
  const [addingOrEditingModuleModalId, setAddingOrEditingModuleModalId] = useState('');

  const [reference, setReference] = useState('');
  const [hasSubtitle, setHasSubtitle] = useState(false);
  const [onlySubscribers, setOnlySubscribers] = useState(false);
  const [freeForSubscribers, setFreeForSubscribers] = useState(false);
  const [showInputValue, setShowInputValue] = useState(true);

  const history = useHistory();

  const selectThumbnail = () => {
    showModal('Selecionar Imagem de Capa web', <CutImage aspectX={22} aspectY={13} onCutImage={onCutImage} />);
  };

  const onCutImage = (file: File) => {
    if (file) {
      setThumbnailWebFileToUpload(file);

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => setThumbnail(`${reader.result}`);

      hideModal();
    }
  };

  const reorderSelectedCourses = (lesson: Content, up: boolean) => {
    if (selectedModules) {
      const localSelectedCourses = [...selectedModules];
      const indexOfLesson = localSelectedCourses.indexOf(lesson);
      if (indexOfLesson > -1 && indexOfLesson < localSelectedCourses.length) {
        localSelectedCourses.splice(indexOfLesson, 1);
        if (up) {
          localSelectedCourses.splice(indexOfLesson - 1, 0, lesson);
        } else {
          localSelectedCourses.splice(indexOfLesson + 1, 0, lesson);
        }
      }

      setSelectedModules(localSelectedCourses);
    }
  };

  const editModule = (moduleId: string) => {
    const localEditingSeasonModalId = showModal(
      'Editar Módulo',
      <CreateAndEditModule
        plansAssociated={plans.map((plan) => plan.value)}
        moduleId={moduleId}
        onCreateOrUpdate={includeCreatedModule}
        courseId={content?.content_id}
      />
    );
    setAddingOrEditingModuleModalId(localEditingSeasonModalId);
  };

  const removeSelectedCourse = async (moduleToBeRemoved: any) => {
    Swal.fire({
      icon: 'question',
      text: 'Tem certeza que deseja desvincular esse módulo da vivência?',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
    }).then(async (result) => {
      if (result.isConfirmed) {
        const modules = [...selectedModules];
        const filteredList = modules.filter((module) => module.content_id !== moduleToBeRemoved.content_id);
        const reorderedList = filteredList.map((course, index) => ({
          ...course,
          position: index + 1,
        }));
        setSelectedModules(reorderedList);

        setModulesToRemove((state) => [...state, moduleToBeRemoved]);
      }
    });
  };

  const includeCreatedModule = (module: Content) => {
    const localSelectedCourses = selectedModules;
    if (!localSelectedCourses.some((selectedModule) => module.content_id === selectedModule.content_id)) {
      setSelectedModules([...localSelectedCourses, module]);
    } else {
      const foundCourse = localSelectedCourses.find(
        (selectedModule) => selectedModule.content_id === module.content_id
      );
      if (foundCourse) {
        const indexOfCourse = localSelectedCourses.indexOf(foundCourse);
        if (indexOfCourse > -1) {
          localSelectedCourses.splice(indexOfCourse, 1, module);
          setSelectedModules([...localSelectedCourses]);
        }
      }
    }
  };

  const addModule = () => {
    if (addingOrEditingModuleModalId) {
      hideModal(addingOrEditingModuleModalId);
    }

    const localAddingSeasonModalId = showModal(
      'Adicionar Módulo',
      <CreateAndEditModule
        courseId={content?.content_id}
        plansAssociated={plans.map((plan) => plan.value)}
        onCreateOrUpdate={includeCreatedModule}
      />
    );
    setAddingOrEditingModuleModalId(localAddingSeasonModalId);
  };

  const goToRetreats = () => {
    history.push('/retreats');
  };

  const onCutBanner = (file: File) => {
    if (file) {
      setBannerFileToUpload(file);

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => setBanner(`${reader.result}`);

      hideModal();
    }
  };

  const selectBanner = () => {
    showModal('Selecionar Imagem do Destaque', <CutImage aspectX={4} aspectY={1} onCutImage={onCutBanner} />);
  };

  const createRetreat = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      if (!price) {
        const result = await Swal.fire({
          icon: 'warning',
          title: 'O valor esta como R$0,00',
          text: 'Deseja realmente criar uma vivência gratuita?',
          showCancelButton: true,
          cancelButtonText: 'Corrigir valor',
          allowOutsideClick: false,
          cancelButtonColor: '#5075CD',
          confirmButtonColor: '#FCC201',
          confirmButtonText: 'Sim',
        });

        if (result.isDenied || result.dismiss) {
          return;
        }
      }

      if (checkEmptyString(name)) {
        throw new Error('Informe um nome válido para a vivência');
      }

      if (!selectedModules.length) {
        throw new Error('Selecione uma ou mais módulos antes.');
      }

      if (!tag.value) {
        throw new Error('Informe as tags desta vivência.');
      }

      if (!author.value) {
        throw new Error('Informe o autor desta vivência.');
      }

      if (!category.value) {
        throw new Error('Informe a categoria desta vivência.');
      }

      if (price && !maxInstallments) {
        throw new Error('Informe um número máximo de parcelas');
      }

      if ((maxInstallments && maxInstallments > 12) || (maxInstallments && maxInstallments <= 0)) {
        throw new Error('Informe um número de 1 a 12');
      }

      let localBanner = '';

      if (!bannerFileToUpload) {
        throw new Error('Selecione uma imagem para destaque');
      }

      if (!reference) {
        throw new Error('Informe um video introdutório.');
      }

      const formData = new FormData();
      formData.append('file', bannerFileToUpload);
      const { reference: localBannerReference } = await uploadFile(formData);
      localBanner = localBannerReference;

      const data: Content = {
        name,
        description: draftToHtml(convertToRaw(descriptionState.getCurrentContent())),
        children: selectedModules.map((module, index) => ({
          child_id: module.content_id,
          position: index + 1,
        })) as unknown as Content[],
        authors: [
          {
            author_id: author.value,
          },
        ] as unknown as Author[],
        categories: [
          {
            category_id: category.value,
          },
        ] as unknown as Category[],
        tags: [{ tag_id: tag.value }] as unknown as Tag[],
        is_highlight: isHighlighted,
        is_recommended: isRecommended,
        flag: 'retreat',
        type: 'retiro',
        is_active: false,
        info: {
          price: Number(price.replaceAll(',', '.')),
          publish_date: publishDate,
          is_free: price === '0' || price === '' ? true : false,
          to_sell: true,
          gamification_points: points,
          free_for_subscribers: freeForSubscribers,
          only_subscribers: onlySubscribers,
          max_installments: maxInstallments,
          lockModules,
        },
        reference,
        associated_plans: plans.map((item) => item.value),
      } as unknown as Content;

      if (localBanner) data.images = { ...data.images, banner_url: localBanner };
      if (price) data.info = { ...data.info, price: Number(price.replaceAll(',', '.')) };

      let localThumbnail = '';

      if (!thumbnailWebFileToUpload) {
        throw new Error('Selecione uma imagem capa web');
      }

      const formDataThumbnail = new FormData();
      formDataThumbnail.append('file', thumbnailWebFileToUpload);
      const { reference: referenceThumbnail } = await uploadFile(formDataThumbnail);
      localThumbnail = referenceThumbnail;

      if (localThumbnail) data.images = { ...data.images, thumbnail_url: localThumbnail };

      await createRetreatService(data);

      if (modulesToRemove.length) {
        for (let module of modulesToRemove) {
          try {
            await deleteContent(module.content_id!);
          } catch (error) {
            Swal.fire({
              title: 'Erro!',
              text: 'Ocorreu um erro ao deletar os módulos',
              icon: 'error',
            });
          }
        }
      }
      goToRetreats();
      Swal.fire({
        title: 'Sucesso!',
        text: 'Vivência criada com sucesso!',
        icon: 'success',
      });
    } catch (error: any) {
      Swal.fire({
        title: 'Erro',
        text: `Houve um erro ao criar a vivência. ${error.message}`,
        icon: 'error',
      });
    }
  };

  const updateRetreat = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      if (!price) {
        const result = await Swal.fire({
          icon: 'warning',
          title: 'O valor esta como R$0,00',
          text: 'Deseja realmente tornar essa vivência gratuita?',
          showCancelButton: true,
          cancelButtonText: 'Corrigir valor',
          allowOutsideClick: false,
          cancelButtonColor: '#5075CD',
          confirmButtonColor: '#FCC201',
          confirmButtonText: 'Sim',
        });

        if (result.isDenied || result.dismiss) {
          return;
        }
      }

      if (checkEmptyString(name)) {
        throw new Error('Informe um nome válido para o vivência');
      }

      if (!selectedModules.length) {
        throw new Error('Selecione um ou mais módulos antes.');
      }

      if (!tag.value) {
        throw new Error('Informe a tags desta vivência.');
      }

      if (!author.value) {
        throw new Error('Informe o autor desta vivência.');
      }

      if (!category.value) {
        throw new Error('Informe a categoria desta vivência.');
      }

      if (!reference) {
        throw new Error('Informe um video introdutório.');
      }

      let localBanner = banner;

      if (!localBanner) {
        throw new Error('Selecione uma imagem destaque.');
      }

      if (bannerFileToUpload) {
        const formData = new FormData();
        formData.append('file', bannerFileToUpload);
        const { reference: localBannerReference } = await uploadFile(formData);
        localBanner = localBannerReference;
      }

      let localThumbnail = thumbnail;

      if (!localThumbnail) {
        throw new Error('Selecione uma imagem para destaque');
      }

      if (thumbnailWebFileToUpload) {
        const formDataThumbnail = new FormData();
        formDataThumbnail.append('file', thumbnailWebFileToUpload);
        const { reference: referenceThumbnail } = await uploadFile(formDataThumbnail);
        localThumbnail = referenceThumbnail;
      }

      if (price && !maxInstallments) {
        throw new Error('Informe um número máximo de parcelas');
      }

      if ((maxInstallments && maxInstallments > 12) || (maxInstallments && maxInstallments <= 0)) {
        throw new Error('Informe um número de 1 a 12');
      }
      const data: Content = {
        name,
        description: draftToHtml(convertToRaw(descriptionState.getCurrentContent())),
        children: selectedModules.map((module, index) => ({
          child_id: module.content_id,
          position: index + 1,
        })) as unknown as Content[],
        authors: [{ author_id: author.value }] as unknown as Author[],
        categories: [{ category_id: category.value }] as unknown as Category[],
        tags: [{ tag_id: tag.value }] as unknown as Tag[],
        is_highlight: isHighlighted,
        is_recommended: isRecommended,
        info: {
          ...content.info,
          publish_date: publishDate,
          gamification_points: points,
          only_subscribers: onlySubscribers,
          free_for_subscribers: freeForSubscribers,
          is_free: price === '0' || price === '' ? true : false,
          max_installments: maxInstallments,
          price: Number(price.replaceAll(',', '.')),
          lockModules,
        },
        reference,
        images: {
          banner_url: localBanner,
          thumbnail_url: localThumbnail,
        },
        associated_plans: plans.map((item) => item.value),
      } as unknown as Content;

      await updateRetreatService(courseId, data);

      if (modulesToRemove.length) {
        for (let module of modulesToRemove) {
          try {
            await deleteContent(module.content_id!);
          } catch (error) {
            Swal.fire({
              title: 'Erro!',
              text: 'Ocorreu um erro ao deletar os módulos',
              icon: 'error',
            });
          }
        }
      }

      goToRetreats();
      Swal.fire({
        title: 'Sucesso!',
        text: 'Vivência editada com sucesso!',
        icon: 'success',
      });
    } catch (error: any) {
      Swal.fire({
        title: 'Erro',
        text: 'Houve um erro ao editar a vivência. ' + error.message,
        icon: 'error',
      });
    }
  };

  const getContent = useCallback(async () => {
    if (courseId) {
      const contentToEdit: Content = await getContentService(courseId);

      const recoveredPlans = await listPlans();
      const options = recoveredPlans.map((item) => ({ label: item.title, value: item.plan_id }));
      setPlansOptions(options);

      if (contentToEdit && Object.keys(contentToEdit).length) {
        setName(contentToEdit.name);
        setDescriptionText(contentToEdit.description || '');
        setThumbnail(contentToEdit.images.thumbnail_url as string);
        setBanner(contentToEdit.images.banner_url as string);
        setIsHighlighted(contentToEdit.is_highlight);
        setIsRecommended(contentToEdit.is_recommended);
        setPublishDate(new Date(contentToEdit.info.publish_date as Date));
        setContent(contentToEdit);
        setPrice(String(contentToEdit?.info?.price) as string);
        setPoints(contentToEdit?.info?.gamification_points as number);
        setOnlySubscribers(contentToEdit?.info?.only_subscribers as boolean);
        setFreeForSubscribers(contentToEdit?.info?.free_for_subscribers as boolean);
        setMaxInstallments((contentToEdit?.info?.max_installments as number) || 0);
        setLockModules(contentToEdit?.info?.lockModules as boolean);

        if (contentToEdit?.associated_plans) {
          const matchPlans = contentToEdit?.associated_plans.map((associated) => {
            return options.find((plan) => plan.value === associated)!;
          });

          setPlans(matchPlans);
        }

        const currentMedia = contentToEdit.reference
          ? await getMedia(contentToEdit.reference.split('/')[contentToEdit.reference.split('/').length - 1])
          : undefined;

        if (contentToEdit.reference) {
          setReference(contentToEdit.reference);
        }
        setHasSubtitle((currentMedia?.captions || []).length > 0);

        if (contentToEdit.authors && contentToEdit.authors.length) {
          const selectedAuthors = contentToEdit.authors.map((author: Author) => ({
            label: author.name,
            value: author.author_id,
          }));
          setAuthor(selectedAuthors[0]);
        }

        if (contentToEdit.categories && contentToEdit.categories.length) {
          const selectedCategories = contentToEdit.categories.map((category: Category) => ({
            label: category.title,
            value: category.category_id,
          }));
          setCategory(selectedCategories[0]);
        }

        if (contentToEdit.tags && contentToEdit.tags.length) {
          const selectedTags = contentToEdit.tags.map((tag: Tag) => ({
            label: tag.title,
            value: tag.tag_id,
          }));
          setTag(selectedTags[0]);
        }

        if (contentToEdit.children && contentToEdit.children.length) {
          setSelectedModules(contentToEdit.children);
        }
      }
    }
  }, [courseId]);

  const onSelectContent = (sambaVideosContent: MediaFromResponse) => {
    if (sambaVideosContent) {
      if (sambaVideosContent.files && sambaVideosContent.files.length) {
        const firstFile = sambaVideosContent.files[0];

        if (firstFile) {
          const playerKey = process.env.REACT_APP_PLAYER_KEY;
          const referenceUrl = `${process.env.REACT_APP_PLAYER_INITIAL_URL}/${playerKey}/${sambaVideosContent.id}`;

          setReference(referenceUrl || '');
        }
      }
      setHasSubtitle((sambaVideosContent.captions || []).length > 0);
    }
  };

  const selectContent = () => {
    showModal('Selecionar Conteúdo', <SelectSambaVideosContent onSelectContent={onSelectContent} />);
  };

  const selectSubtitles = () => {
    showModal('Selecionar Legenda', <SelectSubtitles reference={reference} />);
  };

  useEffect(() => {
    (async () => {
      try {
        const authors = await getAllAuthors();
        setAuthorOptions(authors.map((author) => ({ label: author.name, value: author.author_id })));
      } catch {
        Swal.fire({
          title: 'Erro!',
          text: 'Ocorreu um erro ao recuperar os autores',
          icon: 'error',
        });
      }
    })();

    (async () => {
      try {
        const tags = await getAllTags({ is_active: true });
        setTagOptions(tags.map((tag) => ({ label: tag.title, value: tag.tag_id })));
      } catch {
        Swal.fire({
          title: 'Erro!',
          text: 'Ocorreu um erro ao recuperar as tags',
          icon: 'error',
        });
      }
    })();

    (async () => {
      try {
        const categories = await getAllCategories();
        setCategoriesOptions(categories.map((category) => ({ label: category.title, value: category.category_id })));
      } catch {
        Swal.fire({
          title: 'Erro!',
          text: 'Ocorreu um erro ao recuperar as categorias',
          icon: 'error',
        });
      }
    })();

    getContent();
  }, [getContent]);

  useEffect(() => {
    (async () => {
      if (courseId) return;

      const recoveredPlans = await listPlans();
      const options = recoveredPlans.map((item) => ({ label: item.title, value: item.plan_id }));
      setPlansOptions(options);
    })();
  }, [courseId]);

  useEffect(() => {
    if (freeForSubscribers && onlySubscribers) {
      setShowInputValue(false);
      setPrice('');
    }

    if (!freeForSubscribers && onlySubscribers) {
      setShowInputValue(true);
    }
  }, [freeForSubscribers, onlySubscribers]);

  return (
    <Container>
      <BreadCrumb
        crumbs={[
          <Link to="dashboard">Dashboard</Link>,
          <Link to="courses">Vivências</Link>,
          <span>{!!courseId ? 'Editar' : 'Criar'} Vivência</span>,
        ]}
      />
      <DefaultPageTitle>{!!courseId ? 'Editar' : 'Criar'} Vivência</DefaultPageTitle>

      <SwitchersContainer>
        <DefaultCreationFormGroup>
          <label>Em destaque</label>
          <Switch>
            <input type="checkbox" checked={isHighlighted} onChange={(e) => setIsHighlighted(!isHighlighted)} />
            <span className="slider round"></span>
          </Switch>
        </DefaultCreationFormGroup>

        <SwitchersContainer>
          <DefaultCreationFormGroup>
            <label>Módulos travados</label>
            <Switch>
              <input type="checkbox" checked={lockModules} onChange={(e) => setLockModules((state) => !state)} />
              <span className="slider round"></span>
            </Switch>
          </DefaultCreationFormGroup>
        </SwitchersContainer>

        <DefaultCreationFormGroup>
          <label>Recomendado</label>
          <Switch>
            <input type="checkbox" checked={isRecommended} onChange={(e) => setIsRecommended(!isRecommended)} />
            <span className="slider round"></span>
          </Switch>
        </DefaultCreationFormGroup>

        <DefaultCreationFormGroup>
          <label>Exclusividade para assinantes</label>
          <Switch>
            <input type="checkbox" checked={onlySubscribers} onChange={(e) => setOnlySubscribers(!onlySubscribers)} />
            <span className="slider round"></span>
          </Switch>
        </DefaultCreationFormGroup>
      </SwitchersContainer>

      <SwitchersContainer>
        <DefaultCreationFormGroup>
          <label>Gratuito para assinantes</label>
          <Switch>
            <input
              type="checkbox"
              checked={freeForSubscribers}
              onChange={() => setFreeForSubscribers(!freeForSubscribers)}
            />
            <span className="slider round"></span>
          </Switch>
        </DefaultCreationFormGroup>
      </SwitchersContainer>

      <DefaultCreationForm>
        <DefaultCreationFormGroup>
          <label className="required" htmlFor="name">
            Data de publicação
          </label>
          <ReactDatePicker
            id="publishDate"
            selected={new Date(publishDate)}
            showTimeSelect
            dateFormat="dd/MM/yyyy hh"
            timeIntervals={60}
            onChange={(date: Date) => setPublishDate(date)}
            onChangeRaw={(event) => event.preventDefault()}
          />
        </DefaultCreationFormGroup>

        {showInputValue && (
          <DefaultCreationFormGroup>
            <label className="required" htmlFor="price">
              Valor
            </label>
            <CurrencyInput
              className="currency-input"
              id="price"
              name="price"
              placeholder="Digite um valor"
              prefix="R$ "
              decimalsLimit={2}
              value={price}
              onValueChange={(value) => setPrice(value!)}
            />
          </DefaultCreationFormGroup>
        )}

        <DefaultCreationFormGroup>
          <label className="required" htmlFor="maxInstallments">
            Parcelamento máximo
          </label>
          <DefaultInput
            id="maxInstallments"
            placeholder="Digite a quantidade máxima de parcelamento destAe plano"
            value={maxInstallments}
            type="number"
            onChange={(e) => setMaxInstallments(Number(e.target.value))}
            required
          />
        </DefaultCreationFormGroup>

        <DefaultCreationFormGroup>
          <label htmlFor="points">Pontos da gamificação</label>
          <DefaultInput
            value={points}
            placeholder="Escolha uma pontuação que essa vivência irá conceder ao usuário"
            type="number"
            onChange={(e) => setPoints(Number(e.target.value))}
            id="points"
            required
          />
        </DefaultCreationFormGroup>

        <DefaultCreationFormGroup>
          <label className="required" htmlFor="name">
            Nome
          </label>
          <DefaultInput value={name} onChange={(e) => setName(e.target.value)} id="name" required maxLength={250} />
        </DefaultCreationFormGroup>

        <DefaultCreationFormGroup>
          <label className="required" htmlFor="reference">
            Descrição
          </label>
          <DescriptionTextarea
            description={descriptionText}
            editorState={descriptionState}
            setEditorState={setDescriptionState}
          />
        </DefaultCreationFormGroup>

        <SelectContainer>
          <label className="required" htmlFor="tag">
            Tag
          </label>
          <Select
            id="tag"
            options={tagOptions}
            value={tag}
            defaultValue={tag}
            onChange={(option) => setTag(option!)}
            placeholder="Selecione uma tag"
          />
        </SelectContainer>

        <SelectContainer>
          <label className="required" htmlFor="author">
            Autor(a)
          </label>
          <Select
            id="author"
            options={authorOptions}
            value={author}
            onChange={(option) => setAuthor(option!)}
            placeholder="Selecione um autor"
            noOptionsMessage={() => 'Sem autores disponíveis'}
          />
        </SelectContainer>

        <SelectContainer>
          <label className="required" htmlFor="categories">
            Categoria
          </label>
          <Select
            id="categories"
            options={categoriesOptions}
            value={category}
            onChange={(option) => setCategory(option!)}
            placeholder="Selecione uma ou mais categoria"
            noOptionsMessage={() => 'Sem categorias disponíveis'}
          />
        </SelectContainer>

        <DefaultCreationFormGroup>
          <label className="required" htmlFor="reference">
            Vídeo Introdutório
          </label>

          <ContentUploadOrSelectContainer>
            <ButtonGroupInline>
              <DefaultButton type="button" onClick={selectContent}>
                Selecionar vídeo
              </DefaultButton>

              {reference && !hasSubtitle && (
                <DefaultButton type="button" onClick={selectSubtitles}>
                  Selecionar Legenda
                </DefaultButton>
              )}
            </ButtonGroupInline>

            {reference ? (
              <iframe title="referenced-video" allowFullScreen src={reference} frameBorder={0}></iframe>
            ) : null}
          </ContentUploadOrSelectContainer>
        </DefaultCreationFormGroup>

        <DefaultCreationFormGroup>
          <label className="required" htmlFor="reference">
            Capa web
          </label>

          <ThumbnailUploadContainer>
            <DefaultButton type="button" onClick={selectThumbnail}>
              Selecionar Imagem de Capa
            </DefaultButton>

            {thumbnail && <CourseThumbnail src={thumbnail} />}
          </ThumbnailUploadContainer>
        </DefaultCreationFormGroup>

        <DefaultCreationFormGroup>
          <label className="required" htmlFor="reference">
            Imagem Destaque
          </label>

          <ThumbnailUploadContainer>
            <div>
              <DefaultButton type="button" onClick={selectBanner}>
                Selecionar imagem
              </DefaultButton>

              {banner ? (
                <DefaultButton
                  type="button"
                  onClick={() => {
                    setBanner('');
                    setBannerFileToUpload(undefined);
                  }}
                >
                  Excluir imagem
                </DefaultButton>
              ) : null}
            </div>
            {banner && <CourseThumbnail src={banner} />}
          </ThumbnailUploadContainer>
        </DefaultCreationFormGroup>

        <SelectContainer>
          <label htmlFor="plans">Curso exclusivo dos planos</label>
          <Select
            id="plans"
            options={plansOptions}
            value={plans}
            onChange={(option) => setPlans(option)}
            placeholder="Selecione um ou mais planos para este curso participar"
            noOptionsMessage={() => 'Sem planos disponíveis'}
            isMulti
          />
        </SelectContainer>

        <DefaultCreationFormGroup>
          <label className="required" htmlFor="reference">
            Módulos
          </label>

          <LessonSelectionContainer>
            {selectedModules.length ? (
              <SortLessonsContainer>
                {selectedModules.map((course, index) => (
                  <div key={course.content_id} className="selected-lessons">
                    <div className="buttons">
                      {index > 0 ? (
                        <>
                          {index + 1}
                          <DefaultButton
                            type="button"
                            title="Subir Aula"
                            className="small white up"
                            onClick={() => reorderSelectedCourses(course, true)}
                          >
                            <AiOutlineUp />
                          </DefaultButton>
                        </>
                      ) : null}
                      {index < selectedModules.length - 1 ? (
                        <>
                          {index === 0 ? index + 1 : null}
                          <DefaultButton
                            type="button"
                            title="Descer Aula"
                            className="small white down"
                            onClick={() => reorderSelectedCourses(course, false)}
                          >
                            <AiOutlineDown />
                          </DefaultButton>
                        </>
                      ) : null}
                    </div>
                    <div className="lesson-title">{course.name}</div>
                    <div className="buttons">
                      <DefaultButton
                        className="small"
                        type="button"
                        onClick={() => editModule(course.content_id!)}
                        title="Editar Módulo"
                      >
                        <AiOutlineEdit size={16} />
                      </DefaultButton>
                      <DetachButton onClick={() => removeSelectedCourse(course)} />
                    </div>
                  </div>
                ))}
              </SortLessonsContainer>
            ) : (
              <></>
            )}
            <DefaultButton type="button" onClick={addModule}>
              Adicionar Módulo
            </DefaultButton>
          </LessonSelectionContainer>
        </DefaultCreationFormGroup>

        <DefaultCreationFormButtonGroup>
          <DefaultButton type="button" className="danger" onClick={goToRetreats}>
            Cancelar
          </DefaultButton>
          <DefaultButton onClick={(e) => (!!courseId ? updateRetreat(e) : createRetreat(e))} className="success">
            Salvar
          </DefaultButton>
        </DefaultCreationFormButtonGroup>
      </DefaultCreationForm>
    </Container>
  );
};

export default CreateAndEditRetreat;
