import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import 'react-markdown-editor-lite/lib/index.css';

import BreadCrumb from '../../components/BreadCrumb';
import DefaultCreationForm, {
  DefaultCreationFormButtonGroup,
  DefaultCreationFormGroup,
} from '../../components/DefaultCreationForm';
import { DefaultPageTitle } from '../../components/DefaultPageTitle';
import DefaultButton from '../../components/DefaultButton';
import Swal from 'sweetalert2';
import getErrorMessage from '../../helpers/get-error-message';
import { hideModal, showModal } from '../../helpers/modal';
import SelectSambaVideosContent from '../../components/SelectSambaVideosContent';
import MediaFromResponse from '../../models/from-api-response/media';
import SelectSubtitles from '../../components/SelectSubtitles';
import {
  getPageContent as getPageContentService,
  InfoProps,
  updateLandingPage as updateLandingPageService,
} from '../../services/landingPage';

import {
  TermsContainer,
  ContentUploadOrSelectContainer,
  ButtonGroupInline,
  ThumbnailUploadContainer,
  ContentThumbnail,
} from './styles';
import CutImage from '../../components/CutImage';
import { uploadFile } from '../../services/files';
import { DefaultTextArea } from '../../components/DefaultTextArea';
import { DescriptionTextarea, getCleanHtml } from './Editor';
import { EditorState } from 'draft-js';

const LandingPage: React.FC = () => {
  const [pageId, setPageId] = useState('');
  const [banner, setBanner] = useState('');
  const [bannerFileToUpload, setBannerFileToUpload] = useState<File>();
  const [reference, setReference] = useState('');
  const [hasSubtitle, setHasSubtitle] = useState(false);
  const [presentationText, setPresentationText] = useState('');
  const [editorPresentationText, setEditorPresentationText] = useState(EditorState.createEmpty());

  const cleanPresentationText = getCleanHtml(editorPresentationText);

  const getPageContent = async () => {
    try {
      const currentContent = await getPageContentService();

      setPageId(currentContent[0].home_id);
      setReference(currentContent[0].info.mediaUrl);
      setBanner(currentContent[0].info.bannerUrl);
      setPresentationText(currentContent[0].text);
    } catch {
      Swal.fire({
        title: 'Erro',
        text: 'Ocorreu um erro ao recuperar os dados da página!',
        icon: 'error',
      });
    }
  };

  const updatePageDescription = async (event: React.FormEvent) => {
    if (event.preventDefault) {
      event.preventDefault();
    }

    try {
      const info: InfoProps = { mediaUrl: reference, bannerUrl: '' };

      if (bannerFileToUpload) {
        const formData = new FormData();
        formData.append('file', bannerFileToUpload);
        const { reference } = await uploadFile(formData);
        info.bannerUrl = reference;
      }

      await updateLandingPageService(pageId, info, cleanPresentationText);

      Swal.fire({
        title: 'Sucesso!',
        text: 'Conteúdo editado com sucesso.',
        icon: 'success',
      });
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      Swal.fire({ icon: 'error', text: 'Erro ao alterar conteúdo da página. ' + errorMessage });
    }
  };

  const onSelectContent = (sambaVideosContent: MediaFromResponse) => {
    if (sambaVideosContent) {
      if (sambaVideosContent.files && sambaVideosContent.files.length) {
        const firstFile = sambaVideosContent.files[0];

        if (firstFile) {
          const playerKey = process.env.REACT_APP_PLAYER_KEY;
          const referenceUrl = `${process.env.REACT_APP_PLAYER_INITIAL_URL}/${playerKey}/${sambaVideosContent.id}`;

          setReference(referenceUrl);
        }
      }
      setHasSubtitle(sambaVideosContent.captions.length > 0);
    }

    hideModal();
  };

  const selectContent = () => {
    showModal(`Selecionar Vídeo de apresentação`, <SelectSambaVideosContent onSelectContent={onSelectContent} />);
  };

  const selectSubtitles = () => {
    showModal('Selecionar Legenda', <SelectSubtitles reference={reference} />);
  };

  const selectThumbnail = () => {
    showModal('Selecionar imagem de banner', <CutImage aspectX={247} aspectY={98} onCutImage={onCutThumbnail} />);
  };

  const onCutThumbnail = (file: File) => {
    if (file) {
      setBannerFileToUpload(file);

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => setBanner(`${reader.result}`);

      hideModal();
    }
  };

  useEffect(() => {
    getPageContent();
  }, []);

  return (
    <TermsContainer>
      <BreadCrumb crumbs={[<Link to="/profile">Dashboard</Link>, <span>Cms</span>, <span>Página Inicial</span>]} />

      <DefaultPageTitle>Página Inicial</DefaultPageTitle>

      <DefaultCreationForm onSubmit={updatePageDescription}>
        <DefaultCreationFormGroup>
          <label className="required" htmlFor="reference">
            Texto de apresentação
          </label>
          <DescriptionTextarea
            description={presentationText}
            editorState={editorPresentationText}
            setEditorState={setEditorPresentationText}
          />
        </DefaultCreationFormGroup>

        <DefaultCreationFormGroup>
          <label className="required" htmlFor="reference">
            Vídeo de apresentação
          </label>

          <ContentUploadOrSelectContainer>
            <ButtonGroupInline>
              <DefaultButton type="button" onClick={selectContent}>
                Selecionar vídeo
              </DefaultButton>
              {reference && !hasSubtitle && (
                <DefaultButton type="button" onClick={selectSubtitles}>
                  Selecionar Legenda
                </DefaultButton>
              )}
            </ButtonGroupInline>
            {reference && reference.startsWith(process.env.REACT_APP_PLAYER_INITIAL_URL || '') ? (
              <iframe title="referenced-video" allowFullScreen src={reference} frameBorder={0}></iframe>
            ) : (
              <></>
            )}
          </ContentUploadOrSelectContainer>
        </DefaultCreationFormGroup>

        <DefaultCreationFormGroup>
          <label className="required" htmlFor="reference">
            Thumbnail
          </label>

          <ThumbnailUploadContainer>
            <DefaultButton type="button" onClick={selectThumbnail}>
              Selecionar Imagem de Banner
            </DefaultButton>

            {banner && <ContentThumbnail src={banner} />}
          </ThumbnailUploadContainer>
        </DefaultCreationFormGroup>

        <DefaultCreationFormButtonGroup>
          <DefaultButton className="success">Salvar</DefaultButton>
        </DefaultCreationFormButtonGroup>
      </DefaultCreationForm>
    </TermsContainer>
  );
};

export default LandingPage;
