import styled from 'styled-components';

export const Container = styled.div``;

export const SelectsContainer = styled.section`
  display: flex;
  justify-content: space-around;
  align-items: center;

  margin-bottom: 32px;

  width: 100%;
`;

export const SelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  width: 100%;

  label {
    min-width: 145px;
    margin-bottom: 10px;
    font-size: var(--font-small);
    color: var(--primary-color);
    font-weight: 700;
  }

  & > div {
    width: 90%;
  }
`;

export const ListContainer = styled.section`
  h1 {
    color: #594aad;
    margin-bottom: 32px;
  }
`;

export const DefaultCreationFormGroup = styled.div`
  width: 100%;

  > label {
    min-width: 145px;
    margin-bottom: 10px;
    font-size: var(--font-small);
    color: var(--primary-color);
    font-weight: 700;
  }

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  .currency-input {
    border-radius: 5px;
    border: solid 1px var(--default-dark-gray);
    outline: none;
    font-size: var(--font-small);
    width: 100%;
    height: 44px;
    padding: 0 10px;
    color: var(--default-gray);
  }
`;

export const NumberInput = styled.input`
  border-radius: 5px;
  border: solid 1px var(--default-dark-gray);
  outline: none;
  font-size: var(--font-small);
  width: 10%;
  height: 44px;
  padding: 0 10px;
  color: var(--default-gray);
  margin-bottom: 32px;

  ::-webkit-inner-spin-button {
    -webkit-appearance: none; 
    margin: 0; 
  }
  ::-webkit-outer-spin-button {
    -webkit-appearance: none; 
    margin: 0; 
  } 
`;
