import httpClient from '../http-client';
import { Category } from '../models/category';

interface GetAllCategories {
  title: string;
  description: string;
  is_active: boolean;
  limit: number;
  offset: number;
  order_by: 'title' | 'is_active';
  sort_by: string;
  relations: 'contents';
}

const getAllCategories = async (query?: GetAllCategories) => {
  return httpClient.get<Category[]>('categories', { params: query }).then((res) => res.data);
};

const getCategory = async (categoryId: string) => {
  const category = (await httpClient.get<any>(`categories/${categoryId}`)).data;

  return category;
};

const createCategory = async (newCategory: Partial<Omit<Category, 'category_id' | 'is_active'>>) => {
  return httpClient.post<Category>('categories', newCategory).then((res) => res.data);
};

const updateCategory = async (categoryId: string, newContentForCategory: Partial<Category>) => {
  await httpClient.put(`categories/${categoryId}`, newContentForCategory);
};

const activateOrInactivateCategory = async (categoryId: any, activate: boolean) => {
  return httpClient.patch(`categories/${categoryId}/active`, { is_active: activate });
};

const deleteCategory = async (categoryId: string) => {
  return httpClient.delete(`categories/${categoryId}`);
};

export { getAllCategories, updateCategory, createCategory, getCategory, activateOrInactivateCategory, deleteCategory };
