import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

import BreadCrumb from '../../components/BreadCrumb';
import { DefaultPageTitle } from '../../components/DefaultPageTitle';

import { CouponPageContainer, StyledButton } from './styles';
import DefaultButton from '../../components/DefaultButton';
import { CreateButtonDefaultContainer } from '../../components/CreateButtonDefaultContainer';
import DefaultTable from '../../components/DefaultTable';
import Pagination from '../../components/Pagination';
import { CouponTypes, ICouponDTO } from '../../models/coupon';
import { deleteCoupon, findCoupons, updateCouponStatus } from '../../services/coupons';
import { BiEdit, BiTrash } from 'react-icons/bi';
import Swal from 'sweetalert2';
import { SearchBox } from '../../components/Search';
import { convertToDateBR } from '../../utils/date';
import { AiOutlineCheck, AiOutlineClose } from 'react-icons/ai';

const Coupons: React.FC = () => {
  const history = useHistory();

  const [paginationState, setPaginationState] = useState({ page: 1, length: 0, showPage: true, limit: 12 });
  const [couponsByPage, setCouponsByPage] = useState<Array<ICouponDTO[]>>([[]]);

  const handleUpdatePagination = (values: Record<any, any>) => {
    setPaginationState((state) => ({
      ...state,
      ...values,
    }));
  };

  const handleFindCoupons = useCallback(
    async ({ search, reset }: { search?: string; reset?: boolean } = { reset: false }) => {
      const { limit, page } = paginationState;

      if (reset) {
        setCouponsByPage([[]]);
      }

      if (!couponsByPage[page - 1]?.length || reset) {
        const faqs = await findCoupons({ limit, offset: limit * (page - 1), search });
        const faqsByPageCp = [...couponsByPage];
        faqsByPageCp[page - 1] = faqs.data;

        setCouponsByPage(faqsByPageCp);

        handleUpdatePagination({
          length: Math.ceil(faqs.total / limit),
        });
      }
    },
    [couponsByPage, paginationState]
  );

  const handleDelete = useCallback(async (coupon_id: string) => {
    Swal.fire({
      title: '<strong>Confirmação</strong>',
      html: `Tem certeza que deseja remover este Cupom?`,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      focusConfirm: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await deleteCoupon(coupon_id);
          handleUpdatePagination({ page: 1 });
          await handleFindCoupons({ reset: true });

          Swal.fire({
            icon: 'success',
            title: 'Sucesso!',
            text: 'Cupom excluído com sucesso!',
          });
        } catch (error: any) {
          Swal.fire({
            icon: 'error',
            title: 'Erro',
            text: `Erro ao excluir Cupom. ${
              error?.response?.data?.message || error.message || 'Ocorreu um erro inesperado'
            }`,
          });
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleCouponStatus = useCallback(
    async (coupon_id: string, is_active: boolean) => {
      Swal.fire({
        title: '<strong>Confirmação</strong>',
        html: `Tem certeza que deseja ${is_active ? 'ativar' : 'inativar'} este cupom?`,
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        focusConfirm: false,
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await updateCouponStatus(coupon_id, is_active);
            handleUpdatePagination({ page: 1 });
            await handleFindCoupons({ reset: true });
            Swal.fire({
              icon: 'success',
              title: 'Sucesso!',
              text: `Cupom ${is_active ? 'ativado' : 'inativado'} com sucesso!`,
            });
          } catch (error: any) {
            Swal.fire({
              icon: 'error',
              title: 'Erro',
              text: `Erro ao ${is_active ? 'ativar' : 'inativar'} cupom. ${
                error?.response?.data?.message || error.message || 'Ocorreu um erro inesperado'
              }`,
            });
          }
        }
      });
    },
    [handleFindCoupons]
  );

  const couponsToShow = useMemo(() => {
    return couponsByPage[paginationState.page - 1]?.map((coupon, i) => ({
      code: coupon.code,
      coupon_type: coupon.coupon_type === CouponTypes.PERCENTAGE ? 'porcentagem' : 'valor',
      value: coupon[coupon.coupon_type],
      applications: coupon.applications?.length,
      quantity: coupon.quantity,
      start_at: convertToDateBR(coupon.start_at),
      finish_at: convertToDateBR(coupon.finish_at),
      usage: coupon.usage?.length,
      actions: (
        <>
          <StyledButton
            onClick={() => {
              history.push(`/coupons/${coupon.coupon_id}/edit`);
            }}
            className="small info"
            title="Editar cupom"
          >
            <BiEdit />
          </StyledButton>
          <StyledButton
            onClick={() => toggleCouponStatus(coupon.coupon_id, !coupon.is_active)}
            className="small warning"
            title={`${coupon.is_active ? 'Inativar' : 'Ativar'} cupom`}
          >
            {coupon.is_active ? (
              <>
                <AiOutlineClose />
              </>
            ) : (
              <>
                <AiOutlineCheck />
              </>
            )}
          </StyledButton>
          <StyledButton
            onClick={() => {
              handleDelete(coupon.coupon_id);
            }}
            className="small danger"
            title="Excluir cupom"
          >
            <BiTrash />
          </StyledButton>
        </>
      ),
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [couponsByPage, paginationState.page]);

  useEffect(() => {
    handleFindCoupons();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationState.page]);

  return (
    <CouponPageContainer>
      <BreadCrumb crumbs={[<Link to="/dashboard">Dashboard</Link>, <span>Cupons</span>]} />

      <DefaultPageTitle>Cupons</DefaultPageTitle>

      <CreateButtonDefaultContainer>
        <DefaultButton
          onClick={() => {
            history.push('/coupons/create');
          }}
        >
          Criar cupom
        </DefaultButton>
        <div className="right">
          <SearchBox
            placeholder="Pesquisar por código"
            handleSearch={handleFindCoupons}
            handleClear={() => {
              handleFindCoupons({ reset: true });
            }}
          />
        </div>
      </CreateButtonDefaultContainer>
      <DefaultTable
        headersConfig={[
          {
            headerLabel: <span>Código</span>,
            propName: 'code',
          },
          {
            headerLabel: <span>Tipo</span>,
            propName: 'coupon_type',
          },
          {
            headerLabel: <span>Valor</span>,
            propName: 'value',
          },
          {
            headerLabel: <span>quantidade</span>,
            propName: 'quantity',
          },
          {
            headerLabel: <span>Utilizados</span>,
            propName: 'usage',
          },
          {
            headerLabel: <span>data início</span>,
            propName: 'start_at',
          },
          {
            headerLabel: <span>data fim</span>,
            propName: 'finish_at',
          },
          {
            headerLabel: <span>Ações</span>,
            propName: 'actions',
          },
        ]}
        items={couponsToShow}
        emptyListMessage="Não foram encontrados cupons cadastrados!"
      />

      {paginationState.showPage ? (
        <Pagination
          totalPages={paginationState.length}
          setPage={(page) => setPaginationState((state) => ({ ...state, page }))}
        />
      ) : null}
    </CouponPageContainer>
  );
};

export default Coupons;
