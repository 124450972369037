import React from 'react';
import { BiEdit, BiPowerOff } from 'react-icons/bi';
import { useHistory } from 'react-router';
import Swal from 'sweetalert2';
import { TablePlan } from '../..';

import DefaultButton from '../../../../components/DefaultButton';
import DefaultTable from '../../../../components/DefaultTable';
import { inactivatePlan } from '../../../../services/plans';

import { Container, Count, ActionsContainer, StyledButton } from './styles';

interface Props {
  plans: TablePlan[];
  info: {
    planPeriod: string;
    tableTitle: string;
    type?: number;
  };
  updatePlans: (planId: string, condition: boolean) => void;
}

const PlanTable: React.FC<Props> = ({ plans, info, updatePlans }) => {
  const history = useHistory();

  const handleGoToCreatePlan = () => {
    history.push(`/create-plan/${info.planPeriod}`);
  };

  const handleGoToEditPlan = (planId: string) => {
    history.push(`/edit-plan/${planId}`);
  };

  const handleInactivatePlan = async (planId: string, condition: boolean) => {
    try {
      const textToActivate = 'Ao ativar este plano os usuários poderão voltar a assinar o mesmo.';

      const textToInactivate =
        'Ao inativar este plano os usuários já assinantes do mesmo continuarão a renovar a assinatura, apenas novos usuários não poderão assinar este plano.';

      await Swal.fire({
        icon: condition ? 'warning' : 'question',
        titleText: `Tem certeza que deseja ${condition ? 'inativar' : 'ativar'} esse plano?`,
        text: condition ? textToInactivate : textToActivate,
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
      }).then(async (result) => {
        if (!result.isConfirmed) return;

        await inactivatePlan(planId, !condition);
        updatePlans(planId, !condition);

        Swal.fire({
          title: 'Sucesso!',
          text: `Plano ${condition ? 'inativado' : 'ativado'}`,
          icon: 'success',
        });
      });
    } catch (error: any) {
      Swal.fire({
        title: 'Erro!',
        titleText: `Houve um problema ao ${condition ? 'inativar' : 'ativar'} este plano!`,
        text: error?.response?.data?.message || error.message || 'Ocorreu um erro inesperado',
        icon: 'error',
      });
    }
  };

  const dataToShow = (() => {
    return plans
      .map((plan) => ({
        title: plan.title,
        totalValue: plan.totalValue === '0,00' ? 'Sem desconto' : plan.totalValue,
        value: plan.value === '0,00' ? 'Gratuito' : plan.value,
        discount: `${plan.discount ?? 0}%`,
        status: plan.is_active ? 'Ativo' : 'Inativo',
        actions: (
          <ActionsContainer>
            <StyledButton onClick={() => handleGoToEditPlan(plan.id)} className="small info" title="Editar">
              Editar
              <BiEdit />
            </StyledButton>

            <StyledButton
              disabled={plan.type === 0 || (plans.filter((el) => el.is_active).length >= 3 && !plan.is_active)}
              onClick={() => handleInactivatePlan(plan.id, plan.is_active)}
              className="small danger"
              title="Inativar"
            >
              {plan.is_active ? (
                <span>
                  Inativar
                  <BiPowerOff />
                </span>
              ) : (
                <span>
                  Ativar
                  <BiPowerOff />
                </span>
              )}
            </StyledButton>
          </ActionsContainer>
        ),
      }))
      .sort((el) => (el.status === 'Ativo' ? -1 : 1));
  })();

  return (
    <Container>
      <h2>PLANOS {info.tableTitle.toUpperCase()}</h2>

      <article>
        <DefaultButton disabled={plans.filter((plan) => plan.is_active).length >= 3} onClick={handleGoToCreatePlan}>
          Criar plano {info.planPeriod.toLowerCase()}
        </DefaultButton>

        <Count count={plans.length}>{`${plans.filter((plan) => plan.is_active).length} / 3 planos ativos`}</Count>
      </article>

      <DefaultTable
        headersConfig={[
          {
            headerLabel: <span>Título</span>,
            propName: 'title',
          },
          {
            headerLabel: <span>Valor com desconto</span>,
            propName: 'totalValue',
          },
          {
            headerLabel: <span>Valor Final</span>,
            propName: 'value',
          },
          {
            headerLabel: <span>Desconto em compras</span>,
            propName: 'discount',
          },
          {
            headerLabel: <span>Status</span>,
            propName: 'status',
          },
          {
            headerLabel: <span>Ações</span>,
            propName: 'actions',
          },
        ]}
        items={dataToShow}
        emptyListMessage="Não foram encontrados planos cadastrados!"
      />
    </Container>
  );
};

export default PlanTable;
