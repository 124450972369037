import React, { useEffect, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import Swal from 'sweetalert2';

import DefaultButton from '../../../components/DefaultButton';
import DefaultCreationForm, {
  DefaultCreationFormButtonGroup,
  DefaultCreationFormGroup,
} from '../../../components/DefaultCreationForm';
import DefaultInput from '../../../components/DefaultInput';
import { DefaultPageTitle } from '../../../components/DefaultPageTitle';
import checkEmptyString from '../../../helpers/check-empty-string';
import { EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import {
  createLive as createLiveService,
  getLive as getLiveService,
  updateLive as updateLiveService,
} from '../../../services/lives';
import { LiveForCreate } from '../../../models/for-create/lives';
import { hideModal } from '../../../helpers/modal';
import Live from '../../../models/lives';

import { CreateAndEditContentContainer, InformationMessage } from './style';
import { DescriptionTextarea } from '../../CreateAndEditCourse/Editor';

interface CreateLiveForLessonProps {
  onCreate: (liveId: string, live: Live) => void;
  modalId?: string;
  liveId?: string;
  plansAssociated: string[];
  courseId: string;
}

const CreateLiveForLesson: React.FC<CreateLiveForLessonProps> = ({
  onCreate,
  modalId,
  liveId,
  plansAssociated,
  courseId,
}) => {
  const fiveMinutesDivisorFutureDate = (date: Date) => {
    const actualDate = date;
    const actualMinutes = actualDate.getMinutes() + 5;
    const actualHours = actualDate.getHours();

    let liveInitialMinutes = actualMinutes;
    let hourIncrement = 0;
    let dayIncrement = 0;
    while ((liveInitialMinutes || 5) % 5 !== 0) {
      if (liveInitialMinutes === 59) {
        liveInitialMinutes = 0;
        hourIncrement++;
        if (actualHours === 23) {
          dayIncrement++;
        }
      } else {
        liveInitialMinutes++;
      }
    }

    actualDate.setDate(actualDate.getDate() + dayIncrement);
    actualDate.setHours(actualDate.getHours() + hourIncrement);
    actualDate.setMinutes(liveInitialMinutes);

    return actualDate;
  };

  const [editingLive, setLiveEditing] = useState<Live | null>(null);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [startDate, setStartDate] = useState<any>(fiveMinutesDivisorFutureDate(new Date()));
  const [finishDate, setFinishDate] = useState<any>();
  const liveIsSchedule = editingLive?.status === 'SCHEDULED';
  const [descriptionState, setDescriptionState] = useState(EditorState.createEmpty());

  const validateLive = () => {
    if (checkEmptyString(name)) {
      throw new Error('Informe um nome valido para a live!');
    }

    if ((!editingLive || liveIsSchedule) && new Date().getTime() >= startDate.getTime()) {
      throw new Error(
        'Informe uma data de inicio valida para a live! Certifique-se que a data seja ao menos 5 minutos superior a data atual!'
      );
    }

    if (!startDate) {
      throw new Error('Informe uma data de inicio da live!');
    }

    if (!finishDate) {
      throw new Error('Informe uma data de fim da live!');
    }

    if (finishDate && new Date().getTime() >= finishDate.getTime()) {
      throw new Error(
        'Informe uma data final valida para a live! Certifique-se que a data seja ao menos 5 minutos superior a data de inicio da live!'
      );
    }

    if (finishDate && startDate.getTime() >= finishDate.getTime()) {
      throw new Error('Informe uma data de inicio inferior a data de fim!');
    }
  };

  const createLive = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      validateLive();

      const newLive: LiveForCreate = {
        name,
        description: draftToHtml(convertToRaw(descriptionState.getCurrentContent())),
        start: startDate.toISOString(),
        info: {
          associated_plans: plansAssociated,
          course_id: courseId,
        },
      };

      if (finishDate) {
        newLive.stop = finishDate.toISOString();
      }

      const live = await createLiveService(newLive);

      onCreate(live.live_id, live);
      hideModal(modalId);

      Swal.fire({
        title: 'Sucesso!',
        text: 'Conteúdo criado com sucesso!',
        icon: 'success',
      });
    } catch (error: any) {
      Swal.fire({
        title: 'Erro',
        text: `Houve um erro ao criar o conteúdo. ${
          error?.response?.data?.message || error.message || 'Ocorreu um erro inesperado'
        }`,
        icon: 'error',
      });
    }
  };

  const updateLive = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      validateLive();

      const newLive: LiveForCreate = {
        name,
        description: draftToHtml(convertToRaw(descriptionState.getCurrentContent())),
        info: {
          associated_plans: plansAssociated,
        },
      };

      const updatedLive = await updateLiveService(liveId!, newLive);

      onCreate(updatedLive.live_id, updatedLive);
      hideModal(modalId);

      Swal.fire({
        title: 'Sucesso!',
        text: 'Conteúdo criado com sucesso!',
        icon: 'success',
      });
    } catch (error: any) {
      Swal.fire({
        title: 'Erro',
        text: `Houve um erro ao criar o conteúdo. ${
          error?.response?.data?.message || error.message || 'Ocorreu um erro inesperado'
        }`,
        icon: 'error',
      });
    }
  };

  useEffect(() => {
    (async () => {
      if (!liveId) return;

      const localLive = await getLiveService(liveId);

      setName(localLive.name);
      setStartDate(localLive.start ? new Date(localLive.start) : fiveMinutesDivisorFutureDate(new Date()));
      setFinishDate(localLive.stop ? new Date(localLive.stop) : '');
      setLiveEditing(localLive);
    })();
  }, [liveId]);

  return (
    <CreateAndEditContentContainer>
      <DefaultPageTitle> {liveId ? 'Editar' : 'Criar'} Live</DefaultPageTitle>

      <DefaultCreationForm>
        <DefaultCreationFormGroup>
          <label className="required" htmlFor="name">
            Nome
          </label>
          <DefaultInput value={name} onChange={(e) => setName(e.target.value)} id="name" required />
        </DefaultCreationFormGroup>

        <DefaultCreationFormGroup>
          <label className="required" htmlFor="description">
            Descrição
          </label>
          <DescriptionTextarea
            description={description}
            editorState={descriptionState}
            setEditorState={setDescriptionState}
          />
        </DefaultCreationFormGroup>

        {!liveIsSchedule ? (
          <DefaultCreationFormGroup>
            <label className="required" htmlFor="startDate">
              Início
            </label>
            <ReactDatePicker
              id="startDate"
              selected={startDate}
              showTimeSelect
              dateFormat="dd/MM/yyyy hh:mm"
              timeIntervals={5}
              onChange={(date) => setStartDate(date)}
              onChangeRaw={(event) => event.preventDefault()}
            />
          </DefaultCreationFormGroup>
        ) : null}

        {!liveId ? (
          <DefaultCreationFormGroup>
            <label className="required" htmlFor="finishDate">
              Fim
            </label>
            <ReactDatePicker
              id="finishDate"
              selected={finishDate}
              showTimeSelect
              dateFormat="dd/MM/yyyy hh:mm"
              timeIntervals={5}
              onChange={(date) => setFinishDate(date)}
              onChangeRaw={(event) => event.preventDefault()}
            />
          </DefaultCreationFormGroup>
        ) : null}

        {editingLive && liveIsSchedule ? (
          <InformationMessage>
            <p>
              Lembre-se de vincular os ids do Samba Videos a esta live na <b>página de listagem de lives</b>.
            </p>
          </InformationMessage>
        ) : null}

        {!editingLive ? (
          <InformationMessage>
            <p>
              Para mudar a exclusividade dessa live, primeiro é preciso mudar os planos a qual esse curso é exclusivo,{' '}
              <b>antes de criar a live.</b>
            </p>
          </InformationMessage>
        ) : null}

        <DefaultCreationFormButtonGroup>
          <DefaultButton type="button" className="danger" onClick={() => hideModal(modalId)}>
            Cancelar
          </DefaultButton>
          <DefaultButton onClick={(e) => (liveId ? updateLive(e) : createLive(e))} className="success">
            Salvar
          </DefaultButton>
        </DefaultCreationFormButtonGroup>
      </DefaultCreationForm>
    </CreateAndEditContentContainer>
  );
};

export default CreateLiveForLesson;
