export enum ORDER_BY {
  ASC = 'ASC',
  DESC = 'DESC',
}

export interface IListParamsDTO {
  limit?: number;
  offset?: number;
  sort_by?: string;
  order_by?: ORDER_BY;
}

export interface IPaginatedResult<T> extends IListParamsDTO {
  count: number;
  total: number;
  data: T[];
}
