import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { AiOutlineCheck, AiOutlineClose } from 'react-icons/ai';
import { BiEdit } from 'react-icons/bi';
import Swal from 'sweetalert2';

import BreadCrumb from '../../components/BreadCrumb';
import { DefaultPageTitle } from '../../components/DefaultPageTitle';
import DefaultTable from '../../components/DefaultTable';
import Pagination from '../../components/Pagination';
import calculatePagination from '../../utils/calculatePagination';
import { CreateButtonDefaultContainer } from '../../components/CreateButtonDefaultContainer';
import { getAllTags, deleteTag, activateOrInactivateTag } from '../../services/tags';
import { Tag } from '../../models/tag';
import DefaultButton from '../../components/DefaultButton';
import FilterForm from '../../components/DefaultFilterTable';

import { StyledButton, TagsContainer } from './styles';

const Tags: React.FC = () => {
  const history = useHistory();

  const [allTags, setAllTags] = useState<Tag[]>([]);
  const [tags, setTags] = useState<Tag[]>([]);
  const [paginationState, setPaginationState] = useState({ page: 1, length: 0, showPage: true });

  const getTags = useCallback(
    async (pageParam?: number) => {
      try {
        const { initial, final } = calculatePagination(pageParam || paginationState.page);

        const localTags = await getAllTags();

        if (localTags && localTags.length) {
          setPaginationState((state) => ({ ...state, length: Math.ceil(localTags.length / 15) }));
          setPaginationState((state) => ({ ...state, showPage: true }));
          setTags(localTags.slice(initial, final));
          setAllTags(localTags);
          return;
        }

        setTags([]);
        setAllTags([]);
      } catch {
        Swal.fire({
          title: 'Erro',
          text: 'Erro ao recuperar tags.',
        });
      }
    },
    [paginationState.page]
  );

  const editTag = useCallback(
    (tagId: any) => {
      history.push(`edit-tag/${tagId}`);
    },
    [history]
  );

  const removeTag = useCallback(
    async (tagId: any, pageParam: number) => {
      Swal.fire({
        title: '<strong>Confirmação</strong>',
        html: 'Tem certeza que deseja remover esta categoria?',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        focusConfirm: false,
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await deleteTag(tagId);

            Swal.fire({
              icon: 'success',
              title: 'Sucesso!',
              text: 'Categoria excluída com sucesso!',
            });

            await getTags(pageParam);
          } catch (error: any) {
            if (error.response.status === 409) {
              Swal.fire({
                icon: 'error',
                title: 'Erro',
                text: error?.response?.data?.message || error.message || 'Ocorreu um erro inesperado',
              });
              return;
            }

            Swal.fire({
              icon: 'error',
              title: 'Erro',
              text: 'Ocorreu um erro desconhecido, tente',
            });
          }
        }
      });
    },
    [getTags]
  );

  const toggleTagActiveStatus = useCallback(
    async (tagId, status) => {
      try {
        await activateOrInactivateTag(tagId, status);
        getTags();
      } catch (e: any) {
        Swal.fire({
          icon: 'error',
          title: 'Não foi possível inativar tag',
          text: e.response?.data?.message || 'Tente mais tarde!',
        });
      }
    },
    [getTags]
  );

  const contentsToBeShown = useMemo(() => {
    return tags.map((tag) => ({
      id: tag.tag_id,
      title: tag.title,
      description: tag.description,
      active: tag.is_active ? <AiOutlineCheck /> : <AiOutlineClose />,
      actions: (
        <>
          <StyledButton onClick={() => editTag(tag.tag_id)} className="small info" title="Editar Categoria">
            Editar
            <BiEdit />
          </StyledButton>
          <StyledButton
            onClick={() => toggleTagActiveStatus(tag?.tag_id, !tag.is_active)}
            className="small warning"
            title={(tag.is_active ? 'Inativar' : 'Ativar') + ' Categoria'}
          >
            {tag.is_active ? (
              <>
                Inativar <AiOutlineClose />
              </>
            ) : (
              <>
                Ativar
                <AiOutlineCheck />
              </>
            )}
          </StyledButton>
          <StyledButton
            onClick={() => removeTag(tag.tag_id, paginationState.page)}
            className="small danger"
            title="Deletar Tag"
          >
            Deletar
            <BiEdit />
          </StyledButton>
        </>
      ),
    }));
  }, [tags, editTag, removeTag, paginationState.page, toggleTagActiveStatus]);

  const createTag = async () => {
    history.push('create-tag');
  };

  useEffect(() => {
    getTags();
  }, [getTags]);

  return (
    <TagsContainer>
      <BreadCrumb crumbs={[<Link to="/dashboard">Dashboard</Link>, <span>Tags</span>]} />

      <DefaultPageTitle>Tags</DefaultPageTitle>

      <CreateButtonDefaultContainer>
        <DefaultButton onClick={createTag}>Criar Tag</DefaultButton>

        <div className="right">
          <FilterForm
            data={allTags}
            onFilter={(filtered: Tag[]) => {
              setTags(filtered);
              setPaginationState((state) => ({ ...state, showPage: false }));
            }}
            propName="title"
            clearFilter={() => {
              setPaginationState((state) => ({ ...state, page: 1 }));
              getTags(1);
            }}
          />
        </div>
      </CreateButtonDefaultContainer>

      <DefaultTable
        headersConfig={[
          {
            headerLabel: <span>Título</span>,
            propName: 'title',
          },
          {
            headerLabel: <span>Descrição</span>,
            propName: 'description',
          },
          {
            headerLabel: <span>Ativo</span>,
            propName: 'active',
          },
          {
            headerLabel: <span>Ações</span>,
            propName: 'actions',
          },
        ]}
        items={contentsToBeShown}
        emptyListMessage={'Não foram encontradas tags cadastradas!'}
      />

      {paginationState.showPage ? (
        <Pagination
          totalPages={paginationState.length}
          setPage={(value) => setPaginationState((state) => ({ ...state, page: value }))}
        />
      ) : null}
    </TagsContainer>
  );
};

export default Tags;
