import httpClient from '../http-client';

interface ConcedeContentProps {
  contentId: string;
  userId: string;
  finishDate?: string;
}

export interface ConcedePlanProps {
  plan_id: string;
  user_id: string;
  finish_at: string;
}

const concedeContent = async ({ contentId, userId, finishDate }: ConcedeContentProps) => {
  return httpClient
    .post('/payments/by-admin', { content_id: contentId, user_id: userId, finish_date: finishDate })
    .then((res) => res.data);
};

const updateContentConceded = async ({ contentId, userId, finishDate }: ConcedeContentProps) => {
  return httpClient
    .put('/payments/by-admin', { content_id: contentId, user_id: userId, finish_date: finishDate })
    .then((res) => res.data);
};

const deleteContentConceded = async (user_payment_id: string) => {
  return httpClient.delete(`/payments/${user_payment_id}/by-admin`).then((res) => res.data);
};

const concedePlan = async ({ user_id, plan_id, finish_at }: ConcedePlanProps) => {
  return httpClient.post('/import-user/plan', { user_id, plan_id, finish_at }).then((res) => res.data);
};

const updatePlanConceded = async ({ user_id, plan_id, finish_at }: ConcedePlanProps) => {
  return httpClient.put('/import-user/plan', { user_id, plan_id, finish_at }).then((res) => res.data);
};

const deletePlanConceded = async (user_payment_id: string) => {
  return httpClient.delete(`/import-user/${user_payment_id}/plan`);
};

export {
  concedeContent,
  updateContentConceded,
  concedePlan,
  updatePlanConceded,
  deletePlanConceded,
  deleteContentConceded,
};
