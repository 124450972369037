import httpClient from '../http-client';
import { ICouponDTO, ICreateCouponDTO, IGetCouponDTO, IListCouponsDTO, IUpdateCouponDTO } from '../models/coupon';

export const findCoupons = async (params: IListCouponsDTO = {}) => {
  const { data } = await httpClient.get('/coupons', { params });

  return data;
};

export const createCoupon = async (couponData: ICreateCouponDTO) => {
  const { data } = await httpClient.post('/coupons', couponData);

  return data;
};

export const updateCoupon = async (coupon_id: string, couponData: IUpdateCouponDTO) => {
  const { data } = await httpClient.put(`/coupons/${coupon_id}`, couponData);

  return data;
};

export const deleteCoupon = async (coupon_id: string) => {
  await httpClient.delete(`/coupons/${coupon_id}`);
};

export const getCoupon = async ({ coupon_id }: IGetCouponDTO): Promise<ICouponDTO> => {
  const { data } = await httpClient.get(`/coupons/${coupon_id}`);
  return data;
};

export const updateCouponStatus = async (coupon_id: string, is_active: boolean): Promise<ICouponDTO> => {
  const { data } = await httpClient.patch(`/coupons/${coupon_id}/status`, { is_active });
  return data;
};
