import { UserCourses } from '..';
import { GetUserCoursesResponse } from '../../../../models/from-api-response/user';
import convertPaymentType from './converPaymentType';
import { convertToDateBR } from '../../../../utils/date';

function contentToUserCourses(contents: GetUserCoursesResponse[]): Omit<UserCourses, 'actions'>[] {
  return contents.map((content) => ({
    content_id: content.content_id,
    course: content.content_name,
    acquiredBy: convertPaymentType(content.payment_type),
    dateLimit: content.finish_at ? convertToDateBR(new Date(content.finish_at)) : 'Ilimitado',
    user_payment_id: content.user_payment_id,
  }));
}

export default contentToUserCourses;
