import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { BiEdit, BiTrash } from 'react-icons/bi';
import { Link, useHistory } from 'react-router-dom';
import { format } from 'date-fns';

import BreadCrumb from '../../../components/BreadCrumb';
import Pagination from '../../../components/Pagination';
import DefaultTable from '../../../components/DefaultTable';
import DefaultButton from '../../../components/DefaultButton';
import { DefaultPageTitle } from '../../../components/DefaultPageTitle';
import { CreateButtonDefaultContainer } from '../../../components/CreateButtonDefaultContainer';

import { IFaqDto } from '../../../models/faq';
import { listFaqs, deleteFaq, updateFaqsPositions } from '../../../services/faq';
import { Container, StyledButton } from './style';
import { SearchBox, UpdatePositionActions } from './components';
import Swal from 'sweetalert2';

export const ListFaqs: FC = () => {
  const history = useHistory();
  const [paginationState, setPaginationState] = useState({ page: 1, length: 0, showPage: true, limit: 12 });
  const [faqsByPage, setFaqByPage] = useState<Array<IFaqDto[]>>([[]]);
  const [shouldUpdatePositions, setShouldUpdatePositions] = useState(false);

  const handleUpdatePagination = (values: Record<any, any>) => {
    setPaginationState((state) => ({
      ...state,
      ...values,
    }));
  };

  const handleListFaqs = useCallback(
    async ({ search, reset }: { search?: string; reset?: boolean } = { reset: false }) => {
      const { limit, page } = paginationState;

      if (reset) {
        setFaqByPage([[]]);
      }

      if (!faqsByPage[page - 1]?.length || reset) {
        const faqs = await listFaqs({ limit, offset: limit * (page - 1), search });
        const faqsByPageCp = [...faqsByPage];
        faqsByPageCp[page - 1] = faqs.data;

        setFaqByPage(faqsByPageCp);

        handleUpdatePagination({
          length: Math.ceil(faqs.total / limit),
        });
      }

      setShouldUpdatePositions(false);
    },
    [faqsByPage, paginationState]
  );

  const handleRemoveFaq = useCallback(async (faq_id: string) => {
    Swal.fire({
      title: '<strong>Confirmação</strong>',
      html: `Tem certeza que deseja remover este FAQ?`,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      focusConfirm: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await deleteFaq(faq_id);
          handleUpdatePagination({ page: 1 });
          await handleListFaqs({ reset: true });

          Swal.fire({
            icon: 'success',
            title: 'Sucesso!',
            text: 'FAQ excluído com sucesso!',
          });
        } catch (error: any) {
          Swal.fire({
            icon: 'error',
            title: 'Erro',
            text: `Erro ao excluir FAQ. ${
              error.response && error.response.status === 409
                ? 'Este FAQ já está associado a um curso!'
                : error?.response?.data?.message || error.message || 'Ocorreu um erro inesperado'
            }`,
          });
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleUpdateFaqsPositions = useCallback(async () => {
    Swal.fire({
      title: '<strong>Confirmação</strong>',
      html: `Tem certeza que deseja atualizar posições?`,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      focusConfirm: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await updateFaqsPositions(
            faqsByPage[paginationState.page - 1]?.map(({ faq_id, position }) => ({ faq_id, position }))
          );
          await handleListFaqs({ reset: true });

          Swal.fire({
            icon: 'success',
            title: 'Sucesso!',
            text: 'Posições atualizadas com sucesso!',
          });
        } catch (error: any) {
          Swal.fire({
            icon: 'error',
            title: 'Erro',
            text: `Erro ao atualizar posições. ${
              error?.response?.data?.message || error.message || 'Ocorreu um erro inesperado'
            }`,
          });
        }
      }
    });
  }, [faqsByPage, handleListFaqs, paginationState.page]);

  const handleUpdatePosition = useCallback(
    (_position: number, direction: 1 | -1, index: number) => {
      const faqs = [...faqsByPage[paginationState.page - 1]];

      const faq = faqs[index];
      const targetFaqPosition = faqs[index + direction];

      faqs[index] = { ...targetFaqPosition, position: faq.position };
      faqs[index + direction] = { ...faq, position: targetFaqPosition.position };

      faqsByPage[paginationState.page - 1] = faqs;
      setShouldUpdatePositions(true);
      setFaqByPage([...faqsByPage]);
    },
    [faqsByPage, paginationState.page]
  );

  const faqsToShow = useMemo(() => {
    return faqsByPage[paginationState.page - 1]?.map((faq, i) => ({
      position: faq.position,
      question: faq.question,
      createdAt: format(new Date(faq.created_at), 'dd/MM/yyyy'),
      answer: faq.answer,
      actions: (
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px', justifyContent: 'end' }}>
          <UpdatePositionActions
            position={faq.position!}
            index={i}
            is_first={i === 0}
            is_last={i === faqsByPage[paginationState.page - 1].length - 1}
            handleUpdatePosition={handleUpdatePosition}
          />
          <StyledButton
            onClick={() => {
              history.push(`/faqs/${faq.faq_id}/edit`);
            }}
            className="small info"
            title="Editar FAQ"
          >
            <BiEdit />
          </StyledButton>
          <StyledButton
            onClick={() => {
              handleRemoveFaq(faq.faq_id);
            }}
            className="small danger"
            title="Excluir FAQ"
          >
            <BiTrash />
          </StyledButton>
        </div>
      ),
    }));
  }, [faqsByPage, handleRemoveFaq, handleUpdatePosition, history, paginationState.page]);

  useEffect(() => {
    handleListFaqs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationState.page]);

  return (
    <Container>
      <BreadCrumb crumbs={[<Link to="/dashboard">Dashboard</Link>, <span>FAQs</span>]} />

      <DefaultPageTitle>FAQs</DefaultPageTitle>

      <CreateButtonDefaultContainer>
        <DefaultButton
          style={{ width: '100px' }}
          onClick={() => {
            history.push('/faqs/create');
          }}
        >
          Criar FAQ
        </DefaultButton>
        <div
          className="right"
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
          }}
        >
          {shouldUpdatePositions && (
            <StyledButton onClick={handleUpdateFaqsPositions} className="small success" title="Editar FAQ">
              Salvar
            </StyledButton>
          )}
          <SearchBox
            handleSearch={handleListFaqs}
            handleClear={() => {
              handleListFaqs({ reset: true });
            }}
          />
        </div>
      </CreateButtonDefaultContainer>

      <DefaultTable
        headersConfig={[
          {
            headerLabel: <span>Questão</span>,
            propName: 'question',
          },
          {
            headerLabel: <span>Resposta</span>,
            propName: 'answer',
          },
          {
            headerLabel: <span>Criado em</span>,
            propName: 'createdAt',
          },
          {
            headerLabel: <span>Posição</span>,
            propName: 'position',
          },
          {
            headerLabel: <span>Ações</span>,
            propName: 'actions',
          },
        ]}
        items={faqsToShow}
        emptyListMessage="Não foram encontrados FAQs cadastrados!"
      />

      {paginationState.showPage ? (
        <Pagination
          totalPages={paginationState.length}
          setPage={(page) => setPaginationState((state) => ({ ...state, page }))}
        />
      ) : null}
    </Container>
  );
};
