import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { BiEdit } from 'react-icons/bi';
import Swal from 'sweetalert2';

import BreadCrumb from '../../components/BreadCrumb';
import { DefaultPageTitle } from '../../components/DefaultPageTitle';
import DefaultTable from '../../components/DefaultTable';
import Pagination from '../../components/Pagination';
import calculatePagination from '../../utils/calculatePagination';
import { CreateButtonDefaultContainer } from '../../components/CreateButtonDefaultContainer';
import { getAllContents } from '../../services/contents';
import { updateSchedule as updateScheduleService } from '../../services/contents';
import DefaultButton from '../../components/DefaultButton';
import FilterForm from '../../components/DefaultFilterTable';
import Select, { OptionsType } from 'react-select';

import { SelectContainer, StyledButton, ScheduledContainer } from './styles';
import Content from '../../models/content';

interface SelectOptionProps {
  label: string;
  value: string;
  scheduled: boolean;
}

const Scheduled: React.FC = () => {
  const [coursesOptions, setCoursesOptions] = useState<OptionsType<SelectOptionProps>>([]);
  const [course, setCourse] = useState({ value: '', label: '', scheduled: false });

  const [allContents, setallContents] = useState<Content[]>([]);
  const [contents, setContents] = useState<Content[]>([]);
  const [paginationState, setPaginationState] = useState({ page: 1, length: 0, showPage: true });

  const getContents = useCallback(
    async (pageParam?: number) => {
      const { initial, final } = calculatePagination(pageParam || paginationState.page);
      const localContents = await getAllContents({
        info_key: 'scheduled',
        info_value: true,
      });

      const scheduled = localContents.data;
      setContents(scheduled.slice(initial, final));
      setallContents(scheduled);

      setPaginationState((state) => ({
        ...state,
        length: Math.ceil(localContents.count / 15),
        showPage: true,
      }));
    },
    [paginationState.page]
  );

  const updateSchedule = async (contentOption: SelectOptionProps, scheduled: boolean) => {
    if (contentOption.scheduled) {
      Swal.fire({
        title: 'Erro!',
        text: 'Conteúdo já se encontra agendado!',
        icon: 'error',
      });
      setCourse({ value: '', label: '', scheduled: false });

      return;
    }
    await updateScheduleService(contentOption.value, scheduled);

    getContents();
  };

  const contentsToBeShown = useMemo(() => {
    return contents.map((content) => ({
      id: content.content_id,
      name: content.name,
      actions: (
        <>
          <StyledButton
            onClick={() =>
              updateSchedule(
                { value: content.content_id, label: content.name, scheduled: !!content.info.schedule },
                false
              )
            }
            className="small danger"
            title="Deletar Conteúdo"
          >
            Excluir
            <BiEdit />
          </StyledButton>
        </>
      ),
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contents, paginationState.page]);

  useEffect(() => {
    getContents();

    return () => {
      setCourse({ value: '', label: '', scheduled: false });
    };
  }, [getContents]);

  useEffect(() => {
    if (allContents.length) {
      getAllContents({
        flag: ['course', 'retreat', 'live'],
        type: ['curso', 'retiro', 'palestras'],
        exclude_content_ids: allContents.map((content) => content.content_id),
      })
        .then(({ data }) => {
          setCoursesOptions(
            data.map((course: Content) => ({
              label: course.name,
              value: course.content_id,
              scheduled: !!course.info?.scheduled,
            }))
          );
        })
        .catch((e) => {
          Swal.fire({
            title: 'Erro!',
            text: 'Ocorreu um erro ao recuperar os cursos!',
            icon: 'error',
          });
        });
    }

    return () => {
      setCourse({ value: '', label: '', scheduled: false });
    };
  }, [allContents]);

  return (
    <ScheduledContainer>
      <BreadCrumb
        crumbs={[<Link to="/dashboard">Dashboard</Link>, <span>Cms</span>, <span>Agenda de Conteúdo</span>]}
      />

      <DefaultPageTitle>Agenda de Conteúdo</DefaultPageTitle>

      <SelectContainer>
        <label className="required" htmlFor="content">
          Conteúdos
        </label>
        <Select
          id="course"
          options={coursesOptions}
          value={course}
          onChange={(option) => setCourse(option!)}
          placeholder="Selecione um conteúdo para exibir"
          noOptionsMessage={() => 'Sem conteúdos disponíveis'}
        />
      </SelectContainer>

      <CreateButtonDefaultContainer>
        <DefaultButton onClick={() => updateSchedule(course, true)}>Salvar</DefaultButton>

        <div className="right">
          <FilterForm
            data={allContents}
            onFilter={(filtered: Content[]) => {
              setContents(filtered);
              setPaginationState((state) => ({ ...state, showPage: false }));
            }}
            propName="name"
            clearFilter={() => {
              setPaginationState((state) => ({ ...state, page: 1 }));
              getContents(1);
            }}
          />
        </div>
      </CreateButtonDefaultContainer>

      <DefaultTable
        headersConfig={[
          {
            headerLabel: <span>Título</span>,
            propName: 'name',
          },
          {
            headerLabel: <span>Ações</span>,
            propName: 'actions',
          },
        ]}
        items={contentsToBeShown}
        emptyListMessage={'Não foram encontrados conteúdos agendados!'}
      />
      {paginationState.showPage ? (
        <Pagination
          totalPages={paginationState.length}
          setPage={(value) => setPaginationState((state) => ({ ...state, page: value }))}
        />
      ) : null}
    </ScheduledContainer>
  );
};

export default Scheduled;
