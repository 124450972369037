import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { GiClick } from 'react-icons/gi';

import DefaultTable from '../../../components/DefaultTable';
import Pagination from '../../../components/Pagination';
import Content from '../../../models/from-api-response/content';
import calculatePagination from '../../../utils/calculatePagination';
import { showModal } from '../../../helpers/modal';
import { StyledButton } from '../style';
import { getAllContents, Relations } from '../../../services/contents';
import LinkModuleModal from '../LinkModuleModal';

import { Container } from './styles';

interface Props {
  lessonId: string;
  reloadContents: (agr?: number) => void;
}

const LinkCourseModal: React.FC<Props> = ({ lessonId, reloadContents }) => {
  const [allContents, setAllContents] = useState<Content[]>([]);
  const [contents, setContents] = useState<Content[]>([]);
  const [paginationState, setPaginationState] = useState({ page: 1, length: 0, showPage: true });

  const getContents = useCallback(
    async (pageParam?: number) => {
      const { initial, final } = calculatePagination(pageParam || paginationState.page);
      const localContents = await getAllContents({
        flag: ['course', 'retreats'],
        type: ['on-demand', 'curso', 'retiro'],
        relations: [Relations.tags, Relations.categories],
      });

      const formattedLocalContents = localContents.data.map((item: Content) => ({
        ...item,
        categories: item?.categories?.[0].title || '',
        tags: item?.tags?.[0].title || '',
      }));

      if (formattedLocalContents && formattedLocalContents.length) {
        setContents(formattedLocalContents.slice(initial, final));
        setAllContents(formattedLocalContents);
        setPaginationState((state) => ({
          ...state,
          length: Math.ceil(formattedLocalContents.length / 8),
          showPage: true,
        }));
      }
    },
    [paginationState.page]
  );

  const pagination = useCallback(
    async (pageParam?: number) => {
      const { initial, final } = calculatePagination(pageParam || paginationState.page, 5);

      setContents(allContents.slice(initial, final));
      setPaginationState((state) => ({ ...state, showPage: true }));
    },
    [paginationState.page, allContents]
  );

  const handleSelectCourse = useCallback(
    (courseId) => {
      showModal(
        'Selecione o módulo',
        <LinkModuleModal courseId={courseId} lessonId={lessonId} reloadContents={reloadContents} />
      );
    },
    [lessonId]
  );

  useEffect(() => {
    getContents();
  }, [getContents]);

  useEffect(() => {
    pagination();
  }, [pagination]);

  const contentsToBeShown = useMemo(() => {
    return contents.map((content) => ({
      course: content.name,
      actions: (
        <StyledButton onClick={() => handleSelectCourse(content.content_id)} className="small info" title="Editar">
          Selecionar
          <GiClick />
        </StyledButton>
      ),
    }));
  }, [contents, handleSelectCourse]);

  return (
    <Container>
      <DefaultTable
        headersConfig={[
          {
            headerLabel: <>Curso</>,
            propName: 'course',
          },
          {
            headerLabel: <>Ações</>,
            propName: 'actions',
            noWrap: true,
          },
        ]}
        items={contentsToBeShown}
        emptyListMessage="Não foram encontrados conteúdos cadastrados"
      />

      {paginationState.showPage ? (
        <Pagination
          totalPages={paginationState.length}
          setPage={(page) => setPaginationState((state) => ({ ...state, page }))}
        />
      ) : null}
    </Container>
  );
};

export default LinkCourseModal;
