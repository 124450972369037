import React, { useCallback, useEffect, useState } from 'react';
import Select from 'react-select';
import Swal from 'sweetalert2';
import ReactDatePicker from 'react-datepicker';
import { StyledComponent } from 'styled-components';

import DefaultTable from '../../../components/DefaultTable';
import Pagination from '../../../components/Pagination';
import { getUserById, getUserPlanSubscription } from '../../../services/users';
import calculatePagination from '../../../utils/calculatePagination';

import { StyledButton } from '../styles';
import { Container, SelectWrapper, Tag, TagProps, ButtonsWrapper, ActionButtonsContainer } from './styles';
import { Plan } from '../../../services/plans';
import planToUserPlans from './utils/planToUserPlans';
import { addDays } from 'date-fns';
import { concedePlan, ConcedePlanProps, deletePlanConceded, updatePlanConceded } from '../../../services/payments';

type PlansModalProps = {
  userId: string;
  plans: Plan[];
  onCloseModal: () => void;
};

interface Options {
  value: string;
  label: string;
}

export interface UserPlans {
  user_payment_id: string;
  plan_id: string;
  title: string;
  acquiredBy: string;
  price: number | null;
  overdue: boolean;
  status: StyledComponent<'div', any, TagProps, never>[] | JSX.Element[];
  actions: JSX.Element;
  dateStart: string;
  dateLimit: string;
  cycle: number;
}

interface PlanEditing {
  id: string;
  title: string;
}

const LIMIT_PER_PAGE = 5;

const PlansModal: React.FC<PlansModalProps> = ({ userId, plans, onCloseModal }) => {
  const [loading, setLoading] = useState(true);
  const [options, setOptions] = useState<Options[]>([]);
  const [userPlans, setUserPlans] = useState<UserPlans[]>([]);
  const [allUserPlans, setAllUserPlans] = useState<UserPlans[]>([]);
  const [planSelected, setPlanSelected] = useState('');
  const [finishDate, setFinishDate] = useState(addDays(new Date(), 30));
  const [paginationState, setPaginationState] = useState({ page: 1, length: 0, showPage: true });
  const [planEditing, setPlanEditing] = useState<PlanEditing | null>(null);

  const calculateStatusForPlan = useCallback((plan, index) => {
    if (index === 0) {
      return [<Tag state="active">Ativo</Tag>];
    }

    if (plan.dateLimit === 'Ilimitado' && index !== 0) {
      return [<Tag state="valid">Válido</Tag>, <Tag state="inactive">Inativo</Tag>];
    }

    if (plan.overdue) {
      return [<Tag state="overdue">Vencido</Tag>];
    }

    return [<Tag state="valid">Válido</Tag>, <Tag state="inactive">Inativo</Tag>];
  }, []);

  const handleDeletePlanConceded = useCallback(
    async (user_payment_id: string) => {
      await Swal.fire({
        title: 'Excluir Plano',
        text: 'Tem certeza que deseja excluir o plano?',
        icon: 'warning',
        showCancelButton: true,
      }).then(async (result) => {
        if (!result.isConfirmed) return;

        await deletePlanConceded(user_payment_id)
          .then(() => {
            Swal.fire({
              icon: 'success',
              title: 'Plano excluído com sucesso!',
            });
            onCloseModal();
          })
          .catch(() => {
            Swal.fire({
              icon: 'error',
              title: 'Ocorreu um erro ao tentar excluir o plano!',
              text: 'Por favor, tente novamente.',
            });
          });
      });
    },
    [onCloseModal]
  );

  const getUser = useCallback(async () => {
    setLoading(true);
    const { initial, final } = calculatePagination(1, LIMIT_PER_PAGE);

    const user = await getUserById(userId).finally(() => setLoading(false));

    const plansPaidConverted = planToUserPlans(user.plans_paid || []);

    const plans: UserPlans[] = plansPaidConverted.map((plan, index) => {
      const isConcededByAdmin = plan.acquiredBy === 'AUTORIZADO PELO ADMINISTRADOR';

      return {
        ...plan,
        status: calculateStatusForPlan(plan, index),
        actions: Boolean(plan.price) ? (
          <ActionButtonsContainer>
            <StyledButton
              onClick={() => setPlanEditing({ id: plan.plan_id, title: plan.title })}
              className="small info"
              title={!plan.price || !isConcededByAdmin ? 'Gerenciamento feito pela Vindi' : 'Editar'}
              disabled={!plan.price || !isConcededByAdmin}
            >
              {!plan.price || !isConcededByAdmin ? 'Vindi' : 'Editar'}
            </StyledButton>

            {isConcededByAdmin && (
              <StyledButton
                onClick={() => handleDeletePlanConceded(plan.user_payment_id)}
                className="small danger"
                title="Excluir plano"
                type="button"
              >
                Excluir
              </StyledButton>
            )}
          </ActionButtonsContainer>
        ) : (
          <div />
        ),
      };
    });

    const plansSliced = plans.slice(initial, final);

    plansSliced.forEach(async (plan, position) => {
      if (!plan.price) return;

      const planSubscription = await getUserPlanSubscription(plan.user_payment_id);

      plansSliced[position] = { ...plan, cycle: planSubscription?.current_period?.cycle! || 0 };
    });

    setAllUserPlans(plans);
    setUserPlans(plansSliced);
    setPaginationState((state) => ({ ...state, showPage: true, length: Math.ceil(plans.length / LIMIT_PER_PAGE) }));
  }, [userId, calculateStatusForPlan, handleDeletePlanConceded]);

  const handleConcedePlan = useCallback(async () => {
    if (!planSelected) {
      Swal.fire({
        icon: 'warning',
        text: 'Selecione um plano.',
      });
      return;
    }

    try {
      const params: ConcedePlanProps = {
        plan_id: planSelected,
        user_id: userId,
        finish_at: new Date(finishDate).toISOString(),
      };

      await concedePlan(params);

      Swal.fire({
        icon: 'success',
        text: 'Acesso ao plano concedido.',
      });
      onCloseModal();
    } catch {
      Swal.fire({
        icon: 'error',
        text: 'Houve um erro ao disponibilizar o plano, tente novamente.',
      });
    }
  }, [userId, finishDate, planSelected, onCloseModal]);

  const handleUpdateDatePlanConceded = useCallback(async () => {
    if (!planEditing) {
      Swal.fire({
        icon: 'warning',
        text: 'Selecione um plano para editar.',
      });
      return;
    }

    if (!finishDate || new Date(finishDate) < new Date()) {
      Swal.fire({
        icon: 'warning',
        text: 'Selecione uma data válida.',
      });
      return;
    }

    try {
      const params: ConcedePlanProps = {
        plan_id: planEditing.id,
        user_id: userId,
        finish_at: new Date(finishDate).toISOString(),
      };

      await updatePlanConceded(params);

      Swal.fire({
        icon: 'success',
        text: 'Data de acesso ao curso atualizada.',
      });
      onCloseModal();
    } catch {
      Swal.fire({
        icon: 'error',
        text: 'Houve um erro ao atualizar, tente novamente.',
      });
    }
  }, [userId, finishDate, onCloseModal, planEditing]);

  const handleChangePage = (page: number) => {
    setPaginationState((state) => ({ ...state, page }));

    const { initial, final } = calculatePagination(page, LIMIT_PER_PAGE);

    const plansSliced = allUserPlans.slice(initial, final);

    setUserPlans(plansSliced);
  };

  useEffect(() => {
    const planPeriod = plans.find((plan) => plan.plan_id === planSelected);

    if (!planPeriod) return;

    setFinishDate(addDays(new Date(), planPeriod.period_in_days));
  }, [planSelected, plans]);

  useEffect(() => {
    const plansToOptions = plans
      .map((plan: Plan) => {
        return {
          value: plan.plan_id,
          label: plan.title,
        };
      })
      .filter((option) => userPlans.find((plan) => plan.plan_id === option.value) === undefined);

    setOptions(plansToOptions);
  }, [plans, userPlans]);

  useEffect(() => {
    getUser();
  }, [getUser]);

  return (
    <Container>
      {loading && <div className="spinner small"></div>}

      {!loading && (
        <DefaultTable
          headersConfig={[
            {
              headerLabel: <span>Plano</span>,
              propName: 'title',
            },
            {
              headerLabel: <span>Adquirido por</span>,
              propName: 'acquiredBy',
            },
            {
              headerLabel: <span>Ciclo da cobrança</span>,
              propName: 'cycle',
            },
            {
              headerLabel: <span>Data de início</span>,
              propName: 'dateStart',
            },
            {
              headerLabel: <span>Data limite</span>,
              propName: 'dateLimit',
            },
            {
              headerLabel: <span>Status</span>,
              propName: 'status',
            },
            {
              headerLabel: <span>Ações</span>,
              propName: 'actions',
              noWrap: true,
            },
          ]}
          items={userPlans}
          emptyListMessage="Não foram encontrados planos para este usuário"
        />
      )}

      {paginationState.showPage ? <Pagination totalPages={paginationState.length} setPage={handleChangePage} /> : null}

      <SelectWrapper>
        {planEditing ? <h1>Editando o plano: {planEditing.title}</h1> : <h1>Disponibilizar um plano</h1>}

        {planEditing ? null : (
          <>
            <label htmlFor="select-plans">Planos</label>
            <Select
              id="select-plans"
              options={options}
              placeholder="Selecione um plano"
              noOptionsMessage={() => 'Nenhum plano'}
              onChange={(option) => setPlanSelected(option ? option.value : '')}
            />
          </>
        )}

        <label htmlFor="finishDate">Data limite</label>
        <ReactDatePicker
          id="finishDate"
          selected={new Date(finishDate)}
          dateFormat="dd/MM/yyyy"
          onChange={(date: Date) => setFinishDate(date)}
          onChangeRaw={(event) => event.preventDefault()}
          locale="pt-BR"
        />

        <ButtonsWrapper>
          <StyledButton
            onClick={planEditing ? handleUpdateDatePlanConceded : handleConcedePlan}
            className="small success"
            title="Confirmar"
          >
            Confirmar
          </StyledButton>

          {planEditing && (
            <StyledButton onClick={() => setPlanEditing(null)} className="small warning" title="Cancelar">
              Cancelar
            </StyledButton>
          )}
        </ButtonsWrapper>
      </SelectWrapper>
    </Container>
  );
};

export default PlansModal;
