import { FC, useRef } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import BreadCrumb from '../../../components/BreadCrumb';
import DefaultButton from '../../../components/DefaultButton';
import DefaultCreationForm, { DefaultCreationFormButtonGroup } from '../../../components/DefaultCreationForm';
import DefaultInput from '../../../components/DefaultInput';
import { DefaultPageTitle } from '../../../components/DefaultPageTitle';
import { DefaultTextArea } from '../../../components/DefaultTextArea';
import { createFaq } from '../../../services/faq';
import { DefaultCreationFormGroup } from '../../ContentPrerequisite/styles';
import { Container } from './style';

export const CreateFaq: FC = () => {
  const history = useHistory();
  const questionInputRef = useRef<HTMLInputElement>(null);
  const answerInputRef = useRef<HTMLTextAreaElement>(null);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    createFaq({
      question: questionInputRef.current?.value.trim()!,
      answer: answerInputRef.current?.value.trim()!,
    })
      .then((data) => {
        Swal.fire({
          icon: 'success',
          title: 'Sucesso!',
          text: 'Faq criado com sucesso!',
        });
        history.push('/faqs');
      })
      .catch((e) => {
        Swal.fire({
          icon: 'error',
          title: 'Erro',
          text: `Erro ao criar FAQ. ${e?.response?.data?.message || e.message || 'Ocorreu um erro inesperado'}`,
        });
      });
  };

  return (
    <Container>
      <BreadCrumb crumbs={[<Link to="/dashboard">Dashboard</Link>, <Link to="/faqs">FAQs</Link>, <span>criar</span>]} />

      <DefaultPageTitle>Criar FAQ</DefaultPageTitle>
      <DefaultCreationForm onSubmit={handleSubmit}>
        <DefaultCreationFormGroup>
          <label className="required" htmlFor="question">
            Pergunta
          </label>
          <DefaultInput ref={questionInputRef} type="text" id="question" required maxLength={250} />
        </DefaultCreationFormGroup>

        <DefaultCreationFormGroup>
          <label htmlFor="answer" className="required">
            Resposta
          </label>
          <DefaultTextArea ref={answerInputRef} id="answer" required />
        </DefaultCreationFormGroup>

        <DefaultCreationFormButtonGroup>
          <DefaultButton
            type="button"
            className="danger"
            onClick={() => {
              history.push('/faqs');
            }}
          >
            Cancelar
          </DefaultButton>
          <DefaultButton className="success">Salvar</DefaultButton>
        </DefaultCreationFormButtonGroup>
      </DefaultCreationForm>
    </Container>
  );
};
