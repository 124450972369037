import React, { useState } from 'react';
import ReactDatePicker from 'react-datepicker';

import { generateExamReport } from '../../services/exams';
import DefaultButton from '../DefaultButton';

import { Container, DatePickerContainer, DefaultCreationFormGroup } from './styles';

interface Props {
  examId: string;
}

const RangeDateExamReport: React.FC<Props> = ({ examId }) => {
  const [startDate, setStartDate] = useState<Date | undefined>(undefined);
  const [finalDate, setFinalDate] = useState<Date | undefined>(undefined);

  function downloadCsv() {
    const a = document.createElement('a');
    document.body.appendChild(a);
    return function (data: any) {
      const json = data,
        blob = new Blob([json], { type: 'text/csv;' }),
        url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = examId;
      a.click();
      window.URL.revokeObjectURL(url);
    };
  }

  const handleDownloadReport = async () => {
    const response = await generateExamReport(
      examId,
      startDate?.toISOString().split('T')[0],
      finalDate?.toISOString().split('T')[0]
    );

    downloadCsv()(response);
  };

  return (
    <Container>
      <h1>Selecione um intervalo de datas para filtrar as respostas e gerar o relatório</h1>
      <h2>Ou deixe em branco para recuperar todos as respostas.</h2>

      <DatePickerContainer>
        <DefaultCreationFormGroup>
          <label htmlFor="startDate">Início</label>
          <ReactDatePicker
            id="startDate"
            showTimeSelect={false}
            selected={startDate ? new Date(startDate) : null}
            dateFormat="dd/MM/yyyy"
            timeIntervals={5}
            onChange={(date) => setStartDate(date as Date)}
            onChangeRaw={(event) => event.preventDefault()}
          />
        </DefaultCreationFormGroup>

        <DefaultCreationFormGroup>
          <label htmlFor="startDate">Fim</label>
          <ReactDatePicker
            id="finalDate"
            showTimeSelect={false}
            selected={finalDate ? new Date(finalDate) : null}
            dateFormat="dd/MM/yyyy"
            timeIntervals={5}
            onChange={(date) => setFinalDate(date as Date)}
            onChangeRaw={(event) => event.preventDefault()}
          />
        </DefaultCreationFormGroup>
      </DatePickerContainer>

      <DefaultButton onClick={handleDownloadReport}>Download</DefaultButton>
    </Container>
  );
};

export default RangeDateExamReport;
