export enum PaymentType {
  'CREDIT_CARD' = 'credit_card',
  'BANK_SLIP' = 'bank_slip',
  'ALLOW_BY_ADMIN' = 'allow_by_admin',
  'PIX' = 'pix',
  'OFFSITE' = 'offsite',
}

export interface IUser {
  user_id: string;
  name: string;
  email: string;
  info: Record<string, unknown>;
  is_admin: boolean;
  is_active: boolean;
  vindi_payment_profile_id: number;
  roles: string[];
  vindi_id: number;
  plans_paid: {
    user_payment_id: string;
    vindi_subscription_id: string;
    plan_id: string;
    start_at: string;
    finish_at: string;
    deleted_at: string;
    price: number | null;
    is_paid: boolean;
    canceled_by_user: boolean;
    payment_type: PaymentType;
    status: string;
    plan: {
      price: number;
      plan_id: string;
      title: string;
      period_in_days: 30;
      info: Record<string, string | number>;
    };
  }[];
}

interface UserFromResponse {
  card_numbers: string;
  card_type: string;
  created_at: string;
  deleted_at: string;
  email: string;
  is_admin: boolean;
  is_active: boolean;
  name: string;
  plan: string;
  status: 'active' | 'canceled' | 'debtor';
  type: string;
  updated_at: string;
  user_id: string;
  vindi_id: null;
  vindi_payment_id: null;
  roles: string[];
  points: number;
  level: {
    level_id: string;
    name: string;
    range: {
      end: number;
      start: number;
    };
  };
}
export interface GetUserCoursesResponse {
  user_payment_id: string;
  content_id: string;
  payment_type: PaymentType;
  content_name: string;
  finish_at: string | null;
}

export default UserFromResponse;
