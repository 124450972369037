import React, { useState } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import CurrencyInput from 'react-currency-input-field';
import { AiOutlineArrowDown, AiOutlineArrowUp, AiOutlineCheck, AiOutlineClose } from 'react-icons/ai';

import BreadCrumb from '../../components/BreadCrumb';
import DefaultCreationForm, { DefaultCreationFormGroup } from '../../components/DefaultCreationForm';
import DefaultInput from '../../components/DefaultInput';
import { DefaultPageTitle } from '../../components/DefaultPageTitle';
import checkEmptyString from '../../helpers/check-empty-string';

import { BenefitsList, Container, InputBenefitContainer, StyledButton, Switch } from './styles';
import { BiTrash } from 'react-icons/bi';
import Swal from 'sweetalert2';
import { createPlan } from '../../services/plans';

interface CreatePlanParams {
  planPeriod: string;
}

interface Benefit {
  label: string;
  active: boolean;
}

const CreatePlan: React.FC = () => {
  const [title, setTitle] = useState('');
  const [totalPrice, setTotalPrice] = useState(0);
  const [finalPrice, setFinalPrice] = useState<number | undefined>(undefined);
  const [discount, setDiscount] = useState(0);
  const [benefit, setBenefit] = useState('');
  const [benefitActive, setBenefitActive] = useState<boolean | null>(null);
  const [benefits, setBenefits] = useState<Benefit[]>([]);
  const [isRecommended, setIsRecommended] = useState(false);
  const [maxInstallments, setMaxInstallments] = useState<number | undefined>(undefined);

  const { planPeriod } = useParams<CreatePlanParams>();
  const history = useHistory();

  const handleAddBenefit = () => {
    try {
      if (checkEmptyString(benefit)) {
        throw new Error('O benefício não pode estar em branco');
      } else {
        setBenefits((state) => [...state, { label: benefit, active: !!benefitActive }]);
        setBenefitActive(null);
        setBenefit('');
      }
    } catch (error: any) {
      Swal.fire({
        icon: 'error',
        title: 'Erro ao enviar os dados!',
        text: error.message,
      });
    }
  };

  const handleExcludeBenefit = (label: string) => {
    setBenefits((state) => state.filter((bnf) => bnf.label !== label));
  };

  const handleSavePlan = async () => {
    try {
      if (checkEmptyString(title)) {
        throw new Error('Informe um título');
      }

      if (!finalPrice) {
        throw new Error('Informe um valor com desconto');
      }

      if (benefits.length === 0) {
        throw new Error('Informe ao menos 1 benefício');
      }

      if (!maxInstallments) {
        throw new Error('Informe um número máximo de parcelas');
      }

      if (totalPrice >= finalPrice) {
        throw new Error('Valor atual do plano não pode ser maior que valor anterior');
      }

      const planPeriodToDays: { [key: string]: number } = {
        mensal: 30,
        trimestral: 90,
        semestral: 180,
        anual: 365,
      };

      const type = (() => {
        if (finalPrice === 0) {
          return 0;
        }

        if (benefits.every((benefit) => benefit.active)) {
          return 2;
        }

        return 1;
      })();

      const plan = {
        period_in_days: planPeriodToDays[planPeriod],
        title,
        discount,
        price: finalPrice,
        full_price: totalPrice,
        is_active: true,
        benefits,
        info: {
          isRecommended,
          type,
          max_installments: maxInstallments,
        },
      };

      await createPlan(plan);

      Swal.fire({
        icon: 'success',
        title: 'Novo plano criado com sucesso!',
      });
      history.goBack();
    } catch (error: any) {
      Swal.fire({
        icon: 'error',
        title: 'Erro ao enviar os dados!',
        text: error?.response?.data?.message || error.message || 'Ocorreu um erro inesperado',
      });
    }
  };

  const handleMoveItem = (item: Benefit, position: number, move: 'up' | 'down') => {
    const benefitsAux = [...benefits];

    benefitsAux.splice(position, 1);

    move === 'up' ? benefitsAux.splice(position - 1, 0, item) : benefitsAux.splice(position + 1, 0, item);

    setBenefits(benefitsAux);
  };

  const MAX_INSTALLMENTS_PLAN_MES = 1;
  const MAX_INSTALLMENTS_PLAN_TRIM = 3;
  const MAX_INSTALLMENTS_PLAN_SEM = 6;
  const MAX_INSTALLMENTS_PLAN_ANUAL = 12;

  function getMaxInstallments(value: number) {
    try {
      if (value <= 0) {
        throw new Error('A quantidade mínima possível é a vista de 1x');
      }

      if (planPeriod === 'mensal' && value > MAX_INSTALLMENTS_PLAN_MES) {
        throw new Error(`Quantidade de parcelas acima do permitido. Máximo ${MAX_INSTALLMENTS_PLAN_MES}x.`);
      }
      if (planPeriod === 'trimestral' && value > MAX_INSTALLMENTS_PLAN_TRIM) {
        throw new Error(`Quantidade de parcelas acima do permitido. Máximo ${MAX_INSTALLMENTS_PLAN_TRIM}x.`);
      }
      if (planPeriod === 'semestral' && value > MAX_INSTALLMENTS_PLAN_SEM) {
        throw new Error(`Quantidade de parcelas acima do permitido. Máximo ${MAX_INSTALLMENTS_PLAN_SEM}x.`);
      }
      if (planPeriod === 'anual' && value > MAX_INSTALLMENTS_PLAN_ANUAL) {
        throw new Error(`Quantidade de parcelas acima do permitido. Máximo ${MAX_INSTALLMENTS_PLAN_ANUAL}x.`);
      }

      setMaxInstallments(value);
    } catch (error) {
      setMaxInstallments(1);
      Swal.fire({
        icon: 'error',
        title: 'Erro ao enviar os dados!',
        text: error?.response?.data?.message || error.message || 'Ocorreu um erro inesperado',
      });
    }
  }

  return (
    <Container>
      <BreadCrumb
        crumbs={[<Link to="/dashboard">Dashboard</Link>, <Link to="/plans">Planos</Link>, <span>Criar plano</span>]}
      />

      <DefaultPageTitle>Criar plano {planPeriod}</DefaultPageTitle>

      <DefaultCreationForm>
        <DefaultCreationFormGroup>
          <label>Recomendado</label>
          <Switch>
            <input type="checkbox" checked={isRecommended} onChange={() => setIsRecommended((state) => !state)} />
            <span className="slider round"></span>
          </Switch>
        </DefaultCreationFormGroup>

        <DefaultCreationFormGroup>
          <label className="required" htmlFor="title">
            Título
          </label>
          <DefaultInput
            id="title"
            placeholder="Digite o título deste plano"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />
        </DefaultCreationFormGroup>

        <DefaultCreationFormGroup>
          <label className="required" htmlFor="maxInstallments">
            Parcelamento máximo
          </label>
          <DefaultInput
            id="maxInstallments"
            placeholder="Digite a quantidade máxima de parcelamento deste plano"
            value={maxInstallments}
            type="number"
            onChange={(e) => getMaxInstallments(Number(e.target.value))}
            required
          />
        </DefaultCreationFormGroup>

        <DefaultCreationFormGroup>
          <label htmlFor="price">Valor anterior do plano</label>
          <CurrencyInput
            className="currency-input"
            id="price"
            name="price"
            placeholder="Valor anterior do plano"
            defaultValue={totalPrice}
            prefix="R$"
            onValueChange={(value) => setTotalPrice(parseFloat(value?.replace(',', '.')!))}
          />
        </DefaultCreationFormGroup>

        <DefaultCreationFormGroup>
          <label className="required" htmlFor="percentageDiscount">
            Valor atual do plano
          </label>
          <CurrencyInput
            className="currency-input"
            id="price"
            name="price"
            prefix="R$"
            placeholder="Valor atual do plano"
            defaultValue={finalPrice}
            onValueChange={(value) => setFinalPrice(parseFloat(value?.replace(',', '.')!))}
          />
        </DefaultCreationFormGroup>

        <DefaultCreationFormGroup>
          <label htmlFor="percentageDiscount">Porcentagem de desconto em conteúdos</label>
          <CurrencyInput
            className="currency-input"
            id="percentageDiscount"
            name="percentageDiscount"
            suffix="%"
            allowDecimals={false}
            placeholder="Digite o valor em porcentagem"
            defaultValue={discount}
            onValueChange={(value) => setDiscount(Number(value))}
            maxLength={2}
          />
        </DefaultCreationFormGroup>

        <DefaultCreationFormGroup>
          <label className="required" htmlFor="benefit">
            Benefícios
          </label>

          <InputBenefitContainer>
            <DefaultInput
              value={benefit}
              onChange={(e) => setBenefit(e.target.value)}
              id="benefit"
              placeholder="Escreva a frase resumo de um benefício"
            />

            <StyledButton
              disabled={benefitActive === true}
              type="button"
              className="success"
              onClick={() => setBenefitActive(true)}
            >
              Incluso
            </StyledButton>

            <StyledButton
              disabled={benefitActive === false}
              type="button"
              className="danger"
              onClick={() => setBenefitActive(false)}
            >
              Excluso
            </StyledButton>

            <StyledButton type="button" onClick={handleAddBenefit}>
              Adicionar
            </StyledButton>
          </InputBenefitContainer>
        </DefaultCreationFormGroup>

        <DefaultCreationFormGroup>
          <label>Benefícios adicionados</label>
          <BenefitsList>
            {benefits.length === 0 && <h4>Sem benefícios ainda</h4>}
            {benefits.map((item, index) => (
              <li>
                {item.active ? <AiOutlineCheck color="green" /> : <AiOutlineClose color="red" />}
                <p style={{ textDecoration: item.active ? '' : 'line-through' }}>{item.label}</p>

                {index > 0 && (
                  <StyledButton
                    type="button"
                    className="arrows small info"
                    onClick={() => handleMoveItem(item, index, 'up')}
                  >
                    <AiOutlineArrowUp />
                  </StyledButton>
                )}

                {index < benefits.length - 1 && (
                  <StyledButton
                    type="button"
                    className="arrows small info"
                    onClick={() => handleMoveItem(item, index, 'down')}
                  >
                    <AiOutlineArrowDown />
                  </StyledButton>
                )}

                <StyledButton type="button" className="small danger" onClick={() => handleExcludeBenefit(item.label)}>
                  Excluir
                  <BiTrash />
                </StyledButton>
              </li>
            ))}
          </BenefitsList>
        </DefaultCreationFormGroup>

        <DefaultCreationFormGroup>
          <StyledButton type="button" onClick={handleSavePlan}>
            SALVAR
          </StyledButton>
        </DefaultCreationFormGroup>
      </DefaultCreationForm>
    </Container>
  );
};

export default CreatePlan;
