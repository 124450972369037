import { FC, useEffect, useRef, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import BreadCrumb from '../../../components/BreadCrumb';
import DefaultButton from '../../../components/DefaultButton';
import DefaultCreationForm, { DefaultCreationFormButtonGroup } from '../../../components/DefaultCreationForm';
import DefaultInput from '../../../components/DefaultInput';
import { DefaultPageTitle } from '../../../components/DefaultPageTitle';
import { DefaultTextArea } from '../../../components/DefaultTextArea';
import { IFaqDto } from '../../../models/faq';
import { getFaq, updateFaq } from '../../../services/faq';
import { DefaultCreationFormGroup } from '../../ContentPrerequisite/styles';
import { Container } from './style';

export const UpdateFaq: FC = () => {
  const history = useHistory();
  const { faq_id } = useParams<{ faq_id: string }>();

  const [faq, setFaq] = useState<IFaqDto>();

  const questionInputRef = useRef<HTMLInputElement>(null);
  const positionInputRef = useRef<HTMLInputElement>(null);
  const answerInputRef = useRef<HTMLTextAreaElement>(null);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    updateFaq(faq_id, {
      question: questionInputRef.current?.value.trim()!,
      answer: answerInputRef.current?.value.trim()!,
      position: +positionInputRef.current?.value!,
    })
      .then((data) => {
        Swal.fire({
          icon: 'success',
          title: 'Sucesso!',
          text: 'Faq atualizada com sucesso!',
        });
        history.push('/faqs');
      })
      .catch((e) => {
        Swal.fire({
          icon: 'error',
          title: 'Erro',
          text: `Erro ao atualizar FAQ. ${e?.response?.data?.message || e.message || 'Ocorreu um erro inesperado'}`,
        });
      });
  };

  useEffect(() => {
    if (faq_id) {
      getFaq(faq_id).then((faq) => {
        setFaq(faq);
      });
    }
  }, [faq_id]);

  return (
    <Container>
      <BreadCrumb crumbs={[<Link to="/dashboard">Dashboard</Link>, <Link to="/faqs">FAQs</Link>, <span>criar</span>]} />

      <DefaultPageTitle>Criar FAQ</DefaultPageTitle>
      <DefaultCreationForm onSubmit={handleSubmit}>
        <DefaultCreationFormGroup>
          <label className="required" htmlFor="question">
            Pergunta
          </label>
          <DefaultInput
            ref={questionInputRef}
            defaultValue={faq?.question}
            type="text"
            id="question"
            required
            maxLength={250}
          />
        </DefaultCreationFormGroup>

        <DefaultCreationFormGroup>
          <label className="required" htmlFor="position">
            Posição
          </label>
          <DefaultInput
            defaultValue={faq?.position}
            ref={positionInputRef}
            type="number"
            id="position"
            required
            maxLength={250}
          />
        </DefaultCreationFormGroup>

        <DefaultCreationFormGroup>
          <label htmlFor="answer" className="required">
            Resposta
          </label>
          <DefaultTextArea defaultValue={faq?.answer} ref={answerInputRef} id="answer" required />
        </DefaultCreationFormGroup>

        <DefaultCreationFormButtonGroup>
          <DefaultButton
            type="button"
            className="danger"
            onClick={() => {
              history.push('/faqs');
            }}
          >
            Cancelar
          </DefaultButton>
          <DefaultButton className="success">Salvar</DefaultButton>
        </DefaultCreationFormButtonGroup>
      </DefaultCreationForm>
    </Container>
  );
};
