import { useEffect, useMemo, useState } from 'react';
import Select, { OptionsType } from 'react-select';
import { Link } from 'react-router-dom';
import { BiTrash, BiEdit } from 'react-icons/bi';

import DefaultButton from '../../components/DefaultButton';

import BreadCrumb from '../../components/BreadCrumb';
import { DefaultPageTitle } from '../../components/DefaultPageTitle';
import DefaultTable from '../../components/DefaultTable';
import Pagination from '../../components/Pagination';
import Content from '../../models/content';

import {
  Container,
  SelectsContainer,
  SelectContainer,
  ListContainer,
  DefaultCreationFormGroup,
  NumberInput,
} from './styles';
import {
  createNewPrerequisite,
  deletePrerequisite,
  getPrerequisites,
  Prerequisite,
  updatePrerequisite,
} from '../../services/contentPrerequisite';
import Swal from 'sweetalert2';
import { getAllContents } from '../../services/contents';

interface SelectProps {
  label: string;
  value: string;
}

interface PrerequisiteWithName extends Prerequisite {
  contentName: string;
  contentPrerequisiteName: string;
}

const LIMIT_REGISTERS_IN_PAGE = 20;

export default function ContentPrerequisite() {
  const [contentsWithPrerequisite, setContentsWithPrerequisite] = useState<PrerequisiteWithName[]>([]);
  const [contentsOptions, setContentsOptions] = useState<OptionsType<SelectProps>>([]);
  const [contentsOptionsToPrerequisite, setContentsOptionsToPrerequisite] = useState<OptionsType<SelectProps>>([]);

  const [contentSelected, setContentSelected] = useState<SelectProps | null>(null);
  const [contentPrerequisiteSelected, setContentPrerequisiteSelected] = useState<SelectProps | null>(null);

  const [prerequisiteToEditId, setPrerequisiteToEditId] = useState('');
  const [contentSelectedDisabled, setContentSelectedDisabled] = useState(false);

  const [paginationState, setPaginationState] = useState({ page: 1, length: 0, showPage: true });

  const [requiredPercentage, setRequiredPercentage] = useState<number>(0);

  const clearStates = async (reload?: boolean) => {
    setContentSelected(null);
    setContentPrerequisiteSelected(null);
    setRequiredPercentage(0);
    if (reload) await getOptions();
  };

  const handleAddNewPrerequisite = async () => {
    try {
      if (!contentSelected) {
        throw new Error('Selecione um conteúdo para ter um pré requisito!');
      }

      if (!contentPrerequisiteSelected) {
        throw new Error('Selecione um conteúdo como pré requisito!');
      }

      if (!requiredPercentage) {
        throw new Error('Selecione uma porcentagem mínima de conclusão para um pré requisito!');
      } else if (requiredPercentage < 1) {
        throw new Error('Selecione uma porcentagem mínima de conclusão para um pré requisito acima de 0%!');
      } else if (requiredPercentage > 100) {
        throw new Error('Selecione uma porcentagem mínima de conclusão para um pré requisito abaixo de 101%!');
      }

      await createNewPrerequisite(contentSelected.value, contentPrerequisiteSelected.value, requiredPercentage);
      await clearStates(true);

      Swal.fire({
        title: 'Restrição criada com sucesso!',
        icon: 'success',
      });
    } catch (err: any) {
      Swal.fire({
        title: 'Ocorreu um erro ao criar restrição',
        text: err?.response?.data?.message || err.message,
        icon: 'error',
      });
    }
  };

  const handleUpdatePrerequisite = async (prerequisite: PrerequisiteWithName) => {
    document.getElementById(`page-title`)?.scrollIntoView({ behavior: 'smooth' });

    setContentSelected({ value: prerequisite.content_id, label: prerequisite.contentName });
    setContentPrerequisiteSelected({
      value: prerequisite.content_prerequisite_id,
      label: prerequisite.contentPrerequisiteName,
    });
    setContentSelectedDisabled(true);
    setPrerequisiteToEditId(prerequisite.prerequisite_id);
  };

  const requestUpdatePrerequisite = async () => {
    try {
      if (!contentPrerequisiteSelected) {
        throw new Error('Selecione uma  restrição do conteúdo!');
      }

      if (!requiredPercentage) {
        throw new Error('Selecione uma porcentagem mínima de conclusão para um pré requisito!');
      } else if (requiredPercentage < 1) {
        throw new Error('Selecione uma porcentagem mínima de conclusão para um pré requisito maior que 1%!');
      } else if (requiredPercentage > 100) {
        throw new Error('Selecione uma porcentagem mínima de conclusão para um pré requisito menor que 100%!');
      }

      await updatePrerequisite(prerequisiteToEditId, contentPrerequisiteSelected.value, requiredPercentage);
      clearStates(true);

      Swal.fire({
        title: 'Restrição atualizada com sucesso!',
        icon: 'success',
      });
    } catch (error: any) {
      Swal.fire({
        title: 'Ocorreu um erro ao tentar atualizar pré requisito',
        text: error?.response?.data?.message || error?.message,
        icon: 'error',
      });
    }
  };

  const handleDeletePrerequisite = async (prerequisiteId: string) => {
    try {
      const result = await Swal.fire({
        title: 'Deseja realmente deletar este pré requisito?',
        icon: 'info',
        showCancelButton: true,
      });

      if (result.isDenied || result.isDismissed) {
        clearStates();
        return;
      }

      await deletePrerequisite(prerequisiteId);
      clearStates(true);

      Swal.fire({
        title: 'Pré requisito deletado com sucesso.',
        icon: 'success',
      });
    } catch {
      Swal.fire({
        title: 'Ocorreu um erro ao tentar deletar este pré requisito.',
        icon: 'error',
      });
    }
  };

  const getOptions = async (limit?: number, offset?: number) => {
    try {
      const response = await getPrerequisites(limit, offset);

      const { data: prerequisites, total } = response.data;

      const contentsWithPrerequisitesIds = prerequisites.map((prerequisite) => prerequisite.content_id);

      const { data: contents } = await getAllContents({
        flag: ['course', 'retreat'],
        type: ['on-demand', 'curso', 'retiro'],
      });

      const contentsOptionsFormatted = contents.map((content: Content) => ({
        value: content.content_id,
        label: content.name,
      }));

      const contentsOptionsUnused = contentsOptionsFormatted.filter(
        (contentOption: SelectProps) => !contentsWithPrerequisitesIds.includes(contentOption.value)
      );
      setContentsOptions(contentsOptionsUnused);

      setContentsOptionsToPrerequisite(contentsOptionsFormatted);

      const contentsWithPrerequisiteRelated = prerequisites.map((prerequisite) => {
        const contentRelatedToCurrentPrerequisite = contents.find(
          (contentOption: Content) => contentOption.content_id === prerequisite.content_id
        );

        const contentPrerequisiteRelatedToCurrentPrerequisite = contents.find(
          (contentOption: Content) => contentOption.content_id === prerequisite.content_prerequisite_id
        );

        return {
          ...prerequisite,
          contentPrerequisiteName: contentPrerequisiteRelatedToCurrentPrerequisite.name,
          contentName: contentRelatedToCurrentPrerequisite.name,
        };
      });

      setContentsWithPrerequisite(contentsWithPrerequisiteRelated);
      setPaginationState((state) => ({ ...state, length: Math.ceil(total / LIMIT_REGISTERS_IN_PAGE), showPage: true }));
    } catch {
      Swal.fire({
        title: 'Ocorreu um erro ao buscar os conteúdos, tente novamente.',
        icon: 'error',
      });
    }
  };

  const contentsToBeShown = useMemo(() => {
    return contentsWithPrerequisite.map((prerequisite) => ({
      contentName: prerequisite.contentName,
      contentPrerequisiteName: prerequisite.contentPrerequisiteName,
      requiredPercentage: prerequisite.required_percentage ? prerequisite.required_percentage + '%' : 'Não informado',
      actions: (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'end', gap: 12 }}>
          <DefaultButton className="info" onClick={() => handleUpdatePrerequisite(prerequisite)}>
            <BiEdit size={24} />
          </DefaultButton>

          <DefaultButton className="danger" onClick={() => handleDeletePrerequisite(prerequisite.prerequisite_id)}>
            <BiTrash size={24} color="white" />
          </DefaultButton>
        </div>
      ),
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentsWithPrerequisite]);

  useEffect(() => {
    if (!contentPrerequisiteSelected) {
      setContentSelectedDisabled(false);
      setContentSelected(null);
      setPrerequisiteToEditId('');
    }
  }, [contentPrerequisiteSelected]);

  useEffect(() => {
    const offset = paginationState.page > 1 ? (paginationState.page - 1) * LIMIT_REGISTERS_IN_PAGE : undefined;
    getOptions(LIMIT_REGISTERS_IN_PAGE, offset);
  }, [paginationState.page]);

  return (
    <Container>
      <BreadCrumb crumbs={[<Link to="/dashboard">Dashboard</Link>, <span>Restrição Intercursos</span>]} />

      <DefaultPageTitle id="page-title">Restrição Intercursos</DefaultPageTitle>

      <SelectsContainer>
        <SelectContainer>
          <label htmlFor="content">Conteúdo com restrição</label>
          <Select
            id="content"
            options={contentsOptions}
            value={contentSelected}
            onChange={(option) => setContentSelected(option!)}
            placeholder="Selecione um conteúdo"
            noOptionsMessage={() => 'Sem conteúdos disponíveis'}
            isClearable
            isDisabled={contentSelectedDisabled}
          />
        </SelectContainer>

        <SelectContainer>
          <label htmlFor="content">Restrição do conteúdo</label>
          <Select
            id="content-prerequisite"
            options={contentsOptionsToPrerequisite}
            value={contentPrerequisiteSelected}
            onChange={(option) => setContentPrerequisiteSelected(option!)}
            placeholder="Selecione um conteúdo como dependência"
            noOptionsMessage={() => 'Sem conteúdos disponíveis'}
            isClearable
          />
        </SelectContainer>
      </SelectsContainer>

      <SelectsContainer>
        <DefaultCreationFormGroup>
          <label htmlFor="requiredPercentage">Porcentagem Mínima de Conclusão (%)</label>
          <NumberInput
            value={requiredPercentage}
            onChange={(e) => setRequiredPercentage(parseInt(e.target.value))}
            id="requiredPercentage"
            type="number"
          />
        </DefaultCreationFormGroup>

        <DefaultButton
          className="success"
          onClick={prerequisiteToEditId ? requestUpdatePrerequisite : handleAddNewPrerequisite}
        >
          {prerequisiteToEditId ? 'Atualizar' : 'Salvar'}
        </DefaultButton>
      </SelectsContainer>

      <ListContainer>
        <h1>Conteúdos com restrição</h1>

        <DefaultTable
          headersConfig={[
            {
              headerLabel: <span>Conteúdo</span>,
              propName: 'contentName',
            },
            {
              headerLabel: <span>Conteúdo de restrição</span>,
              propName: 'contentPrerequisiteName',
            },
            {
              headerLabel: <span>%</span>,
              propName: 'requiredPercentage',
            },
            {
              headerLabel: <span>Ações</span>,
              propName: 'actions',
            },
          ]}
          items={contentsToBeShown}
          emptyListMessage="Não foram encontrados conteúdos cadastrados!"
        />

        {paginationState.showPage ? (
          <Pagination
            totalPages={paginationState.length}
            setPage={(page) => setPaginationState((state) => ({ ...state, page }))}
          />
        ) : null}
      </ListContainer>
    </Container>
  );
}
