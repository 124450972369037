import styled from 'styled-components';
import DefaultButton from '../../components/DefaultButton';

export const Container = styled.div``;

export const SelectContainer = styled.div`
  width: 100%;

  label {
    min-width: 145px;
    margin-bottom: 10px;
    font-size: var(--font-small);
    color: var(--primary-color);
    font-weight: 700;
  }

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  & > div {
    width: 100%;
  }
`;

export const Switch = styled.label`
  position: relative;
  display: inline-block;
  min-width: 60px !important;
  height: 34px;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: '';
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: #594aad;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #2196f3;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
`;

export const ThumbnailUploadContainer = styled.div`
  border-radius: 5px;
  border: solid 1px var(--default-dark-gray);
  padding: 10px 15px;
  width: 100%;

  img {
    margin-top: 10px;
  }

  div {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 10px;
  }

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const CourseThumbnail = styled.img`
  max-width: 600px;
`;

export const LessonSelectionContainer = styled.div`
  width: 100%;
`;

export const SortLessonsContainer = styled.div`
  margin-bottom: 40px;
  width: 100%;
  & .selected-lessons {
    width: 100%;
    border-radius: 4px;
    border: solid 1px var(--default-dark-gray);
    padding: 5px;

    & > .buttons {
      svg {
        cursor: pointer;
      }

      button {
        svg {
          display: block;
        }
        &.down {
          svg {
            fill: var(--danger-color);
          }
        }
        &.up {
          svg {
            fill: var(--success-color);
          }
        }
      }

      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 5px;
    }

    & > .lesson-title {
      flex-grow: 1;
      color: var(--primary-color);
      font-size: var(--font-small);
      font-weight: 600;
    }

    & > :not(:last-child) {
      margin-right: 10px;
    }

    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  & > div:not(:last-child) {
    margin-bottom: 10px;
  }

  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
`;

export const SwitchersContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: center;
`;

export const StyledButton = styled(DefaultButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.4rem !important;
  margin: 0.2rem 0;
  transition: filter 0.2s;
  width: 100px;

  &:hover {
    filter: brightness(0.9);
  }
`;

export const ContentUploadOrSelectContainer = styled.div`
  border-radius: 5px;
  border: solid 1px var(--default-dark-gray);
  padding: 10px 15px;
  width: 100%;

  iframe {
    margin-top: 10px;
    width: 600px;
    height: 340px;
  }

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const ButtonGroupInline = styled.div`
  display: flex;
  align-items: center;

  button + button {
    margin-left: 10px;
  }
`;
