import styled from 'styled-components';

const Container = styled.div``;

const TextArea = styled.textarea`
  width: 100%;
  height: 150px;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  font-size: 16px;
  resize: none;
`;

const SelectContainer = styled.div`
  width: 100%;

  label {
    min-width: 145px;
    margin-bottom: 10px;
    font-size: var(--font-small);
    color: var(--primary-color);
    font-weight: 700;
  }

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  & > div {
    width: 100%;
  }
`;

export { Container, TextArea, SelectContainer };
