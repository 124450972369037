import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px;
`;

export const LessonSelectionContainer = styled.div`
  width: 100%;
`;

export const SortLessonsContainer = styled.div`
  margin-bottom: 40px;
  width: 100%;
  & .selected-lessons {
    width: 100%;
    border-radius: 4px;
    border: solid 1px var(--default-dark-gray);
    padding: 5px;

    & > .buttons {
      svg {
        cursor: pointer;
      }

      button {
        svg {
          display: block;
        }
        &.down {
          svg {
            fill: var(--danger-color);
          }
        }
        &.up {
          svg {
            fill: var(--success-color);
          }
        }
      }

      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 5px;
    }

    & > .lesson-title {
      flex-grow: 1;
      color: var(--primary-color);
      font-size: var(--font-small);
      font-weight: 600;
    }

    & > :not(:last-child) {
      margin-right: 10px;
    }

    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  & .new-lesson {
    border-color: #acc32b;
  }

  & > div:not(:last-child) {
    margin-bottom: 10px;
  }

  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
`;
