import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import 'react-markdown-editor-lite/lib/index.css';
import { EditorState } from 'draft-js';
import Swal from 'sweetalert2';

import BreadCrumb from '../../components/BreadCrumb';
import DefaultCreationForm, {
  DefaultCreationFormButtonGroup,
  DefaultCreationFormGroup,
} from '../../components/DefaultCreationForm';
import { DefaultPageTitle } from '../../components/DefaultPageTitle';
import DefaultButton from '../../components/DefaultButton';
import getErrorMessage from '../../helpers/get-error-message';
import { createTermsAndPrivacyPolicy, getPageContent, updateTermsAndPrivacyPolicy } from '../../services/terms';
import { DescriptionTextarea, getCleanHtml } from './Editor';
import checkEmptyString from '../../helpers/check-empty-string';

import { TermsContainer } from './styles';

const Terms: React.FC = () => {
  const [editorTermsState, setEditorTermsState] = useState(EditorState.createEmpty());
  const [editorPrivacyState, setEditorPolicyState] = useState(EditorState.createEmpty());
  const [pageId, setPageId] = useState('');

  const [termsText, setTermsText] = useState('');
  const [privacyText, setPrivacyText] = useState('');

  const cleanTermsHTML = getCleanHtml(editorTermsState);
  const cleanPrivacyHTML = getCleanHtml(editorPrivacyState);

  const updateTerms = async (event: React.FormEvent) => {
    if (event.preventDefault) {
      event.preventDefault();
    }

    if (checkEmptyString(cleanTermsHTML) || checkEmptyString(cleanPrivacyHTML)) {
      throw new Error('Preencha todos os campos.');
    }

    try {
      if (pageId) {
        await updateTermsAndPrivacyPolicy(pageId, cleanTermsHTML, cleanPrivacyHTML);
      } else {
        await createTermsAndPrivacyPolicy(cleanTermsHTML, cleanPrivacyHTML);
      }

      Swal.fire({
        title: 'Sucesso!',
        text: `Atualizado com sucesso!`,
        icon: 'success',
      });
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      Swal.fire({ icon: 'error', text: 'Erro ao alterar termos de uso e politica de privacidade. ' + errorMessage });
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const localTerms = await getPageContent();

        if (localTerms) {
          setTermsText(localTerms.info.termsOfUse || '');
          setPrivacyText(localTerms.info.privacyPolicy || '');
          setPageId(localTerms.home_id);
        }
      } catch (error) {
        Swal.fire({ icon: 'error', text: 'Erro ao recuperar os termos de uso e política de privacidade.' });
      }
    })();
  }, []);

  return (
    <TermsContainer>
      <BreadCrumb crumbs={[<Link to="/dashboard">Dashboard</Link>, <span>Termos de uso</span>]} />

      <DefaultPageTitle>Termos de uso</DefaultPageTitle>

      <DefaultCreationForm onSubmit={updateTerms}>
        <DefaultCreationFormGroup>
          <label className="required" htmlFor="reference">
            Termos de Uso
          </label>
          <DescriptionTextarea
            description={termsText}
            editorState={editorTermsState}
            setEditorState={setEditorTermsState}
          />
        </DefaultCreationFormGroup>

        <DefaultCreationFormGroup style={{ marginTop: '24px' }}>
          <label className="required" htmlFor="reference">
            Política de Privacidade
          </label>

          <DescriptionTextarea
            description={privacyText}
            editorState={editorPrivacyState}
            setEditorState={setEditorPolicyState}
          />
        </DefaultCreationFormGroup>

        <DefaultCreationFormButtonGroup>
          <DefaultButton className="success">Salvar</DefaultButton>
        </DefaultCreationFormButtonGroup>
      </DefaultCreationForm>
    </TermsContainer>
  );
};

export default Terms;
