import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import BreadCrumb from '../../components/BreadCrumb';
import { DefaultPageTitle } from '../../components/DefaultPageTitle';
import { listPlans } from '../../services/plans';
import PlanTable from './components/PlanTable';

import { Container } from './styles';

export interface TablePlan {
  id: string;
  title: string;
  value: string;
  totalValue?: string;
  period: number;
  discount: number;
  type?: number;
  isRecommended?: boolean;
  is_active: boolean;
}

const Plans: React.FC = () => {
  const [plans, setPlans] = useState<TablePlan[]>([]);

  const fetchPlans = async () => {
    try {
      const response = await listPlans();

      setPlans(
        response.map((plan) => ({
          id: plan.plan_id,
          title: plan.title,
          totalValue: plan?.full_price?.toFixed(2).replace('.', ',') || '',
          value: plan.price.toFixed(2).replace('.', ','),
          discount: plan.discount,
          period: plan.period_in_days,
          type: plan.info.type,
          is_active: plan.is_active,
        }))
      );
    } catch (error: any) {
      Swal.fire({
        title: 'Erro!',
        text: `Houve um problema ao recuperar os planos! ${
          error?.response?.data?.message || error.message || 'Ocorreu um erro inesperado'
        }`,
        icon: 'error',
      });
    }
  };

  const updatePlans = (planId: string, condition: boolean) => {
    setPlans((state) => state.map((plan) => (plan.id !== planId ? plan : { ...plan, is_active: condition })));
  };

  useEffect(() => {
    fetchPlans();
  }, []);

  return (
    <Container>
      <BreadCrumb crumbs={[<Link to="/dashboard">Dashboard</Link>, <span>Planos</span>]} />

      <DefaultPageTitle>Planos</DefaultPageTitle>

      <PlanTable
        info={{
          planPeriod: 'mensal',
          tableTitle: 'mensais',
        }}
        plans={plans.filter((plan) => plan.period === 30)}
        updatePlans={updatePlans}
      />

      <PlanTable
        info={{
          planPeriod: 'trimestral',
          tableTitle: 'trimestrais',
        }}
        plans={plans.filter((plan) => plan.period === 90)}
        updatePlans={updatePlans}
      />

      <PlanTable
        info={{
          planPeriod: 'semestral',
          tableTitle: 'semestrais',
        }}
        plans={plans.filter((plan) => plan.period === 180)}
        updatePlans={updatePlans}
      />

      <PlanTable
        info={{
          planPeriod: 'anual',
          tableTitle: 'anuais',
        }}
        plans={plans.filter((plan) => plan.period === 365)}
        updatePlans={updatePlans}
      />
    </Container>
  );
};

export default Plans;
