import httpClient from '../http-client';

export interface Plan {
  plan_id: string;
  title: string;
  period_in_days: number;
  price: number;
  full_price?: number;
  discount: number;
  is_active: boolean;
  benefits: {
    label: string;
    active: boolean;
  }[];
  info: {
    type?: number;
    isRecommended?: boolean;
    max_installments?: number;
  };
  vindi_product_id?: string;
  vindi_plan_id?: string;
  created_at?: string;
  updated_at?: string;
  deleted_at?: string;
}

const listPlans = async () => {
  return httpClient.get<Plan[]>('/plans').then((res) => res.data);
};

const getPlan = async (planId: string) => {
  return httpClient.get(`/plans/${planId}`).then((res) => res.data);
};

const inactivatePlan = async (planId: string, condition: boolean) => {
  return httpClient
    .patch(`/plans/${planId}/status`, {
      is_active: condition,
    })
    .then((res) => res.data);
};

const createPlan = async (data: Omit<Plan, 'plan_id'>) => {
  return httpClient.post('/plans', data).then((res) => res.data);
};

const updatePlan = async (planId: string, data: Omit<Plan, 'plan_id'>) => {
  return httpClient.put(`/plans/${planId}`, data).then((res) => res.data);
};

export { listPlans, getPlan, inactivatePlan, createPlan, updatePlan };
