import { IListParamsDTO } from './pagination';

export enum CouponTypes {
  PERCENTAGE = 'percentage',
  AMOUNT = 'amount',
}

export enum CouponTargetTypes {
  CONTENT = 'content',
}

export interface ICouponApplicationDTO {
  coupon_application_id: string;
  coupon_target_type: CouponTargetTypes;
  coupon_id: string;
  target_id?: string | null;
  apply_to_all: boolean;
  created_at: Date;
  updated_at: Date;
  deleted_at?: Date | null;
}

export interface ICouponDTO {
  coupon_id: string;
  coupon_type: CouponTypes;
  code: string;
  quantity?: number | null;
  usage?: any[];
  is_active: boolean;
  amount?: number | null;
  percentage?: number | null;
  start_at: Date | string | null;
  finish_at?: Date | string | null;
  applications: ICouponApplicationDTO[];
  created_at: Date | string;
  updated_at: Date | string;
  deleted_at?: Date | string | null;
}

export interface ICreateCouponApplicationDTO {
  coupon_target_type: CouponTargetTypes;
  coupon_id?: string;
  target_id?: string | null;
  apply_to_all?: boolean;
}

export interface ICreateCouponDTO {
  coupon_type: CouponTypes;
  code?: string;
  quantity?: number | null;
  amount?: number | null;
  percentage?: number | null;
  start_at?: Date | null;
  finish_at?: Date | null;
  created_at?: Date;
  updated_at?: Date;
  deleted_at?: Date | null;
  applications: ICreateCouponApplicationDTO[];
}

export interface IUpdateCouponDTO extends Omit<ICreateCouponDTO, 'applications'> {
  include_applications: ICreateCouponApplicationDTO[];
  exclude_application_ids: string[];
}

export interface IListCouponsDTO extends IListParamsDTO {
  search?: string;
}

export interface IGetCouponDTO {
  code?: string;
  coupon_id?: string;
}
