import { formatISO } from 'date-fns';

export function convertToDateBR(date?: Date | string | null) {
  if (!date) return null;
  const dateFormatter = new Intl.DateTimeFormat('pt-BR', { timeZone: 'utc' });
  return dateFormatter.format(new Date(date));
}

export function toUTCDate(date?: Date | string | null) {
  if (!date) return undefined;
  return formatISO(new Date(date), { representation: 'date' });
}

export function oneMinToMidNight(date?: Date | string | null){
  if(!date) return undefined;

  const d = new Date(date);

  d.setHours(23, 59, 59)

  return d;
}
