import convertPaymentType from './convertPaymentType';
import { format, isPast } from 'date-fns';
import { UserPlans } from '..';
import { IUser } from '../../../../models/from-api-response/user';

type IPlanPaid = IUser['plans_paid'][0];

function planToUserPlans(plansPaid: IPlanPaid[]): Omit<UserPlans, 'actions'>[] {
  return plansPaid.map((planPaid) => ({
    user_payment_id: planPaid.user_payment_id,
    plan_id: planPaid.plan.plan_id,
    title: planPaid.plan.title,
    acquiredBy: convertPaymentType(planPaid.payment_type),
    price: planPaid.price || planPaid.plan.price,
    overdue: isPast(new Date(planPaid.finish_at)),
    status: [],
    dateStart: format(new Date(planPaid.start_at), 'dd/MM/yyyy'),
    dateLimit: planPaid.finish_at ? format(new Date(planPaid.finish_at), 'dd/MM/yyyy') : 'Ilimitado',
    cycle: 0,
  }));
}

export default planToUserPlans;
