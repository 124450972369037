import { OptionsType } from 'react-select';

interface NotificationFormState {
  title: string;
  description: string;
  plans: Plan[];
  selectedPlan: OptionsType<Plan>;
  courses: Course[];
  selectedCourse: OptionsType<Course>;
}

type NotificationFormAction =
  | ActionUpdateTitle
  | ActionUpdateDescription
  | ActionsUpdatePlan
  | ActionGetAllPlans
  | ActionGetAllCourses
  | ActionsUpdateCourse;

export enum ActionTypes {
  UPDATE_TITLE,
  UPDATE_DESCRIPTION,
  GET_ALL_PLANS,
  UPDATE_PLAN,
  GET_ALL_COURSES,
  UPDATE_COURSE,
}

type Plan = {
  label: string;
  value: string;
};

type ActionUpdateTitle = {
  type: ActionTypes.UPDATE_TITLE;
  payload: string;
};

type ActionUpdateDescription = {
  type: ActionTypes.UPDATE_DESCRIPTION;
  payload: string;
};

type ActionGetAllPlans = {
  type: ActionTypes.GET_ALL_PLANS;
  payload: Plan[];
};

type ActionsUpdatePlan = {
  type: ActionTypes.UPDATE_PLAN;
  payload: OptionsType<Plan>;
};

type ActionGetAllCourses = {
  type: ActionTypes.GET_ALL_COURSES;
  payload: Course[];
};

type ActionsUpdateCourse = {
  type: ActionTypes.UPDATE_COURSE;
  payload: OptionsType<Course>;
};

type Course = {
  label: string;
  value: string;
};

export const notificationFormReducer = (state: NotificationFormState, action: NotificationFormAction) => {
  switch (action.type) {
    case ActionTypes.UPDATE_TITLE:
      return {
        ...state,
        title: action.payload,
      };
    case ActionTypes.UPDATE_DESCRIPTION:
      return {
        ...state,
        description: action.payload,
      };
    case ActionTypes.UPDATE_PLAN:
      return {
        ...state,
        selectedPlan: action.payload,
      };
    case ActionTypes.GET_ALL_PLANS:
      return {
        ...state,
        plans: action.payload,
      };
    case ActionTypes.GET_ALL_COURSES:
      return {
        ...state,
        courses: action.payload,
      };
    case ActionTypes.UPDATE_COURSE:
      return {
        ...state,
        selectedCourse: action.payload,
      };
    default:
      return state;
  }
};
