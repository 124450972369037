import React, { useCallback, useRef, useState } from 'react';
import Swal from 'sweetalert2';
import Cropper from 'react-easy-crop';
import { Point } from 'react-easy-crop/types';
import Slider from '@material-ui/core/Slider';

import getCroppedImg from '../../helpers/cropImage';
import { hideModal } from '../../helpers/modal';
import DefaultButton from '../DefaultButton';

import {
  CutImageContainer,
  ImageCutContainer,
  SelectImageContainer,
  CropContainer,
  Buttons,
  SliderContainer,
} from './style';

interface CutImageProps {
  onCutImage: (file: File) => void;
  aspectX: number;
  aspectY: number;
  modalId?: string;
}

const CutImage: React.FC<CutImageProps> = ({ onCutImage, aspectX, aspectY, modalId }) => {
  const [initialThumbnailSrc, setInitialThumbnailSrc] = useState('');

  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState({});

  const fileInput = useRef<HTMLInputElement>(null);

  const activeFileInput = () => {
    if (fileInput.current) {
      fileInput.current.click();
    }
  };

  const handleFile = (event: React.FormEvent<HTMLInputElement>) => {
    event.preventDefault();

    try {
      if (event.currentTarget) {
        let file = event.currentTarget.files && event.currentTarget.files.length ? event.currentTarget.files[0] : null;

        if (file) {
          let fr = new FileReader();

          fr.onloadend = () => {
            setInitialThumbnailSrc(`${fr.result}`);
          };

          fr.readAsDataURL(file);
        }
      }
    } catch (e) {
      Swal.fire({
        title: 'Erro',
        text: 'Ocorreu um erro ao carregar a imagem selecionada!',
        icon: 'error',
      });
    }
  };

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const selectImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(initialThumbnailSrc, croppedAreaPixels);

      onCutImage(croppedImage);
      hideModal(modalId);
    } catch (e) {
      Swal.fire({
        title: 'Erro!',
        text: 'Houve um problema durante o corte da imagem!',
        timer: 4000,
      });
    }
  }, [croppedAreaPixels, initialThumbnailSrc, onCutImage, modalId]);

  return (
    <CutImageContainer>
      <SelectImageContainer>
        {!initialThumbnailSrc && (
          <DefaultButton type="button" onClick={activeFileInput}>
            Selecionar Imagem
          </DefaultButton>
        )}
        <input
          ref={fileInput}
          style={{ display: 'none' }}
          accept=".bmp, .jpg, .jpeg, .png"
          type="file"
          onChange={handleFile}
        />
      </SelectImageContainer>
      <ImageCutContainer>
        {initialThumbnailSrc ? (
          <>
            <CropContainer>
              <Cropper
                image={initialThumbnailSrc}
                crop={crop}
                zoom={zoom}
                aspect={aspectX / aspectY}
                onCropChange={setCrop}
                onZoomChange={setZoom}
                onCropComplete={onCropComplete}
              />
            </CropContainer>

            <Buttons>
              <DefaultButton type="button" onClick={activeFileInput}>
                Selecionar Imagem
              </DefaultButton>
              <DefaultButton className="success" type="button" onClick={selectImage}>
                Cortar Imagem
              </DefaultButton>
              <DefaultButton className="danger" type="button" onClick={() => hideModal(modalId)}>
                Fechar
              </DefaultButton>
            </Buttons>

            <SliderContainer>
              <label htmlFor="slider">Zoom</label>
              <Slider
                id="slider"
                value={zoom}
                min={1}
                max={3}
                step={0.1}
                aria-labelledby="Zoom"
                onChange={(e, zoom) => setZoom(Number(zoom))}
              />
            </SliderContainer>
          </>
        ) : null}
      </ImageCutContainer>
    </CutImageContainer>
  );
};

export default CutImage;
