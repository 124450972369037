import styled from 'styled-components';

export const CreateCouponPageContainer = styled.div``;

export const CreateCouponForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 12px;

  #date-limites {
    display: flex;
    align-items: center;
    gap: 24px;
  }

  .explain {
    font-weight: normal;
    color: rgba(0, 0, 0, 0.25);
  }

  .show-false {
    display: none !important;
  }
`;

export const SelectContainer = styled.div`
  width: 100%;

  label {
    min-width: 145px;
    margin-bottom: 10px;
    font-size: var(--font-small);
    color: var(--primary-color);
    font-weight: 700;
  }

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  & > div {
    width: 100%;
  }
`;

export const CouponTypeContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  gap: 64px;

  label {
    font-weight: 700;
    font-size: var(--font-small);
    display: flex;
    align-items: center;
    gap: 8px;
    color: var(--primary-color);
    margin-bottom: 10px;
    cursor: pointer;
  }
`;
