import React, { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';

import BreadCrumb from '../../components/BreadCrumb';
import DefaultButton from '../../components/DefaultButton';
import DefaultCreationForm, {
  DefaultCreationFormButtonGroup,
  DefaultCreationFormGroup,
} from '../../components/DefaultCreationForm';
import DefaultInput from '../../components/DefaultInput';
import { DefaultPageTitle } from '../../components/DefaultPageTitle';
import { DefaultTextArea } from '../../components/DefaultTextArea';
import checkEmptyString from '../../helpers/check-empty-string';
import {
  createTag as createTagService,
  updateTag as updateTagService,
  getTag as getTagService,
} from '../../services/tags';

import { CreateAndEditCategoryContainer } from './styles';

interface CreateAndEditTagProps {
  tagId: string;
}

const CreateAndEditTag: React.FC = () => {
  const { tagId } = useParams<CreateAndEditTagProps>();

  const history = useHistory();

  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');

  const createTag = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      if (checkEmptyString(title)) {
        throw new Error('Informe um título válido para a categoria.');
      }

      await createTagService({
        title: title,
        description: description.trim().length ? description : undefined,
      });

      Swal.fire({
        title: 'Sucesso!',
        text: 'Tag criada com sucesso!',
        icon: 'success',
      });

      goToTags();
    } catch (error: any) {
      Swal.fire({
        title: 'Erro',
        text: `Houve um erro ao criar a tag.${
          error?.response?.data?.message || error.message || 'Ocorreu um erro inesperado'
        }`,
        icon: 'error',
      });
    }
  };

  const goToTags = () => {
    history.push('/tags');
  };

  const updateDescription = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { target } = event;
    setDescription(target.value);
  };

  const isEditing = (() => {
    if (tagId) {
      return true;
    }

    return false;
  })();

  const updateTag = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      if (checkEmptyString(title)) {
        throw new Error('Informe um título válido para a categoria.');
      }
      await updateTagService(tagId, {
        title: title,
        description: description || '',
      });

      Swal.fire({
        title: 'Sucesso!',
        text: 'Conteúdo editado com sucesso!',
        icon: 'success',
      });

      goToTags();
    } catch (error: any) {
      Swal.fire({
        title: 'Erro',
        text: `Houve um erro ao editar o conteúdo.${
          error?.response?.data?.message || error.message || 'Ocorreu um erro inesperado'
        }`,
        icon: 'error',
      });
    }
  };

  useEffect(() => {
    (async () => {
      try {
        if (tagId) {
          const content = await getTagService(tagId);
          if (content && Object.keys(content).length) {
            setTitle(content.title);
            setDescription(content.description || '');
          }
        }
      } catch (error: any) {
        Swal.fire({
          title: 'Erro',
          text: `Houve um erro ao criar o categoria.${
            error?.response?.data?.message || error.message || 'Ocorreu um erro inesperado'
          }`,
          icon: 'error',
        });
      }
    })();
  }, [tagId]);

  return (
    <CreateAndEditCategoryContainer>
      <BreadCrumb
        crumbs={[
          <Link to="/dashboard">Dashboard</Link>,
          <Link to="/tags">Tags</Link>,
          <span>{isEditing ? 'Editar' : 'Criar'} Tag</span>,
        ]}
      />

      <DefaultPageTitle>{isEditing ? 'Editar' : 'Criar'} Tag</DefaultPageTitle>

      <DefaultCreationForm>
        <DefaultCreationFormGroup>
          <label className="required" htmlFor="title">
            Título
          </label>
          <DefaultInput value={title} onChange={(e) => setTitle(e.target.value)} id="title" required maxLength={250} />
        </DefaultCreationFormGroup>

        <DefaultCreationFormGroup>
          <label htmlFor="description">Descrição</label>
          <DefaultTextArea value={description || ''} onChange={updateDescription} id="description" />
        </DefaultCreationFormGroup>

        <DefaultCreationFormButtonGroup>
          <DefaultButton type="button" className="danger" onClick={goToTags}>
            Cancelar
          </DefaultButton>
          <DefaultButton onClick={(e) => (isEditing ? updateTag(e) : createTag(e))} className="success">
            Salvar
          </DefaultButton>
        </DefaultCreationFormButtonGroup>
      </DefaultCreationForm>
    </CreateAndEditCategoryContainer>
  );
};

export default CreateAndEditTag;
