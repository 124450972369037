import { AiOutlineFileDone, AiFillTags } from 'react-icons/ai';
import { BiEdit, BiLink } from 'react-icons/bi';
import { BsReverseLayoutTextWindowReverse, BsReverseLayoutTextSidebarReverse } from 'react-icons/bs';
import { MdAttachMoney, MdDashboard, MdOndemandVideo } from 'react-icons/md';
import { FaQuestionCircle } from 'react-icons/fa';
import { FiUsers } from 'react-icons/fi';
import { ImBooks, ImUser } from 'react-icons/im';
import { RiCoupon3Line, RiLiveFill, RiPlayListAddFill, RiSurveyFill } from 'react-icons/ri';
import { IoIosNotificationsOutline, IoMdBonfire } from 'react-icons/io';
import { MdClass } from 'react-icons/md';
import { FaGamepad, FaBookReader } from 'react-icons/fa';
import { HiOutlineDocumentReport } from 'react-icons/hi';

interface Menu {
  label: JSX.Element;
  path?: string;
  children?: SubMenu[];
  disabled?: boolean;
}

interface SubMenu {
  label: JSX.Element;
  path: string;
}

const menus: Menu[] = [
  {
    label: (
      <>
        <span className="icon">
          <MdDashboard />
        </span>
        <span className="text">Dashboard</span>
      </>
    ),
    path: '/dashboard',
  },
  {
    label: (
      <>
        <span className="icon">
          <RiPlayListAddFill />
        </span>
        <span className="text">Categorias</span>
      </>
    ),
    path: '/categories',
  },
  {
    label: (
      <>
        <span className="icon">
          <FaBookReader />
        </span>
        <span className="text">Autores</span>
      </>
    ),
    path: '/authors',
  },
  {
    label: (
      <>
        <span className="icon">
          <AiFillTags />
        </span>
        <span className="text">Tags</span>
      </>
    ),
    path: '/tags',
  },
  {
    label: (
      <>
        <span className="icon">
          <ImUser />
        </span>
        <span className="text">Mestres Ascencionados</span>
      </>
    ),
    path: '/ascended-masters',
  },
  {
    label: (
      <>
        <span className="icon">
          <AiOutlineFileDone />
        </span>
        <span className="text">Termos de uso e privacidade</span>
      </>
    ),
    path: '/terms-of-use',
  },
  {
    label: (
      <>
        <span className="icon">
          <BiEdit />
        </span>
        <span className="text">Gestão de Conteúdo</span>
      </>
    ),
    children: [
      {
        label: (
          <>
            <span className="icon">
              <BsReverseLayoutTextSidebarReverse />
            </span>
            <span className="text">Página Inicial</span>
          </>
        ),
        path: '/cms/landing-page',
      },
      {
        label: (
          <>
            <span className="icon">
              <BsReverseLayoutTextWindowReverse />
            </span>
            <span className="text">Sobre o Ima</span>
          </>
        ),
        path: '/cms/about-ima',
      },
      {
        label: (
          <>
            <span className="icon">
              <BsReverseLayoutTextWindowReverse />
            </span>
            <span className="text">Sobre H.G.</span>
          </>
        ),
        path: '/cms/about-halu',
      },
      {
        label: (
          <>
            <span className="icon">
              <BsReverseLayoutTextWindowReverse />
            </span>
            <span className="text">Agenda de Conteúdo</span>
          </>
        ),
        path: '/cms/scheduled',
      },
      {
        label: (
          <>
            <span className="icon">
              <BsReverseLayoutTextWindowReverse />
            </span>
            <span className="text">Lançamentos</span>
          </>
        ),
        path: '/cms/next-release',
      },
    ],
  },
  {
    label: (
      <>
        <span className="icon">
          <FiUsers />
        </span>
        <span className="text">Usuários</span>
      </>
    ),
    path: '/users',
  },
  {
    label: (
      <>
        <span className="icon">
          <RiLiveFill />
        </span>
        <span className="text">Lives</span>
      </>
    ),
    path: '/lives',
  },
  {
    label: (
      <>
        <span className="icon">
          <ImBooks />
        </span>
        <span className="text">Cursos</span>
      </>
    ),
    path: '/courses',
  },
  {
    label: (
      <>
        <span className="icon">
          <IoMdBonfire />
        </span>
        <span className="text">Vivências</span>
      </>
    ),
    path: '/retreats',
  },
  {
    label: (
      <>
        <span className="icon">
          <BiLink />
        </span>
        <span className="text">Restrição Intercursos</span>
      </>
    ),
    path: '/content-prerequisites',
  },
  {
    label: (
      <>
        <span className="icon">
          <MdClass />
        </span>
        <span className="text">Aulas salvas</span>
      </>
    ),
    path: '/saved-classes',
  },
  {
    label: (
      <>
        <span className="icon">
          <MdOndemandVideo />
        </span>
        <span className="text">On Demand</span>
      </>
    ),
    path: '/courses/on-demand',
  },
  {
    label: (
      <>
        <span className="icon">
          <RiCoupon3Line />
        </span>
        <span className="text">Cupons</span>
      </>
    ),
    path: '/coupons',
  },
  {
    label: (
      <>
        <span className="icon">
          <RiSurveyFill />
        </span>
        <span className="text">Questionários</span>
      </>
    ),
    path: '/exams',
  },
  {
    label: (
      <>
        <span className="icon">
          <MdAttachMoney />
        </span>
        <span className="text">Planos</span>
      </>
    ),
    path: '/plans',
  },
  {
    label: (
      <>
        <span className="icon">
          <FaQuestionCircle />
        </span>
        <span className="text">FAQ</span>
      </>
    ),
    path: '/faqs',
  },
  {
    label: (
      <>
        <span className="icon">
          <FaGamepad />
        </span>
        <span className="text">Gameficação</span>
      </>
    ),
    path: '/levels',
  },
  {
    label: (
      <>
        <span className="icon">
          <IoIosNotificationsOutline />
        </span>
        <span className="text">Notificações</span>
      </>
    ),
    path: '/notifications',
  },
  {
    label: (
      <>
        <span className="icon">
          <HiOutlineDocumentReport />
        </span>
        <span className="text">Relatórios</span>
      </>
    ),
    children: [
      {
        label: <span className="text">Engajamento</span>,
        path: '/reports/engagement',
      },
      {
        label: <span className="text">Conteúdos finalizados</span>,
        path: '/reports/content-finished',
      },
    ],
  },
] as Menu[];

export default menus;
