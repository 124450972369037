import styled from 'styled-components';

export const DefaultFilterTableWrapper = styled.div`
  display: flex;
  gap: 8px;
  margin-bottom: 20px;
  align-items: center;

  div {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  h2 {
    font-size: var(--font-small);
    color: var(--primary-color);
    font-weight: 500;
  }

  svg {
    margin-left: -22px;
    cursor: pointer;
  }
`;

export const Filter = styled.input`
  min-width: 500px;
  color: var(--default-gray);
  padding: 8px 12px;
  border-radius: 5px;
  border: solid 1px var(--default-dark-gray);
  outline: none;
`;
