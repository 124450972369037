import React from 'react';
import { AppContainer, AppContent, AppLeftContent, AppRightContent } from './AppStyle';
import { ModalProvider } from './components/ModalProvider';
import Routes from './components/Routes';
import SideBar from './components/SideBar';
import { ExamProvider } from './hooks/ExamHook';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import ptBR from 'date-fns/locale/pt-BR';

function App() {
  registerLocale('pt-BR', ptBR);
  setDefaultLocale('pt-BR');

  return (
    <ModalProvider>
      <AppContainer>
        <ExamProvider>
          <AppLeftContent>
            <SideBar />
          </AppLeftContent>
          <AppRightContent>
            <AppContent>
              <Routes />
            </AppContent>
          </AppRightContent>
        </ExamProvider>
      </AppContainer>
    </ModalProvider>
  );
}

export default App;
