import { FC } from 'react';
import { AiOutlineDown, AiOutlineUp } from 'react-icons/ai';
import styled from 'styled-components';
import DefaultButton from '../../../../components/DefaultButton';

interface UpdatePositionActionsProps {
  position: number;
  index: number;
  is_first: boolean;
  is_last: boolean;
  handleUpdatePosition: (...params: any) => void;
}

const UpdatePositionActionsContainer = styled.div`
    display: 'flex',
    justifyContent: 'space-around',
`;

export const UpdatePositionActions: FC<UpdatePositionActionsProps> = ({
  position,
  index,
  is_first,
  is_last,
  handleUpdatePosition,
}) => {
  return (
    <UpdatePositionActionsContainer>
      {!is_first && (
        <>
          <DefaultButton
            type="button"
            onClick={() => {
              handleUpdatePosition(position, -1, index);
            }}
            title="Mover para cima"
            className="small white up"
          >
            <AiOutlineUp />
          </DefaultButton>
        </>
      )}
      {!is_last && (
        <>
          <DefaultButton
            type="button"
            onClick={() => handleUpdatePosition(position, 1, index)}
            title="Mover para baixo"
            className="small white down"
          >
            <AiOutlineDown />
          </DefaultButton>
        </>
      )}
    </UpdatePositionActionsContainer>
  );
};
