import styled from 'styled-components';

import DefaultButton from '../../components/DefaultButton';

const NextReleaseContainer = styled.div``;

const SelectContainer = styled.div`
  width: 100%;
  margin-bottom: 10px;

  label {
    min-width: 145px;
    margin-bottom: 10px;
    font-size: var(--font-small);
    color: var(--primary-color);
    font-weight: 700;
  }

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  & > div {
    width: 100%;
  }
`;

const StyledButton = styled(DefaultButton)`
  margin-left: 0.5rem;
  padding: 0.4rem !important;
  width: 80px;

  transition: filter 0.2s;

  &:hover {
    filter: brightness(0.9);
  }

  @media (max-width: 768px) {
    margin-top: 1rem;
  }
`;

export { NextReleaseContainer, StyledButton, SelectContainer };
