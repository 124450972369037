import React, { useCallback, useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { BiEdit } from 'react-icons/bi';
import { MdAddShoppingCart, MdRemoveShoppingCart } from 'react-icons/md';
import { CgLogOff } from 'react-icons/cg';
import Swal from 'sweetalert2';

import BreadCrumb from '../../components/BreadCrumb';
import { DefaultPageTitle } from '../../components/DefaultPageTitle';
import DefaultTable from '../../components/DefaultTable';
import { activateOrInactivateContent, getContents as getContentsService, updateContent } from '../../services/contents';
import Pagination from '../../components/Pagination';
import FilterForm from '../../components/DefaultFilterTable';
import Content from '../../models/from-api-response/content';
import calculatePagination from '../../utils/calculatePagination';

import { Container, StyledButton } from './styles';

const OnDemand: React.FC = () => {
  const [contents, setContents] = useState<Content[]>([]);
  const [paginationState, setPaginationState] = useState({ page: 1, length: 0, showPage: true });
  const history = useHistory();

  const getContents = useCallback(
    async (pageParam?: number) => {
      const { initial, final } = calculatePagination(pageParam || paginationState.page);
      const response = await getContentsService({ flag: ['course', 'retreat'], type: ['on-demand', 'retiro'] });

      setContents(response.data.slice(initial, final));
      setPaginationState((state) => ({ ...state, length: Math.ceil(response.data.length / 15), showPage: true }));
    },
    [paginationState.page]
  );

  const toggleProvideSale = async (content: Content) => {
    try {
      if (content.info.to_sell) {
        await updateContent(content.content_id, { is_active: true, info: { ...content.info, to_sell: false } });
        Swal.fire({
          icon: 'success',
          title: 'Sucesso',
          text: `Conteúdo ${content.info.to_sell ? 'inabilitado' : 'disponibilizado'} para venda.`,
        });
        getContents();
        return;
      }

      await updateContent(content.content_id, { is_active: true, info: { ...content.info, to_sell: true } });
      Swal.fire({
        icon: 'success',
        title: 'Sucesso',
        text: `Conteúdo ${content.info.to_sell ? 'inabilitado' : 'disponibilizado'} para venda.`,
      });
      getContents();
    } catch {
      Swal.fire({
        icon: 'error',
        text: 'Erro ao alterar a disponibilidade do conteúdo.',
      });
    }
  };

  const editContent = async (contentId: string) => {
    history.push(`/edit-course/${contentId}`);
  };

  const toggleDisable = async (content: Content) => {
    Swal.fire({
      title: '<strong>Confirmação</strong>',
      html: `Tem certeza que deseja ${content.is_active ? 'desabilitar' : 'habilitar'}  este conteúdo? <br /> Ele ${
        content.is_active ? 'sumirá da' : 'irá voltar para a'
      } plataforma${content.is_active ? ', mas pode ser reativado se desejar.' : '.'}`,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      focusConfirm: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await activateOrInactivateContent(content.content_id, !content.is_active);

          Swal.fire({
            icon: 'success',
            title: 'Sucesso!',
            text: `Conteúdo ${content.is_active ? 'desabilitado' : 'habilitado'} com sucesso!`,
          });

          getContents();
        } catch (error) {
          Swal.fire({
            icon: 'error',
            title: 'Erro',
            text: 'Ocorreu um erro ao tentar alterar o status deste conteúdo.',
          });
        }
      }
    });
  };

  const contentsToBeShown = (() => {
    return contents.map((content: Content) => ({
      name: content.name,
      price: (Number(content.info.price) as number).toFixed(2),
      status: content.is_active ? 'Ativo' : 'Inativo',
      sell: content.info.to_sell ? 'Habilitada' : 'Inabilitada',
      actions: (
        <>
          <StyledButton
            onClick={() => toggleProvideSale(content)}
            className={`small ${content.info.to_sell ? 'warning' : 'info'}`}
            title="Disponibilizar venda"
            style={{
              width: 160,
            }}
          >
            {content.info.to_sell ? (
              <>
                Inabilitar
                <MdRemoveShoppingCart />
              </>
            ) : (
              <>
                Disponibilizar venda
                <MdAddShoppingCart />
              </>
            )}
          </StyledButton>
          <StyledButton onClick={() => editContent(content.content_id)} className="small success" title="Cursos">
            Editar
            <BiEdit />
          </StyledButton>
          <StyledButton
            onClick={() => toggleDisable(content)}
            className={`small ${content.is_active ? 'danger' : 'warning'}`}
            title="Cursos"
          >
            {content.is_active ? 'Desabilitar' : 'Habilitar'}
            <CgLogOff />
          </StyledButton>
        </>
      ),
    }));
  })();

  useEffect(() => {
    getContents();
  }, [getContents]);

  return (
    <Container>
      <BreadCrumb
        crumbs={[<Link to="/dashboard">Dashboard</Link>, <Link to="/courses">Cursos</Link>, <span>On Demand</span>]}
      />

      <DefaultPageTitle>Cursos On Demand</DefaultPageTitle>

      <FilterForm
        data={contents}
        onFilter={(filtered) => {
          setContents(filtered);
          setPaginationState((state) => ({ ...state, showPage: false }));
        }}
        propName="name"
        clearFilter={() => {
          setPaginationState((state) => ({ ...state, page: 1 }));
          getContents(1);
        }}
      />

      <DefaultTable
        headersConfig={[
          {
            headerLabel: <>Nome</>,
            propName: 'name',
          },
          {
            headerLabel: <>Status</>,
            propName: 'status',
            noWrap: true,
          },
          {
            headerLabel: <>Preço</>,
            propName: 'price',
          },
          {
            headerLabel: <>Venda</>,
            propName: 'sell',
          },
          {
            headerLabel: <>Ações</>,
            propName: 'actions',
            noWrap: true,
          },
        ]}
        items={contentsToBeShown}
        emptyListMessage="Não foram encontrados cursos on demand"
      />

      {paginationState.showPage ? (
        <Pagination
          totalPages={paginationState.length}
          setPage={(page) => setPaginationState((state) => ({ ...state, page }))}
        />
      ) : null}
    </Container>
  );
};

export default OnDemand;
