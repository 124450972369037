import React, { useState, useEffect } from 'react';
import { DefaultFilterTableWrapper, Filter } from './style';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { CgSearchLoading } from 'react-icons/cg';
import { debounce } from 'lodash';

import { getUsers } from '../../../services/users';

interface IFilter {
  data: Array<any>;
  onFilter: (filteredData: Array<any>) => void;
  clearFilter: () => void;
}

const UsersFilterTable: React.FC<IFilter> = ({ data, onFilter, clearFilter }) => {
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState(data);

  const filteredResults = () => {
    if (!searchTerm) {
      onFilter(data);
      clearFilter();
    }
    onFilter(searchResults);
  };

  const handleChange = (event: any) => {
    setSearchTerm(event.target.value);
    if (event.target.value) {
      debouncedSearch(event.target.value.trim());
    }
  };

  const handleClearFilter = () => {
    clearFilter();
    setSearchTerm('');
  };

  const debouncedSearch = React.useRef(
    debounce(async (search: string) => {
      const response = await getUsers({ search });
      setSearchResults(response.users.users);
      onFilter(response.users.users);
    }, 1500)
  ).current;

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  return (
    <DefaultFilterTableWrapper>
      <div>
        <Filter placeholder='buscar por nome ou email' value={searchTerm} onChange={handleChange} />

        {searchTerm && searchTerm.length && !loading ? <AiOutlineCloseCircle onClick={handleClearFilter} /> : null}
        {loading && <CgSearchLoading />}
      </div>
    </DefaultFilterTableWrapper>
  );
};

export default UsersFilterTable;
