import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { AiOutlineCheck, AiOutlineClose } from 'react-icons/ai';
import { BiEdit } from 'react-icons/bi';
import Swal from 'sweetalert2';

import BreadCrumb from '../../components/BreadCrumb';
import { DefaultPageTitle } from '../../components/DefaultPageTitle';
import DefaultTable from '../../components/DefaultTable';
import Pagination from '../../components/Pagination';
import calculatePagination from '../../utils/calculatePagination';
import { CreateButtonDefaultContainer } from '../../components/CreateButtonDefaultContainer';
import { getAllContents, getContent } from '../../services/contents';
import { updateNextRelease as updateNextReleaseService } from '../../services/contents';
import DefaultButton from '../../components/DefaultButton';
import FilterForm from '../../components/DefaultFilterTable';
import Select, { OptionsType } from 'react-select';

import { SelectContainer, StyledButton, NextReleaseContainer } from './styles';
import Content from '../../models/content';

interface SelectProps {
  label: string;
  value: string;
}

const NextRelease: React.FC = () => {
  const [coursesOptions, setCoursesOptions] = useState<OptionsType<SelectProps>>([]);
  const [course, setCourse] = useState({ value: '', label: '' });

  const [allContents, setallContents] = useState<Content[]>([]);
  const [contents, setContents] = useState<Content[]>([]);
  const [paginationState, setPaginationState] = useState({ page: 1, length: 0, showPage: true });

  const getContents = useCallback(
    async (pageParam?: number) => {
      const { initial, final } = calculatePagination(pageParam || paginationState.page);
      const localContents = await getAllContents({
        info_key: 'next_release',
        info_value: true,
      });

      const nextRelease = localContents.data;
      setContents(nextRelease.slice(initial, final));
      setallContents(nextRelease);

      setPaginationState((state) => ({
        ...state,
        length: Math.ceil(localContents.count / 15),
        showPage: true,
      }));

      setCourse({ value: '', label: '' });
    },
    [paginationState.page]
  );

  useEffect(() => {
    (async () => {
      try {
        const response = await getAllContents({
          flag: ['course', 'retreat'],
          type: ['curso', 'retiro', 'on-demand'],
        });
        setCoursesOptions(response.data.map((course: Content) => ({ label: course.name, value: course.content_id })));
      } catch {
        Swal.fire({
          title: 'Erro!',
          text: 'Ocorreu um erro ao recuperar os cursos!',
          icon: 'error',
        });
      }
    })();
  }, []);

  const updateNextRelease = async (contentId: string, nextRelease: boolean) => {
    await updateNextReleaseService(contentId, nextRelease);

    getContents();
  };

  useEffect(() => {
    getContents();
  }, [getContents]);

  const contentsToBeShown = useMemo(() => {
    return contents.map((content) => ({
      id: content.content_id,
      name: content.name,
      actions: (
        <>
          <StyledButton
            onClick={() => updateNextRelease(content.content_id, false)}
            className="small danger"
            title="Deletar Conteúdo"
          >
            Deletar
            <BiEdit />
          </StyledButton>
        </>
      ),
    }));
  }, [contents, paginationState.page]);

  return (
    <NextReleaseContainer>
      <BreadCrumb
        crumbs={[<Link to="/dashboard">Dashboard</Link>, <span>Cms</span>, <span>Agenda de Conteúdo</span>]}
      />

      <DefaultPageTitle>Lançamentos</DefaultPageTitle>

      <SelectContainer>
        <label className="required" htmlFor="content">
          Lançamentos
        </label>
        <Select
          id="course"
          options={coursesOptions}
          value={course}
          onChange={(option) => setCourse(option!)}
          placeholder="Selecione um conteúdo para exibir"
          noOptionsMessage={() => 'Sem conteúdos disponíveis'}
        />
      </SelectContainer>

      <CreateButtonDefaultContainer>
        <DefaultButton disabled={!course?.value} onClick={() => updateNextRelease(course.value, true)}>
          Salvar
        </DefaultButton>

        <div className="right">
          <FilterForm
            data={allContents}
            onFilter={(filtered: Content[]) => {
              setContents(filtered);
              setPaginationState((state) => ({ ...state, showPage: false }));
            }}
            propName="name"
            clearFilter={() => {
              setPaginationState((state) => ({ ...state, page: 1 }));
              getContents(1);
            }}
          />
        </div>
      </CreateButtonDefaultContainer>

      <DefaultTable
        headersConfig={[
          {
            headerLabel: <span>Título</span>,
            propName: 'name',
          },
          {
            headerLabel: <span>Ações</span>,
            propName: 'actions',
          },
        ]}
        items={contentsToBeShown}
        emptyListMessage={'Não foram encontrados lançamentos!'}
      />
      {paginationState.showPage ? (
        <Pagination
          totalPages={paginationState.length}
          setPage={(value) => setPaginationState((state) => ({ ...state, page: value }))}
        />
      ) : null}
    </NextReleaseContainer>
  );
};

export default NextRelease;
