import httpClient from '../http-client';

interface GenerateReportProps {
  type: 'csv' | 'pdf' | 'xls';
  startAt: Date;
  finishAt: Date;
}

const generateReportEngagement = async ({ type, startAt, finishAt }: GenerateReportProps) => {
  return httpClient.get(`/reports/engagement/${type}`, {
    params: {
      start_at_gt: startAt,
      start_at_lt: finishAt,
    },
    responseType: 'blob',
  });
};

const generateReportFinishContent = async ({ type, startAt, finishAt }: GenerateReportProps) => {
  return httpClient.get(`/reports/content-finished/${type}`, {
    params: {
      start_at_gt: startAt,
      finish_at_lt: finishAt,
    },
   responseType: 'blob',
  });
};

export { generateReportEngagement, generateReportFinishContent };
