import styled from 'styled-components';

const CutImageContainer = styled.div`
  width: 80%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
`;

export const CropContainer = styled.div`
  width: 80%;
  height: 200;
  background: #333;
`;

export const Buttons = styled.div`
  position: relative;
  width: 80%;
  display: flex;
  justify-content: center;
  margin: 0 auto;

  button {
    margin: 0 8px;
  }
`;

export const SliderContainer = styled.div`
  position: relative;
  background: #fff;
  padding: 16px;
  width: 50%;
  border-radius: 20px;

  position: absolute;
  left: 50%;
  top: 80%;
  transform: translate(-50%, -50%);
`;

const SelectImageContainer = styled.div`
  width: 100%;
  height: 100%;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ImageCutContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .buttons {
    margin: 25px 0 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    button:first-of-type {
      margin-right: 20px;
    }
  }
`;

export { CutImageContainer, SelectImageContainer, ImageCutContainer };
