import httpClient from '../http-client';

const getPageContent = async () => {
  return httpClient.get('/home-info', { params: { type: 'aboutImaContent' } }).then((res) => res.data);
};

const updateAboutIma = async (id: string, description: string, info: Record<string, unknown>) => {
  return httpClient
    .put(`home-info/${id}`, {
      text: description,
      info,
    })
    .then((res) => res.data);
};

export { getPageContent, updateAboutIma };
