import React, { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';

import BreadCrumb from '../../components/BreadCrumb';
import DefaultButton from '../../components/DefaultButton';
import DefaultCreationForm, {
  DefaultCreationFormButtonGroup,
  DefaultCreationFormGroup,
} from '../../components/DefaultCreationForm';
import DefaultInput from '../../components/DefaultInput';
import { DefaultPageTitle } from '../../components/DefaultPageTitle';
import checkEmptyString from '../../helpers/check-empty-string';
import {
  createAuthor as createAuthorService,
  updateAuthor as updateAuthorService,
  getAuthor as getAuthorService,
} from '../../services/authors';

import { CreateAndEditAuthorContainer } from './styles';

interface CreateAndEditAuthorProps {
  author_id: string;
}

const CreateAndEditAuthor: React.FC = () => {
  const history = useHistory();

  const { author_id: authorId } = useParams<CreateAndEditAuthorProps>();
  const [name, setName] = useState('');

  const createAuthor = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      if (checkEmptyString(name)) {
        throw new Error('O nome não pode estar em branco.');
      }

      await createAuthorService(name);

      Swal.fire({
        title: 'Sucesso!',
        text: 'Autor criado com sucesso!',
        icon: 'success',
      });
      goToAuthors();
    } catch (error: any) {
      Swal.fire({
        title: 'Erro',
        text: `Houve um erro ao criar o autor. ${
          error?.response?.data?.message || error.message || 'Ocorreu um erro inesperado'
        }`,
        icon: 'error',
      });
    }
  };

  const goToAuthors = () => {
    history.push('/authors');
  };

  const isEditing = (() => {
    if (authorId) {
      return true;
    }

    return false;
  })();

  const updateAuthor = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      if (checkEmptyString(name)) {
        throw new Error('Informe um nome válido para o autor');
      }

      await updateAuthorService(authorId, name);

      goToAuthors();
      Swal.fire({
        title: 'Sucesso!',
        text: 'Autor editado com sucesso!',
        icon: 'success',
      });
    } catch (error: any) {
      Swal.fire({
        title: 'Erro',
        text: `Houve um erro ao editar o autor. ${
          error?.response?.data?.message || error.message || 'Ocorreu um erro inesperado'
        }`,
        icon: 'error',
      });
    }
  };

  useEffect(() => {
    (async () => {
      try {
        if (authorId) {
          const content = await getAuthorService(authorId);
          if (content && Object.keys(content).length) {
            setName(content.name);
          }
        }
      } catch (error: any) {
        Swal.fire({
          title: 'Erro',
          text: `Houve um erro ao recuperar o autor. ${
            error?.response?.data?.message || error.message || 'Ocorreu um erro inesperado'
          }`,
          icon: 'error',
        });
      }
    })();
  }, [authorId]);

  return (
    <CreateAndEditAuthorContainer>
      <BreadCrumb
        crumbs={[
          <Link to="/dashboard">Dashboard</Link>,
          <Link to="/authors">Autores</Link>,
          <span>{isEditing ? 'Editar' : 'Criar'} Autor</span>,
        ]}
      />

      <DefaultPageTitle>{isEditing ? 'Editar' : 'Criar'} Autor</DefaultPageTitle>

      <DefaultCreationForm>
        <DefaultCreationFormGroup>
          <label className="required" htmlFor="name">
            Nome
          </label>
          <DefaultInput value={name} onChange={(e) => setName(e.target.value)} id="name" required maxLength={250} />
        </DefaultCreationFormGroup>

        <DefaultCreationFormButtonGroup>
          <DefaultButton type="button" className="danger" onClick={goToAuthors}>
            Cancelar
          </DefaultButton>
          <DefaultButton onClick={(e) => (isEditing ? updateAuthor(e) : createAuthor(e))} className="success">
            Salvar
          </DefaultButton>
        </DefaultCreationFormButtonGroup>
      </DefaultCreationForm>
    </CreateAndEditAuthorContainer>
  );
};

export default CreateAndEditAuthor;
