import styled from 'styled-components';
export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;

  @media (max-width: 768px) {
    background-color: var(--white);
    flex-direction: column;
  }
`;

export const LogoContainer = styled.div`
  background: #594aad;
  flex: 1 0 50%;
  position: relative;

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 450px;
  }

  &:after {
    content: '';
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
  @media (max-width: 768px) {
    background: #999;
    flex: 1;
    margin: 20vw 0;

    &:after {
      background-image: none;
    }
  }
`;

export const LoginContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 0 50%;
  padding: 0 20px;
  background: #141414;

  @media (max-width: 768px) {
    align-items: flex-start;
  }
`;

export const LoginBlock = styled.div`
  max-width: 500px;
  width: 100%;
`;

export const LoginTitle = styled.h1`
  color: #fff;
  font-family: 'Lato', sans-serif;
  font-size: 3.5rem;
  font-weight: 700;
  margin-bottom: 16px;

  @media (max-width: 768px) {
    font-size: 2rem;
    font-weight: 500;
  }
`;

export const LoginDescription = styled.p`
  color: #fff;
  font-family: 'Lato', sans-serif;
  font-size: 1.2rem;
  margin-bottom: 40px;
  line-height: 28px;

  strong {
    color: var(--blue);
  }

  @media (max-width: 768px) {
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 20px;

    strong {
      font-weight: 400;
    }
  }
`;

export const LoginForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const LoginLabel = styled.label`
  color: #594aad;
  width: 100%;
  font-weight: bold;
  font-size: 1.2rem;

  &:not(:first-child) {
    margin-top: 32px;
  }
`;

export const LoginInput = styled.input`
  font-family: 'Lato', sans-serif;

  &[type='email'],
  &[type='password'] {
    width: 100%;
    margin-top: 8px;
    font-size: 1.4rem;
    border: 1px solid rgba(155, 153, 175, 0.31);
    border-radius: 8px;
    padding: 8px;
    outline: none;
  }

  &[type='submit'] {
    color: #594aad;
    background-color: #fff;
    border-radius: 8px;
    border: none;
    line-height: 49px;
    padding: 8px;
    width: 147px;
    font-size: 1.8rem;
    font-weight: 800;
    margin-top: 32px;
    cursor: pointer;
    transition: all 0.3s;

    &:hover {
      background: #594aad;
      color: #fff;
    }
  }
`;
