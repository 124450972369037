import httpClient from '../http-client';
import UploadedFile from '../models/from-api-response/file';

const uploadFile = async (formData: FormData): Promise<UploadedFile> => {
  const uploadFileResponse = (
    await httpClient.post(`files`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  ).data;

  return uploadFileResponse;
};

export { uploadFile };
