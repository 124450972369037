import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { AiOutlineCheck, AiOutlineClose } from 'react-icons/ai';
import { BiEdit, BiTrash } from 'react-icons/bi';
import { Link, useHistory } from 'react-router-dom';

import BreadCrumb from '../../components/BreadCrumb';
import { DefaultPageTitle } from '../../components/DefaultPageTitle';
import DefaultTable from '../../components/DefaultTable';
import Pagination from '../../components/Pagination';
import {
  getAllCategories,
  activateOrInactivateCategory as activateOrInactivateCategoryService,
  deleteCategory,
} from '../../services/categories';
import { Category } from '../../models/category';

import { CategoriesContainer, StyledButton } from './style';
import { CreateButtonDefaultContainer } from '../../components/CreateButtonDefaultContainer';
import DefaultButton from '../../components/DefaultButton';
import FilterForm from '../../components/DefaultFilterTable';
import Swal from 'sweetalert2';
import calculatePagination from '../../utils/calculatePagination';

const Categories: React.FC = () => {
  const [allCategories, setAllCategories] = useState<Category[]>([]);
  const [categories, setCategories] = useState<Category[]>([]);
  const [paginationState, setPaginationState] = useState({ page: 1, length: 0, showPage: true });

  const history = useHistory();

  const getCategories = useCallback(
    async (pageParam?: number) => {
      try {
        const { initial, final } = calculatePagination(pageParam || paginationState.page);

        const localCategories = await getAllCategories();

        if (localCategories.length) {
          setPaginationState((state) => ({ ...state, length: Math.ceil(localCategories.length / 15) }));
          setPaginationState((state) => ({ ...state, showPage: true }));
          setCategories(localCategories.slice(initial, final));
          setAllCategories(localCategories);
          return;
        }
        setCategories([]);
        setAllCategories([]);
      } catch {
        Swal.fire({
          title: 'Erro',
          text: 'Erro ao recuperar categorias.',
        });
      }
    },
    [paginationState.page]
  );

  const editCategory = useCallback(
    (categoryId: any) => {
      history.push(`edit-category/${categoryId}`);
    },
    [history]
  );

  const activateOrInactivateCategory = useCallback(
    async (categoryId: any, activate: boolean, page: number) => {
      Swal.fire({
        title: 'Confirmação',
        text: `Tem certeza que deseja ${activate ? 'ativar' : 'inativar'} esta categoria?`,
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        focusConfirm: false,
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await activateOrInactivateCategoryService(categoryId, activate);
            await getCategories(page);
          } catch (error: any) {
            if (error.response.status === 409) {
              Swal.fire({
                icon: 'error',
                title: 'Erro',
                text: error?.response?.data?.message || error.message || 'Ocorreu um erro inesperado',
              });
              return;
            }

            Swal.fire({
              icon: 'error',
              title: 'Erro',
              text: 'Ocorreu um erro desconhecido, tente',
            });
          }
        }
      });
    },
    [getCategories]
  );

  const removeCategory = useCallback(
    async (categoryId: any, pageParam: number) => {
      Swal.fire({
        title: '<strong>Confirmação</strong>',
        html: 'Tem certeza que deseja remover esta categoria?',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        focusConfirm: false,
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await deleteCategory(categoryId);

            Swal.fire({
              icon: 'success',
              title: 'Sucesso!',
              text: 'Categoria excluída com sucesso!',
            });

            await getCategories(pageParam);
          } catch (error: any) {
            if (error.response.status === 409) {
              Swal.fire({
                icon: 'error',
                title: 'Erro',
                text: error?.response?.data?.message || error.message || 'Ocorreu um erro inesperado',
              });
              return;
            }

            Swal.fire({
              icon: 'error',
              title: 'Erro',
              text: 'Ocorreu um erro desconhecido, tente',
            });
          }
        }
      });
    },
    [getCategories]
  );

  const contentsToBeShown = useMemo(() => {
    return categories.map((category) => ({
      id: category.category_id,
      title: category.title,
      description: category.description,
      active: category.is_active ? <AiOutlineCheck /> : <AiOutlineClose />,
      actions: (
        <>
          <StyledButton
            onClick={() => editCategory(category.category_id)}
            className="small info"
            title="Editar Categoria"
          >
            Editar
            <BiEdit />
          </StyledButton>
          <StyledButton
            onClick={() =>
              activateOrInactivateCategory(category?.category_id, !category.is_active, paginationState.page)
            }
            className="small warning"
            title={(category.is_active ? 'Inativar' : 'Ativar') + ' Categoria'}
          >
            {category.is_active ? (
              <>
                Inativar <AiOutlineClose />
              </>
            ) : (
              <>
                Ativar
                <AiOutlineCheck />
              </>
            )}
          </StyledButton>
          <StyledButton
            onClick={() => {
              removeCategory(category.category_id, paginationState.page);
            }}
            className="small danger"
            title="Excluir Categoria"
          >
            Deletar
            <BiTrash />
          </StyledButton>
        </>
      ),
    }));
  }, [categories, editCategory, activateOrInactivateCategory, removeCategory, paginationState.page]);

  const createCategory = async () => {
    history.push('create-category');
  };

  useEffect(() => {
    getCategories();
  }, [getCategories]);

  return (
    <CategoriesContainer>
      <BreadCrumb crumbs={[<Link to="/dashboard">Dashboard</Link>, <span>Categorias</span>]} />

      <DefaultPageTitle>Categorias</DefaultPageTitle>

      <CreateButtonDefaultContainer>
        <DefaultButton onClick={createCategory}>Criar Categoria</DefaultButton>

        <div className="right">
          <FilterForm
            data={allCategories}
            onFilter={(filtered: Category[]) => {
              setCategories(filtered);
              setPaginationState((state) => ({ ...state, showPage: false }));
            }}
            propName="title"
            clearFilter={() => {
              setPaginationState((state) => ({ ...state, page: 1 }));
              getCategories(1);
            }}
          />
        </div>
      </CreateButtonDefaultContainer>

      <DefaultTable
        headersConfig={[
          {
            headerLabel: <span>Título</span>,
            propName: 'title',
          },
          {
            headerLabel: <span>Descrição</span>,
            propName: 'description',
          },
          {
            headerLabel: <span>Ativo</span>,
            propName: 'active',
          },
          {
            headerLabel: <span>Ações</span>,
            propName: 'actions',
          },
        ]}
        items={contentsToBeShown}
        emptyListMessage="Não foram encontrados categorias cadastradas!"
      />

      {paginationState.showPage && (
        <Pagination
          totalPages={paginationState.length}
          setPage={(value) => setPaginationState((state) => ({ ...state, page: value }))}
        />
      )}
    </CategoriesContainer>
  );
};

export default Categories;
