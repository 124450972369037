import React, { useEffect, useState } from 'react';
import { BiEdit } from 'react-icons/bi';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom';

import BreadCrumb from '../../components/BreadCrumb';
import DefaultButton from '../../components/DefaultButton';
import { DefaultPageTitle } from '../../components/DefaultPageTitle';
import DefaultTable from '../../components/DefaultTable';
import { Level, listLevels } from '../../services/level';
import { CreateButtonDefaultContainer } from '../../components/CreateButtonDefaultContainer';

import { ActionsContainer, Container, Count, StyledButton } from './styles';

const Gamification: React.FC = () => {
  const [levels, setLevels] = useState<Level[]>([]);

  const history = useHistory();

  const handleCreateLevel = () => {
    history.push('/create-level');
  };

  const handleEditLevel = (levelId: string) => {
    history.push(`/edit-level/${levelId}`);
  };

  useEffect(() => {
    const fetchLevels = async () => {
      try {
        const response = await listLevels();

        setLevels(response);
      } catch (error: any) {
        Swal.fire({
          title: 'Erro!',
          text: `Houve um problema ao recuperar os níveis! ${
            error?.response?.data?.message || error.message || 'Ocorreu um erro inesperado'
          }`,
          icon: 'error',
        });
      }
    };

    fetchLevels();
  }, []);

  const dataToShow = (() => {
    return levels.map((level) => ({
      name: level.name,
      minPoints: level?.range?.start,
      maxPoints: level?.range?.end,
      actions: (
        <ActionsContainer>
          <StyledButton onClick={() => handleEditLevel(level.level_id)} className="small info" title="Editar">
            Editar
            <BiEdit />
          </StyledButton>
        </ActionsContainer>
      ),
    }));
  })();

  return (
    <Container>
      <BreadCrumb crumbs={[<Link to="/dashboard">Dashboard</Link>, <span>Gameficação</span>]} />
      <DefaultPageTitle>Gameficação</DefaultPageTitle>

      <CreateButtonDefaultContainer>
        <DefaultButton disabled={levels.length === 5} onClick={handleCreateLevel}>
          Criar nível
        </DefaultButton>
        <Count count={levels.length}>{`${levels.length} / 5`}</Count>
      </CreateButtonDefaultContainer>

      <DefaultTable
        headersConfig={[
          {
            headerLabel: <span>Nome</span>,
            propName: 'name',
          },
          {
            headerLabel: <span>Pontuação mínima</span>,
            propName: 'minPoints',
          },
          {
            headerLabel: <span>Pontuação máxima</span>,
            propName: 'maxPoints',
          },
          {
            headerLabel: <span>Ações</span>,
            propName: 'actions',
            noWrap: true,
          },
        ]}
        items={dataToShow}
        emptyListMessage="Não foram encontrados níveis"
      />
    </Container>
  );
};

export default Gamification;
