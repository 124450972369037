import React, { createContext, useState, useContext, useCallback } from 'react';
import Swal from 'sweetalert2';

import { ExamQuestion, Exam } from '../models/exam';
import {
  createQuestion as createQuestionService,
  createExam as createExamService,
  updateExam as updateExamService,
  deleteQuestion,
} from '../services/exams';

interface ExamContextData {
  exam: Exam;
  questions: ExamQuestion[];
  createQuestions: (examId: string) => Promise<void>;
  addQuestion: (question: ExamQuestion) => void;
  addQuestions: (questions: ExamQuestion[]) => void;
  removeQuestion: (question: ExamQuestion) => void;
  createExam: (exam: Exam, contentId: string) => Promise<void>;
  updateExam: (exam: Exam) => Promise<void>;
  addExam: (exam: Exam) => void;
  clearQuestions: () => void;
  getLocalQuestion: (localId: string) => ExamQuestion;
  updateLocalQuestion: (localId: string, data: Partial<ExamQuestion>) => void;
}

const ExamContext = createContext<ExamContextData>({} as ExamContextData);

const ExamProvider: React.FC = ({ children }) => {
  const [questions, setQuestions] = useState<ExamQuestion[]>([]);
  const [exam, setExam] = useState<Exam>({} as Exam);

  const addExam = useCallback((exam: Exam) => {
    setExam(exam);
  }, []);

  const clearQuestions = useCallback(() => {
    setQuestions([]);
  }, []);

  const addQuestion = useCallback((question: ExamQuestion) => {
    setQuestions((state) => {
      return [...state, { ...question, position: state.length + 1 }];
    });
  }, []);

  const addQuestions = useCallback((questions: ExamQuestion[]) => {
    setQuestions(questions);
  }, []);

  const createQuestions = useCallback(
    async (examId: string) => {
      try {
        await Promise.all(
          questions.map(async ({ id, ...question }) => {
            await createQuestionService({
              ...question,
              exam_id: examId,
            });
          })
        );
      } catch (error: any) {
        Swal.fire({
          title: 'Erro',
          text: `Erro ao cadastrar uma questão. ${
            error?.response?.data?.message || error.message || 'Ocorreu um erro inesperado'
          }`,
          icon: 'error',
        });
      }
    },
    [questions]
  );

  const createExam = useCallback(
    async (exam: Exam, contentId: string) => {
      const response = await createExamService({
        title: exam.title,
        description: exam.description,
        attempts: exam.attempts,
        average_in_percent: exam.average_in_percent,
        contents: [{ content_id: contentId }],
      });

      await createQuestions(response.exam_id);

      setQuestions([]);
    },
    [createQuestions]
  );

  const updateExam = useCallback(
    async (examToUpdate: Exam) => {
      const newQuestions = questions.filter((question) => !question.exam_question_id);

      await Promise.all(
        newQuestions.map(async ({ id, ...question }) => {
          await createQuestionService({
            ...question,
            exam_id: examToUpdate.exam_id,
          });
        })
      );

      await updateExamService(examToUpdate.exam_id, { ...examToUpdate, exam_id: undefined });
      setQuestions([]);
    },
    [questions]
  );

  const removeQuestion = useCallback(async (question: ExamQuestion) => {
    await deleteQuestion(question.exam_question_id);
  }, []);

  const getLocalQuestion = useCallback(
    (localId: string) => {
      return questions.find((question) => (question?.id ?? question?.exam_question_id) === localId)!;
    },
    [questions]
  );

  const updateLocalQuestion = useCallback((localId: string, data: Partial<ExamQuestion>) => {
    setQuestions((state) =>
      state.map((question) => (question.id === localId ? Object.assign(question, data) : question))
    );
  }, []);

  return (
    <ExamContext.Provider
      value={{
        exam,
        questions,
        addExam,
        createQuestions,
        addQuestion,
        addQuestions,
        createExam,
        updateExam,
        removeQuestion,
        clearQuestions,
        getLocalQuestion,
        updateLocalQuestion,
      }}
    >
      {children}
    </ExamContext.Provider>
  );
};

function useExam() {
  const context = useContext(ExamContext);

  if (!context) {
    throw new Error('useExam should be used within an ExamProvider');
  }

  return context;
}

export { ExamProvider, useExam, ExamContext };
