import styled from 'styled-components';

const Container = styled.div`
  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;
`;

const DashboardContainer = styled.div`
  display: flex;
`;

const DashboardCardsContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  margin: 35px;
`;

const DashboardCard = styled.div`
  background: var(--primary-color);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: space-between;
  margin: 20px;
  padding: 16px 24px;
  height: 7rem;
  width: 24rem;
  position: relative;

  span {
    max-width: 80%;
    margin-bottom: 16px;
  }

  span,
  strong {
    color: #fff;
  }

  @media (max-width: 768px) {
    height: 9rem;
    width: 25rem;
  }
`;

const DashboardCardList = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: space-between;

  background: var(--primary-color);
  border-radius: 10px;

  margin: 20px;
  padding: 16px 24px;
  height: auto;
  min-width: 24rem;

  position: relative;

  > span {
    color: #fff;
    font-weight: bold;
    font-size: 1.2rem;
    margin-bottom: 24px;
  }

  @media (max-width: 768px) {
    height: 9rem;
    width: 25rem;
  }
`;

const UsersPlan = styled.div`
  display: flex;
  justify-content: space-between;

  span {
    max-width: 80%;
    margin-bottom: 16px;
  }

  span,
  strong {
    color: #fff;
  }
`;

const IconContainer = styled.span`
  background: #594aad;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45px;
  height: 45px;
  position: absolute;
  top: 16px;
  right: 24px;

  svg {
    width: 25px;
    height: 25px;
  }
`;

export {
  Container,
  DashboardContainer,
  DashboardCardsContainer,
  DashboardCard,
  IconContainer,
  DashboardCardList,
  UsersPlan,
};
