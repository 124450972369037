import React, { useCallback, useEffect, useRef, useState } from 'react';
import CurrencyInput from 'react-currency-input-field';
import { Link, useHistory, useParams } from 'react-router-dom';
import Select from 'react-select';
import Swal from 'sweetalert2';

import BreadCrumb from '../../../components/BreadCrumb';
import DefaultButton from '../../../components/DefaultButton';
import { DefaultCreationFormButtonGroup, DefaultCreationFormGroup } from '../../../components/DefaultCreationForm';
import DefaultInput from '../../../components/DefaultInput';
import { DefaultPageTitle } from '../../../components/DefaultPageTitle';
import Content from '../../../models/content';
import { CouponTargetTypes, CouponTypes, ICouponDTO, IUpdateCouponDTO } from '../../../models/coupon';
import { getAllContents, getContent } from '../../../services/contents';
import { getCoupon, updateCoupon } from '../../../services/coupons';
import { oneMinToMidNight, toUTCDate } from '../../../utils/date';
import { CreateCouponForm, CreateCouponPageContainer, SelectContainer, CouponTypeContainer } from './styles';

interface ISelectOption {
  label: string;
  value: string;
}

export const EditCouponPage = () => {
  const history = useHistory();
  const { coupon_id } = useParams<{ coupon_id: string }>();

  const [existingCoupon, setExistingCoupon] = useState<ICouponDTO>();

  const [contentsOptions, setContentsOptions] = useState<ISelectOption[]>([]);
  const [selectedContent, setSelectedContent] = useState<ISelectOption | null>();

  const [couponType, setCouponType] = useState<CouponTypes>(CouponTypes.AMOUNT);
  const [couponTypeAmount, setCouponTypeAmount] = useState(0);
  const [couponTypePercentage, setCouponTypePercentage] = useState(5);
  const [couponUsageType, setCouponUsageType] = useState<'quantity' | 'time'>();
  const couponCodeInputRef = useRef<HTMLInputElement>(null);
  const couponQuantityInputRef = useRef<HTMLInputElement>(null);
  const couponStartAtInputRef = useRef<HTMLInputElement>(null);
  const couponFinishAtInputRef = useRef<HTMLInputElement>(null);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const value = couponType === CouponTypes.AMOUNT ? couponTypeAmount : couponTypePercentage;

    const couponContentApplications = existingCoupon?.applications.filter((app) => app.target_id) ?? [];
    const couponApplicationsMap = couponContentApplications.reduce(
      (map, app) => map.set(app.coupon_application_id, app),
      new Map()
    );

    const contentReplaced =
      (couponContentApplications.length && !selectedContent) ||
      (selectedContent?.value && !couponApplicationsMap?.has(selectedContent.value));
    const [currentContent] = couponContentApplications;
    const exclude_application_ids = contentReplaced && currentContent ? [currentContent.coupon_application_id] : [];

    const include_applications =
      selectedContent && contentReplaced
        ? [
            {
              coupon_target_type: CouponTargetTypes.CONTENT,
              target_id: selectedContent.value,
            },
          ]
        : [];

    const data: IUpdateCouponDTO = {
      coupon_type: couponType as CouponTypes,
      code: couponCodeInputRef.current?.value || undefined,
      [couponType]: value,
      quantity: couponQuantityInputRef.current?.valueAsNumber || null,
      start_at: couponStartAtInputRef.current?.valueAsDate || null,
      finish_at: oneMinToMidNight(couponFinishAtInputRef.current?.valueAsDate) || null,
      include_applications,
      exclude_application_ids,
    };

    try {
      if (!data.code || !/^\w{8,}$/i.test(data.code)) {
        throw new Error(
          'Código de cupom deve conter no mínimo 8 caráteres composto apenas por letras, números, e o símbolo de (_)!'
        );
      }
      if (couponType === CouponTypes.AMOUNT && !couponTypeAmount) {
        throw new Error('O valor do cupom deve ser maior que zero!');
      }

      if (
        couponType === CouponTypes.PERCENTAGE &&
        (!couponTypePercentage || couponTypePercentage < 5 || couponTypePercentage > 100)
      ) {
        throw new Error('A porcentagem deve ser estar entre 5% à 100%');
      }

      if (data.quantity && data.quantity <= 0) {
        throw new Error('O número de utilizações deve ser maior que zero!');
      }

      if (data.start_at && data.finish_at && data.start_at >= data.finish_at) {
        throw new Error('A data inicial deve ser menor que a final!');
      }

      await updateCoupon(coupon_id, data);

      Swal.fire({
        title: 'Cupom atualizado com sucesso.',
        icon: 'success',
      });
      history.replace('/coupons');
    } catch (e: any) {
      Swal.fire({
        title: 'Erro!',
        text: e.response?.data.message || e.message,
        icon: 'error',
      });
    }
  };

  const handleGetCoupon = useCallback(async (coupon_id: string) => {
    try {
      const coupon = await getCoupon({ coupon_id });

      const [currentApplication] = coupon.applications;

      if (currentApplication?.target_id) {
        getContent(currentApplication.target_id).then((data) => {
          setSelectedContent({ value: currentApplication.target_id!, label: data.name });
        });
      }

      setExistingCoupon({ ...coupon, applications: coupon.applications ?? [] });
      setCouponType(coupon.coupon_type);
      coupon.amount && setCouponTypeAmount(coupon.amount);
      coupon.percentage && setCouponTypePercentage(coupon.percentage);
      setCouponUsageType(coupon.quantity ? 'quantity' : 'time');
    } catch (e) {
      Swal.fire({
        title: 'Erro!',
        text: 'Ocorreu um erro ao recuperar o cupom!',
        icon: 'error',
      });
      history.replace('/coupons');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    handleGetCoupon(coupon_id);
  }, [coupon_id, handleGetCoupon]);

  useEffect(() => {
    if (!existingCoupon) return;
    (async () => {
      try {
        const response = await getAllContents({
          flag: ['course', 'retreat'],
          type: ['on-demand'],
        });
        setContentsOptions(response.data?.map((course: Content) => ({ label: course.name, value: course.content_id })));
      } catch {
        Swal.fire({
          title: 'Erro!',
          text: 'Ocorreu um erro ao recuperar os cursos!',
          icon: 'error',
        });
      }
    })();
  }, [existingCoupon]);

  if (!existingCoupon) return null;

  return (
    <CreateCouponPageContainer>
      <BreadCrumb
        crumbs={[<Link to="/dashboard">Dashboard</Link>, <Link to="/coupons">Cupons</Link>, <span>Criar cupom</span>]}
      />

      <DefaultPageTitle>Criar cupom</DefaultPageTitle>
      <section>
        <CreateCouponForm onSubmit={handleSubmit}>
          <DefaultCreationFormGroup>
            <label className="required" htmlFor="code">
              Código
            </label>
            <DefaultInput
              style={{ textTransform: 'uppercase' }}
              ref={couponCodeInputRef}
              id="code"
              defaultValue={existingCoupon?.code}
              name="code"
              required
              maxLength={250}
            />
          </DefaultCreationFormGroup>

          <CouponTypeContainer>
            <label className="required">
              <input
                required
                type="radio"
                name="coupon_type"
                defaultChecked={existingCoupon?.coupon_type === CouponTypes.AMOUNT}
                onClick={() => setCouponType(CouponTypes.AMOUNT)}
              />
              <span>Valor</span>
            </label>
            <label className="required">
              <input
                required
                type="radio"
                name="coupon_type"
                defaultChecked={existingCoupon?.coupon_type === CouponTypes.PERCENTAGE}
                onClick={() => setCouponType(CouponTypes.PERCENTAGE)}
              />
              <span>Porcentagem</span>
            </label>
          </CouponTypeContainer>

          <DefaultCreationFormGroup>
            <CurrencyInput
              className={`currency-input show-${couponType === CouponTypes.AMOUNT}`}
              id="amountDiscount"
              name="amountDiscount"
              placeholder="Digite um valor"
              prefix="R$ "
              value={+couponTypeAmount}
              decimalScale={2}
              decimalSeparator=","
              groupSeparator="."
              intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
              onValueChange={(value) => {
                setCouponTypeAmount(Number(value?.replace(',', '.')) || 0);
              }}
            />
            <CurrencyInput
              className={`currency-input show-${couponType !== CouponTypes.AMOUNT}`}
              id="percentageDiscount"
              name="percentageDiscount"
              suffix=" %"
              allowDecimals={false}
              placeholder="Digite o valor em porcentagem"
              value={+couponTypePercentage}
              onValueChange={(value) => {
                setCouponTypePercentage(Number(value?.replace(',', '.')) || 0);
              }}
              decimalSeparator=","
              groupSeparator="."
              maxLength={3}
              max={100}
            />
          </DefaultCreationFormGroup>

          <CouponTypeContainer>
            <label className="required">
              <input
                required
                type="radio"
                name="coupon_usage_type"
                defaultChecked={!!existingCoupon?.quantity}
                onClick={() => setCouponUsageType('quantity')}
              />
              <span>Número de utilizações</span>
            </label>
            <label className="required">
              <input
                required
                type="radio"
                name="coupon_usage_type"
                defaultChecked={!existingCoupon?.quantity}
                onClick={() => setCouponUsageType('time')}
              />
              <span>Tempo de utilização</span>
            </label>
          </CouponTypeContainer>

          {couponUsageType === 'quantity' ? (
            <DefaultCreationFormGroup>
              <DefaultInput
                required
                ref={couponQuantityInputRef}
                type="number"
                min={1}
                defaultValue={existingCoupon?.quantity ?? 0}
                id="quantity"
              />
            </DefaultCreationFormGroup>
          ) : (
            <div id="date-limites">
              <DefaultCreationFormGroup>
                <label className="required" htmlFor="start_at">
                  Inicia em
                </label>
                <DefaultInput
                  required
                  ref={couponStartAtInputRef}
                  id="start_at"
                  defaultValue={toUTCDate(existingCoupon?.start_at)}
                  name="start_at"
                  type="date"
                />
              </DefaultCreationFormGroup>

              <DefaultCreationFormGroup>
                <label className="required" htmlFor="finish_at">
                  Termina em
                </label>
                <DefaultInput
                  required
                  ref={couponFinishAtInputRef}
                  id="finish_at"
                  defaultValue={toUTCDate(existingCoupon?.finish_at)}
                  name="finish_at"
                  type="date"
                />
              </DefaultCreationFormGroup>
            </div>
          )}

          <SelectContainer>
            <label htmlFor="contents">
              Curso <small className="explain"> | Deixar em branco o cupom é aplicado a todos os cursos</small>
            </label>
            <Select
              id="contents"
              options={contentsOptions}
              value={selectedContent}
              onChange={(option) => setSelectedContent(option)}
              placeholder="Selecione um curso"
              isClearable
              noOptionsMessage={() => 'Sem cursos disponíveis'}
            />
          </SelectContainer>

          <DefaultCreationFormButtonGroup>
            <DefaultButton
              type="button"
              className="danger"
              onClick={() => {
                history.replace('/coupons');
              }}
            >
              Cancelar
            </DefaultButton>
            <DefaultButton type="submit" className="success">
              Salvar
            </DefaultButton>
          </DefaultCreationFormButtonGroup>
        </CreateCouponForm>
      </section>
    </CreateCouponPageContainer>
  );
};
