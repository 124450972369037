import { EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import Select, { OptionsType } from 'react-select';
import Swal from 'sweetalert2';
import { AiOutlineDown, AiOutlineEdit, AiOutlineUp } from 'react-icons/ai';
import CurrencyInput from 'react-currency-input-field';

import BreadCrumb from '../../components/BreadCrumb';
import CutImage from '../../components/CutImage';
import DefaultButton from '../../components/DefaultButton';
import DefaultCreationForm, {
  DefaultCreationFormButtonGroup,
  DefaultCreationFormGroup,
} from '../../components/DefaultCreationForm';
import DefaultInput from '../../components/DefaultInput';
import { DefaultPageTitle } from '../../components/DefaultPageTitle';
import { hideModal, showModal } from '../../helpers/modal';
import { DescriptionTextarea } from './Editor';
import { ReactComponent as DetachButton } from '../../assets/detach.svg';
import Content from '../../models/content';
import {
  updateContent as updateCourseService,
  deleteContent,
  createContent as createCourseService,
  getContent as getContentService,
} from '../../services/contents';
import { getAllAuthors } from '../../services/authors';
import { getAllTags } from '../../services/tags';
import { getAllCategories } from '../../services/categories';
import CreateAndEditModule from '../CreateAndEditModule';
import checkEmptyString from '../../helpers/check-empty-string';
import { uploadFile } from '../../services/files';
import { Category } from '../../models/category';
import { Author } from '../../models/author';
import { Tag } from '../../models/tag';

import {
  Container,
  Switch,
  ThumbnailUploadContainer,
  CourseThumbnail,
  SelectContainer,
  LessonSelectionContainer,
  SortLessonsContainer,
  SwitchersContainer,
  ContentUploadOrSelectContainer,
  ButtonGroupInline,
} from './styles';
import ReactDatePicker from 'react-datepicker';
import SelectSambaVideosContent from '../../components/SelectSambaVideosContent';
import MediaFromResponse from '../../models/from-api-response/media';
import SelectSubtitles from '../../components/SelectSubtitles';
import { getMedia } from '../../services/medias';
import { listPlans } from '../../services/plans';

interface SelectProps {
  label: string;
  value: string;
}

interface CreateAndEditCourseParams {
  courseId: string;
}

const CreateAndEditCourse: React.FC = () => {
  const { courseId } = useParams<CreateAndEditCourseParams>();

  const [content, setContent] = useState<Content>({} as Content);
  const [descriptionState, setDescriptionState] = useState(EditorState.createEmpty());
  const [descriptionText, setDescriptionText] = useState('');
  const [thumbnail, setThumbnail] = useState('');
  const [thumbnailWebFileToUpload, setThumbnailWebFileToUpload] = useState<File>();
  const [bannerFileToUpload, setBannerFileToUpload] = useState<File>();
  const [banner, setBanner] = useState('');
  const [isHighlighted, setIsHighlighted] = useState(false);
  const [lockModules, setLockModules] = useState(false);
  const [isRecommended, setIsRecommended] = useState(false);
  const [isOnDemand, setIsOnDemand] = useState(false);
  const [freeForSubscribers, setFreeForSubscribers] = useState(false);
  const [name, setName] = useState('');
  const [price, setPrice] = useState('');
  const [points, setPoints] = useState<number | undefined>(undefined);
  const [publishDate, setPublishDate] = useState(new Date());
  const [publishEndDate, setPublishEndDate] = useState<Date>();
  const [maxInstallments, setMaxInstallments] = useState<number | undefined>(undefined);
  const [daysToExpire, setDaysToExpire] = useState<number | undefined>(undefined);

  const [tag, setTag] = useState({ value: '', label: '' });
  const [author, setAuthor] = useState({ value: '', label: '' });
  const [category, setCategory] = useState({ value: '', label: '' });
  const [plans, setPlans] = useState<OptionsType<SelectProps>>([]);
  const [tagOptions, setTagOptions] = useState<OptionsType<SelectProps>>([]);
  const [authorOptions, setAuthorOptions] = useState<OptionsType<SelectProps>>([]);
  const [categoriesOptions, setCategoriesOptions] = useState<OptionsType<SelectProps>>([]);
  const [plansOptions, setPlansOptions] = useState<OptionsType<SelectProps>>([]);

  const [selectedModules, setSelectedModules] = useState<Content[]>([]);
  const [modulesToRemove, setModulesToRemove] = useState<Content[]>([]);
  const [addingOrEditingModuleModalId, setAddingOrEditingModuleModalId] = useState('');

  const [reference, setReference] = useState('');
  const [hasSubtitle, setHasSubtitle] = useState(false);
  const [onlySubscribers, setOnlySubscribers] = useState(false);

  const history = useHistory();

  const selectThumbnail = () => {
    showModal('Selecionar Imagem de Capa web', <CutImage aspectX={22} aspectY={13} onCutImage={onCutImage} />);
  };

  const onCutImage = (file: File) => {
    if (file) {
      setThumbnailWebFileToUpload(file);

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => setThumbnail(`${reader.result}`);

      hideModal();
    }
  };

  const reorderSelectedCourses = (lesson: Content, up: boolean) => {
    if (selectedModules) {
      const localSelectedCourses = [...selectedModules];
      const indexOfLesson = localSelectedCourses.indexOf(lesson);
      if (indexOfLesson > -1 && indexOfLesson < localSelectedCourses.length) {
        localSelectedCourses.splice(indexOfLesson, 1);
        if (up) {
          localSelectedCourses.splice(indexOfLesson - 1, 0, lesson);
        } else {
          localSelectedCourses.splice(indexOfLesson + 1, 0, lesson);
        }
      }

      setSelectedModules(localSelectedCourses);
    }
  };

  const editModule = (moduleId: string) => {
    const localEditingSeasonModalId = showModal(
      'Editar Módulo',
      <CreateAndEditModule
        plansAssociated={plans.map((plan) => plan.value)}
        moduleId={moduleId}
        onCreateOrUpdate={includeCreatedModule}
        courseId={courseId}
      />
    );
    setAddingOrEditingModuleModalId(localEditingSeasonModalId);
  };

  const removeSelectedModule = async (moduleToBeRemoved: any) => {
    Swal.fire({
      icon: 'question',
      text: 'Tem certeza que deseja desvincular esse módulo do curso?',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
    }).then(async (result) => {
      if (result.isConfirmed) {
        const modules = [...selectedModules];
        const moduleToRemoveIndex = modules.findIndex((module) => module.content_id === moduleToBeRemoved.content_id);

        modules.splice(moduleToRemoveIndex, 1);
        const reorderedList = modules.map((course, index) => ({
          ...course,
          position: index + 1,
        }));

        setSelectedModules(reorderedList);
        setModulesToRemove((state) => [...state, moduleToBeRemoved]);
      }
    });
  };

  const includeCreatedModule = (module: Content) => {
    const localSelectedCourses = selectedModules;
    if (!localSelectedCourses.some((selectedModule) => module.content_id === selectedModule.content_id)) {
      setSelectedModules([...localSelectedCourses, module]);
    } else {
      const foundCourse = localSelectedCourses.find(
        (selectedModule) => selectedModule.content_id === module.content_id
      );
      if (foundCourse) {
        const indexOfCourse = localSelectedCourses.indexOf(foundCourse);
        if (indexOfCourse > -1) {
          localSelectedCourses.splice(indexOfCourse, 1, module);
          setSelectedModules([...localSelectedCourses]);
        }
      }
    }
  };

  const addModule = () => {
    if (addingOrEditingModuleModalId) {
      hideModal(addingOrEditingModuleModalId);
    }

    const localAddingSeasonModalId = showModal(
      'Adicionar Módulo',
      <CreateAndEditModule
        courseId={courseId}
        plansAssociated={plans.map((plan) => plan.value)}
        onCreateOrUpdate={includeCreatedModule}
      />
    );
    setAddingOrEditingModuleModalId(localAddingSeasonModalId);
  };

  const goToCourses = () => {
    history.push('/courses');
  };

  const onCutBanner = (file: File) => {
    if (file) {
      setBannerFileToUpload(file);

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => setBanner(`${reader.result}`);

      hideModal();
    }
  };

  const selectBanner = () => {
    showModal('Selecionar Imagem do Destaque', <CutImage aspectX={4} aspectY={1} onCutImage={onCutBanner} />);
  };

  const createCourse = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      if (checkEmptyString(name)) {
        throw new Error('Informe um nome válido para o curso');
      }

      if (!selectedModules.length) {
        throw new Error('Selecione um ou mais módulos antes.');
      }

      if (!tag.value) {
        throw new Error('Informe uma tag para este curso.');
      }

      if (!author.value) {
        throw new Error('Informe um autor para este curso.');
      }

      let localBanner = '';

      if (!bannerFileToUpload) {
        throw new Error('Selecione uma imagem para destaque');
      }

      if (!reference) {
        throw new Error('Informe um video introdutório.');
      }

      const cleanPrice = Number(price?.replaceAll(',', '.'));

      if (isOnDemand && !cleanPrice) {
        throw new Error('Adicione um valor a este curso de compra avulsa.');
      }

      if (isOnDemand && !maxInstallments) {
        throw new Error('Informe um número máximo de parcelas');
      }

      if ((maxInstallments && maxInstallments > 12) || (maxInstallments && maxInstallments <= 0)) {
        throw new Error('Informe um número de 1 a 12');
      }

      const formData = new FormData();
      formData.append('file', bannerFileToUpload);
      const { reference: localBannerReference } = await uploadFile(formData);
      localBanner = localBannerReference;

      const data: Content = {
        name,
        description: draftToHtml(convertToRaw(descriptionState.getCurrentContent())),
        children: selectedModules.map((module, index) => ({
          child_id: module.content_id,
          position: index + 1,
        })) as unknown as Content[],
        authors: [
          {
            author_id: author.value,
          },
        ] as unknown as Author[],
        categories: [
          {
            category_id: category.value,
          },
        ] as unknown as Category[],
        tags: [{ tag_id: tag.value }] as unknown as Tag[],
        is_highlight: isHighlighted,
        is_recommended: isRecommended,
        flag: 'course',
        type: isOnDemand ? 'on-demand' : 'curso',
        is_active: false,
        info: {
          publish_date: publishDate,
          publish_end_date: publishEndDate,
          is_free: !isOnDemand,
          free_for_subscribers: freeForSubscribers,
          gamification_points: points,
          only_subscribers: onlySubscribers,
          max_installments: maxInstallments,
          days_to_expire: daysToExpire,
          lockModules,
        },
        reference,
        associated_plans: plans.map((item) => item.value),
      } as unknown as Content;

      if (localBanner) data.images = { ...data.images, banner_url: localBanner };
      if (isOnDemand) data.info = { ...data.info, to_sell: true };

      if (cleanPrice) data.info = { ...data.info, price: cleanPrice };

      let localThumbnail = '';

      if (!thumbnailWebFileToUpload) {
        throw new Error('Selecione uma imagem capa web');
      }

      const formDataThumbnail = new FormData();
      formDataThumbnail.append('file', thumbnailWebFileToUpload);
      const { reference: referenceThumbnail } = await uploadFile(formDataThumbnail);
      localThumbnail = referenceThumbnail;

      if (localThumbnail) data.images = { ...data.images, thumbnail_url: localThumbnail };

      await createCourseService(data);

      if (modulesToRemove.length) {
        for (let module of modulesToRemove) {
          try {
            await deleteContent(module.content_id!);
          } catch (error) {
            Swal.fire({
              title: 'Erro!',
              text: 'Ocorreu um erro ao deletar os módulos',
              icon: 'error',
            });
          }
        }
      }
      goToCourses();
      Swal.fire({
        title: 'Sucesso!',
        text: 'Curso criado com sucesso!',
        icon: 'success',
      });
    } catch (error: any) {
      Swal.fire({
        title: 'Erro',
        text: `Houve um erro ao criar o curso. ${
          error?.response?.data?.message || error.message || 'Ocorreu um erro inesperado'
        }`,
        icon: 'error',
      });
    }
  };

  const updateCourse = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      if (checkEmptyString(name)) {
        throw new Error('Informe um nome válido para o curso');
      }

      if (!selectedModules.length) {
        throw new Error('Selecione um ou mais módulos antes.');
      }

      if (!tag.value) {
        throw new Error('Informe a tags deste curso.');
      }

      if (!author.value) {
        throw new Error('Informe o autor deste curso.');
      }

      if (!category.value) {
        throw new Error('Informe a categoria deste curso.');
      }

      if (!reference) {
        throw new Error('Informe um video introdutório.');
      }

      const cleanPrice = Number(price?.replaceAll(',', '.'));

      if (isOnDemand && !cleanPrice) {
        throw new Error('Adicione um valor a este curso.');
      }

      if (isOnDemand && !maxInstallments) {
        throw new Error('Informe um número máximo de parcelas');
      }

      if ((maxInstallments && maxInstallments > 12) || (maxInstallments && maxInstallments <= 0)) {
        throw new Error('Informe um número de 1 a 12');
      }

      let localBanner = banner;

      if (!localBanner) {
        throw new Error('Selecione uma imagem destaque.');
      }

      if (bannerFileToUpload) {
        const formData = new FormData();
        formData.append('file', bannerFileToUpload);
        const { reference: localBannerReference } = await uploadFile(formData);
        localBanner = localBannerReference;
      }

      let localThumbnail = thumbnail;

      if (!localThumbnail) {
        throw new Error('Selecione uma imagem para destaque');
      }

      if (thumbnailWebFileToUpload) {
        const formDataThumbnail = new FormData();
        formDataThumbnail.append('file', thumbnailWebFileToUpload);
        const { reference: referenceThumbnail } = await uploadFile(formDataThumbnail);
        localThumbnail = referenceThumbnail;
      }

      const equalsPlansAssociated = content.associated_plans?.every((value, index) => value === plans[index]?.value);

      if (!equalsPlansAssociated) {
        let choice = false;

        await Swal.fire({
          icon: 'warning',
          title: 'Deseja realmente alterar esse curso de um plano?',
          text: 'Você removeu um ou mais planos deste curso, os usuários que possuírem os planos que foram removidos ficarão sem acesso a este conteúdo!',
          showCancelButton: true,
        }).then((result) => {
          choice = result.isConfirmed;
        });

        if (!choice) return;
      }

      const data: Content = {
        name,
        description: draftToHtml(convertToRaw(descriptionState.getCurrentContent())),
        children: selectedModules.map((module, index) => ({
          child_id: module.content_id,
          position: index + 1,
        })) as unknown as Content[],
        authors: [{ author_id: author.value }] as unknown as Author[],
        categories: [{ category_id: category.value }] as unknown as Category[],
        tags: [{ tag_id: tag.value }] as unknown as Tag[],
        is_highlight: isHighlighted,
        is_recommended: isRecommended,
        type: isOnDemand ? 'on-demand' : 'curso',
        info: {
          ...content.info,
          publish_date: publishDate,
          publish_end_date: publishEndDate,
          free_for_subscribers: freeForSubscribers,
          gamification_points: points,
          only_subscribers: onlySubscribers,
          max_installments: maxInstallments,
          days_to_expire: daysToExpire,
          lockModules,
          price: cleanPrice || undefined,
        },
        reference,
        images: {
          banner_url: localBanner,
          thumbnail_url: localThumbnail,
        },
        associated_plans: plans.map((item) => item.value),
      } as unknown as Content;

      if (cleanPrice) data.info = { ...data.info, price: cleanPrice };
      if (isOnDemand) data.info = { ...data.info, to_sell: true, is_free: false };
      if (!isOnDemand) {
        data.info = { ...data.info, to_sell: false, is_free: true, price: 0 };
      }

      await updateCourseService(courseId, data);

      if (modulesToRemove.length) {
        for (let module of modulesToRemove) {
          try {
            await deleteContent(module.content_id!);
          } catch (error) {
            Swal.fire({
              title: 'Erro!',
              text: 'Ocorreu um erro ao deletar os módulos',
              icon: 'error',
            });
          }
        }
      }

      goToCourses();
      Swal.fire({
        title: 'Sucesso!',
        text: 'Curso editado com sucesso!',
        icon: 'success',
      });
    } catch (error: any) {
      Swal.fire({
        title: 'Erro',
        text: `Houve um erro ao editar o curso. ${
          error?.response?.data?.message || error.message || 'Ocorreu um erro inesperado'
        }`,
        icon: 'error',
      });
    }
  };

  const getContent = useCallback(async () => {
    if (courseId) {
      const contentToEdit: Content = await getContentService(courseId);

      const recoveredPlans = await listPlans();
      const options = recoveredPlans.map((item) => ({ label: item.title, value: item.plan_id }));
      setPlansOptions(options);

      if (contentToEdit && Object.keys(contentToEdit).length) {
        setName(contentToEdit.name);
        setDescriptionText(contentToEdit.description || '');
        setThumbnail(contentToEdit.images.thumbnail_url as string);
        setBanner(contentToEdit.images.banner_url as string);
        setIsHighlighted(contentToEdit.is_highlight);
        setIsOnDemand(contentToEdit.type === 'on-demand');
        setFreeForSubscribers(!!contentToEdit.info.free_for_subscribers);
        setIsRecommended(contentToEdit.is_recommended);
        setPrice(contentToEdit.info.price as string);
        setOnlySubscribers(contentToEdit.info.only_subscribers as boolean);
        setPublishDate(new Date((contentToEdit?.info?.publish_date as Date) || new Date()));
        setPublishEndDate(
          contentToEdit?.info?.publish_end_date ? new Date(contentToEdit?.info?.publish_end_date as string) : undefined
        );
        setContent(contentToEdit);
        setPrice(String(contentToEdit?.info?.price) as string);
        setPoints(contentToEdit?.info?.gamification_points as number);
        setMaxInstallments(contentToEdit?.info?.max_installments as number);
        setDaysToExpire(contentToEdit?.info?.days_to_expire as number);
        setLockModules(contentToEdit?.info?.lockModules as boolean);

        if (contentToEdit?.associated_plans) {
          const matchPlans = contentToEdit?.associated_plans.map((associated) => {
            return options.find((plan) => plan.value === associated)!;
          });

          setPlans(matchPlans);
        }

        const currentMedia = contentToEdit.reference
          ? await getMedia(contentToEdit.reference.split('/')[contentToEdit.reference.split('/').length - 1])
          : undefined;

        if (contentToEdit.reference) {
          setReference(contentToEdit.reference);
        }
        setHasSubtitle((currentMedia?.captions || []).length > 0);

        if (contentToEdit.authors && contentToEdit.authors.length) {
          const selectedAuthors = contentToEdit.authors.map((el: Author) => ({
            label: el.name,
            value: el.author_id,
          }));
          setAuthor(selectedAuthors[0]);
        }

        if (contentToEdit.categories && contentToEdit.categories.length) {
          const selectedCategories = contentToEdit.categories.map((el: Category) => ({
            label: el.title,
            value: el.category_id,
          }));
          setCategory(selectedCategories[0]);
        }

        if (contentToEdit.tags && contentToEdit.tags.length) {
          const selectedTags = contentToEdit.tags.map((el: Tag) => ({
            label: el.title,
            value: el.tag_id,
          }));
          setTag(selectedTags[0]);
        }

        if (contentToEdit.children && contentToEdit.children.length) {
          setSelectedModules(contentToEdit.children);
        }
      }
    }
  }, [courseId]);

  const onSelectContent = (sambaVideosContent: MediaFromResponse) => {
    if (sambaVideosContent) {
      if (sambaVideosContent.files && sambaVideosContent.files.length) {
        const firstFile = sambaVideosContent.files[0];

        if (firstFile) {
          const playerKey = process.env.REACT_APP_PLAYER_KEY;
          const referenceUrl = `${process.env.REACT_APP_PLAYER_INITIAL_URL}/${playerKey}/${sambaVideosContent.id}`;

          setReference(referenceUrl || '');
        }
      }
      setHasSubtitle((sambaVideosContent.captions || []).length > 0);
    }
  };

  const selectContent = () => {
    showModal('Selecionar Conteúdo', <SelectSambaVideosContent onSelectContent={onSelectContent} />);
  };

  const selectSubtitles = () => {
    showModal('Selecionar Legenda', <SelectSubtitles reference={reference} />);
  };

  useEffect(() => {
    (async () => {
      try {
        const authors = await getAllAuthors();
        setAuthorOptions(authors.map((author) => ({ label: author.name, value: author.author_id })));
      } catch {
        Swal.fire({
          title: 'Erro!',
          text: 'Ocorreu um erro ao recuperar os autor',
          icon: 'error',
        });
      }
    })();

    (async () => {
      try {
        const tags = await getAllTags({ is_active: true });
        setTagOptions(tags.map((tag) => ({ label: tag.title, value: tag.tag_id })));
      } catch {
        Swal.fire({
          title: 'Erro!',
          text: 'Ocorreu um erro ao recuperar as tags',
          icon: 'error',
        });
      }
    })();

    (async () => {
      try {
        const categories = await getAllCategories();
        setCategoriesOptions(categories.map((item) => ({ label: item.title, value: item.category_id })));
      } catch {
        Swal.fire({
          title: 'Erro!',
          text: 'Ocorreu um erro ao recuperar as categoria',
          icon: 'error',
        });
      }
    })();

    getContent();
  }, [getContent]);

  useEffect(() => {
    (async () => {
      if (courseId) return;

      const recoveredPlans = await listPlans();
      const options = recoveredPlans.map((item) => ({ label: item.title, value: item.plan_id }));
      setPlansOptions(options);
    })();
  }, [courseId]);

  useEffect(() => {
    if (freeForSubscribers && isOnDemand && onlySubscribers) {
      setIsOnDemand(false);
      setFreeForSubscribers(false);
    }

    if (freeForSubscribers && !isOnDemand) {
      setFreeForSubscribers(false);
    }
  }, [freeForSubscribers, isOnDemand, onlySubscribers]);

  const isEditing = useMemo(() => {
    return !!courseId;
  }, [courseId]);

  return (
    <Container>
      <BreadCrumb
        crumbs={[
          <Link to="/dashboard">Dashboard</Link>,
          <Link to="/courses">Cursos</Link>,
          <span>{!!courseId ? 'Editar' : 'Criar'} Curso</span>,
        ]}
      />
      <DefaultPageTitle>{!!courseId ? 'Editar' : 'Criar'} Curso</DefaultPageTitle>

      <SwitchersContainer>
        <DefaultCreationFormGroup>
          <label>Em destaque</label>
          <Switch>
            <input type="checkbox" checked={isHighlighted} onChange={(e) => setIsHighlighted(!isHighlighted)} />
            <span className="slider round"></span>
          </Switch>
        </DefaultCreationFormGroup>
      </SwitchersContainer>

      <SwitchersContainer>
        <DefaultCreationFormGroup>
          <label>Módulos travados</label>
          <Switch>
            <input type="checkbox" checked={lockModules} onChange={(e) => setLockModules((state) => !state)} />
            <span className="slider round"></span>
          </Switch>
        </DefaultCreationFormGroup>
      </SwitchersContainer>

      <SwitchersContainer>
        {!onlySubscribers && (
          <DefaultCreationFormGroup>
            <label>Curso para vendas "On Demand"</label>
            <Switch>
              <input type="checkbox" checked={isOnDemand} onChange={(e) => setIsOnDemand(e.target.checked)} />
              <span className="slider round"></span>
            </Switch>
          </DefaultCreationFormGroup>
        )}

        {isOnDemand && (
          <DefaultCreationFormGroup>
            <label>Gratuito para assinantes</label>
            <Switch>
              <input
                type="checkbox"
                checked={freeForSubscribers}
                onChange={() => setFreeForSubscribers(!freeForSubscribers)}
              />
              <span className="slider round"></span>
            </Switch>
          </DefaultCreationFormGroup>
        )}
        <DefaultCreationFormGroup>
          <label>Recomendado</label>
          <Switch>
            <input type="checkbox" checked={isRecommended} onChange={(e) => setIsRecommended(!isRecommended)} />
            <span className="slider round"></span>
          </Switch>
        </DefaultCreationFormGroup>

        {!isOnDemand && (
          <DefaultCreationFormGroup>
            <label>Exclusividade para assinantes</label>
            <Switch>
              <input type="checkbox" checked={onlySubscribers} onChange={(e) => setOnlySubscribers(!onlySubscribers)} />
              <span className="slider round"></span>
            </Switch>
          </DefaultCreationFormGroup>
        )}
      </SwitchersContainer>

      <DefaultCreationForm>
        <SwitchersContainer>
          <DefaultCreationFormGroup>
            <label className="required" htmlFor="name">
              Data de publicação
            </label>
            <ReactDatePicker
              id="publishDate"
              selected={new Date(publishDate)}
              showTimeSelect
              dateFormat="dd/MM/yyyy hh"
              timeIntervals={60}
              onChange={(date: Date) => setPublishDate(date)}
              onChangeRaw={(event) => event.preventDefault()}
            />
          </DefaultCreationFormGroup>

          <DefaultCreationFormGroup>
            <label htmlFor="publishEndDate">Fim da publicação</label>
            <ReactDatePicker
              id="publishEndDate"
              selected={publishEndDate}
              showTimeSelect
              dateFormat="dd/MM/yyyy hh"
              isClearable
              timeIntervals={60}
              onChange={(date: Date) => setPublishEndDate(date)}
              onChangeRaw={(event) => event.preventDefault()}
            />
          </DefaultCreationFormGroup>
        </SwitchersContainer>

        {isOnDemand ? (
          <>
            <DefaultCreationFormGroup>
              <label className="required" htmlFor="price">
                Valor
              </label>
              <CurrencyInput
                className="currency-input"
                id="price"
                name="price"
                placeholder="Digite um valor"
                prefix="R$ "
                decimalsLimit={2}
                value={price}
                onValueChange={(value) => {
                  setPrice(value!);
                }}
                decimalSeparator=","
                intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
              />
            </DefaultCreationFormGroup>

            <DefaultCreationFormGroup>
              <label className="required" htmlFor="maxInstallments">
                Parcelamento máximo
              </label>
              <DefaultInput
                id="maxInstallments"
                placeholder="Digite a quantidade máxima de parcelamento deste plano"
                value={maxInstallments}
                type="number"
                onChange={(e) => setMaxInstallments(Number(e.target.value))}
                required
              />
            </DefaultCreationFormGroup>

            <DefaultCreationFormGroup>
              <label className="required" htmlFor="daysToExpire" style={{ width: 300 }}>
                Duração do curso (em dias)
              </label>
              <DefaultInput
                id="daysToExpire"
                placeholder="Digite a quantidade máxima de duração deste curso"
                value={daysToExpire}
                type="number"
                onChange={(e) => setDaysToExpire(Number(e.target.value))}
                required
              />
            </DefaultCreationFormGroup>
          </>
        ) : null}

        <DefaultCreationFormGroup>
          <label htmlFor="points">Pontos da gamificação</label>
          <DefaultInput
            placeholder="Escolha uma pontuação que esse curso irá conceder ao usuário"
            value={points}
            type="number"
            onChange={(e) => setPoints(Number(e.target.value))}
            id="points"
            required
          />
        </DefaultCreationFormGroup>

        <DefaultCreationFormGroup>
          <label className="required" htmlFor="name">
            Nome
          </label>
          <DefaultInput value={name} onChange={(e) => setName(e.target.value)} id="name" required maxLength={250} />
        </DefaultCreationFormGroup>

        <DefaultCreationFormGroup>
          <label className="required" htmlFor="reference">
            Descrição
          </label>
          <DescriptionTextarea
            description={descriptionText}
            editorState={descriptionState}
            setEditorState={setDescriptionState}
          />
        </DefaultCreationFormGroup>

        <SelectContainer>
          <label className="required" htmlFor="tag">
            Tag
          </label>
          <Select
            id="tag"
            options={tagOptions}
            value={tag}
            defaultValue={tag}
            onChange={(option) => setTag(option!)}
            placeholder="Selecione uma ou mais tags"
          />
        </SelectContainer>

        <SelectContainer>
          <label className="required" htmlFor="author">
            Autor(a)
          </label>
          <Select
            id="author"
            options={authorOptions}
            value={author}
            onChange={(option) => setAuthor(option!)}
            placeholder="Selecione um ou mais autor"
            noOptionsMessage={() => 'Sem autor disponíveis'}
          />
        </SelectContainer>

        <SelectContainer>
          <label className="required" htmlFor="categories">
            Categoria
          </label>
          <Select
            id="categories"
            options={categoriesOptions}
            value={category}
            onChange={(option) => setCategory(option!)}
            placeholder="Selecione uma ou mais categoria"
            noOptionsMessage={() => 'Sem categoria disponíveis'}
          />
        </SelectContainer>

        <DefaultCreationFormGroup>
          <label className="required" htmlFor="reference">
            Vídeo Introdutório
          </label>

          <ContentUploadOrSelectContainer>
            <ButtonGroupInline>
              <DefaultButton type="button" onClick={selectContent}>
                Selecionar vídeo
              </DefaultButton>

              {reference && !hasSubtitle && (
                <DefaultButton type="button" onClick={selectSubtitles}>
                  Selecionar Legenda
                </DefaultButton>
              )}
            </ButtonGroupInline>

            {reference ? (
              <iframe title="referenced-video" allowFullScreen src={reference} frameBorder={0}></iframe>
            ) : null}
          </ContentUploadOrSelectContainer>
        </DefaultCreationFormGroup>

        <DefaultCreationFormGroup>
          <label className="required" htmlFor="reference">
            Capa web
          </label>

          <ThumbnailUploadContainer>
            <DefaultButton type="button" onClick={selectThumbnail}>
              Selecionar Imagem de Capa
            </DefaultButton>

            {thumbnail && <CourseThumbnail src={thumbnail} />}
          </ThumbnailUploadContainer>
        </DefaultCreationFormGroup>

        <DefaultCreationFormGroup>
          <label className="required" htmlFor="reference">
            Imagem Destaque
          </label>

          <ThumbnailUploadContainer>
            <div>
              <DefaultButton type="button" onClick={selectBanner}>
                Selecionar imagem
              </DefaultButton>

              {banner ? (
                <DefaultButton
                  type="button"
                  onClick={() => {
                    setBanner('');
                    setBannerFileToUpload(undefined);
                  }}
                >
                  Excluir imagem
                </DefaultButton>
              ) : null}
            </div>
            {banner && <CourseThumbnail src={banner} />}
          </ThumbnailUploadContainer>
        </DefaultCreationFormGroup>

        <SelectContainer>
          <label htmlFor="plans">Curso exclusivo dos planos</label>
          <Select
            id="plans"
            options={plansOptions}
            value={plans}
            onChange={(option) => setPlans(option)}
            placeholder="Selecione um ou mais planos para este curso participar"
            noOptionsMessage={() => 'Sem planos disponíveis'}
            isMulti
          />
        </SelectContainer>

        <DefaultCreationFormGroup>
          <label className="required" htmlFor="reference">
            Módulos
          </label>

          <LessonSelectionContainer>
            {selectedModules.length ? (
              <SortLessonsContainer>
                {selectedModules.map((course, index) => (
                  <div key={course.content_id} className="selected-lessons">
                    <div className="buttons">
                      {index > 0 ? (
                        <>
                          {index + 1}
                          <DefaultButton
                            type="button"
                            title="Subir Aula"
                            className="small white up"
                            onClick={() => reorderSelectedCourses(course, true)}
                          >
                            <AiOutlineUp />
                          </DefaultButton>
                        </>
                      ) : null}
                      {index < selectedModules.length - 1 ? (
                        <>
                          {index === 0 ? index + 1 : null}
                          <DefaultButton
                            type="button"
                            title="Descer Aula"
                            className="small white down"
                            onClick={() => reorderSelectedCourses(course, false)}
                          >
                            <AiOutlineDown />
                          </DefaultButton>
                        </>
                      ) : null}
                    </div>
                    <div className="lesson-title">{course.name}</div>
                    <div className="buttons">
                      <DefaultButton
                        className="small"
                        type="button"
                        onClick={() => editModule(course.content_id!)}
                        title="Editar Módulo"
                      >
                        <AiOutlineEdit size={16} />
                      </DefaultButton>
                      <DetachButton onClick={() => removeSelectedModule(course)} />
                    </div>
                  </div>
                ))}
              </SortLessonsContainer>
            ) : null}
            <DefaultButton type="button" onClick={addModule}>
              Adicionar Módulo
            </DefaultButton>
          </LessonSelectionContainer>
        </DefaultCreationFormGroup>

        <DefaultCreationFormButtonGroup>
          <DefaultButton type="button" className="danger" onClick={goToCourses}>
            Cancelar
          </DefaultButton>
          <DefaultButton onClick={(e) => (!!courseId ? updateCourse(e) : createCourse(e))} className="success">
            Salvar
          </DefaultButton>
        </DefaultCreationFormButtonGroup>
      </DefaultCreationForm>
    </Container>
  );
};

export default CreateAndEditCourse;
