import React, { useEffect, useState } from 'react';
import { AiOutlineSortAscending, AiOutlineSortDescending } from 'react-icons/ai';
import { FaSort } from 'react-icons/fa';
import constants from '../../utils/constants';
import { DefaultTableContainer, Table, Tbody, Td, Th, Thead, Tr } from './style';

interface HeaderConfig {
  propName: string;
  headerLabel: React.ReactElement;
  noWrap?: boolean;
  order?: (items: any, currentOrder: string) => void;
  attributes?: React.StyleHTMLAttributes<HTMLElement>;
}

interface DefaultTableProps {
  headersConfig: HeaderConfig[];
  items: any[];
  emptyListMessage?: string;
}

const DefaultTable: React.FC<DefaultTableProps> = ({
  headersConfig: initialHeadersConfig,
  items: initialItems,
  emptyListMessage,
}) => {
  const [items, setItems] = useState([] as any[]);
  const [currentOrder, setCurrentOrder] = useState('');
  const [headersConfig, setHeadersConfig] = useState([] as HeaderConfig[]);

  const removeAccentuation = (str: string) => {
    const withAccentuation = constants.WITH_ACCENTUATION;
    const withoutAccentuation = constants.WITHOUT_ACCENTUATION;
    let newString = '';
    for (let i = 0; i < str.length; i++) {
      let troca = false;
      for (let a = 0; a < withAccentuation.length; a++) {
        if (str.substr(i, 1) === withAccentuation.substr(a, 1)) {
          newString += withoutAccentuation.substr(a, 1);
          troca = true;
          break;
        }
      }
      if (troca === false) {
        newString += str.substr(i, 1);
      }
    }
    return newString;
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const configureDefaultOrder = () => {
    for (let headerConfig of headersConfig) {
      if (!headerConfig.order) {
        const firstItem = items && items.length && items[0];
        if (firstItem) {
          const itemProp = firstItem[headerConfig.propName];
          if (!itemProp || (itemProp && (typeof itemProp === 'string' || typeof itemProp === 'number'))) {
            headerConfig.order = (localItems: any[], currentOrder: string) => {
              setItems([
                ...items.sort((a, b) => {
                  const isDescendant =
                    currentOrder.includes(headerConfig.propName) &&
                    currentOrder.replace(headerConfig.propName, '').includes('asc');

                  const treatedAProp = a[headerConfig.propName]
                    ? removeAccentuation(`${a[headerConfig.propName]}`.trim().toLocaleLowerCase())
                    : '';
                  const treatedBProp = b[headerConfig.propName]
                    ? removeAccentuation(`${b[headerConfig.propName]}`.trim().toLocaleLowerCase())
                    : '';

                  if (isDescendant) {
                    return treatedAProp < treatedBProp ? 1 : -1;
                  } else {
                    return treatedAProp < treatedBProp ? -1 : 1;
                  }
                }),
              ]);
            };
          }
        }
      }
    }
    setHeadersConfig((headers) => [...headers]);
  };

  useEffect(() => {
    setItems(initialItems);
  }, [initialItems]);

  useEffect(() => {
    setTimeout(() => {
      configureDefaultOrder();
    }, 0.1);
  }, [items, configureDefaultOrder]);

  useEffect(() => {
    if (initialHeadersConfig) {
      setHeadersConfig(initialHeadersConfig);
    }
  }, [initialHeadersConfig]);

  return (
    <DefaultTableContainer>
      <Table>
        <Thead>
          <Tr>
            {headersConfig && headersConfig.length ? (
              headersConfig.map((headerConfig, index) => (
                <Th
                  style={{
                    cursor: headerConfig.order ? 'pointer' : 'normal',
                    ...headerConfig.attributes?.style,
                  }}
                  onClick={() =>
                    headerConfig.order
                      ? (() => {
                          headerConfig.order && headerConfig.order(items, currentOrder);
                          setCurrentOrder(
                            `${headerConfig.propName} ${
                              !currentOrder.replace(headerConfig.propName, '').includes('desc') &&
                              !currentOrder.replace(headerConfig.propName, '').includes('asc')
                                ? 'asc'
                                : currentOrder.replace(headerConfig.propName, '').includes('desc')
                                ? 'asc'
                                : 'desc'
                            }`
                          );
                        })()
                      : undefined
                  }
                  key={index}
                >
                  <span>{headerConfig.headerLabel}</span>
                  {headerConfig.order ? (
                    currentOrder.includes(headerConfig.propName) ? (
                      currentOrder.replace(headerConfig.propName, '').includes('asc') ? (
                        <AiOutlineSortAscending size={16} />
                      ) : (
                        <AiOutlineSortDescending size={16} />
                      )
                    ) : (
                      <FaSort />
                    )
                  ) : null}
                </Th>
              ))
            ) : (
              <></>
            )}
          </Tr>
        </Thead>
        <Tbody>
          {items && items.length ? (
            items.map((item, index) => (
              <Tr key={index}>
                {item && Object.keys(item).length && headersConfig && headersConfig.length ? (
                  headersConfig.map((headerConfig, index) => (
                    <Td
                      key={index}
                      style={{
                        whiteSpace: headerConfig.noWrap ? 'nowrap' : 'normal',
                      }}
                    >
                      {(() => {
                        switch (typeof item[headerConfig.propName]) {
                          case 'boolean':
                            return `${item[headerConfig.propName]}`;
                          default:
                            return item[headerConfig.propName];
                        }
                      })()}
                    </Td>
                  ))
                ) : (
                  <></>
                )}
              </Tr>
            ))
          ) : (
            <Tr>
              <Td style={{ textAlign: 'center' }} colSpan={headersConfig.length}>
                {emptyListMessage || 'Não foram fornecidos itens para a lista'}
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>
    </DefaultTableContainer>
  );
};

export default DefaultTable;
