import React, { useCallback, useEffect, useState } from 'react';
import Select from 'react-select';
import Swal from 'sweetalert2';

import DefaultTable from '../../../components/DefaultTable';
import Pagination from '../../../components/Pagination';
import Content from '../../../models/from-api-response/content';
import { getUserCourses as getUserCoursesService } from '../../../services/users';
import calculatePagination from '../../../utils/calculatePagination';
import contentToUserCourses from './utils/contentToUserCourses';
import { concedeContent, updateContentConceded, deleteContentConceded } from '../../../services/payments';

import { StyledButton } from '../styles';
import { Container, SelectWrapper } from './styles';
import { PaymentType } from '../../../models/from-api-response/user';
import convertPaymentType from './utils/converPaymentType';
import { AxiosError } from 'axios';

type CourseModalProps = {
  userId: string;
  courses: Content[];
  onCloseModal: () => void;
};

interface Options {
  value: string;
  label: string;
}

export interface UserCourses {
  content_id: string;
  course: string;
  acquiredBy: string;
  actions: JSX.Element;
  user_payment_id: string;
}

interface CourseEditing {
  id: string;
  name: string;
}

type ConcedeContentParams = {
  contentId: string;
  userId: string;
  finishDate?: string;
};

const LIMIT_PER_PAGE = 9;

const CoursesModal: React.FC<CourseModalProps> = ({ userId, courses, onCloseModal }) => {
  const [loading, setLoading] = useState(true);
  const [options, setOptions] = useState<Options[]>([]);
  const [userCourses, setUserCourses] = useState<UserCourses[]>([]);
  const [allUserCourses, setAllUserCourses] = useState<UserCourses[]>([]);
  const [date, setDate] = useState('');
  const [contentSelected, setContentSelected] = useState<string>('');
  const [paginationState, setPaginationState] = useState({ page: 1, length: 0, showPage: true });
  const [courseEditing, setCourseEditing] = useState<CourseEditing | null>(null);

  const getUserCourses = useCallback(async () => {
    setLoading(true);
    const { initial, final } = calculatePagination(1, LIMIT_PER_PAGE);

    const response = await getUserCoursesService(userId).finally(() => setLoading(false));

    const userCoursesConverted = contentToUserCourses(response.data || []);

    const courses: UserCourses[] = userCoursesConverted.map((courseConverted) => ({
      ...courseConverted,
      actions: (
        <>
          <StyledButton
            onClick={() => setCourseEditing({ id: courseConverted.content_id, name: courseConverted.course })}
            className="small info"
            disabled={courseConverted.acquiredBy !== convertPaymentType(PaymentType.ALLOW_BY_ADMIN)}
            title="Editar"
          >
            Editar
          </StyledButton>
          <StyledButton
            onClick={() => handleRemoveContent(courseConverted.user_payment_id)}
            className="small danger"
            disabled={courseConverted.acquiredBy !== convertPaymentType(PaymentType.ALLOW_BY_ADMIN)}
            title="Remover"
          >
            Remover
          </StyledButton>
        </>
      ),
    }));

    const coursesSliced = courses.slice(initial, final);

    setAllUserCourses(courses);
    setUserCourses(coursesSliced);
    setPaginationState((state) => ({ ...state, showPage: true, length: Math.ceil(courses.length / LIMIT_PER_PAGE) }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  const handleConcedeContent = useCallback(async () => {
    if (!contentSelected) {
      Swal.fire({
        icon: 'warning',
        text: 'Selecione um curso.',
      });
      return;
    }

    try {
      const params: ConcedeContentParams = {
        contentId: contentSelected,
        userId,
      };

      if (date) params.finishDate = date;

      await concedeContent(params);

      Swal.fire({
        icon: 'success',
        text: 'Acesso ao curso concedido.',
      });
      onCloseModal();
    } catch {
      Swal.fire({
        icon: 'error',
        text: 'Houve um erro ao disponibilizar o curso, tente novamente.',
      });
    }
  }, [userId, date, contentSelected, onCloseModal]);

  const handleUpdateContentConceded = useCallback(async () => {
    if (!courseEditing) {
      Swal.fire({
        icon: 'warning',
        text: 'Selecione um curso para editar.',
      });
      return;
    }

    if (!date || new Date(date) < new Date() || !courseEditing) {
      Swal.fire({
        icon: 'warning',
        text: 'Selecione uma data válida.',
      });
      return;
    }

    try {
      const params: ConcedeContentParams = {
        contentId: courseEditing.id,
        userId,
        finishDate: date,
      };

      await updateContentConceded(params);

      Swal.fire({
        icon: 'success',
        text: 'Data de acesso ao curso atualizada.',
      });
      onCloseModal();
    } catch {
      Swal.fire({
        icon: 'error',
        text: 'Houve um erro ao atualizar, tente novamente.',
      });
    }
  }, [userId, date, onCloseModal, courseEditing]);

  const handleRemoveContent = async (user_payment_id: string) => {
    setLoading(true);
    try {
      await deleteContentConceded(user_payment_id);
      Swal.fire({
        icon: 'success',
        text: 'Acesso ao curso removido.',
      });
      onCloseModal();
    } catch (e: any) {
      if (e?.isAxiosError) {
        Swal.fire({
          icon: 'error',
          text: `Houve um erro ao remover o curso - ${(e ?? ({} as AxiosError))?.response.data.message}`,
        });
      } else {
        Swal.fire({
          icon: 'error',
          text: `Erro inesperado ao remover o curso - por favor tente novamente.`,
        });
      }
      setLoading(false);
    }
  };

  const handleChangePage = (page: number) => {
    setPaginationState((state) => ({ ...state, page }));

    const { initial, final } = calculatePagination(page, LIMIT_PER_PAGE);

    const coursesSliced = allUserCourses.slice(initial, final);

    setUserCourses(coursesSliced);
  };

  useEffect(() => {
    const coursesToOptions = courses
      .map((course) => {
        return {
          value: course.content_id,
          label: course.name,
        };
      })
      .filter((option) => userCourses.find((course) => course.content_id === option.value) === undefined);

    setOptions(coursesToOptions);
  }, [courses, userCourses]);

  useEffect(() => {
    if (userId) getUserCourses();
  }, [getUserCourses, userId]);

  return (
    <Container>
      {loading && <div className="spinner small"></div>}

      {!loading && (
        <div id="table-wrapper">
          <DefaultTable
            headersConfig={[
              {
                headerLabel: <>Curso</>,
                propName: 'course',
              },
              {
                headerLabel: <>Adquirido por</>,
                propName: 'acquiredBy',
              },
              {
                headerLabel: <>Data limite</>,
                propName: 'dateLimit',
              },
              {
                headerLabel: <>Ações</>,
                propName: 'actions',
                noWrap: true,
              },
            ]}
            items={userCourses}
            emptyListMessage="Não foram encontrados cursos para este usuário"
          />
        </div>
      )}

      {paginationState.showPage ? <Pagination totalPages={paginationState.length} setPage={handleChangePage} /> : null}

      <SelectWrapper>
        {courseEditing ? <h1>Editando o curso: {courseEditing.name}</h1> : <h1>Disponibilizar um curso</h1>}

        {courseEditing ? null : (
          <Select
            options={options}
            placeholder="Selecione um curso"
            noOptionsMessage={() => 'Nenhum curso'}
            onChange={(option) => setContentSelected(option ? option.value : '')}
          />
        )}

        <input type="date" value={date} onChange={(event) => setDate(event.target.value)} />

        <StyledButton
          onClick={courseEditing ? handleUpdateContentConceded : handleConcedeContent}
          className="small success"
          title="Confirmar"
        >
          Confirmar
        </StyledButton>

        {courseEditing ? (
          <StyledButton onClick={() => setCourseEditing(null)} className="small warning" title="Cancelar">
            Cancelar
          </StyledButton>
        ) : null}
      </SelectWrapper>
    </Container>
  );
};

export default CoursesModal;
