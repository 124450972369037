import { convertToLive } from '../helpers/convertes/convert-live';
import httpClient from '../http-client';
import { LiveForCreate } from '../models/for-create/lives';
import LiveFromResponse from '../models/from-api-response/lives';
import Live from '../models/lives';

const getAllLives = async () => {
  const sambaVideosLives = (
    await httpClient.get<LiveFromResponse[]>('/lives', {
      params: {
        client_relations: ['content'],
      },
    })
  ).data;

  let convertedList = [] as Live[];
  if (sambaVideosLives) {
    convertedList = sambaVideosLives.map((res) => convertToLive(res));
  }

  return convertedList;
};

const createLive = async (newLive: LiveForCreate) => {
  return httpClient.post<Live>('/lives', newLive).then((res) => res.data);
};

const updateLive = async (liveId: string, newLive: LiveForCreate) => {
  return httpClient.put<Live>(`/lives/${liveId}`, newLive).then((res) => res.data);
};

const getLive = async (liveId: string) => {
  return httpClient.get<Live>(`/lives/${liveId}`).then((res) => res.data);
};

const deleteLive = async (liveId: string) => {
  return httpClient.delete(`/lives/${liveId}`);
};

const changeLiveStatus = async (liveId: string, status: 'ACTIVE' | 'STOPPED') => {
  await httpClient.patch(`/lives/${liveId}/status`, {
    status,
  });
};

const getLivesSv = async () => {
  return httpClient.get(`/lives/samba-videos`);
};

export { getLivesSv, getAllLives, createLive, updateLive, getLive, deleteLive, changeLiveStatus };
