import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 1000px;
  max-width: 100%;
  min-height: 500px;
  max-height: 90vh;
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.3);
  padding: 24px;
  overflow: hidden;

  #table-wrapper {
    width: 100%;
    height: fit-content;
    max-height: 500px;
    overflow-y: auto;
  }
`;

export const SelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  margin-top: 24px;

  > div,
  input,
  button,
  h1 {
    margin-bottom: 8px;
  }

  h1 {
    font-size: 1em;
    margin-bottom: 8px;
  }
`;

export const Overlay = styled.div`
  z-index: 99;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.4;
`;
