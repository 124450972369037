import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { BiEdit } from 'react-icons/bi';
import Swal from 'sweetalert2';

import BreadCrumb from '../../components/BreadCrumb';
import { DefaultPageTitle } from '../../components/DefaultPageTitle';
import DefaultTable from '../../components/DefaultTable';
import Pagination from '../../components/Pagination';
import calculatePagination from '../../utils/calculatePagination';
import { CreateButtonDefaultContainer } from '../../components/CreateButtonDefaultContainer';
import { getAllAscendedMasters, deleteAscendedMaster } from '../../services/ascendedMasters';
import { AscendedMaster } from '../../models/ascendedMaster';
import DefaultButton from '../../components/DefaultButton';
import FilterForm from '../../components/DefaultFilterTable';

import { AscendedMastersContainer, StyledButton } from './styles';

const AscendedMasters: React.FC = () => {
  const history = useHistory();

  const [allAscendedMasters, setAllAscendedMasters] = useState<AscendedMaster[]>([]);
  const [ascendedMasters, setAscendedMasters] = useState<AscendedMaster[]>([]);
  const [paginationState, setPaginationState] = useState({ page: 1, length: 0, showPage: true });

  const getAscendedMasters = useCallback(
    async (pageParam?: number) => {
      try {
        const { initial, final } = calculatePagination(pageParam || paginationState.page);

        const localAscendedMasters = await getAllAscendedMasters();

        if (localAscendedMasters && localAscendedMasters.length) {
          setPaginationState((state) => ({ ...state, length: Math.ceil(localAscendedMasters.length / 15) }));
          setPaginationState((state) => ({ ...state, showPage: true }));
          setAscendedMasters(localAscendedMasters.slice(initial, final));
          setAllAscendedMasters(localAscendedMasters);
          return;
        }

        setAscendedMasters([]);
        setAllAscendedMasters([]);
      } catch {
        Swal.fire({
          title: 'Erro',
          text: 'Erro ao recuperar conteúdo.',
        });
      }
    },
    [paginationState.page]
  );

  const editAscendedMaster = useCallback(
    (ascendedMaster_id: any) => {
      history.push(`edit-ascended-master/${ascendedMaster_id}`);
    },
    [history]
  );

  const removeAscendedMaster = useCallback(
    async (ascendedMasterId: any, pageParam: number) => {
      Swal.fire({
        title: '<strong>Confirmação</strong>',
        html: 'Tem certeza que deseja remover esse Mestre Ascencionado?',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        focusConfirm: false,
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await deleteAscendedMaster(ascendedMasterId);

            Swal.fire({
              icon: 'success',
              title: 'Sucesso!',
              text: 'Mestre Ascencionado excluído com sucesso!',
            });

            await getAscendedMasters(pageParam);
          } catch (error: any) {
            if (error.response.status === 409) {
              Swal.fire({
                icon: 'error',
                title: 'Erro',
                text: error?.response?.data?.message || error.message || 'Ocorreu um erro inesperado',
              });
              return;
            }

            Swal.fire({
              icon: 'error',
              title: 'Erro',
              text: 'Ocorreu um erro desconhecido, tente',
            });
          }
        }
      });
    },
    [getAscendedMasters]
  );

  const contentsToBeShown = useMemo(() => {
    return ascendedMasters.map((ascendedMaster) => ({
      id: ascendedMaster.ascended_master_id,
      name: ascendedMaster.name,
      description: ascendedMaster.description,
      actions: (
        <>
          <StyledButton
            onClick={() => editAscendedMaster(ascendedMaster.ascended_master_id)}
            className="small info"
            title="Editar Mestre Ascencionados"
          >
            Editar
            <BiEdit />
          </StyledButton>
          <StyledButton
            onClick={() => removeAscendedMaster(ascendedMaster.ascended_master_id, paginationState.page)}
            className="small danger"
            title="Deletar Mestre Ascencionados"
          >
            Deletar
            <BiEdit />
          </StyledButton>
        </>
      ),
    }));
  }, [ascendedMasters, editAscendedMaster, removeAscendedMaster, paginationState.page]);

  const createAscendedMaster = async () => {
    history.push('create-ascended-master');
  };

  useEffect(() => {
    getAscendedMasters();
  }, [getAscendedMasters]);

  return (
    <AscendedMastersContainer>
      <BreadCrumb crumbs={[<Link to="/dashboard">Dashboard</Link>, <span>Mestre Ascencionados</span>]} />

      <DefaultPageTitle>Mestre Ascencionados</DefaultPageTitle>

      <CreateButtonDefaultContainer>
        <DefaultButton onClick={createAscendedMaster}>Criar Mestre Ascencionado</DefaultButton>

        <div className="right">
          <FilterForm
            data={allAscendedMasters}
            onFilter={(filtered: AscendedMaster[]) => {
              setAscendedMasters(filtered);
              setPaginationState((state) => ({ ...state, showPage: false }));
            }}
            propName="name"
            clearFilter={() => {
              setPaginationState((state) => ({ ...state, page: 1 }));
              getAscendedMasters(1);
            }}
          />
        </div>
      </CreateButtonDefaultContainer>

      <DefaultTable
        headersConfig={[
          {
            headerLabel: <span>Nome</span>,
            propName: 'name',
          },
          {
            headerLabel: <span>Descrição</span>,
            propName: 'description',
          },
          {
            headerLabel: <span>Ações</span>,
            propName: 'actions',
          },
        ]}
        items={contentsToBeShown}
        emptyListMessage={'Não foram encontrados Mestres Ascencionados cadastrados!'}
      />

      {paginationState.showPage ? (
        <Pagination
          totalPages={paginationState.length}
          setPage={(value) => setPaginationState((state) => ({ ...state, page: value }))}
        />
      ) : null}
    </AscendedMastersContainer>
  );
};

export default AscendedMasters;
