import httpClient from '../http-client';

export interface Prerequisite {
  prerequisite_id: string;
  content_id: string;
  content_prerequisite_id: string;
  required_percentage: number;
}

export interface GetPrerequisitesResponse {
  data: Prerequisite[];
  total: number;
}

export async function getPrerequisites(limit?: number, offset?: number) {
  return httpClient.get<GetPrerequisitesResponse>('/content-prerequisite', {
    params: { limit: limit, offset: offset },
  });
}

export async function createNewPrerequisite(content_id: string, content_prerequisite_id: string, required_percentage: number) {
  return httpClient.post('/content-prerequisite', {
    content_id,
    content_prerequisite_id,
    required_percentage,
  });
}

export async function updatePrerequisite(prerequisite_id: string, content_prerequisite_id: string, required_percentage: number) {
  return httpClient.put(`/content-prerequisite/${prerequisite_id}`, {
    content_prerequisite_id,
    required_percentage,
  });
}

export async function deletePrerequisite(prerequisite_id: string) {
  return httpClient.delete(`/content-prerequisite/${prerequisite_id}`);
}
