import React, { useContext } from 'react';
import ReactDOM from 'react-dom';
import { GrFormClose } from 'react-icons/gr';

import { ModalContext } from '../ModalProvider';

import { ModalCloseButton, ModalContent, ModalContentContainer, ModalHeader, ModalTitle } from './style';

interface Props {
  onClose: () => void;
  title: string;
}

const ModalWithContext: React.FC<Props> = ({ onClose, title, children, ...props }) => {
  const modalNode = useContext(ModalContext);

  return modalNode
    ? ReactDOM.createPortal(
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            background: 'rgba(0, 0, 0, 0.3)',
            width: '100vw',
            height: '100vh',
            zIndex: 4,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <ModalContentContainer>
            <ModalHeader>
              <ModalTitle>{title}</ModalTitle>
              <ModalCloseButton type="button" onClick={onClose}>
                <GrFormClose />
              </ModalCloseButton>
            </ModalHeader>
            <ModalContent>{children}</ModalContent>
          </ModalContentContainer>
        </div>,
        modalNode
      )
    : null;
};

export { ModalWithContext };
