import React, { useEffect, useMemo, useState, useCallback } from 'react';
import ReactDatePicker from 'react-datepicker';
import { Link, useHistory, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';

import BreadCrumb from '../../components/BreadCrumb';
import DefaultButton from '../../components/DefaultButton';
import DefaultCreationForm, {
  DefaultCreationFormButtonGroup,
  DefaultCreationFormGroup,
} from '../../components/DefaultCreationForm';
import DefaultInput from '../../components/DefaultInput';
import { DefaultPageTitle } from '../../components/DefaultPageTitle';
import checkEmptyString from '../../helpers/check-empty-string';
import {
  getLive as getLiveService,
  createLive as createLiveService,
  updateLive as updateLiveService,
  getLivesSv,
} from '../../services/lives';
import { LiveForCreate, LiveForUpdate } from '../../models/for-create/lives';

import { CreateAndEditContentContainer, Select } from './style';
import Live from '../../models/lives';
import { DescriptionTextarea } from '../Terms/Editor';

interface CreateAndEditLiveProps {
  liveId: string;
}

interface Option {
  label: string;
  value: string;
  thumbnail_url?: string;
}

interface IChannel {
  id: string;
  name: string;
  thumbnail_url?: string;
  transmissions: ITransmission[];
}

type ITransmission = Omit<IChannel, 'transmissions'> & { thumbnail_url?: string };

const CreateAndEditLive: React.FC = () => {
  const { liveId } = useParams<CreateAndEditLiveProps>();

  const history = useHistory();

  const fiveMinutesDivisorFutureDate = (date: Date) => {
    const actualDate = date;
    const actualMinutes = actualDate.getMinutes() + 5;
    const actualHours = actualDate.getHours();

    let liveInitialMinutes = actualMinutes;
    let hourIncrement = 0;
    let dayIncrement = 0;
    while ((liveInitialMinutes || 5) % 5 !== 0) {
      if (liveInitialMinutes === 59) {
        liveInitialMinutes = 0;
        hourIncrement++;
        if (actualHours === 23) {
          dayIncrement++;
        }
      } else {
        liveInitialMinutes++;
      }
    }

    actualDate.setDate(actualDate.getDate() + dayIncrement);
    actualDate.setHours(actualDate.getHours() + hourIncrement);
    actualDate.setMinutes(liveInitialMinutes);

    return actualDate;
  };

  const [live, setLive] = useState<Live>({} as Live);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [startDate, setStartDate] = useState<any>(fiveMinutesDivisorFutureDate(new Date()));
  const [finishDate, setFinishDate] = useState<any>();
  const [descriptionState, setDescriptionState] = useState(EditorState.createEmpty());

  const [channelOptions, setChannelOptions] = useState<Option[]>([]);
  const [liveChannelOptions, setLiveChannelOptions] = useState<Option[]>([]);
  const [channels, setChannels] = useState<IChannel[]>([]);

  const [channelSelectedId, setChannelSelectedId] = useState('');
  const [liveChannelSelectedId, setLiveChannelSelectedId] = useState('');

  const validateLive = () => {
    if (checkEmptyString(name)) {
      throw new Error('Informe um nome valido para a live!');
    }

    if (checkEmptyString(description)) {
      throw new Error('Informe uma descrição valida para a live!');
    }

    if (new Date().getTime() >= startDate.getTime()) {
      throw new Error(
        'Informe uma data de inicio valida para a live! Certifique-se que a data seja ao menos 5 minutos superior a data atual!'
      );
    }

    if (finishDate && new Date().getTime() >= finishDate.getTime()) {
      throw new Error(
        'Informe uma data final valida para a live! Certifique-se que a data seja ao menos 5 minutos superior a data de inicio da live!'
      );
    }

    if (finishDate && startDate.getTime() >= finishDate.getTime()) {
      throw new Error('Informe uma data de inicio inferior a data de fim!');
    }
  };

  const createLive = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      validateLive();

      const newLive: LiveForCreate = {
        name,
        description: draftToHtml(convertToRaw(descriptionState.getCurrentContent())),
        start: startDate.toISOString(),
      };

      if (finishDate) {
        newLive.stop = finishDate.toISOString();
      }

      await createLiveService(newLive);

      Swal.fire({
        title: 'Sucesso!',
        text: 'Live criada com sucesso!',
        icon: 'success',
      });

      goToLives();
    } catch (error: any) {
      Swal.fire({
        title: 'Erro',
        text: `Houve um erro ao criar o conteúdo.${
          error?.response?.data?.message || error.message || 'Ocorreu um erro inesperado'
        }`,
        icon: 'error',
      });
    }
  };

  const updateLive = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      validateLive();

      const channel = channels.find((channel) => channel.id === channelSelectedId);

      if (!channel) return;

      const newLive: LiveForUpdate = {
        name,
        description: draftToHtml(convertToRaw(descriptionState.getCurrentContent())),
        channel_sv_id: channelSelectedId,
        live_sv_id: liveChannelSelectedId,
        images: {
          thumbnail_url: liveChannelOptions.find((liveOption) => liveOption.value === liveChannelSelectedId)
            ?.thumbnail_url,
        },
      };

      if (finishDate && live.status === 'SCHEDULED') {
        newLive.stop = finishDate.toISOString();
      }

      if (startDate && live.status === 'SCHEDULED') {
        newLive.start = startDate.toISOString();
      }

      await updateLiveService(liveId, newLive);

      Swal.fire({
        title: 'Sucesso!',
        text: 'Live editada com sucesso!',
        icon: 'success',
      });

      goToLives();
    } catch (error: any) {
      Swal.fire({
        title: 'Erro',
        text: `Houve um erro ao editar a live.${
          error?.response?.data?.message || error.message || 'Ocorreu um erro inesperado'
        }`,
        icon: 'error',
      });
    }
  };

  const goToLives = () => {
    history.push('/lives');
  };

  const getLive = useCallback(async () => {
    let localLive;

    localLive = await getLiveService(liveId);

    setName(localLive.name);
    setDescription(localLive.description);
    setStartDate(localLive.start ? new Date(localLive.start) : fiveMinutesDivisorFutureDate(new Date()));
    setFinishDate(localLive.stop ? new Date(localLive.stop) : '');
    setLive(localLive);
    setLiveChannelSelectedId(localLive.live_sv_id);
    setChannelSelectedId(localLive.channel_sv_id as string);
  }, [liveId]);

  const isEditing = useMemo(() => {
    if (liveId) {
      return true;
    }

    return false;
  }, [liveId]);

  useEffect(() => {
    getLive();
  }, [getLive]);

  useEffect(() => {
    (async () => {
      const channelsSv = await getLivesSv();

      setChannelOptions(channelsSv.data.map((channel: IChannel) => ({ label: channel.name, value: channel.id })));
      setChannels(channelsSv.data);
    })();
  }, []);

  useEffect(() => {
    const selectedChannelObject = channels.find((channel) => channel.id === channelSelectedId);

    setLiveChannelOptions(
      selectedChannelObject?.transmissions.map((transmission) => ({
        label: transmission.name,
        value: transmission.id,
        thumbnail_url: transmission.thumbnail_url,
      })) || []
    );
  }, [channelSelectedId, channels]);

  return (
    <CreateAndEditContentContainer>
      <BreadCrumb
        crumbs={[
          <Link to="/profile">Perfil</Link>,
          <Link to="/lives">Lives</Link>,
          <span>{isEditing ? 'Editar' : 'Criar'} Live</span>,
        ]}
      />

      <DefaultPageTitle>{isEditing ? 'Editar' : 'Criar'} Live com Samba Videos</DefaultPageTitle>

      <DefaultCreationForm>
        <DefaultCreationFormGroup>
          <label className="required" htmlFor="name">
            Nome
          </label>
          <DefaultInput value={name} onChange={(e) => setName(e.target.value)} id="name" required />
        </DefaultCreationFormGroup>

        <DefaultCreationFormGroup>
          <label className="required" htmlFor="description">
            Descrição
          </label>
          <DescriptionTextarea
            description={description}
            editorState={descriptionState}
            setEditorState={setDescriptionState}
          />
        </DefaultCreationFormGroup>

        {isEditing && (
          <>
            <DefaultCreationFormGroup>
              <label className="required" htmlFor="name">
                Canal do Samba vídeos
              </label>
              <Select
                options={channelOptions}
                placeholder="Selecione um canal do Samba vídeos"
                value={channelOptions.find((channel) => channel.value === channelSelectedId) ?? null}
                noOptionsMessage={() => 'Nenhum canal'}
                onChange={(option: { label: string; value: string }) =>
                  setChannelSelectedId(option ? option.value : '')
                }
              />
            </DefaultCreationFormGroup>

            <DefaultCreationFormGroup>
              <label className="required" htmlFor="name">
                Live neste canal do Samba vídeos
              </label>
              <Select
                options={liveChannelOptions}
                value={liveChannelOptions.find((live) => live.value === liveChannelSelectedId) ?? null}
                placeholder="Selecione uma live deste canal"
                noOptionsMessage={() => 'Nenhuma live'}
                onChange={(option: { label: string; value: string }) =>
                  setLiveChannelSelectedId(option ? option.value : '')
                }
              />
            </DefaultCreationFormGroup>
          </>
        )}

        {(isEditing && live.status === 'SCHEDULED') || !isEditing ? (
          <DefaultCreationFormGroup>
            <label className="required" htmlFor="description">
              Início
            </label>
            <ReactDatePicker
              id="startDate"
              selected={startDate}
              showTimeSelect
              dateFormat="dd/MM/yyyy hh:mm"
              timeIntervals={5}
              onChange={(date) => setStartDate(date)}
              onChangeRaw={(event) => event.preventDefault()}
            />
          </DefaultCreationFormGroup>
        ) : null}

        <DefaultCreationFormButtonGroup>
          <DefaultButton type="button" className="danger" onClick={goToLives}>
            Cancelar
          </DefaultButton>
          <DefaultButton onClick={(e) => (isEditing ? updateLive(e) : createLive(e))} className="success">
            Salvar
          </DefaultButton>
        </DefaultCreationFormButtonGroup>
      </DefaultCreationForm>
    </CreateAndEditContentContainer>
  );
};

export default CreateAndEditLive;
