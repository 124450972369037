import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { AiOutlineCheck, AiOutlineClose } from 'react-icons/ai';
import { BiEdit, BiTrash } from 'react-icons/bi';
import { HiOutlineDocumentReport } from 'react-icons/hi';
import { Link, useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';

import BreadCrumb from '../../components/BreadCrumb';
import { CreateButtonDefaultContainer } from '../../components/CreateButtonDefaultContainer';
import DefaultButton from '../../components/DefaultButton';
import { DefaultPageTitle } from '../../components/DefaultPageTitle';
import DefaultTable from '../../components/DefaultTable';
import Content from '../../models/content';
import {
  deleteContent,
  getAllContents,
  activateOrInactivateContent as activateOrInactivateContentService,
  Relations,
} from '../../services/contents';
import Pagination from '../../components/Pagination';
import FilterForm from '../../components/DefaultFilterTable';
import calculatePagination from '../../utils/calculatePagination';

import { ContentsContainer, StyledButton } from './style';
import { showModal } from '../../helpers/modal';
import RangeDateExamReport from '../../components/RangeDateExamReport';
import { Exam } from '../../models/exam';

const Courses: React.FC = () => {
  const history = useHistory();

  const [allContents, setAllContents] = useState([]);
  const [contents, setContents] = useState<Content[]>([]);
  const [paginationState, setPaginationState] = useState({ page: 1, length: 0, showPage: true });

  const getContents = useCallback(
    async (pageParam?: number) => {
      const { initial, final } = calculatePagination(pageParam || paginationState.page);
      const localContents = await getAllContents({
        flag: ['course'],
        type: ['on-demand', 'curso'],
        relations: [Relations.tags, Relations.categories],
      });

      const formattedLocalContents = localContents.data.map((item: Content) => ({
        ...item,
        categories: item?.categories?.[0].title || '',
        tags: item?.tags?.[0].title || '',
      }));

      if (formattedLocalContents && formattedLocalContents.length) {
        setContents(formattedLocalContents.slice(initial, final));
        setAllContents(formattedLocalContents);
        setPaginationState((state) => ({
          ...state,
          length: Math.ceil(formattedLocalContents.length / 15),
          showPage: true,
        }));
      }
    },
    [paginationState.page]
  );

  const editContent = useCallback(
    (courseId: string) => {
      history.push(`edit-course/${courseId}`);
    },
    [history]
  );

  const removeContent = useCallback(
    async (contentId: string, pageParam: number) => {
      Swal.fire({
        title: '<strong>Confirmação</strong>',
        html: `Tem certeza que deseja remover este conteúdo?`,
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        focusConfirm: false,
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await deleteContent(contentId);

            Swal.fire({
              icon: 'success',
              title: 'Sucesso!',
              text: `Conteúdo excluído com sucesso!`,
            }).then((result) => {
              if (result.isConfirmed) {
                history.goBack();
              }
            });
            await getContents(pageParam);
          } catch (error: any) {
            Swal.fire({
              icon: 'error',
              title: 'Erro',
              text: `Erro ao excluir $conteúdo. ${
                error.response && error.response.status === 423
                  ? `Este conteúdo já está associado a uma curso!`
                  : error?.response?.data?.message || error.message || 'Ocorreu um erro inesperado'
              }`,
            });
          }
        }
      });
    },
    [getContents, history]
  );

  const createContent = async () => {
    history.push('create-course');
  };

  const activateOrInactivateContent = useCallback(
    async (contentId: string, activate: boolean, page: number) => {
      Swal.fire({
        title: 'Confirmação',
        text: `Tem certeza que deseja ${activate ? 'ativar' : 'inativar'} este conteúdo?`,
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        focusConfirm: false,
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await activateOrInactivateContentService(contentId, activate);

            getContents(page);
          } catch (error: any) {
            Swal.fire({
              title: 'Erro',
              text: `Erro ao ${activate ? 'ativar' : 'inativar'} conteúdo. ${
                error.response && error.response.status === 423
                  ? `Erro ao inativar $conteúdo!`
                  : error?.response?.data?.message || error.message || 'Ocorreu um erro inesperado'
              }`,
            });
          }
        }
      });
    },
    [getContents]
  );

  const handleOpenReportModal = (exams: Exam[]) => {
    showModal('Selecione um intervalo de datas', <RangeDateExamReport examId={exams[0].exam_id!} />);
  };

  const contentsToBeShown = useMemo(() => {
    return contents.map((content) => ({
      title: content.name,
      isHighlighted: content.is_highlight ? 'Sim' : 'Não',
      isRecommended: content.is_recommended ? 'Sim' : 'Não',
      category: content?.categories,
      tag: content?.tags,
      price: content?.info?.price,
      active: content.is_active ? <AiOutlineCheck /> : <AiOutlineClose />,
      actions: (
        <>
          <StyledButton
            onClick={() => editContent(content.content_id)}
            className="small info"
            title={`Editar conteúdo`}
          >
            Editar
            <BiEdit />
          </StyledButton>
          <StyledButton
            onClick={() => activateOrInactivateContent(content.content_id, !content.is_active, paginationState.page)}
            className="small warning"
            title={`${content.is_active ? 'Inativar' : 'Ativar'} conteúdo`}
          >
            {content.is_active ? (
              <>
                Inativar <AiOutlineClose />
              </>
            ) : (
              <>
                Ativar <AiOutlineCheck />
              </>
            )}
          </StyledButton>
          <StyledButton
            onClick={() => {
              removeContent(content.content_id, paginationState.page);
            }}
            className="small danger"
            title="Excluir conteúdo"
          >
            Deletar
            <BiTrash />
          </StyledButton>
          {content.exams ? (
            <StyledButton
              onClick={() => handleOpenReportModal(content?.exams!)}
              className="small info"
              title="Relatório Questionário"
            >
              Relatório
              <HiOutlineDocumentReport />
            </StyledButton>
          ) : null}
        </>
      ),
    }));
  }, [contents, editContent, removeContent, activateOrInactivateContent, paginationState.page]);

  useEffect(() => {
    getContents();
  }, [getContents]);

  return (
    <ContentsContainer>
      <BreadCrumb crumbs={[<Link to="/dashboard">Dashboard</Link>, <span>Cursos</span>]} />

      <DefaultPageTitle>Cursos</DefaultPageTitle>

      <CreateButtonDefaultContainer>
        <DefaultButton onClick={createContent}>Criar curso</DefaultButton>
        <div className="right">
          <FilterForm
            data={allContents}
            onFilter={(filtered) => {
              setContents(filtered);
              setPaginationState((state) => ({ ...state, showPage: false }));
            }}
            propName="name"
            clearFilter={() => {
              setPaginationState((state) => ({ ...state, page: 1 }));
              getContents(1);
            }}
          />
        </div>
      </CreateButtonDefaultContainer>

      <DefaultTable
        headersConfig={[
          {
            headerLabel: <span>Título</span>,
            propName: 'title',
          },
          {
            headerLabel: <span>Recomendado</span>,
            propName: 'isRecommended',
          },
          {
            headerLabel: <span>Em destaque</span>,
            propName: 'isHighlighted',
          },
          {
            headerLabel: <span>Categoria</span>,
            propName: 'category',
          },
          {
            headerLabel: <span>Tag</span>,
            propName: 'tag',
          },
          {
            headerLabel: <span>Preço</span>,
            propName: 'price',
          },
          {
            headerLabel: <span>Ativo</span>,
            propName: 'active',
          },
          {
            headerLabel: <span>Ações</span>,
            propName: 'actions',
          },
        ]}
        items={contentsToBeShown}
        emptyListMessage="Não foram encontrados conteúdos cadastrados!"
      />

      {paginationState.showPage ? (
        <Pagination
          totalPages={paginationState.length}
          setPage={(page) => setPaginationState((state) => ({ ...state, page }))}
        />
      ) : null}
    </ContentsContainer>
  );
};

export default Courses;
